/* eslint-disable no-unused-expressions */

import { Button, Col, ConfigProvider, Form, Input, InputRef, Row, Space, Table, TableColumnType, notification } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AddPrivilege } from "../../../components/modelpopup/AddPrivilege";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import {
  useGetHimsPrivilegesQuery,
  usePostNewRolePrivilegeMutation,
} from "../../../services/ApiSlice";
import { FilterDropdownProps } from "antd/es/table/interface";

const EditableCell: React.FC<any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <Input type="number" /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PrivilegeList: React.FC<{}> = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  type DataIndex = keyof IPrivilege ;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<IPrivilege > => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7465ad',
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) => record[dataIndex]
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: <i className="fa-solid fa-magnifying-glass "  style={{marginLeft: 5}}/>
  });

  const isEditing = (record: any) => record.rolePrivilegeID === editingKey;

  const edit = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.rolePrivilegeID);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData: any = [...data];
      const index = newData.findIndex((item: any) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) { }
  };

  const { data: allPrivileges, isFetching: isAllPrivilegesLoading } =
    useGetHimsPrivilegesQuery("");
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);

  useEffect(() => {
    if (isRolePrivilege) {
      formInstance.resetFields();
    }
  }, [isRolePrivilege]);

  const columns: any = [
    {
      title: "Privilege Name",
      dataIndex: "privilegeName",
      ...getColumnSearchProps("privilegeName"),
      editable: true,
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (a: { roleName: string }, b: { roleName: string }) =>
        a.roleName?.length - b.roleName?.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      editable: true,
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (
        a: { roleName: string | any[] },
        b: { roleName: string | any[] }
      ) => a.roleName?.length - b.roleName?.length,
    },
    // {
    //   title: "Modified By",
    //   dataIndex: "modifiedBy",
    //   render: (text: string) => <span>{text || "-"}</span>,
    //   sorter: (
    //     a: { roleName: string | any[] },
    //     b: { roleName: string | any[] }
    //   ) => a.roleName?.length - b.roleName?.length,
    // },
    // {
    //   title: "Modified Date",
    //   dataIndex: "modifiedDate",
    //   render: (text: string) => <span>{FormatDateTime(text) || "-"}</span>,
    // },
    // {
    //   title: "Priviliges",
    //   dataIndex: "rolePrivilege",
    //   render: (privileges: any[]) => (
    //     <ul>
    //       {privileges.map(
    //         (privilege: any) =>
    //           privilege.hasPrivilege && (
    //             <li key={privilege.screenID}>{privilege.screenName}</li>
    //           )
    //       )}
    //     </ul>
    //   ),
    //   sorter: (
    //     a: { priviliges: string | any[] },
    //     b: { priviliges: string | any[] }
    //   ) => a.priviliges.length - b.priviliges.length,
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_: any, record: any) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         Edit
    //       </Typography.Link>
    //     );
    //   }
    //   render: (record: { roleID: number }) => (
    //     <Space>
    //       <Link to={`/role-details/${record?.roleID}`}>
    //         <span className="link ">
    //           <i className="las la-pen"></i>
    //         </span>
    //       </Link>
    //       <Link to="#">
    //         <span className="link ">
    //           <i className="las la-trash"></i>
    //         </span>
    //       </Link>
    //     </Space>
    //   ),
    // },
  ];

  const mergedColumns = columns.map(
    (col: { editable: any; dataIndex: string; title: any }) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  );

  const [formInstance] = Form.useForm();

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allPrivileges)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allPrivileges?.filter((privilege: any) =>
        privilege.privilegeName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allPrivileges, searchQuery]);

  const handleSubmit = async (values: any) => {
    const roleExists = allPrivileges?.some(
      (role: { roleName: string }) =>
        role.roleName.toLowerCase().trim() === values.role.toLowerCase().trim()
    );
    if (!roleExists) {
      try {
        await postNewRolePrivilege({ RoleName: values.role }).unwrap();
        // Optionally, refetch data after successful post
        //  await refetch();
      } catch (error) {
        console.error("Failed to post data:", error);
      }
    } else {
      notification["warning"]({
        message: "Role already exists",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  };

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };

  return (
    <>
      {/* <Sidebar />
      <Header /> */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* <Spin spinning={isAllPatientsLoading} fullscreen /> */}

          <Breadcrumbs
            maintitle="Privileges"
            title="Home"
            subtitle="Privilege List"
            // modal="#add_privilege"
            // name="Add Privilege"
          />
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block">
                <Row>
                  <Col>
                    <Input
                      placeholder="Search Privilege"
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <Form form={form} component={false}>
            <Table
           components={{
            body: {
            cell: EditableCell,
             },
             }}
            bordered
            dataSource={filteredPatients}
            columns={mergedColumns}
            rowClassName="editable-row"
            // pagination={false}
            className="table-striped"
             />
            </Form> */}
                <Table
                  loading={isAllPrivilegesLoading}
                  columns={columns}
                  dataSource={filteredPatients}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* <AddPrivilege />
      <DeleteModal Name="Delete Holiday" /> */}
    </>
  );
};

export default PrivilegeList;