import { Avatar, Card, Space, Typography } from "antd";
import { useMemo } from "react";
import { CiLocationOn } from "react-icons/ci";
import { FaUserGraduate } from "react-icons/fa";

const { Meta } = Card;
const { Text } = Typography;

function DoctorCard({
  details,
  selectedDoctor,
  onDoctorChange,
}: {
  details: ISpecialistDetails;
  selectedDoctor: ISpecialistDetails | null;
  onDoctorChange: (doctor: ISpecialistDetails) => void;
}) {
  const isSelected = useMemo(
    () => selectedDoctor?.doctorID === details.doctorID,
    [selectedDoctor, details]
  );
  return (
    <Card
      hoverable
      style={isSelected ? { borderColor: "blue" } : {}}
      onClick={() => onDoctorChange(details)}
    >
      <Meta
        style={{ alignItems: "center" }}
        avatar={
          <Avatar
            size={{ xs: 80, sm: 64, md: 80, lg: 80, xl: 64, xxl: 100 }}
            icon={<i className="fa-regular fa-user" />}
          />
        }
        title={details?.name}
        description={
          <Space direction="vertical" size="small">
            <Space>
              <FaUserGraduate className="speclist-card-img" />
              <Text type="secondary">{details?.speciality}</Text>
            </Space>
            <Space>
              <FaUserGraduate className="speclist-card-img" />
              <Text strong>{details?.qualification}</Text>
            </Space>

            {/* <Text type="secondary">
                <MdPersonAddAlt className="speclist-card-img" />
                {details?.experience}{" "}
                {details?.experience === "1"
                  ? "Year Experience"
                  : "Years Experience"}
              </Text> */}
            <Space>
              <Text type="secondary">
                <i className="fa fa-volume-up" />
              </Text>
              <Text type="secondary">{details?.language}</Text>
            </Space>

            <Space>
              <Text type="secondary">
                <CiLocationOn className="speclist-card-img" />
              </Text>

              <Text type="secondary">{details?.location}</Text>
            </Space>
          </Space>
        }
      />
    </Card>
  );
}

export default DoctorCard;
