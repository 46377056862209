import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import {
  useGetAllRolePrivilegesQuery,
  useGetOutPatientListQuery,
  usePostOutPatientTimeSlotMutation,
} from "../../../services/ApiSlice";
import { Link } from "react-router-dom";
import { Table, notification } from "antd";
import Sidebar from "../../layout/Sidebar";
import Header from "../../layout/Header";

const RoleDetails: React.FC<{}> = (porps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [checkedMap, setCheckedMap] = React.useState<{
    [rolePrivilegeID: number]: boolean;
  }>({});
  const [postTimeSlotData, { isLoading: isPosting, error: postError }] =
    usePostOutPatientTimeSlotMutation();
  const [postUpdatedPrivilegeData] = '';
  const { data: allPrivileges, isLoading: isAllPatientsLoading } =
    useGetAllRolePrivilegesQuery("");

  const privilegeData = allPrivileges
    ?.filter((x: { roleID: string | undefined }) => x.roleID == id)
    ?.at(0)?.rolePrivilege;


  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);

  useEffect(() => {
    if (Array.isArray(privilegeData)) {
      const checkedMap: { [rolePrivilegeID: number]: boolean } = {};
      privilegeData.forEach(({ rolePrivilegeID, hasPrivilege }) => {
        checkedMap[rolePrivilegeID] = hasPrivilege;
      });
      setCheckedMap(checkedMap);
    }
  }, [privilegeData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    record: any
  ) => {
    setCheckedMap((prv) => ({
      ...prv,
      [record.rolePrivilegeID]: e.target.checked,
    }));
  };

  const handleUpdate = async (rolePrivilegeID: any, hasPrivilege: any) => {
    const updatedPrivilegeData = {
      rolePrivilegeID: rolePrivilegeID,
      hasPrivilege: checkedMap[rolePrivilegeID],
    };
    try {
      // await postUpdatedPrivilegeData(updatedPrivilegeData).unwrap();
      // Optionally, refetch data after successful post
      //  await refetch();
      notification["success"]({
        message: "Role is Updated",
        icon: (
          <i className="las la-check-circle" style={{ color: "green" }}></i>
        ),
      });
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const columns: any = [
    {
      title: "Screen ID",
      dataIndex: "screenID",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | Iterable<ReactNode>
          | ReactPortal
          | Iterable<ReactNode>
          | null
          | undefined
      ) => <span>{text || "-"}</span>,
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
    },
    {
      title: "Screen Name",
      dataIndex: "screenName",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | Iterable<ReactNode>
          | ReactPortal
          | Iterable<ReactNode>
          | null
          | undefined
      ) => <span>{text || "-"}</span>,
      sorter: (
        a: { roleName: string | any[] },
        b: { roleName: string | any[] }
      ) => a.roleName.length - b.roleName.length,
    },
    {
      title: "Display",
      render: (record: any) => (
        <span>
          {" "}
          <input
            checked={checkedMap[record.rolePrivilegeID]}
            type="checkbox"
            className="chck"
            onChange={(e) => handleChange(e, record)}
          />
        </span>
      ),
      sorter: (
        a: { priviliges: string | any[] },
        b: { priviliges: string | any[] }
      ) => a.priviliges.length - b.priviliges.length,
    },
    {
      title: "Action",
      key: "action",
      render: (record: { rolePrivilegeID: any; hasPrivilege: any }) => (
        <Link to={""}>
          <span
            className="link "
            onClick={() => {
              handleUpdate(record?.rolePrivilegeID, record?.hasPrivilege);
            }}
          >
            Update
          </span>
        </Link>
      ),
    },
  ];

  return (
    <>
      {/* <Sidebar />
      <Header /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Roles</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/roles">Role Management</Link>
                  </li>
                  <li className="breadcrumb-item active">Roles</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body card-buttons">
                <div className="row">
                  <div className="col-md-12">
                    <div className="firstcol">
                      <div className="firstfield">
                        <div className="inputfield">
                          <p className="lblVal1">Role ID:</p>
                          <label className="lblVal">{id}</label>
                        </div>
                      </div>
                      <div className="firstfield">
                        <div className="inputfield">
                          <p className="lblVal1">Role Name:</p>
                          <label className="lblVal">
                            {privilegeData?.at(0)?.roleName}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={privilegeData}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const ListItem: React.FC<{ data: { [key: string]: string } }> = ({
  data,
}) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <li>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            <div className="text">{value}</div>
          </li>
        ))}
      </div>
    </>
  );
};

export default RoleDetails;
