import { useCallback, useEffect, useMemo, useState } from "react";

type UseListReturn<Element> = [
  Element[],
  {
    set: (l: Element[]) => void;
    push: (element: Element) => void;
    remove: (index: number) => void;
    insert: (index: number, element: Element) => void;
    update: (index: number, element: Element) => void;
    clear: () => void;
    filter: (callBack: (Element: Element) => boolean) => void;
    append: (elements: Element[]) => void;
  }
];

export const useList = <Element>(
  defaultList: Element[] = []
): UseListReturn<Element> => {
  const [list, setList] = useState<Element[]>(defaultList);

  const set = useCallback((l: Element[]) => {
    setList(l);
  }, []);

  const push = useCallback((element: Element) => {
    setList((l) => [...l, element]);
  }, []);

  const remove = useCallback((index: number) => {
    setList((l) => [...l.slice(0, index), ...l.slice(index + 1)]);
  }, []);

  const insert = useCallback((index: number, element: Element) => {
    setList((l) => [...l.slice(0, index), element, ...l.slice(index)]);
  }, []);

  const update = useCallback((index: number, element: Element) => {
    setList((l) => l.map((e, i) => (i === index ? element : e)));
  }, []);

  const clear = useCallback(() => setList([]), []);

  const filter = useCallback(
    (callBack: (Element: Element) => boolean) =>
      setList((prvList) => prvList.filter(callBack)),
    []
  );

  const append = useCallback((elements: Element[]) => {
    setList((prvList) => [...prvList, ...elements]);
  }, []);

  const listActions = useMemo(
    () => ({ set, push, remove, insert, update, clear, filter, append }),
    []
  );

  return [list, listActions];
};

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
