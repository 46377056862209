import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Modal,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useDeleteDrugMutation,
  useGetBedDetailsQuery,
  useGetIPRoomDetailsQuery,
  useGetPrescriptionByIDQuery,
  useGetRoomDetailsQuery,
  usePostIPRoomDetailsByTCIPIDMutation,
} from "../../../../services/ApiSlice";
import { Link } from "react-router-dom";

const { Title } = Typography;
const { confirm } = Modal;

function Prescription({
  patientID,
  TCIPID,
}: {
  patientID: string;
  TCIPID: string;
}) {
  const [formInstance] = Form.useForm<any>();
  const user = useSelector(selectUser);
  const selectedRoom = Form.useWatch(["roomID"], formInstance);
  const Rooms = useGetRoomDetailsQuery("");
  const Beds = useGetBedDetailsQuery(selectedRoom ?? skipToken);
  const RoomDetails = useGetIPRoomDetailsQuery(TCIPID ?? skipToken);
  const GetPrescription = useGetPrescriptionByIDQuery(TCIPID ?? skipToken);
  const [postRoomDetails, PostRoomDetails] =
    usePostIPRoomDetailsByTCIPIDMutation();
  const [deleteDrug, DeleteDrug] = useDeleteDrugMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDownload = (url: string) => {
    window.open(url);
  };

  const handleOk = async () => {
    try {
      const values = await formInstance.validateFields();
      console.log("values", values);
      postRoomDetails({
        ...values,
        createdUserID: user?.himsEmployeeID,
        tcipid: TCIPID,
      });
    } catch (error) {
      console.error("Failed to post data ", error);
    }
    setIsModalOpen(false);
  };

  const handleCancle = () => {
    setIsModalOpen(false);
    formInstance.resetFields();
  };

  const handleDelete = (record: any) => {
    confirm({
      title: "Do you want to cancel the request ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteDrug({ drugID: record?.patientDrugID });
      },
    });
  };

  useEffect(() => {
    if (PostRoomDetails.isSuccess) {
      handleCancle();
    }
  }, [PostRoomDetails]);

  useEffect(() => {
    formInstance.setFieldValue("bedID", null);
  }, [selectedRoom]);

  const RoomNo = useMemo(
    () =>
      Array.isArray(Rooms.data)
        ? Rooms.data.map((item) => ({
          value: item?.roomID,
          label: item.roomNo,
        }))
        : [],
    [Rooms]
  );

  const BedsList = useMemo(
    () =>
      Array.isArray(Beds.data)
        ? Beds.data.map((item) => ({
          value: item?.bedID,
          label: item.bedNo,
        }))
        : [],
    [Beds]
  );
  const currentDate = dayjs();
  const admissionCols: any = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text: string) => FormatDateTime(text),
      sorter: true,
    },
    {
      title: "Added By",
      dataIndex: "prescribedBy",
      sorter: true,
    },

    {
      title: "Doctor Visit ID",
      dataIndex: "doctorVisitID",
      render: (doctorVisitID: any) => (
        <Link
           to={""}          
          // to={`/inpatient-notes` { state: { activeKey: "2" } }}
        >
          {doctorVisitID || "-"}
        </Link >
      ),
      // render: // TODO: add link to doctor visit
    },
    // {
    //   title: "QTY",
    //   dataIndex: "qty",
    //   sorter: true,
    // },
    //   {
    //     title: "Brand",
    //     dataIndex: "entryDate",
    //     render: (text: string) => FormatDateTime(text),
    //     sorter: true,
    //   },
    //   {
    //     title: "Prescribed By",
    //     dataIndex: "exitDate",
    //     render: (dischargeDate: string) => (
    //       <span>{FormatDateTime(dischargeDate) || "-"}</span>
    //     ),
    //     sorter: true,
    //   },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button
          type="link"
          onClick={() => handleDownload(record.blobUrl)}
        >
          {record.blobUrl ? "Download Prescription" : null}
        </Button>
      ),
      sorter: true,
    },
  ];
  return (
    <Card>
      <Spin spinning={DeleteDrug.isLoading} fullscreen />

      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Select Room
          </Title>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancle}
        maskClosable={false}

      // cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form
          form={formInstance}
          disabled={PostRoomDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="admissionDate"
            label="Admission Date"
            initialValue={currentDate}
          >
            <DatePicker style={{ width: "100%" }} showTime={true} />
          </Form.Item>
          <Form.Item
            name="roomID"
            label="Room No"
            rules={[{ required: true, message: "Please Enter Room No" }]}
          >
            <Select
              placeholder="Room No"
              style={{ width: "100%" }}
              options={RoomNo}
              loading={Rooms.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>

          <Form.Item
            name="bedID"
            label="Bed No"
            rules={[{ required: true, message: "Please Enter Bed No" }]}
          >
            <Select
              placeholder="Bed No"
              style={{ width: "100%" }}
              options={BedsList}
              loading={Beds.isFetching}
              showSearch
              optionFilterProp="label"
              disabled={!selectedRoom}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        loading={RoomDetails.isFetching}
        pagination={RoomDetails.data?.length < 10 ? false : undefined}
        columns={admissionCols}
        dataSource={GetPrescription.data}
      // rowKey={(record) => record.id}
      // title={() => (
      //     <Row gutter={24} justify="end">
      //         <Col>
      //             <Button onClick={showModal} type="primary">
      //                Add Medicine
      //             </Button>
      //         </Col>
      //     </Row>
      // )}
      />
    </Card>
  );
}

export default Prescription;
