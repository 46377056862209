import { Card, Descriptions, DescriptionsProps } from "antd";
import { useMemo } from "react";

const formatDate = (dateString: string | number) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

const fields: {
  key: keyof IPatientDishcharge;
  label: string;
  format?: (value: IPatientDishcharge[keyof IPatientDishcharge]) => string;
}[] = [
  { key: "firstName", label: "Name" },
  { key: "age", label: "Age", format: (value) => `${value} yrs` },
  { key: "gender", label: "Gender" },
  { key: "location", label: "Location" },
  // { key: "tcipid", label: "HOUTCIP" },
  { key: "uhid", label: "UHID" },
];

type Props = {
  patientDetails: IPatientDishcharge;
};

// function PatientCard({ patientDetails }: Props) {
//   const items: DescriptionsProps["items"] = useMemo(() => {
//     if (patientDetails) {
//       return fields.map((field) => ({
//         ...field,
//         children: field.format
//           ? field.format(patientDetails[field.key])
//           : patientDetails[field.key],
//       }));
//     }
//     return [];
//   }, [patientDetails]);

function LabPatientCard({ patientDetails }: Props) {
  const items: DescriptionsProps["items"] = useMemo(() => {
    if (patientDetails) {
      return fields.map((field) => ({
        ...field,
        children: field.format ? (
          field.format(patientDetails[field.key])
        ) : field.key === "uhid" ? (
          <a
            className="hyperlink"
            href={`/patients/${patientDetails?.patientID}`}
          >
            {patientDetails[field.key]}
          </a>
        ) : field.key === "tcipid" ? (
          <a
            className="hyperlink"
            href={`/inpatient-details/${patientDetails[field.key]}`}
          >
            {patientDetails[field.key]}
          </a>
        ) : (
          patientDetails[field.key]
        ),
      }));
    }
    return [];
  }, [patientDetails]);

  return (
    // <Card bordered={false}>
    <Descriptions items={items} />
    // </Card>
  );
}

export default LabPatientCard;
