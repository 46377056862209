import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { IPRIVILEGE, Role } from "../models/PrivilegeRoles";
import { selectUser } from "../reducers/UserSlice";

export const useRoleBasedVisible = () => {
  const user = useSelector(selectUser);
  return useCallback(
    (roles: Role[]) => !!user?.himsRoleID && roles.includes(user?.himsRoleID),
    [user]
  );
};

export const useIsAllowed = () => {
  const user = useSelector(selectUser);

  const privilegeMap = useMemo(() => {
    const privileges: IPrivilege[] = user?.privileges ?? [];

    const privilegeMap = {} as { [privilegeID in IPRIVILEGE]: boolean };

    for (const { privilegeID, hasPrivilege } of privileges) {
      privilegeMap[privilegeID] = hasPrivilege;
    }

    return privilegeMap;
  }, [user]);

  return useCallback(
    (privilegeID: IPRIVILEGE) => privilegeMap[privilegeID],
    [privilegeMap]
  );
};
