import { Avatar, Button, Card, Divider, Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaGraduationCap } from "react-icons/fa";
import { GiStarsStack } from "react-icons/gi";

import { FaUserGraduate } from "react-icons/fa";
import { MdPersonAddAlt } from "react-icons/md";
import { useParams } from "react-router";
const { Meta } = Card;
const { Text, Paragraph } = Typography;

function DoctorCard({ details }: { details: ISpecialistDetails }) {
  return (
    <Card
    // actions={[
    //   <Space
    //     split={<Divider type="vertical" />}
    //     align="center"
    //     style={{ justifyContent: "space-evenly", width: "100%" }}
    //   >
    //     <Button
    //       type="primary"
    //       style={{
    //         backgroundColor: details?.availabilityStatus ? "green" : "red",
    //       }}
    //     >
    //       {details?.availabilityStatus ? "Available" : "Not Available"}
    //     </Button>

    //     <Link to={details.doctorID}>
    //       <Button type="primary">Book Appointment</Button>
    //     </Link>
    //   </Space>,
    // ]}
    >
      <Meta
        style={{ alignItems: "center" }}
        avatar={
          <Avatar
            size={{ xs: 80, sm: 64, md: 80, lg: 80, xl: 64, xxl: 100 }}
            icon={<i className="fa-regular fa-user" />}
          />
        }
        title={details.name}
        description={
          <Space direction="vertical" size="small">
            <Space>
              <FaUserGraduate className="speclist-card-img" />
              <Text>{details?.speciality}</Text>
            </Space>

            <Space>
              <FaUserGraduate className="speclist-card-img" />
              <Text strong>{details?.qualification}</Text>
            </Space>

            {/* <Text type="secondary">
              <MdPersonAddAlt className="speclist-card-img" />
              {details.experience}{" "}
              {details.experience === "1"
                ? "Year Experience"
                : "Years Experience"}
            </Text> */}
            <Text type="secondary">
              {" "}
              <i className="fa fa-volume-up" /> {details.language}
            </Text>
            <Text type="secondary">
              {" "}
              <CiLocationOn className="speclist-card-img" />
              {details.location}
            </Text>
            {/* <Text type="secondary">
              <HiOutlineBuildingOffice2 className="speclist-card-img" /> Health
              On Us
            </Text> */}
          </Space>
        }
      />
    </Card>
  );
}

export default DoctorCard;
