//IP Vitals.tsx

import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Col, Form, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useAddOrUpdateIPVitalsMutation,
  useGetVitalsByTCIPIDQuery,
  useLazyGetVitalsDetailsByVitalsIDQuery,
} from "../../../../services/ApiSlice";
import { isNumber } from "../../../../util";
import {
  EVitalStatus,
  IVitalConfig,
} from "../../Dashboard/Vitals/VitalCard.Vitals";
import VitalsForm from "../../Dashboard/Vitals/VitalsForm";

type Props = {
  patientData: IPatientDetails;

  TCIPID: string;
  VitalsID: string;
};

function IPVitals({ patientData, TCIPID, VitalsID }: Props) {
  const { id }: any = useParams();
  const isRoleBasedVisible = useRoleBasedVisible();
  // const { data: vitals } = useGetPatientVitalsQuery(
  //   "50905B3D-F8E7-47A1-AC0D-9A2EE20E78CC "
  // patientData?.patientID ?? skipToken
  // );

  // const { data: vitals } = useGetIPVitalsQuery(TCIPID ?? skipToken);

  const { data: vitals } = useGetVitalsByTCIPIDQuery(TCIPID ?? skipToken);
  console.log("vitalssss", vitals);

  const [getVital, GetVital] = useLazyGetVitalsDetailsByVitalsIDQuery();

  // const getVitals =useLazyGetVitalsDetailsByVitalsIDQuery();

  const generateVitalsValues = (
    values: {
      vitalDDSID: number;
      value: string;
    }[]
  ) => {
    const vitals: { [key: string]: string } = {};
    values.forEach(({ vitalDDSID, value }) => {
      vitals[vitalDDSID] = value;
    });
    return vitals;
  };

  useEffect(() => {
    if (GetVital.data) {
      viewFormInstance.setFieldsValue(generateVitalsValues(GetVital.data));
    }
  }, [GetVital]);

  const vitalsConfig: IVitalConfig[] = [
    {
      title: "Height",
      key: "98",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "cm",
      inputs: 1,
    },
    {
      title: "Weight",
      key: "99",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "kg",
      inputs: 1,
    },
    {
      title: "BMI",
      key: "100",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "kg/m2",
      dependencies: ["98", "99"],
      inputs: 0,
      formatter: (values) =>
        isNumber(values["98"]) && isNumber(values["99"])
          ? BMICalculator(values["98"], values["99"])
          : "",
    },
    {
      title: "Pulse",
      key: "101",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "bpm",
      inputs: 1,
    },
    {
      title: "Temp",
      key: "102",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "F",
      inputs: 1,
    },
    {
      title: "Respiration",
      key: "103",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "brpm",
      inputs: 1,
    },
    {
      title: "Systolic",
      key: "104",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "mmHg",
      inputs: 2,
    },
    {
      title: "Diastolic",
      key: "107",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "mmHg",
      inputs: 2,
    },
    {
      title: "GRBS",
      key: "105",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "mg/dl",
      inputs: 1,
    },
    {
      title: "SpO2",
      key: "108",
      statusFun: (value) => EVitalStatus.SAFE,
      unit: "%",
      inputs: 1,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null);

  const user = useSelector(selectUser);
  const [formInstance] = Form.useForm();
  const [viewFormInstance] = Form.useForm();
  const [addOrUpdateVitals, AddOrUpdateVitals] =
    useAddOrUpdateIPVitalsMutation();

  useEffect(() => {
    if (AddOrUpdateVitals.isSuccess) {
      setIsModalOpen(false);
      setIsViewOpen(false);
      setIsEditOpen(false);
    }
  }, [AddOrUpdateVitals]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showViewModal = (record: any) => {
    getVital(record?.vitalsID);
    setIsViewOpen(true);

    console.log("record", record);
  };

  const showEditModel = () => {
    setIsEditOpen(true);
  };

  const onSubmit = async () => {
    try {
      const values = await formInstance.validateFields();

      // const commonPayload = {
      //   modifiedBy: user?.himsEmployeeID ?? "",
      //   createdBy: user?.himsEmployeeID ?? "",
      //   tcipid: TCIPID,
      //   // vitalID: selectedRecord?.vitalID,
      //   recordedDate: dayjs().toISOString(),
      //   createDate: dayjs().toISOString(),
      //   modifiedDate: dayjs().toISOString(),
      //   isActive: true,
      // };

      // addOrUpdateVitals(generateVitalsPayload(values, commonPayload));

      const payload: any = {
        createBy: user?.himsEmployeeID,
        modifiedBy: user?.himsEmployeeID,
        categoryID: 0,
        categoryRefID: TCIPID,
        vitalsJson: generateVitalsPayload(values),
      };
      addOrUpdateVitals(payload);
    } catch (error) {
      console.log("error");
    }
  };

  const handleOk = () => {
    onSubmit();
  };

  const handleCancle = () => {
    setIsModalOpen(false);
    setIsViewOpen(false);
    setIsEditOpen(false);
    formInstance.resetFields();
    viewFormInstance.resetFields();
  };

  const dataTest = [
    {
      date: new Date(),
      pulse: "72 bpm",
      systolic: "80 bpm",
      diastolic: "120/80 mm Hg",
      createdBy: "Dijili Saritha",
    },
    {
      date: new Date(),
      pulse: "80 bpm",
      systolic: "85 bpm",
      diastolic: "120/80 mm Hg",
      createdBy: "Madhuri",
    },
  ];

  const columns: any = [
    {
      title: "ID",
      dataIndex: "vitalsID",
      // render: (text: any) => FormatDateTime(text),
      render: (text: string, record: any) => {
        const handleIdClick = () => {
          getVital(record?.vitalsID);
          setIsViewOpen(true);
        };
        return (
          <span className="hyperlink" onClick={handleIdClick}>
            {text || "-"}
          </span>
        );
        // sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        //   a.tcipid.length - b.tcipid.length,
      },
    },
    {
      title: "Date",
      dataIndex: "createDate",
      // render: (text: any) => FormatDateTime(text),
      render: (text: string) => FormatDateTime(text),
      // sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
      //   a.tcipid.length - b.tcipid.length,
    },

    {
      title: "Recorded   By",
      dataIndex: "modifiedBy",
      // render: (text: string) => <span>{text || "-"}</span>,
    },

    // {
    //   title: "Status",
    //   dataIndex: "",
    //   render: (record: any) => (
    //     <div>
    //       {record.requestStatus !== "CANCELLED" && (
    //         <>
    //           <Space>
    //             {/* <span className="link">View</span> */}

    //             <Button
    //               htmlType="submit"
    //               type="primary"
    //               // disabled={patientsByMobile.isFetching}
    //               style={{ backgroundColor: "#7465ad", float: "right" }}
    //               // onClick={addVitals}
    //               onClick={() => showViewModal(record)}
    //             >
    //               View
    //             </Button>
    //             {/* {isRoleBasedVisible([Role.Admin, Role.Nurse]) && (
    //               <Button
    //                 htmlType="submit"
    //                 type="primary"
    //                 // disabled={patientsByMobile.isFetching}
    //                 style={{ backgroundColor: "#7465ad", float: "right" }}
    //                 // onClick={addVitals}
    //                 onClick={showEditModel}
    //               >
    //                 Edit
    //               </Button>
    //             )} */}
    //             {/*
    //             <span className="link">Edit</span> */}
    //           </Space>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   // render: (text: string | number | Date) => <span></span>,
    // },
  ];

  return (
    <>
      <Modal
        title="Add Vitals"
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancle}
        // cancelButtonProps={{ style: { display: "none" } }}
        // okButtonProps={{ style: { display: "none" } }}
        width="1000px"
        zIndex={2000}
        maskClosable={false}
      >
        <VitalsForm
          isEditable={isRoleBasedVisible([Role.Admin, Role.Nurse])} // TODO: add status check
          patientId={patientData?.patientID}
          TCIPID={""}
          formInstance={formInstance}
          loading={AddOrUpdateVitals.isLoading}
        />
      </Modal>

      <Modal
        title="Vitals Details"
        open={isViewOpen}
        onCancel={handleCancle}
        // cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width="1000px"
        zIndex={2000}
        cancelText="Close"
        maskClosable={false}
      >
        <VitalsForm
          // isEditable={isRoleBasedVisible([Role.Admin, Role.Nurse])} // TODO: add status check
          isEditable={false}
          patientId={patientData?.patientID}
          TCIPID={""}
          formInstance={viewFormInstance}
          loading={GetVital.isLoading}
        />
      </Modal>

      {/* <Modal
        title="Edit Vitals"
        open={isEditOpen}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancle}
        // cancelButtonProps={{ style: { display: "none" } }}
        // okButtonProps={{ style: { display: "none" } }}
        width="1000px"
        zIndex={2000}
        maskClosable={false}
      >
        <VitalsForm
          isEditable={isRoleBasedVisible([Role.Admin, Role.Nurse])} // TODO: add status check
          patientId={patientData?.patientID}
          TCIPID={""}
          formInstance={formInstance}
          loading={AddOrUpdateVitals.isLoading}
        />
      </Modal> */}

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              pagination={columns?.length > 10 ? undefined : false}
              //   loading={getPaymentdetails}
              columns={columns}
              // bordered
              dataSource={vitals}
              className="table-striped"
              // rowKey={(record) => record.value}
              title={() => (
                <Row gutter={24} justify="end">
                  {isRoleBasedVisible([
                    Role.Admin,
                    Role.Nurse,
                    Role.Doctor,
                  ]) && (
                    <Col>
                      <Button onClick={showModal} type="primary">
                        Add
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default IPVitals;

// const generateVitalsPayload = (
//   values: { [key: string]: string },
//   commonPayload: { [key: string]: string | number | boolean }
// ) => {
//   const vitals: any[] = [];
//   // const vitals: IVital[] = [];
//   for (const key in values) {
//     vitals.push({
//       vitalDDSID: parseInt(key),
//       value: values[key],
//       ...commonPayload,
//     });
//   }
//   return vitals;
// };

const generateVitalsPayload = (values: { [key: string]: string }) => {
  const vitals: IVital[] = [];
  for (const key in values) {
    vitals.push({
      vitalDDSID: parseInt(key),
      value: values[key],
    });
  }
  return vitals;
};

const BMICalculator = (weight: number, height: number) => {
  let BMI = (weight / height / height) * 10000;
  return BMI.toFixed(2);
};
