import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import { Role } from "../models/PrivilegeRoles";
import { selectUser } from "../reducers/UserSlice";
import { RootState } from "../store";

interface IAuthGuardProps {
  component: React.ComponentType<any>;
}

const PatientListGuard: React.FC<IAuthGuardProps> = ({
  component: Component,
  ...rest
}) => {
  const { accounts, inProgress, instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => selectUser(state));

  const hasPrivileges = (id: number) => {
    return [Role.Admin, Role.Mod].includes(id);
  };

  useEffect(() => {
    // if (inProgress === Constants.LOGIN || inProgress === Constants.HANDLEREDIRECT) {
    //    <div>Loading...</div>;
    // }

    // if (!accounts || accounts.length === 0) {
    //     navigate("/")
    // }
    // if (user) {
    //     const isAuthorized = hasPrivileges(user?.himsRoleID as number);
    //     if (!isAuthorized) {
    //       navigate('/');
    //     }
    //   } else {
    //     navigate('/');
    //   }
    if (!user?.himsRoleID) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Component
      {...rest}
      render={(props: JSX.IntrinsicAttributes) => <Component {...props} />}
    />
  );
};

export default PatientListGuard;
