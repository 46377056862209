import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { DischargeHeader } from "../../../Routes/ImagePath";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetDischargeSummaryQuery,
  useGetDropdownQuery,
  useGetPatientDischargeSummaryQuery,
  useGetVisitTypesQuery,
  useLazyGetDownloadDischargeSummaryByPatientIDQuery,
  usePostDischargeMutation,
} from "../../../services/ApiSlice";
import PatientCard from "./PatientDetailsCard";

interface Props {
  tcPID: any;
}

const { Text } = Typography;

const DischargeSummary: React.FC<Props> = (porps) => {
  let clinicalSummaries = [];
  let investigations = [];
  let treatmentProgress = [];
  let treatmentGiven = [];
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { id }: any = useParams();
  const { data } = useGetDischargeSummaryQuery(id);
  const [postDischarge, PostDischarge] = usePostDischargeMutation();
  const DischargeType = useGetDropdownQuery("DischargeType");
  const componentRef = useRef<any>(null);
  const { data: patientDetails, isLoading } = useGetDischargeSummaryQuery(id);
  const DischargeSummary: any = useGetPatientDischargeSummaryQuery(
    id ?? skipToken
  );
  const [
    getPrintData,
    {
      data: printData,
      isFetching: isPrintDataFetching,
      isSuccess: isPrintDataSuccess,
      isLoading: downloadPrintData,
    },
  ] = useLazyGetDownloadDischargeSummaryByPatientIDQuery();

  const [formInstance] = Form.useForm();

  try {
    clinicalSummaries = JSON.parse(DischargeSummary.data?.clinicalSummary);
    investigations = JSON.parse(DischargeSummary.data?.investigationDone);
    treatmentProgress = JSON.parse(DischargeSummary.data?.treatmentProgress);
    treatmentGiven = JSON.parse(DischargeSummary.data?.treatmentgiven);
  } catch (error) {
    console.error("Error parsing data:", error);
  }

  const dischargeType = useMemo(
    () =>
      Array.isArray(DischargeType.data)
        ? DischargeType.data.map((item) => ({
          value: item?.dropDownSetID,
          label: item.dropDownValue,
        }))
        : [],
    [DischargeType]
  );

  const [formData, setFormData] = useState<any>({
    patientDishchargeSummaryID: "",
    patientID: "",
    patientName: "",
    age: "data.age",
    gender: "data.gender",
    admissionDate: "",
    dateOfOperation: "",
    dischargeDate: "",
    location: "",
    uhid: "",
    tcipid: "",
    tcopid: "",
    finalDiagnosis: "",
    clinicalSummary: "",
    investigationDone: "",
    treatmentGivenHospital: "",
    progressInHospital: "",
  });

  const location = useLocation();
  const { TextArea } = Input;
  const incomingFormData = location.state?.formData;

  useEffect(() => {
    if (DischargeSummary.data) {
      formInstance.setFieldsValue({
        ...DischargeSummary.data,
        admissionDate: DischargeSummary.admissionDate
          ? dayjs(DischargeSummary.admissionDate)
          : undefined,
        dischargeDate: DischargeSummary.dischargeDate
          ? dayjs(DischargeSummary.dischargeDate)
          : undefined,
      });
    }
  }, [DischargeSummary.data]);

  useEffect(() => {
    if (PostDischarge.isSuccess) {
      if (PostDischarge.data) {
        notification["success"]({
          message: "Successfully saved",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
        formInstance.resetFields();
        navigate(`/inpatient-details/${id}`, { state: { activeKey: "8" } });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    }
    if (PostDischarge.isError) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [PostDischarge]);

  useEffect(() => {
    if (printData) {
      window.open(printData);
    }
  }, [printData]);

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      // Check if formData is present in location state
      if (incomingFormData) {
        setFormData(incomingFormData); // Populate formData with incoming data
      } else {
        setFormData(data); // Use data from API call
      }
    }
  }, [id, incomingFormData, data]);

  const handleCancel = () => {
    navigate(`/inpatient-details/${id}`, { state: { activeKey: "8" } });
  };

  const handleFinish = async (values: {}) => {
    setFormData({ ...data });
    try {
      await postDischarge({
        // ...data,
        ...values,
        createdBy: user?.himsEmployeeID,
        tcipid: id,
      }).unwrap();
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  return (
    <>
      { }
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin
            spinning={downloadPrintData || PostDischarge.isLoading}
            fullscreen
          />
          <Form
            layout="vertical"
            onFinish={handleFinish}
            form={formInstance}
            disabled={data?.patientDishchargeSummaryID}
          >
            <div ref={componentRef} className="printable-content">
              <img
                className="tblboxheader"
                src={DischargeHeader}
                width={1000}
                height={40}
                alt="img"
              />

              <div className="tblbox">
                {isLoading ? (
                  <Skeleton
                    active
                    paragraph={{ rows: 2, width: 300 }}
                    title={false}
                  />
                ) : (
                  <PatientCard patientDetails={patientDetails} />
                )}
              </div>

              <div className="tblbox1">
                <Row gutter={[24, 16]}>
                  <Col span={6}>
                    <Form.Item name="admissionDate" label="Date of Admission">
                      <Text type="secondary">
                        {dayjs(
                          formInstance.getFieldValue("admissionDate")
                        ).format("DD-MM-YYYY")}
                      </Text>
                      {/* <DatePicker style={{ width: "100%" }} disabled  format="DD-MM-YYYY HH:mm" /> */}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="dischargeDate"
                      label="Discharge Date and Time"
                    >
                      <DatePicker
                        // style={{ width: "100%" }}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        defaultValue={dayjs()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="dischargeReasonID"
                      label="Discharge Reason"
                      // style={{ width: 300 }}
                      rules={[
                        { required: true, message: "Please Enter Reason" },
                      ]}
                    >
                      <Select
                        placeholder="Select Reason"
                        style={{ width: "100%" }}
                        options={dischargeType}
                        loading={DischargeType.isFetching}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="finalDiagnosis" label="Final Diagnosis">
                  <TextArea
                    showCount
                    maxLength={10000}
                    autoSize={{ minRows: 3, maxRows: 8 }}
                    placeholder="Please Enter Comments"
                  />
                </Form.Item>
                <Form.Item
                  name="clinicalSummary"
                  label="Clinical Summary"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Row gutter={[0, 16]}>
                    <Col span={24}>
                      {clinicalSummaries?.map((summary: any, index: any) => (
                        <ClinicalSummary key={index} summary={summary} />
                      ))}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="investigationDone" label="Investigation Done">
                  <Row gutter={[0, 16]}>
                    <Col span={24}>
                      {investigations?.map((investigation: any, index: any) => (
                        <InvestigationDone
                          key={index}
                          investigation={investigation}
                        />
                      ))}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  name="treatmentGivenHospital"
                  label="Treatment Given Hospital"
                >
                   <Row gutter={[0, 16]}>
                    <Col span={24}>
                      {treatmentProgress?.map((progress: any, index: any) => (
                        <ProgressInHospital key={index} progress={progress} />
                      ))}
                    </Col>
                  </Row>
                </Form.Item>

                  <Form.Item
                    name="progressInHospital"
                    label="Progress In Hospital"
                  >
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        {treatmentGiven?.map((treatment: any, index: any) => (
                          <TreatmentGivenAtHospital
                            key={index}
                            treatment={treatment}
                          />
                        ))}
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    name="patientCondition"
                    label="Condition At The Time Of Discharge"
                  >
                    <TextArea
                      showCount
                      placeholder="Please Enter Comments"
                      maxLength={10000}
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                  <Form.Item name="diet" label="Diet">
                    <TextArea
                      showCount
                      placeholder="Please Enter Comments"
                      maxLength={10000}
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="specialInstructions"
                    label="Special Instructions"
                  >
                    <TextArea
                      showCount
                      placeholder="Please Enter Comments"
                      maxLength={10000}
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>


                 

              </div>
            </div>
            <div className="text-end mt-4 btnmargin">
              <Row justify="end">
                {/* <Col>
                  {!formData?.patientDishchargeSummaryID ? (
                    <Button
                      type="primary"
                      disabled={true}
                      className="btn btn-primary mx-3"
                    >
                      Print
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="btn btn-primary mx-3"
                      disabled={false}
                      // onClick={() => handleDownloadFile()}
                      onClick={handleResponsePrint}
                    >
                      Print
                    </Button>
                  )}
                </Col> */}
                <Col>
                  {formData?.patientDishchargeSummaryID ? null : (
                    <Row justify="end" gutter={[10, 0]}>
                      <Col>
                        <Button
                          type="primary"
                          className="btn btn-primary"
                          aria-label="Close"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          htmlType="submit"
                          disabled={formData?.paymentPending}
                          type="primary"
                          className="btn btn-primary"
                          aria-label="Close"
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default DischargeSummary;

const ClinicalSummary = ({ summary }: any) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <Space direction="vertical">
        <Text type="secondary">{summary.ClinicalSummary}</Text>
        <Text type="secondary" style={{ float: "right" }}>
          By: {summary.DoctorName} |{" "}
          {FormatDateTime(new Date(summary.VisitDate).toString())}
        </Text>
      </Space>
    </div>
  );
};

const InvestigationDone = ({ investigation }: any) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <Space direction="vertical">
        <Text type="secondary">{investigation.InvestigationDone}</Text>
        <Text type="secondary" style={{ float: "right" }}>
          By: {investigation.DoctorName} |{" "}
          {FormatDateTime(new Date(investigation.VisitDate).toString())}
        </Text>
      </Space>
    </div>
  );
};

const ProgressInHospital = ({ progress }: any) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <Space direction="vertical">
        <Text type="secondary">{progress.ProgressInHospital}</Text>
        <Text type="secondary" style={{ float: "right" }}>
          By: {progress.DoctorName} |{" "}
          {FormatDateTime(new Date(progress.VisitDate).toString())}
        </Text>
      </Space>
    </div>
  );
};

const TreatmentGivenAtHospital = ({ treatment }: any) => {
  return (
    <div style={{ marginBottom: "16px" }}>
      <Space direction="vertical">
        <Text type="secondary">{treatment.TreatmentGivenAtHospital}</Text>
        <Text type="secondary" style={{ float: "right" }}>
          By: {treatment.DoctorName} |{" "}
          {FormatDateTime(new Date(treatment.VisitDate).toString())}
        </Text>
      </Space>
    </div>
  );
};
