export const alphaNumericPattern = /^[a-zA-Z0-9_ .-]*$/;

export const emailrgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

//export const IndianMobileNumber = /^[0-9]{10}$/;
export const IndianMobileNumber = /^[1-9][0-9]{9}$/;

// export const number = /^(0?\d{1,2}|1\d{2}|200)$/;

export const number = /^(?!0$)(?!00$)(?:[1-9]|[1-9][0-9]|1\d{2}|200)$/;
