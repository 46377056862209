import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router";
import Select from "react-select";
import {
  useGetDistrictQuery,
  useGetDropdownQuery,
  useLazyGetPatientQuery,
  useGetStateQuery,
  usePostDataMutation,
} from "../../../services/ApiSlice";
import { Link } from "react-router-dom";
import { Spin } from "antd";

interface Props {
  patientID?: any;
  isModelopen?: boolean;
}
export const AddPatients: React.FC<Props> = (Props) => {
  const {
    data: bloodGroupDropdown,
    error,
    isLoading,
  } = useGetDropdownQuery("BloodGroup");
  const { data: genderDropdown } = useGetDropdownQuery("Gender");
  const { data: stateData } = useGetStateQuery("state");
  const modalRef = useRef<HTMLDivElement>(null);
  const modalRef1 = useRef<HTMLDivElement>(null);
  // const { data: diseasesData } = useGetDiseaseQuery("disease");

  const scrolltopRef = useRef<HTMLDivElement>(null);

  const [
    getPatientByPatientID,
    {
      data: patientData,
      isFetching: isPatientDetailsFetching,
      isSuccess: isEmployeeDetailsSuccess,
    },
  ] = useLazyGetPatientQuery();
  const [postData, { isLoading: isPosting, error: postError }] =
    usePostDataMutation();

  const { address = {}, diseases = [] } = patientData ?? [];
  const [stateIDForDistrict, setStateIDForDistrict] = useState<number>(2);
  const { data: districtData } = useGetDistrictQuery(stateIDForDistrict);
  const options =
    bloodGroupDropdown?.map((item: any) => ({
      id: item?.dropDownSetID,
      value: item.dropDownValue,
      label: item.dropDownValue,
    })) || [];
  const stateOptions =
    stateData?.map((item: any) => ({
      id: item?.stateID,
      value: item.stateName,
      label: item.stateName,
    })) || [];
  const district =
    districtData?.map((item: any) => ({
      id: item?.districtID,
      value: item.districtName,
      label: item.districtName,
    })) || [];
  // const disease =
  //   diseasesData?.map((item: any) => ({
  //     id: item?.diseaseID,
  //     value: item.diseaseName,
  //     label: item.diseaseName,
  //   })) || [];
  const gender =
    genderDropdown?.map((item: any) => ({
      id: item?.dropDownSetID,
      value: item.dropDownValue,
      label: item.dropDownValue,
    })) || [];
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const prevCountRef = useRef<number | null>(null);
  const BloodGroup: any = [
    { value: 1, label: "A+" },
    { value: 2, label: "O+" },
    { value: 3, label: "B+" },
    { value: 4, label: "AB+" },
  ];
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNo: "",
    // diseaseName: "",
    dob: "",
  });
  // const [diseaseData, setDiseaseData] = useState({
  //   diseaseID: 0,
  //   diseaseName: "",
  //   description: "",
  // });
  // const [diseaseDataEdit, setDiseaseDataEdit] = useState([
  //   {
  //     diseaseID: 0,
  //     diseaseName: "",
  //     description: "",
  //   },
  // ]);
  const [addressData, setAddressData] = useState({
    addressLine1: "",
    addressLine2: "",
    landMark: "",
    stateName: "",
    stateID: 0,
    districtID: 0,
    city: "",
    districtName: "",
    pinCode: "",
    flatNumber: "",
  });

  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    emailID: "",
    phoneNo: "",
    dob: "",
    genderDDSID: 0,
    bloodGroupDDSID: 0,
    gender: "",
    bloodGroup: "",
    comments: "",
    age: "",
  });

  const [requiredFeilds, setRequiredFeilds] = useState({
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    phoneNo: formData?.phoneNo,
    dob: formData?.dob,
    // diseaseName: diseaseData.diseaseName,
  });
  const [entries, setEntries]: any = useState([]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (prevCountRef.current !== null && prevCountRef.current !== entries) {
    }
    prevCountRef.current = entries;
  }, [entries]);
  useEffect(() => {
    if (Props.patientID) {
      getPatientByPatientID(Props.patientID);
      // setDiseaseDataEdit(diseases);
    }
  }, []);

  useEffect(() => {
    if (Props.isModelopen && patientData) {
      setFormData(patientData);
      setAddressData(address);
      // setDiseaseDataEdit(diseases);
      setIsEdit(true);
    }
  }, [patientData, Props.isModelopen]);

  useEffect(() => {
    setRequiredFeilds({
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      dob: formData?.dob,
      phoneNo: formData?.phoneNo,
      // diseaseName: diseaseData.diseaseName,
    });
  }, [formData]);

  const validateField = (name: any, value: any) => {
    let errorMessage = "";
    switch (name) {
      case "firstName":
        errorMessage = value?.trim()
          ? value.length >= 3
            ? ""
            : "Enter atleast 3 characters"
          : "Name is required";

        break;
      case "lastName":
        errorMessage = value?.trim()
          ? value.length >= 3
            ? ""
            : "Enter atleast 3 characters"
          : "Name is required";
        break;
      case "phoneNo":
        errorMessage = value?.trim()
          ? /^\d{10}$/.test(value)
            ? ""
            : "Enter valid Number"
          : "Number is required";
        break;
      default:
        if (name in errors) errorMessage = value ? "" : "Is required";
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    return errorMessage;
  };

  const handleChange: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    let updatedFormData = { ...formData };
    // let updatedDiseaseData = { ...diseaseData };
    let updatedAddressData = { ...addressData };
    switch (name) {
      case "dob":
        let formattedDate = null;
        if (value) {
          formattedDate = format(
            value,
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ).toString();
          // Format the date
        }
        updatedFormData = { ...updatedFormData, dob: formattedDate };
        break;
      case "bloodGroup":
        const bloodGroupID =
          options.find((option: any) => option.value === value)?.id || 0;
        updatedFormData = {
          ...updatedFormData,
          bloodGroupDDSID: bloodGroupID,
          bloodGroup: value,
        };
        break;
      case "gender":
        const genderDDSID =
          gender.find((option: any) => option.value === value)?.id || 0;
        updatedFormData = {
          ...updatedFormData,
          genderDDSID: genderDDSID,
          gender: value,
        };
        break;
      // case "diseaseName":
      //   const diseaseID =
      //     disease.find((option: any) => option.value === value)?.id || 0;
      //   updatedDiseaseData = {
      //     ...updatedDiseaseData,
      //     diseaseID: diseaseID,
      //     diseaseName: value,
      //   };
      //   break;
      case "stateName":
        const stateID =
          stateOptions.find((option: any) => option.value === value)?.id || 0;
        setStateIDForDistrict(stateID);
        updatedAddressData = { ...updatedAddressData, stateID: stateID };
        break;
      case "district":
        const districtID =
          district.find((option: any) => option.value === value)?.id || 0;
        updatedAddressData = { ...updatedAddressData, districtID: districtID };
        break;
      default:
        updatedFormData = { ...updatedFormData, [name]: value };
        // updatedDiseaseData = { ...updatedDiseaseData, [name]: value };
        updatedAddressData = { ...updatedAddressData, [name]: value };
    }
    setFormData(updatedFormData);
    // setDiseaseData(updatedDiseaseData);
    setAddressData(updatedAddressData);
    validateField(name, value);
  };

  const handleCrossClick = () => {
    setIsEdit(false);
    navigate("/patients");
  };

  const isRequiredFilled = () => {
    let isFilled = true;
    for (const [name, value] of Object.entries(requiredFeilds)) {
      if (validateField(name, value)) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleAddUser = async () => {
    if (scrolltopRef.current) scrolltopRef!.current!.scrollIntoView();
    const allFilledFeilds = isRequiredFilled();
    // allFilledFeilds && Object.values(errors).every((error) => error === '')
    if (
      allFilledFeilds &&
      Object.values(errors).every((error) => error === "")
    ) {
      if (isEdit) {
        setFormData({ ...patientData, patientID: patientData.patientID });
        try {
          await postData(formData).unwrap();
          // Optionally, refetch data after successful post
          //  await refetch();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
        handleClose();
        // const contact = contactService.update(formData);
        // setFormData(contact);
        setIsEdit(false);
        navigate("/patients");
      } else {
        setFormData(formData);
        setAddressData(addressData);
        // setDiseaseData(diseaseData);
        try {
          await postData({
            ...formData,
            address: addressData,
            // diseases: [diseaseData],
          }).unwrap();
          // Optionally, refetch data after successful post
          // await refetch();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
        navigate("/patients");
        handleClose();
      }
    } else {
      alert("Please provide required details correctly");
    }
  };
  const handleClose = () => {
    setFormData({
      firstName: "",
      lastName: "",
      emailID: "",
      phoneNo: "",
      dob: "",
      genderDDSID: 0,
      bloodGroupDDSID: 0,
      gender: "",
      bloodGroup: "",
      comments: "",
    });
    setAddressData({
      addressLine1: "",
      addressLine2: "",
      landMark: "",
      stateName: "",
      stateID: 0,
      districtID: 0,
      city: "",
      districtName: "",
      pinCode: "",
      flatNumber: "",
    });
    // setDiseaseData({
    //   diseaseID: 0,
    //   diseaseName: "",
    //   description: "",
    // });
    setErrors({
      firstName: "",
      lastName: "",
      phoneNo: "",
      // diseaseName: "",
      dob: "",
    });
  };
  const customStyles = {
    option: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
          <Spin spinning={isPosting} fullscreen />
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Patient</h3>
              </div>
            </div>
            <div
              // className="modal custom-modal fade"
              // id="add_holiday"
              role="dialog"
              ref={modalRef}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                ref={scrolltopRef}
                id="patient-dialog"
              >
                <div className="modal-content" id="add_patient">
                  <div className="modal-header">
                    {/* <h5 className="modal-title">Add Patient</h5> */}
                    {/* <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">×</span>
                    </button> */}
                  </div>
                  <div className="modal-body" id="add-patient">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          {/* <div className="card-header">
                            <h4 className="card-title mb-0">
                              Personal Information
                            </h4>
                          </div> */}

                          <div className="card-body">
                            {/* <h4 className="card-title">Personal Information</h4> */}
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleAddUser();
                              }}
                            >
                              <div className="row">
                                <div className="col-xl-6">
                                  <h4 className="card-title ">
                                    Personal Information
                                  </h4>

                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      First Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.firstName ? "is-invalid" : ""
                                        }`}
                                        name="firstName"
                                        value={formData?.firstName}
                                        onChange={handleChange}
                                      />
                                      {errors.firstName && (
                                        <div className="invalid-feedback">
                                          {errors.firstName}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Last Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.lastName ? "is-invalid" : ""
                                        }`}
                                        name="lastName"
                                        value={formData?.lastName}
                                        onChange={handleChange}
                                      />
                                      {errors.lastName && (
                                        <div className="invalid-feedback">
                                          {errors.lastName}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Gender
                                    </label>
                                    <div className="col-lg-9">
                                      <Select
                                        options={gender}
                                        placeholder="Select Gender"
                                        styles={customStyles}
                                        name="gender"
                                        value={gender.find(
                                          (option: any) =>
                                            option.value === formData?.gender
                                        )}
                                        onChange={(selectedOption: any) => {
                                          handleChange({
                                            target: {
                                              name: "gender",
                                              value: selectedOption.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Blood Group
                                    </label>
                                    <div className="col-lg-9">
                                      <Select
                                        options={options}
                                        placeholder="Select Blood Group"
                                        styles={customStyles}
                                        name="bloodGroup"
                                        value={options.find(
                                          (option: any) =>
                                            option.value ===
                                            formData?.bloodGroup
                                        )}
                                        onChange={(selectedOption: any) => {
                                          handleChange({
                                            target: {
                                              name: "bloodGroup",
                                              value: selectedOption?.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-6 mt-5">
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-4 col-form-label">
                                      Email{" "}
                                    </label>
                                    <div className="col-lg-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="emailID"
                                        value={formData?.emailID}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3  row">
                                    <label className="col-lg-4 col-form-label">
                                      Mobile No.{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-8">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.phoneNo ? "is-invalid" : ""
                                        }`}
                                        name="phoneNo"
                                        value={formData?.phoneNo}
                                        onChange={handleChange}
                                      />
                                      {errors.phoneNo && (
                                        <div className="invalid-feedback">
                                          {errors.phoneNo}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-4 col-form-label ">
                                      DOB
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-8 ">
                                      <div className="cal-icon">
                                        <div
                                          className={
                                            errors.dob ? "is-invalid" : ""
                                          }
                                        >
                                          <DatePicker
                                            selected={
                                              formData?.dob
                                                ? new Date(formData?.dob)
                                                : null
                                            } // Convert the dob string to a Date object
                                            onChange={(date) =>
                                              handleChange({
                                                target: {
                                                  name: "dob",
                                                  value: date,
                                                },
                                              })
                                            }
                                            className={`form-control datetimepicker ${
                                              errors.dob ? "is-invalid" : ""
                                            }`}
                                            dateFormat="dd-MM-yyyy"
                                          />
                                        </div>
                                        {errors.dob && (
                                          <div className="invalid-feedback">
                                            {errors.dob}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Age{" "}
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="age"
                                        value={formData?.age}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div> */}

                                  {/* <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Diseases
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      className={
                                        errors.diseaseName ? "is-invalid" : ""
                                      }
                                      options={disease}
                                      placeholder="Select Disease"
                                      styles={{
                                        ...customStyles,
                                        control: (provided) => ({
                                          ...provided,
                                          border: errors.diseaseName
                                            ? "1px solid red"
                                            : "1px solid #cccccc",
                                        }),
                                      }}
                                      name="diseaseName"
                                      value={disease.find(
                                        (option: any) =>
                                          option.value ===
                                          diseaseData.diseaseName
                                      )}
                                      onChange={(selectedOption: any) => {
                                        handleChange({
                                          target: {
                                            name: "diseaseName",
                                            value: selectedOption.value,
                                          },
                                        });
                                      }}
                                    />
                                    {errors.diseaseName && (
                                      <div className="invalid-feedback">
                                        {errors.diseaseName}
                                      </div>
                                    )}
                                  </div>
                                </div> */}
                                </div>
                              </div>
                              {/* <h4 className="card-title">Parent</h4>
                             <div className="row">
                            <div className="col-xl-6">
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  First Name
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="addressLine1"
                                    value={addressData.addressLine1}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Last Name
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="addressLine2"
                                    value={addressData.addressLine2}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                        
                            </div>
                            <div className="col-xl-6">
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Mobile No
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    value={addressData.city}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Relation
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    value={addressData.city}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                         
                            
                            </div>
                          </div> */}
                              <h4 className="card-title">Address</h4>
                              <div className="row">
                                <div className="col-xl-6">
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Address Line 1
                                    </label>

                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="addressLine1"
                                        value={addressData.addressLine1}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Address Line 2
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="addressLine2"
                                        value={addressData.addressLine2}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Land Mark
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="landMark"
                                        value={addressData.landMark}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Flat/Plot No.
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="flatNumber"
                                        value={addressData.flatNumber}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-6">
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      City
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        value={addressData.city}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      State
                                    </label>
                                    <div className="col-lg-9">
                                      <Select
                                        options={stateOptions}
                                        placeholder="Select State"
                                        styles={customStyles}
                                        name="stateName"
                                        value={stateOptions.find(
                                          (option: any) =>
                                            option.value ===
                                            addressData.stateName
                                        )}
                                        onChange={(selectedOption: any) => {
                                          handleChange({
                                            target: {
                                              name: "stateName",
                                              value: selectedOption.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      Pincode
                                    </label>
                                    <div className="col-lg-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="pinCode"
                                        value={addressData.pinCode}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="input-block mb-3 row">
                                    <label className="col-lg-3 col-form-label">
                                      District
                                    </label>
                                    <div className="col-lg-9">
                                      <Select
                                        options={district}
                                        placeholder="Select District"
                                        styles={customStyles}
                                        name="district"
                                        value={district.find(
                                          (option: any) =>
                                            option.value ===
                                            addressData.districtName
                                        )}
                                        onChange={(selectedOption: any) => {
                                          handleChange({
                                            target: {
                                              name: "district",
                                              value: selectedOption.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <h4 className="card-title">Remarks</h4>
                            <div className="input-block mb-3 row">
                              <label className="col-form-label col-md-2">
                                Comments
                              </label>
                              <div className="col-md-10">
                                <textarea
                                  rows={5}
                                  cols={5}
                                  className="form-control"
                                  placeholder="Enter text here"
                                  // defaultValue={""}
                                  name="comments"
                                  onChange={handleChange}
                                  value={formData.comments}
                                />
                              </div>
                            </div> */}
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  // data-bs-dismiss={
                                  //   Object.values(requiredFeilds).every(
                                  //     (value) => value !== ""
                                  //   )
                                  //     ? "modal"
                                  //     : ""
                                  // }
                                  // aria-label={
                                  //   Object.values(requiredFeilds).every(
                                  //     (value) => value !== ""
                                  //   )
                                  //     ? "Close"
                                  //     : ""
                                  // }
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPatients;
