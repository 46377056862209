import type { CheckboxProps, FormInstance } from "antd";
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useGetDropdownQuery } from "../../../services/ApiSlice";
import { IndianMobileNumber } from "../Authentication/RegEx";

type Props = {
  formInstance: FormInstance;
  isSelfBooking: boolean;
  setIsSelfBooking: Dispatch<SetStateAction<boolean>>;
  isEditable: boolean;
};

function AttendeeForm({
  formInstance,
  isSelfBooking,
  setIsSelfBooking,
  isEditable,
}: Props) {
  const Relationship = useGetDropdownQuery("Relationship");

  const onBookingForChange: CheckboxProps["onChange"] = (e) => {
    setIsSelfBooking(e.target.checked);
  };

  const relationshipOptions = useMemo(
    () =>
      Array.isArray(Relationship.data)
        ? Relationship.data.map(({ dropDownValue, dropDownSetID }) => ({
            label: dropDownValue,
            value: dropDownSetID,
          }))
        : [],
    [Relationship]
  );

  return (
    <Card
      title="Attendee Details"
      // extra={
      //   <Checkbox checked={isSelfBooking} onChange={onBookingForChange}>
      //     Self
      //   </Checkbox>
      // }
    >
      <Form layout="vertical" form={formInstance} disabled={!isEditable}>
        <Row gutter={[24, 12]}>
          {/* {!isSelfBooking ? (
            <> */}
          {/* <Col span={24}>
                <Typography.Title level={5}>Attendee Details</Typography.Title>
              </Col> */}
          <Col span={12}>
            <Form.Item name="firstName" label="First Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="lastName" label="Last Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobileNumber"
              label="Mobile No."
              rules={[
                {
                  pattern: IndianMobileNumber,
                  message: "Please Enter Valid Mobile Number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="relation" label="Relation">
              <Select
                options={relationshipOptions}
                loading={Relationship.isFetching}
                disabled={Relationship.isFetching || !isEditable}
              />
            </Form.Item>
          </Col>
          <Divider />
          {/* </>
          ) : null} */}

          <Col span={24}>
            <Form.Item name="comments" label="Reason for visit">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AttendeeForm;
