import DetailsCard from "../../../components/DetailsCard";

const formatDate = (dateString: string | number | undefined) => {
  if (dateString === undefined) {
    return "Invalid Date";
  }
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

const fields: {
  key: keyof IPatientDetails;
  label: string;
  formatter?: (value: IPatientDetails[keyof IPatientDetails]) => string;
}[] = [
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "uhid", label: "UHID" },
  { key: "phoneNo", label: "Mobile No" },
  { key: "emailID", label: "Email ID" },
  { key: "dob", label: "Date Of Birth", formatter: formatDate },
  { key: "gender", label: "Gender" },
  { key: "bloodGroup", label: "Blood Group" },
];

type Props = {
  details: IPatientDetails;
  loading: boolean;
};

function PatientCard({ details, loading }: Props) {
  return (
    <DetailsCard
      config={fields}
      title="Patient Information"
      data={details}
      showTwoCols
      loading={loading}
      // action={
      //   <Link to="/patients">
      //     <Button type="text">Change</Button>
      //   </Link>
      // }
    />
  );
}

export default PatientCard;
