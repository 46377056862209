import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { AddPatient } from "../../components/modelpopup/AddPatient";
import { AddPayment } from "../../components/modelpopup/AddPayment";
import AuthGuard from "../../guards/AuthGuard";
import DashBoardGuard from "../../guards/DashBoardGuard";
import PatientListGuard from "../../guards/PaitientListGuard";
import { PRIVILEGE } from "../../models/PrivilegeRoles";
import store from "../../store";
import Header from "../../views/layout/Header";
import Sidebar from "../../views/layout/Sidebar";
import AppointmentDetails from "../../views/pages/Appointments/AppointmentDetails";
import EditAppointment from "../../views/pages/Appointments/EditAppointment";
import OpSummary from "../../views/pages/Appointments/OpSummary";
import ViewAppointment from "../../views/pages/Appointments/ViewAppointment";
import Login from "../../views/pages/Authentication/Login";
import Register from "../../views/pages/Authentication/Register";
import BillingInfo from "../../views/pages/Billing/BillingInfo";
import IPBilling from "../../views/pages/Billing/IPBilling";
import BookAppointment from "../../views/pages/BookAppointment/BookAppointment";
import BookSpecialist from "../../views/pages/BookSpecialist/BookSpecialist.page";
import Doctors from "../../views/pages/BookSpecialist/SearchSpecialist/Doctors";
import SearchSpecialist from "../../views/pages/BookSpecialist/SearchSpecialist/SearchSpecialist.page";
import SearchDoctor from "../../views/pages/BookSpecialist/SearchSpecialist/searchDoctors";
import SpecialistDetails from "../../views/pages/BookSpecialist/SpecialistDetails/SpecialistDetails.page";
import DetailsTab from "../../views/pages/Dashboard/DetailsTab";
import PatientDashboard from "../../views/pages/Dashboard/PatientDashboard";
import LabRequests from "../../views/pages/LabTests/LabRequests";
import NewLabRequest from "../../views/pages/LabTests/NewLabRequest";
import NotAuthorized from "../../views/pages/NotAuthorizes";
import NewAddPrescription from "../../views/pages/Prescription/AddPrescription";
import PrivilegeList from "../../views/pages/RoleManagement/PrivilegeList";
import RoleDetails from "../../views/pages/RoleManagement/RoleDetails";
import Roles from "../../views/pages/RoleManagement/Roles";
import UserAction from "../../views/pages/RoleManagement/UserActions";
import UserList from "../../views/pages/RoleManagement/UserList";
import Practice from "../../views/pages/RoleManagement/practice";
import RolePrivilegeMap from "../../views/pages/RolePrivilegeMap/RolePrivilegeMap.Page";
import AddPatients from "../../views/pages/UserDetails/AddPatients";
import AddPrescription from "../../views/pages/UserDetails/AddPrescriptions";

import LabBilling from "../../views/pages/Billing/LabBilling";
import LabBillingDetails from "../../views/pages/Billing/LabBillingDetails";
import AddUpdateExternalDoctor from "../../views/pages/RoleManagement/AddUpdateExternalDoctor";
import ApprovedDoctors from "../../views/pages/RoleManagement/ApprovedDoctors";
import ExternalDoctors from "../../views/pages/RoleManagement/ExternalDoctors";
import QualificationLookup from "../../views/pages/RoleManagement/QualificationLookup";
import SpecialityLookup from "../../views/pages/RoleManagement/SpecialityLookup";
import UserDetails from "../../views/pages/RoleManagement/UserDetails";
import AddUpdatePatient from "../../views/pages/UserDetails/AddUpdatePatient";
import DischargeSummary from "../../views/pages/UserDetails/DischargeSummary";
import DischargeSummaryList from "../../views/pages/UserDetails/DischargeSummaryList";
import IPPaymentDetails from "../../views/pages/UserDetails/IPPaymentDetails";
import InpatientDetails from "../../views/pages/UserDetails/InpatientDetails";
import IPDSAddPrescription from "../../views/pages/UserDetails/InpatientTabs/IPDSAddPrescription";
import VisitNotes from "../../views/pages/UserDetails/InpatientTabs/VisitNotes";
import PatientList from "../../views/pages/UserDetails/PatientList";
import PatientTab from "../../views/pages/UserDetails/PatientTab";
import PaymentDetails from "../../views/pages/UserDetails/PaymentDetails";
import PaymentTab from "../../views/pages/UserDetails/PaymentTab";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  useEffect(() => {
    localStorage.setItem("email", "admin@dreamstechnologies.com");
    localStorage.setItem("password", "123456");
    localStorage.setItem("colorschema", "orange");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");
  }, []);

  const SidebarLayout = () => (
    <>
      <Header />
      <Sidebar />
      {/* <OffCanvas /> */}
      <Outlet />
    </>
  );

  const routingObjects = [
    {
      id: 1,
      path: "patients/patient-list",
      element: <PatientListGuard component={PatientList} />,
    },
    {
      id: 2,
      path: "dashboard",
      element: <DashBoardGuard component={PatientDashboard} />,
    },
    {
      id: 3,
      path: "appointment-details",
      element: (
        <AuthGuard
          component={AppointmentDetails}
          privilegeID={PRIVILEGE.OutPatient}
        />
      ),
    },

    {
      id: 4,
      path: "viewAppointment/:id",
      element: <AuthGuard component={ViewAppointment} />,
    },

    {
      id: 5,
      path: "patients",
      element: (
        <AuthGuard component={PatientList} privilegeID={PRIVILEGE.Client} />
      ),
    },
    {
      id: 6,
      path: "patients/:patientID",
      element: (
        <AuthGuard component={PatientTab} privilegeID={PRIVILEGE.Client_View} />
      ),
    },
    {
      id: 1281,
      path: "patients/add-patient",
      element: (
        <AuthGuard
          component={AddUpdatePatient}
          privilegeID={PRIVILEGE.Client_AddPatient}
        />
      ),
    },
    {
      id: 1282,
      path: "patients/:patientID/update",
      element: (
        <AuthGuard
          component={AddUpdatePatient}
          privilegeID={PRIVILEGE.Client_View_EditPatient}
        />
      ),
    },
    // {
    //   id: 129,
    //   path: "patients/:patientID/book-appointment",
    //   element: <AuthGuard component={BookSpecialist} />,
    //   children: [
    //     {
    //       index: true,
    //       element: <AuthGuard component={SearchSpecialist} />,
    //     },
    //     {
    //       path: ":doctorID",
    //       element: <AuthGuard component={SpecialistDetails} />,
    //     },
    //   ],
    // },
    {
      id: 7,
      path: "patients/:patientID/book-appointment",
      element: (
        <AuthGuard
          component={BookAppointment}
          privilegeID={PRIVILEGE.Client_View_BookAppointment}
        />
      ),
    },
    {
      id: 8,
      path: "edit-appointment/:tcopid",
      element: <AuthGuard component={EditAppointment} />,
    },
    {
      id: 9,
      path: "edit-appointment/:tcopid/:patientID/book-appointment",
      element: <AuthGuard component={BookSpecialist} />,
      children: [
        {
          index: true,
          element: <AuthGuard component={SearchSpecialist} />,
        },
        {
          path: ":doctorID",
          element: <AuthGuard component={SpecialistDetails} />,
        },
      ],
    },
    {
      id: 10,
      path: "doctor",
      element: <AuthGuard component={Doctors} />,
      children: [
        {
          index: true,
          element: <AuthGuard component={SearchDoctor} />,
        },
        {
          path: ":doctorID",
          element: <AuthGuard component={SpecialistDetails} />,
        },
      ],
    },
    {
      id: 11,
      path: "billing",
      element: (
        <AuthGuard
          component={BillingInfo}
          privilegeID={PRIVILEGE.BillingInfo}
        />
      ),
    },
    {
      id: 12,
      path: "privilege",
      element: <AuthGuard component={PrivilegeList} />,
    },
    {
      id: 13,
      path: "details-tab/:id",
      element: (
        <AuthGuard
          component={DetailsTab}
          privilegeID={PRIVILEGE.OutPatient_View}
        />
      ),
    },
    {
      id: 14,
      path: "privilege-map",
      element: <AuthGuard component={RolePrivilegeMap} />,
    },
    // {
    //   id: 15,
    //   path: "out-patient-details",
    //   element: <AuthGuard component={OutPatients} />,
    // },
    {
      id: 16,
      path: "add-patient",
      element: (
        <AuthGuard
          component={AddPatients}
          privilegeID={PRIVILEGE.Client_AddPatient}
        />
      ),
    },
    {
      id: 17,
      path: "patient-details-form/:id",
      element: <AddPatient patientID={undefined} />,
    },

    {
      id: 18,
      path: "payment-details/:id",
      element: <AuthGuard component={PaymentDetails} />,
    },
    {
      id: 19,
      path: "payment-tab/:id",
      element: <AuthGuard component={PaymentTab} />,
    },
    {
      id: 20,
      path: "discharge-summary/:id",
      element: <AuthGuard component={DischargeSummary} />,
    },
    {
      id: 21,
      path: "discharge-summary-list",
      element: (
        <AuthGuard
          component={DischargeSummaryList}
          privilegeID={PRIVILEGE.InPatient}
        />
      ),
    },
    {
      id: 22,
      path: "book-specialist",
      element: <AuthGuard component={BookSpecialist} />,
      children: [
        {
          index: true,
          element: <AuthGuard component={SearchSpecialist} />,
        },
        {
          path: ":doctorID/:patientID", // NEEDED
          element: <AuthGuard component={SpecialistDetails} />,
        },
      ],
    },
    {
      id: 23,
      path: "payment-add-tab",
      element: <AddPayment tCIPID={undefined} billingID={null} />,
    },
    {
      id: 24,
      path: "payment-details-tab/:id",
      element: <AuthGuard component={PaymentDetails} />,
    },
    { id: 25, path: "roles", element: <AuthGuard component={Roles} /> },
    {
      id: 26,
      path: "role-details/:id",
      element: <AuthGuard component={RoleDetails} />,
    },
    {
      id: 27,
      path: "user-actions",
      element: <AuthGuard component={UserAction} />,
    },
    { id: 28, path: "not-authorized", element: <NotAuthorized /> },
    { id: 29, path: "userList", element: <AuthGuard component={UserList} /> },
    { id: 30, path: "practice", element: <Practice /> },
    {
      id: 31,
      path: "op-summary/:id",
      element: <AuthGuard component={OpSummary} />,
    },
    {
      id: 32,
      path: "patients/:patientID/book-appointment/:tcOPID",
      element: <AuthGuard component={BookAppointment} />,
    },
    {
      id: 33,
      path: "inpatient-details/:id",
      element: <AuthGuard component={InpatientDetails} />,
    },
    {
      id: 34,
      path: "lab-requests",
      element: <AuthGuard component={LabRequests} />,
    },
    {
      id: 35,
      path: "lab-requests/new-request",
      element: <AuthGuard component={NewLabRequest} />,
    },
    {
      id: 36,
      path: "inpatient-notes/:TCIPID",
      element: <AuthGuard component={VisitNotes} />,
    },
    {
      id: 37,
      path: "ipbilling",
      element: (
        <AuthGuard
          component={IPBilling}
          // privilegeID={PRIVILEGE.IPBillingInfo}
        />
      ),
    },
    {
      id: 38,
      path: "IPpayment-details/:id",
      element: <AuthGuard component={IPPaymentDetails} />,
    },
    {
      id: 39,
      path: "AddPrescription/:id",
      element: <AuthGuard component={AddPrescription} />,
    },
    {
      id: 40,
      path: "admissions",
      element: (
        <AuthGuard
          component={DischargeSummaryList}
          privilegeID={PRIVILEGE.InPatient}
        />
      ),
    },
    {
      id: 41,
      path: "admissions/:TCIPID",
      element: <AuthGuard component={InpatientDetails} />,
    },
    {
      id: 42,
      path: "admissions/:TCIPID/add-prescription",
      element: <AuthGuard component={NewAddPrescription} />,
    },
    {
      id: 43,
      path: "admissions/:TCIPID/add-visit",
      element: <AuthGuard component={VisitNotes} />,
    },
    {
      id: 44,
      path: "admissions/:TCIPID/add-lab-request",
      element: <AuthGuard component={NewLabRequest} />,
    },
    {
      id: 45,
      path: "admissions/:TCIPID/prescribed-drug",
      element: <AuthGuard component={NewAddPrescription} />,
    },
    {
      id: 45,
      path: "IPDSAddPrescription/:id",
      element: <AuthGuard component={IPDSAddPrescription} />,
    },
    {
      id: 46,
      path: "ApprovedDoctors",
      element: <AuthGuard component={ApprovedDoctors} />,
    },
    {
      id: 47,
      path: "ExternalDoctors",
      element: <AuthGuard component={ExternalDoctors} />,
    },
    {
      id: 48,
      path: "AddUpdateExternalDoctors",
      element: <AuthGuard component={AddUpdateExternalDoctor} />,
    },
    {
      id: 49,
      path: "user-details/:id",
      element: <AuthGuard component={UserDetails} />,
    },
    {
      id: 50,
      path: "SpecialityLookup",
      element: <AuthGuard component={SpecialityLookup} />,
    },
    {
      id: 51,
      path: "QualificationLookup",
      element: <AuthGuard component={QualificationLookup} />,
    },
    {
      id: 52,
      path: "lab-billing",
      element: <AuthGuard component={LabBilling} />,
    },
    {
      id: 53,
      path: "lab-billing-details/:id",
      element: <AuthGuard component={LabBillingDetails} />,
    },
    {
      id: 54,
      path: "AddUpdateExternalDoctors/:id",
      element: <AuthGuard component={AddUpdateExternalDoctor} />,
    },
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/*",
      element: <AuthGuard component={SidebarLayout} />,
      children: routingObjects.map((item) => ({
        path: item.path,
        element: React.cloneElement(item.element, { key: item.id }),
        children: item.children,
      })),
    },
  ]);

  return (
    <>
      <Provider store={store}>
        <Suspense fallback={<>...</>}>
          <RouterProvider router={router} />
        </Suspense>
      </Provider>
    </>
  );
};

export default AppRouter;
