import { Flex, Radio, Space } from "antd";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

export const AddUserRole: React.FC<{}> = () => {
  const [selectedDate1, setSelectedDate1] = useState<any>(null);
  const handleDateChange1: any = (date: React.SetStateAction<null>) => {
    setSelectedDate1(date);
  };

  return (
    <div>
      <div className="modal custom-modal fade" id="add_user_role" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Assign Role</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <Flex vertical gap="middle">
              
                <Radio.Group defaultValue="a" buttonStyle="solid">
                <Space size={[32, 16]} wrap>
                  <Radio.Button value="a">Admin</Radio.Button>
                  <Radio.Button value="b">Nurse</Radio.Button>
                  <Radio.Button value="c">Doctor</Radio.Button>
                  <Radio.Button value="d">Front Desk</Radio.Button>
                  <Radio.Button value="a">Admin</Radio.Button>
                  <Radio.Button value="b">Nurse</Radio.Button>
                  <Radio.Button value="c">Doctor</Radio.Button>
                  </Space>
                </Radio.Group>
               
             
              </Flex>
              <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Assign
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
