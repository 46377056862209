import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
  notification
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { paymentStatusColorMap } from "../../../../models/Colors";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useDeletePtMutation,
  useGetIPPtLookUpsQuery,
  useGetPTNurseQuery,
  useGetPTProcedureDetailsQuery,
  useGetProcedurestatusdropDownQuery,
  usePostPTDetailsMutation,
} from "../../../../services/ApiSlice";



const { Title, Text } = Typography;

function Physiotherapy({
  patientID,
  TCIPID,
}: {
  patientID: string;
  TCIPID: string;
}) {
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModelOpenEdit, setIsModelOpenEdit] = useState<boolean>(false);
  const SelectStateDropDown = useGetProcedurestatusdropDownQuery("");
  const [editFormInstance] = Form.useForm<any>();
  const [addFormInstance] = Form.useForm<any>();
  const user = useSelector(selectUser);
  const isRoleBasedVisible = useRoleBasedVisible();
  const { confirm } = Modal;

  const ProcedureNameDropDown = useGetIPPtLookUpsQuery("");
  const ProcedureDoctorDropDown = useGetPTNurseQuery("");
  const getptDetails = useGetPTProcedureDetailsQuery(TCIPID ?? skipToken);
  const [PostptDetails, postPTDetails] = usePostPTDetailsMutation();
  const [selectedRecord, setSelectedRecord] = useState<IPTDetails | null>(null);
  const [preocedureDelete, ProcedureDelete] = useDeletePtMutation();

  const showModal = () => {
    setIsModalOpenAdd(true);
  };

  const selectProcedure = useMemo(
    () =>
      Array.isArray(ProcedureNameDropDown.data)
        ? ProcedureNameDropDown.data.map((item) => ({
          value: item?.ptProcedureMasterID,
          label: item.ptProcedureName,
        }))
        : [],
    [ProcedureNameDropDown]
  );

  const selectDoctor = useMemo(
    () =>
      Array.isArray(ProcedureDoctorDropDown.data)
        ? ProcedureDoctorDropDown.data.map((item) => ({
          value: item?.himsEmployeeID,
          label: item.employeeName,
        }))
        : [],
    [ProcedureDoctorDropDown]
  );

  const selectState = useMemo(
    () =>
      Array.isArray(SelectStateDropDown.data)
        ? SelectStateDropDown.data.map(({ dropDownSetID, dropDownValue }) => ({
          value: dropDownSetID,
          label: dropDownValue,
        }))
        : [],
    [SelectStateDropDown]
  );

  const addPTData = async () => {
    try {
      const values = await addFormInstance.validateFields();
      PostptDetails({
        ...values,
        lastChangedBy: user?.himsEmployeeID,
        tcipid: TCIPID,
      });
      handleCancleAdd();
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  const handleCancleAdd = () => {
    setIsModalOpenAdd(false);
    addFormInstance.resetFields();
    //editFormInstance.resetFields();
  };

  const handleDelete = (record: IPTDetails) => {
    confirm({
      title: "Do you want to delete the procedure ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        try {
          preocedureDelete({
            PtProcedureID: record?.ptProcedureID,
            modifiedBy: user?.himsEmployeeID,
          });
          if (ProcedureDelete.isSuccess) {
            notification["success"]({
              message: "Procedure Deleted",
              icon: <i className="las la-check-circle text-success"></i>,
            });
          }

        } catch (error) {
          console.error("Failed to delete data:", error);
        }
      },
    });
  };
  useEffect(() => {
    if (ProcedureDelete.isSuccess) {
      notification.success({
        message: "Success",
        description: "Successfully",
      });
    } else if (ProcedureDelete.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [ProcedureDelete]);



  const handleEdit = (record: IPTDetails) => {
    setIsModelOpenEdit(true);
    setSelectedRecord(record);
    //addFormInstance.setFieldsValue(record);
    editFormInstance.setFieldsValue({ ...record, scheduledDate: dayjs(record.scheduledDate) });
  };


  const editPtDetails = async () => {
    if (selectedRecord) {
      try {
        const values = await editFormInstance.validateFields();
        PostptDetails({
          ...values,
          tcipid: TCIPID,
          lastChangedBy: user?.himsEmployeeID,
          ptProcedureID: selectedRecord?.ptProcedureID
        });
        onCancelEdit();
      } catch (error) {
        console.error("Failed to post data ", error);
      }
    }
  };

  const onCancelEdit = () => {
    setIsModelOpenEdit(false);
    addFormInstance.resetFields();
    editFormInstance.resetFields();
    setSelectedRecord(null);
  };

  useEffect(() => {
    if (postPTDetails.isSuccess) {
      notification.success({
        message: "Success",
        description: "Successfully",
      });
    } else if (postPTDetails.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [postPTDetails]);


  const admissionCols: TableProps<IPTDetails>["columns"] = [
    {
      title: "Procedure Name",
      dataIndex: "ptProcedureName",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      render: (date) => (FormatDateTime(date))
    },
    {
      title: "Procedure Status",
      dataIndex: "status",
      render: (text: string) => (
        <Tag color={paymentStatusColorMap[text?.toUpperCase()]}>
          {text}
        </Tag>),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedDoctor",
    },
    {
      title: "Modified On",
      dataIndex: "modifiedDate",
      render: (date) => (FormatDateTime(date))
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
    },
    {
      title: "Action",
      key: "",
      render: (record) => (
        <Space>
          {record.status !== 'Completed' && <><Link to="#">
            <span className="link "
              onClick={() => handleEdit(record)}
            >
              <i className="las la-pen"></i>
            </span>
          </Link><Link to="#">
              <span
                className="link btnColor"
                onClick={() => handleDelete(record)}
              >
                <i className="las la-trash"></i>
              </span>
            </Link></>

          }


        </Space>
      ),
    },
  ];

  return (
    <Card>
      <Modal
        open={isModelOpenEdit}
        centered
        title="Update  Procedure"
        onCancel={onCancelEdit}
        onOk={editPtDetails}
        maskClosable={false}
      >
        <Form
          form={editFormInstance}
          disabled={postPTDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="ptProcedureMasterID"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectProcedure}
              loading={ProcedureNameDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="assignedDoctorID"
            label="Assigned To"
            rules={[
              { required: true, message: "Please Enter Assigned to" },
            ]}
          >
            <Select
              placeholder="Select "
              style={{ width: "100%" }}
              options={selectDoctor}
              loading={ProcedureDoctorDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label="Scheduled Date"
            rules={[
              { required: true, message: "Please Enter Scheduled Date" },
            ]}
          >
            {/* <DatePicker style={{ width: "100%" }} disabledDate={(d) => dayjs().isAfter(d)} /> */}
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              disabledDate={(d) => dayjs().isAfter(d)}
            //disabledDate={(current) => current && current < dayjs().endOf('day')}
            />
          </Form.Item>
          <Form.Item
            name="statusID"
            label="Status"
          >
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              options={selectState}
              loading={SelectStateDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          {/* <Form.Item
            name="lastChangedBy"
            label="Edited Date"
            rules={[
              { required: true, message: "Please Enter Edited Date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} disabledDate={(d) => {
              const currentDate = dayjs().startOf('day');
              const selectedDate = dayjs(d).startOf('day');
              return !currentDate.isSame(selectedDate);
            }} />
          </Form.Item> */}
        </Form>
      </Modal>




      {/* add  ki rakated */}
      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Select Procedure
          </Title>
        }
        open={isModalOpenAdd}
        onOk={addPTData}
        onCancel={handleCancleAdd}
        maskClosable={false}
      >
        <Form
          form={addFormInstance}
          disabled={postPTDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="ptProcedureMasterID"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectProcedure}
              loading={ProcedureNameDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="assignedDoctorID"
            label="Assigned To"
            rules={[
              { required: true, message: "Please Enter Assigned to" },
            ]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectDoctor}
              loading={ProcedureDoctorDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label="Scheduled Date"
            rules={[
              { required: true, message: "Please Enter Scheduled Date" },
            ]}
          >
            {/* <DatePicker style={{ width: "100%" }} disabledDate={(d) => dayjs().isAfter(d)} /> */}
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              disabledDate={(d) => dayjs().isAfter(d)}
            //disabledDate={(current) => current && current < dayjs().endOf('day')}
            />

          </Form.Item>
          <Form.Item
            name="statusID"
            label="Status"
          >
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              options={selectState}
              loading={SelectStateDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          {/* <Form.Item
            name="lastChangedBy"
            label="Edited Date"
            rules={[
              { required: true, message: "Please Enter Edited Date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} disabledDate={(d) => {
              const currentDate = dayjs().startOf('day');
              const selectedDate = dayjs(d).startOf('day');
              return !currentDate.isSame(selectedDate);
            }} />
          </Form.Item> */}
        </Form>
      </Modal>

      <Table
        loading={getptDetails.isFetching}
        pagination={getptDetails.data?.length < 10 ? false : undefined}
        columns={admissionCols}
        dataSource={getptDetails.data}
        scroll={{ x: true }}
        // rowKey={(record) => record.id}
        title={() => (
          <Row gutter={24} justify="end">
            {isRoleBasedVisible([Role.Admin,Role.Nurse,Role.Doctor]) && (
              <Col>
                <Button onClick={showModal} type="primary">
                  Add Procedure
                </Button>
              </Col>
            )}
          </Row>
        )}
      />
    </Card>
  );
}

export default Physiotherapy;
