import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Card, Col, Form, Modal, Row, Spin } from "antd";
import { useEffect } from "react";
import {
  useAddOrUpdateVitalsMutation,
  useGetPatientVitalsQuery,
} from "../../../../services/ApiSlice";
import { isNumber } from "../../../../util";
import VitalCard, { EVitalStatus, IVitalConfig } from "./VitalCard.Vitals";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { selectUser } from "../../../../reducers/UserSlice";

const { confirm } = Modal;

type Props = {
  isEditable: boolean;
  patientId: string;
};
const vitalsConfig: IVitalConfig[] = [
 
  {
    title: "Height",
    key: "98",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "cm",
    inputs: 1,
  },
  {
    title: "Weight",
    key: "99",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "kg",
    inputs: 1,
  },
  {
    title: "BMI",
    key: "100",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "kg/m2",
    dependencies: ["98", "99"],
    inputs: 0,
    formatter: (values) =>
      isNumber(values["98"]) && isNumber(values["99"])
        ? BMICalculator(values["98"], values["99"])
        : "",
  },
  {
    title: "Pulse",
    key: "101",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "bpm",
    inputs: 1,
  },
  {
    title: "Temp",
    key: "102",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "F",
    inputs: 1,
  },
  {
    title: "Respiration",
    key: "103",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "brpm",
    inputs: 1,
  },
  {
    title: "Systolic",
    key: "104",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "mmHg",
    inputs: 2,
  },
  {
    title: "Diastolic",
    key: "107",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "mmHg",
    inputs: 2,
  },
  {
    title: "GRBS",
    key: "105",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "mg/dl",
    inputs: 1,
  },
  {
    title: "SpO2",
    key: "108",
    statusFun: (value) => EVitalStatus.SAFE,
    unit: "%",
    inputs: 1,
  },
];

function Vitals({ isEditable, patientId }: Props) {

  const user = useSelector((state: RootState) => selectUser(state));

  const [addOrUpdateVitals, AddOrUpdateVitals] = useAddOrUpdateVitalsMutation();

  const PatientVitals = useGetPatientVitalsQuery(patientId ?? skipToken);

  const [formInstance] = Form.useForm();

  useEffect(() => {
    if (PatientVitals.data) {
      formInstance.setFieldsValue(generateVitalsValues(PatientVitals.data));
    }
  }, [PatientVitals]);

  const onSubmit = async () => {
    confirm({
      title: "Do you want to save ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const values = await formInstance.validateFields();
          const payload = {
            patientID: patientId,
            modifiedBy: user?.himsEmployeeID,
            vitals: generateVitalsPayload(values),
          };
          addOrUpdateVitals(payload);
        } catch (error) {
          console.log("error");
        }
      },
    });
  };

  return (
    <Card
    // title="Vitals"
    >
      <Spin spinning={AddOrUpdateVitals.isLoading} fullscreen />
      <Form form={formInstance}>
        <Row gutter={[24, 24]} align="bottom">
          {vitalsConfig.map((config) => (
            <Col span={6} key={config.key}>
              <VitalCard
                config={config}
                isEditable={AddOrUpdateVitals.isLoading ? false : isEditable}
                loading={PatientVitals.isFetching}
              />
            </Col>
          ))}
          <Col flex="auto"></Col>
          {isEditable ? (
            <Col>
              <Button
                disabled={AddOrUpdateVitals.isLoading}
                loading={AddOrUpdateVitals.isLoading}
                type="primary"
                onClick={onSubmit}
              >
                Save Vitals
              </Button>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Card>
  );
}

export default Vitals;

const generateVitalsValues = (
  values: {
    vitalDDSID: number;
    value: string;
  }[]
) => {
  const vitals: { [key: string]: string } = {};
  values.forEach(({ vitalDDSID, value }) => {
    vitals[vitalDDSID] = value;
  });
  return vitals;
};

const generateVitalsPayload = (values: { [key: string]: string }) => {
  const vitals: IVital[] = [];
  for (const key in values) {
    vitals.push({
      vitalDDSID: parseInt(key),
      value: values[key],
    });
  }
  return vitals;
};

const BMICalculator = (weight: number, height: number) => {
  let BMI = (weight / height / height) * 10000;
  return BMI.toFixed(2);
};
