import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export const api = createApi({
  baseQuery: async (args, api, extraOptions) => {
    const authToken = (api.getState() as RootState).auth.authToken;
    const baseQueryResult = await fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASEURL ?? "",
      // prepareHeaders: (headers) => {
      //   if (authToken) {
      //     headers.set("Authorization", `Bearer ${authToken}`);
      //   }
      //   return headers;
      // },
    })(args, api, extraOptions);

    return baseQueryResult;
  },
  tagTypes: [
    "Patients",
    "Payments",
    "PatientAdmission",
    "OutPatient",
    "Role",
    "EmployeeRole",
    "Privileges",
    "ROLE_PRIVILEGES",
    "PATIENT_VITALS",
    "OUT_PATIENT_SUMMARY",
    "Users",
    "DoctorNotes",
    "ADDED_LAB_TESTS",
    "RoomDetails",
    "ADDED_DRUGS",
    "Drugs",
    "GENERATE_PRESCRIPTION",
    "NS_Details",
    "PT_Details",
    "NS",
    "PT",
    "Discount",
    "STNDetails",
    "ADD_INDENT_SRN",
    "APPROVED_DOCTORS",
    "QUALIFICATION",
    "SPECILIZATION",
    "UPLOAD"
  ],
  endpoints: (builder) => ({
    getDropdown: builder.query({
      query: (dropDownGroup) =>
        `api/Common/GetDropdown?dropDownGroup=${dropDownGroup}`,
    }),

    getState: builder.query({
      query: (state) => `api/Common/GetStates`,
    }),

    getLabStatus: builder.query({
      query: (dropDownGroup) =>
        `api/Common/GetDropdown?dropDownGroup=${dropDownGroup}`,
    }),

    getDistrict: builder.query({
      query: (stateID) => `api/Common/GetDistrictsByID?stateID=${stateID}`,
    }),

    getDisease: builder.query({
      query: (state) => `api/Common/GetAllDiseases`,
    }),

    getLocations: builder.query<ILocation[], void>({
      query: () => "api/Common/GetLocations",
    }),

    getDischageSummaryList: builder.query({
      query: (patientID) => {
        return `api/Patient/GetIPHistoryByPatientID?patientID=${patientID}`;
      },
      providesTags: ["PatientAdmission"],
    }),

    getOutPatientList: builder.query<IOutPatient[], number>({
      query: (locationID) => {
        return `api/Patient/GetOutPatients?locationID=${locationID}`;
      },
      providesTags: ["PatientAdmission", "OutPatient", "Payments"],
    }),

    getOutPatientSummary: builder.query({
      query: (tcOPID) => {
        return `api/Patient/GetOutPatientSummaryByTCOPID?tcOPID=${tcOPID}`;
      },
      providesTags: ["PatientAdmission"],
    }),

    getAllPatients: builder.query<IPatientDetails[], void>({
      query: () => `api/Patient/GetAllPatients`,
      providesTags: ["Patients"],
    }),

    getPatient: builder.query({
      query: (patientID) => {
        return `api/Patient/GetPatientByID?patientID=${patientID}`;
      },
      providesTags: ["Patients"],
    }),

    getDischargeSummary: builder.query({
      query: (tcipID) =>
        `api/Patient/GetAdmissionDetailsByTCIPID?tcIPID=${tcipID}`,
      providesTags: ["PatientAdmission", "Patients"],
    }),

    getPayments: builder.query({
      query: (ID) => {
        return `api/Patient/GetPaymentDetailsByTCID?ID=${ID}`;
      },
      providesTags: ["Payments"],
    }),

    getDoctorsByService: builder.query<
      ISpecialistDetails[],
      ISpecialistDetailsArgs
    >({
      query: (params) => `api/Patient/GetDoctorsByService?${getParams(params)}`,
    }),

    getSpecializations: builder.query<ISpecialization[], void>({
      query: () => `/api/Patient/GetSpecialitions`,
      providesTags: ["SPECILIZATION"],
    }),

    getQualification: builder.query<IQualification[], void>({
      query: () => `/api/Employee/GetQualifications`,
      providesTags: ["QUALIFICATION"],
    }),

    getHIMSEmployeeDetails: builder.query({
      query: (employeeID) =>
        `api/Employee/GetHIMSEmployeeDetails?employeeID=${employeeID}`,
    }),

    getHIMSEmployeeDetailsByEmailID: builder.query({
      query: (email) =>
        `api/Employee/GetHIMSEmployeeDetailsByEmail?email=${email}`,
      providesTags: ["EmployeeRole"],
    }),

    getDoctorDetails: builder.query({
      query: (doctorID) =>
        `api/Patient/GetDoctorDetailByID?doctorID=${doctorID}`,
    }),
    getOutPatientBYPatientID: builder.query({
      query: (params) =>
        `api/Patient/GetOutPatientHistoryByPatientID?${getParams(params)}`,
      providesTags: ["OutPatient", "Payments"],
    }),

    getDoctorByID: builder.query<
      ISpecialistDetails,
      { doctorID: string | undefined }
    >({
      query: (params) => `api/Patient/GetDoctorDetailByID?${getParams(params)}`,
    }),

    getDoctorAvailability: builder.query<
      IDoctorAvailability,
      IDoctorAvailabilityArgs
    >({
      query: (params) =>
        `api/Patient/GetDoctorAvailability?${getParams(params)}`,
    }),

    getPatientByMobile: builder.query<IPatientDetails[], IPatientDetailsArgs>({
      query: (params) =>
        `api/Patient/GetPatientDetailsByMobileNumber?${getParams(params)}`,
    }),

    getAllRolePrivileges: builder.query({
      query: () => `api/Common/GetAllRolePrivileges`,
    }),

    getIPBillings: builder.query({
      query: () => `api/Patient/GetBilling`,
      providesTags: ["Payments"],
    }),
    GetLabBillingInfo: builder.query({
      query: () => `api/Patient/GetLabBillingInfo`,
      providesTags: ["Payments", "ADDED_LAB_TESTS", "DoctorNotes"],
    }),

    getOutPatientByOPID: builder.query<IOutPatient, string>({
      query: (tcOPID) => `api/Patient/GetOutPatientByTCOPID?tcOPID=${tcOPID}`,
      providesTags: ["Role", "OutPatient"],
    }),

    getHimsRoles: builder.query<IHimsRole[], void>({
      query: () => `api/Common/GetHIMSRoles`,
      providesTags: ["Role"],
    }),

    getBillingInfo: builder.query({
      query: (ID) => `api/Patient/GetBillingInfo?id=${ID}`,
      providesTags: ["OutPatient", "PatientAdmission", "Payments"],
    }),

    getCountsForIPBilling: builder.query({
      query: (ID) => `api/Patient/GetCountsForIPBilling?tcIPID=${ID}`,
      providesTags: [
        "OutPatient",
        "PatientAdmission",
        "Payments",
        "PT_Details",
        "NS_Details",
        "Discount"
      ],
    }),

    getIPBillingDetailsBYTCIPID: builder.query({
      query: (ID) => `api/Patient/GetBillingDetailsByID?billTypeRefID=${ID}`,
      providesTags: ["OutPatient", "PatientAdmission", "Payments"],
    }),

    getHimsPrivileges: builder.query({
      query: () => `api/Common/GetHIMSPrivileges`,
      providesTags: ["Privileges"],
    }),

    getPrivilegesByRole: builder.query<IPrivilege[][], number>({
      query: (roleID) =>
        `api/Common/GetHIMSPrivilegesForUserMngt?roleID=${roleID}`,
      providesTags: ["ROLE_PRIVILEGES"],
    }),

    getAllEmployees: builder.query({
      query: () => `api/Employee/GetAllEmployees`,
      providesTags: ["Privileges", "Users"],
    }),

    getOutPatientForNurse: builder.query({
      query: () => `api/Patient/GetOutPatientForNurse`,
      providesTags: ["PatientAdmission"],
    }),

    getCountsForDashboard: builder.query({
      query: () => `api/Common/GetCountsForDashBoard`,
      providesTags: ["Payments"],
    }),

    getDoctorSlotAvailability: builder.query<
      ISpecialistDetails[],
      {
        locationID: number;
        dateTime: string;
        specialityID: number | null;
      }
    >({
      query: (body) => ({
        url: "api/Employee/GetDoctorSlotAvailability",
        method: "POST",
        body,
      }),
    }),

    getDoctorAppointments: builder.query({
      query: (doctorID) => ({
        url: `api/Patient/GetAppointmentsByDoctorID?doctorID=${doctorID}`,
        method: "POST",
      }),
    }),

    getHimsScreens: builder.query<IScreen[], void>({
      query: () => `api/Employee/GetHIMSScreens`,
      providesTags: ["Privileges"],
    }),

    getPatientVitals: builder.query<IVital[], string>({
      query: (patientID) =>
        `api/Patient/GetPatientVitals?patientID=${patientID}`,
      providesTags: ["PATIENT_VITALS"],
    }),

    GetOPSummary: builder.query<IOutPatientSummary, string>({
      query: (tcOPID) =>
        `api/Patient/GetOutPatientSummaryByTCOPID?tcOPID=${tcOPID}`,
      providesTags: ["OUT_PATIENT_SUMMARY"],
    }),

    getRoomDetails: builder.query({
      query: () => `api/Common/GetRoomDetails`,
    }),

    getBedDetails: builder.query({
      query: (roomID) => `api/Common/GetBedDetailsByRoomID?roomID=${roomID}`,
    }),

    getDoctorVisitNotes: builder.query<IDoctorVisit[], string>({
      query: (tcIPID) => `api/Employee/GetDoctorVisit?tcIPID=${tcIPID}`,
      providesTags: ["DoctorNotes"],
    }),

    getPatientVitalHistory: builder.query<IVitalsHistory, string>({
      query: (patientID) =>
        `api/Employee/GetPatientVitalHistory?patientID=${patientID}`,
      providesTags: ["PATIENT_VITALS"],
    }),

    getDoctorVisitByvisitID: builder.query({
      query: (visitID) =>
        `api/Employee/GetDoctorVisitByVisitID?visitID=${visitID}`,
      providesTags: ["DoctorNotes", "ADDED_DRUGS", "Drugs", "ADD_INDENT_SRN"],
    }),
    getVitalsDetailsByVitalsID: builder.query<any, string>({
      query: (VitalsID) =>
        `api/Patient/GetVitalDetailsByVitalsID?VitalsID=${VitalsID}`,
      providesTags: ["PATIENT_VITALS"],
    }),

    getVitalsByTCIPID: builder.query<any, string>({
      query: (tcIPID) => `api/Patient/GetVitalsByTCIPID?tcIPID=${tcIPID}`,
      providesTags: ["PATIENT_VITALS"],
    }),

    getDownloadInPatientBill: builder.query({
      query: (tcIPID) => ({
        url: `api/Patient/DownloadInPatientBill?tcipid=${tcIPID}`,
        responseHandler: (response: any) => response.text(),
      }),
    }),

    getDrugsByVitalID: builder.query({
      query: (params) => `api/Patient/GetDrugsByVisitID?${getParams(params)}`,
      providesTags: ["ADDED_DRUGS"],
    }),

    getAllCoupons: builder.query<Coupon[], void>({
      query: () => `api/Patient/GetDiscountCoupon`,
    }),

    getBillingGroup: builder.query<
      {
        [x: string]: any;
        billingGroupID: number;
        billingGroup: string;
      },
      void
    >({
      query: () => `api/Patient/GetBillingGroup`,
    }),
    getChargesByBillingGroup: builder.query({
      query: (params) =>
        `api/Patient/GetChargesByBillingGroup?${getParams(params)}`,
    }),
    GetUserEmailValidation: builder.query({
      query: (params) => ({
        url: `api/Employee/GetUserEmailValidation?${getParams(params)}`,
        responseHandler: (response: any) => response.text(),
      })
    }),
    getSTNDetails: builder.query({
      query: (tcipID) => `api/Patient/GetRaisedIndentByTCIPID?tcIPID=${tcipID}`,
      providesTags: ["STNDetails", "ADDED_DRUGS", "ADD_INDENT_SRN"],
    }),

    getRaisedIndentDetailsByRivida: builder.query({
      query: (IndentNumber) =>
        `api/Patient/GetRaisedIndentDetailsByRivida?IndentNumber=${IndentNumber}`,
      providesTags: ["STNDetails", "ADD_INDENT_SRN", "ADDED_DRUGS"],
    }),

    getIndentDetailsByIndentNo: builder.query({
      query: (ServiceIndentNumber) =>
        `api/Patient/GetRaisedIndentDetailsByRivida?IndentNumber=${ServiceIndentNumber}`,
      providesTags: ["STNDetails", "ADDED_DRUGS", "ADD_INDENT_SRN"],
    }),
    getIndentDetails: builder.query({
      query: (ServiceIndentNumber) =>
        `api/Patient/GetRaisedIndentDetailsByIndentNo?ServiceIndentNumber=${ServiceIndentNumber}`,
      providesTags: ["STNDetails", "ADDED_DRUGS", "ADD_INDENT_SRN"],
    }),
    GetHIMSEmployeeDetailsByADID: builder.query<IEmployee, any>({
      query: (adid) =>
        `api/Employee/GetHIMSEmployeeDetailsByADID?adid=${adid}`,
      providesTags: ["Users"]
    }),
    postData: builder.mutation({
      query: (postData) => ({
        url: "api/Patient/AddorUpdatePatient",
        method: "POST",
        body: postData,
      }),
      invalidatesTags: ["Patients"],
    }),

    addOrUpdatePatient: builder.mutation<string, IPatientDetailsWithAddress>({
      query: (body) => ({
        url: "api/Patient/AddorUpdatePatient",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Patients"],
    }),

    addOrUpdateEmployee: builder.mutation({
      query: (body) => ({
        url: "api/Employee/AddOrUpdateEmployee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),

    postDischarge: builder.mutation({
      query: (postDischarge) => ({
        url: "api/Patient/AddOrUpdateDischargeSummary",
        method: "POST",
        body: postDischarge,
      }),
      invalidatesTags: ["PatientAdmission"],
    }),

    postOutPatient: builder.mutation({
      query: (postOutPatient) => ({
        url: "api/Patient/AddOutPatientSummary",
        method: "POST",
        body: postOutPatient,
      }),
      invalidatesTags: ["PatientAdmission", "OUT_PATIENT_SUMMARY"],
    }),

    postAdmission: builder.mutation({
      query: (postAdmission) => ({
        url: "api/Patient/AddOrUpdatePatientAdmission",
        method: "POST",
        body: postAdmission,
      }),
      invalidatesTags: ["PatientAdmission"],
    }),

    postRaiseIndent: builder.mutation({
      query: (requestData) => ({
        url: `api/Patient/RaiseDrugIndent?Json=${requestData}`,
        method: "POST",
      }),
      invalidatesTags: ["ADDED_DRUGS"],
    }),

    postPayment: builder.mutation({
      query: (postPayment) => ({
        url: "api/Patient/AddorUpdatePaymentDetails",
        method: "POST",
        body: postPayment,
      }),
      invalidatesTags: ["Payments"],
    }),
    postStatusUpdate: builder.mutation({
      query: (postPayment) => ({
        url: "/api/Common/UpdatePaymentStatus",
        method: "POST",
        body: postPayment,
      }),
      invalidatesTags: ["Payments"],
    }),

    postLabTestsUpdate: builder.mutation({
      query: (postPayment) => ({
        url: "/api/Patient/UpdatePatientLabTests",
        method: "POST",
        body: postPayment,
      }),
      invalidatesTags: ["ADDED_LAB_TESTS", "DoctorNotes"],
    }),

    postOutPatientTimeSlot: builder.mutation({
      query: (postTimeSlot) => ({
        url: "/api/Patient/AddOrUpdateOutPatient",
        method: "POST",
        body: postTimeSlot,
      }),
      invalidatesTags: ["OutPatient"],
    }),

    postCancelAppointment: builder.mutation<
      boolean,
      { tcOPID: string; status: string }
    >({
      query: (args) => ({
        url: `/api/Patient/CancelRequestBytcOPID?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["PatientAdmission"],
    }),

    postNewRolePrivilege: builder.mutation({
      query: (newRole) => ({
        url: `/api/Common/AddOrUpdateRole`,
        method: "POST",
        body: newRole,
      }),
      invalidatesTags: ["Role"],
    }),

    AddExternalUserLogin: builder.mutation({
      query: (ExternalUserLogin) => ({
        url: `/api/Employee/ExternalUserLogin`,
        method: "POST",
        body: ExternalUserLogin,
      }),

    }),

    getDownloadDischargeSummaryByPatientID: builder.query({
      query: (tcPID) => ({
        url: `api/Patient/DownloadDischargeSummaryByTCIPID?tcIPID=${tcPID}`,
        responseHandler: (response: any) => response.text(),
      }),
    }),

    getDownloadLabReportPatientID: builder.query({
      query: (tcPID) => ({
        url: `api/Patient/DownloadLabReport?tcIPID=${tcPID}`,
        responseHandler: (response: any) => response.text(),
      }),
    }),

    getDownloadPaymentDetails: builder.query<any, { paymentDetailsID: string }>(
      {
        query: (paymentDetailsID) => ({
          url: `api/Patient/DownloadInvoiceSummary?paymentDetailsID=${paymentDetailsID}`,
          responseHandler: (response: any) => response.text(),
        }),
      }
    ),

    getDownloadOPSummary: builder.query({
      query: (tcOPID) => ({
        url: `api/Patient/DownloadOPInitialAssesmentByTCOPID?tcOPID=${tcOPID}`,
        responseHandler: (response: any) => response.text(),
      }),
    }),
    DownloadLabReport: builder.query({
      query: (id) => ({
        url: `api/Patient/DownloadLabReport?id=${id}`,
        responseHandler: (response: any) => response.text(),
      }),
    }),

    getLabServiceGroups: builder.query<ILabServiceGroup[], void>({
      query: () => ({
        url: "api/Common/GetLabServiceGroups",
      }),
    }),

    getLabTestsByServiceGroupID: builder.query<ILabTest[], string>({
      query: (serviceGroupID) => ({
        url: `api/Common/GetLabTestsByServiceGroupID?serviceGroupID=${serviceGroupID}`,
      }),
    }),

    getIPRoomDetails: builder.query({
      query: (tcipID) => `api/Patient/GetIPRoomsByTCIPID?tcIPID=${tcipID}`,
      providesTags: ["RoomDetails"],
    }),

    getPTProcedureDetails: builder.query({
      query: (tcipID) => `api/Patient/GetPTProcedureByTCIPID?tcIPID=${tcipID}`,
      providesTags: ["PT_Details"],
    }),

    getNSProcedureDetails: builder.query({
      query: (tcipID) => `api/Patient/GetNSProcedureByTCIPID?tcIPID=${tcipID}`,
      providesTags: ["NS_Details"],
    }),

    getIPNSLookUps: builder.query({
      query: () => `api/Patient/GetNursingProcedureLookUps`,
      providesTags: ["NS_Details"],
    }),

    getIPPtLookUps: builder.query({
      query: () => `api/Patient/GetPTProcedureLookUps`,
      providesTags: ["PT_Details"],
    }),
    getNSNurse: builder.query({
      query: () => `api/Common/GetEmployeesByRole?roleID=3`,
      providesTags: ["NS_Details"],
    }),
    getPTNurse: builder.query({
      query: () => `api/Common/GetEmployeesByRole?roleID=3`,
      providesTags: ["PT_Details"],
    }),

    getProcedurestatusdropDown: builder.query({
      query: () => `api/Common/GetDropdown?dropDownGroup=Procedurestatus`,
      providesTags: ["NS_Details"],
    }),

    getVisitTypes: builder.query({
      query: (VisitType) => `api/Common/GetDropdown?dropDownGroup=${VisitType}`,
    }),

    getDrugsByID: builder.query({
      query: (tcIPID) => `api/Patient/GetDrugsByID?id=${tcIPID}`,
      providesTags: ["Drugs", "ADDED_DRUGS"],
    }),

    getPrescriptionByID: builder.query({
      query: (tcIPID) => `api/Patient/GetPrescriptionByID?id=${tcIPID}`,
      providesTags: ["Drugs", "GENERATE_PRESCRIPTION"],
    }),

    getPatientDischargeSummary: builder.query({
      query: (tcIPID) =>
        `api/Patient/GetPatientDishchargeSummaryByTCIPID?tcIPID=${tcIPID}`,
      providesTags: ["PatientAdmission"],
    }),
    getApprovedDoctors: builder.query<IApprovedDoctors[], void>({
      query: () =>
        `api/Employee/GetApprovedDoctors`,
      providesTags: ["APPROVED_DOCTORS"]
    }),
    GetLabBillingDetails: builder.query({
      query: (uhid) =>
        `api/Patient/GetLabBillingDetailsByID?billingID=${uhid}`,
      providesTags: ["ADDED_LAB_TESTS", "DoctorNotes"]
    }),
    GetCountsForLabBilling: builder.query({
      query: (ID) =>
        `api/Patient/GetCountsForLabBilling?billingID=${ID}`,
      providesTags: ["Payments"]
    }),
    postUpdateRolePrivilege: builder.mutation({
      query: (updateRole) => ({
        url: `/api/Common/UpdateRolePrivileges`,
        method: "POST",
        body: updateRole,
      }),
      invalidatesTags: ["Role"],
    }),

    postUpdateEmployeeRole: builder.mutation({
      query: (updateRole) => ({
        url: `/api/Common/UpdateEmployeeRole`,
        method: "POST",
        body: updateRole,
      }),
      invalidatesTags: ["EmployeeRole"],
    }),

    postPrivilege: builder.mutation({
      query: (updatePrivilege) => ({
        url: `/api/Common/AddPrivilege`,
        method: "POST",
        body: updatePrivilege,
      }),
      invalidatesTags: ["Privileges"],
    }),

    postUpdateRequestStatus: builder.mutation({
      query: (args) => ({
        url: `/api/Patient/UpdateRequestStatusByID?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["OutPatient", "PatientAdmission"],
    }),

    postDoctorVisitNotes: builder.mutation({
      query: (postNotes) => ({
        url: `/api/Employee/AddOrUpdateDoctorVisit`,
        method: "POST",
        body: postNotes,
      }),
      invalidatesTags: ["DoctorNotes"],
    }),

    postIPRoomDetailsByTCIPID: builder.mutation({
      query: (postRoomDetails) => ({
        url: `/api/Patient/AddOrUpdateIPRoomDetailsByTCIPID`,
        method: "POST",
        body: postRoomDetails,
      }),
      invalidatesTags: ["RoomDetails"],
    }),

    postPTDetails: builder.mutation({
      query: (postRoomDetails) => ({
        url: `api/Patient/AddOrUpdatePTProcedure`,
        method: "POST",
        body: postRoomDetails,
      }),
      invalidatesTags: ["PT_Details"],
    }),

    postNSDetails: builder.mutation({
      query: (postRoomDetails) => ({
        url: `api/Patient/AddOrUpdateNSProcedure`,
        method: "POST",
        body: postRoomDetails,
      }),
      invalidatesTags: ["NS_Details"],
    }),

    postUpdateDrug: builder.mutation({
      query: (args) => ({
        url: `api/Patient/UpdateDrug?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["ADDED_DRUGS"],
    }),

    postIPRoomCheckOutDetails: builder.mutation({
      query: (checkOutDetails) => ({
        url: `api/Patient/UpdateIPRoomCheckOutDetails`,
        method: "POST",
        body: checkOutDetails,
      }),
      invalidatesTags: ["RoomDetails"],
    }),

    updateRolePrivileges: builder.mutation<
      void,
      { RolePrivilegeID: number; HasPrivilege: boolean }[]
    >({
      query: (privileges) => ({
        url: "/api/Common/UpdateRolePrivileges",
        method: "POST",
        body: { rolePrivilege: privileges },
      }),
      invalidatesTags: ["ROLE_PRIVILEGES"],
    }),

    updateHimsRole: builder.mutation({
      query: (updateRole) => ({
        url: `/api/Common/UpdateHIMSRole`,
        method: "POST",
        body: updateRole,
      }),
      invalidatesTags: ["Role"],
    }),

    AddOrUpdateExternalEmployee: builder.mutation({
      query: (addEmployee) => ({
        url: `/api/Employee/AddOrUpdateExternalEmployee`,
        method: "POST",
        body: addEmployee,
      }),
      invalidatesTags: ["Users"],
    }),


    updatePatientAdmissionDate: builder.mutation({
      query: (updateAdmission) => ({
        url: `api/Patient/UpdatePatientAdmissionDate`,
        method: "POST",
        body: updateAdmission,
      }),
      invalidatesTags: ["PatientAdmission"],
    }),

    addOrUpdateVitals: builder.mutation<
      void,
      {
        patientID: string;
        vitals: IVital[];
      }
    >({
      query: (body) => ({
        url: "api/Patient/AddOrUpdatePatientVitals",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PATIENT_VITALS"],
    }),

    addOrUpdateIPVitals: builder.mutation<
      void,
      {
        vitalsJson: [
          {
            vitalDDSID: 0;
            value: string;
          }
        ];
        createBy: string;
        modifiedBy: string;
        categoryID: 0;
        categoryRefID: string;
      }[]
    >({
      query: (body) => ({
        url: "api/Patient/AddUpdateVitals",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PATIENT_VITALS"],
    }),

    deleteHimsRole: builder.mutation({
      query: (args) => ({
        url: `/api/Common/DeleteHIMSRole?${getParams(args)}`,
        method: "GET",
      }),
      invalidatesTags: ["Role"],
    }),

    deleteNS: builder.mutation({
      query: (args) => ({
        url: `api/Patient/DeleteNSProcedureByID?${getParams(args)}`,
        method: "GET",
      }),
      invalidatesTags: ["NS", "NS_Details"],
    }),

    deletePt: builder.mutation({
      query: (args) => ({
        url: `api/Patient/DeletePTProcedureByID?${getParams(args)}`,
        method: "GET",
      }),
      invalidatesTags: ["PT", "PT_Details"],
    }),

    deleteHimsEmployee: builder.mutation({
      query: (args) => ({
        url: `/api/Employee/DeleteHIMSEmployee?${getParams(args)}`,
        method: "GET",
      }),
      invalidatesTags: ["Users"],
    }),

    deleteDrug: builder.mutation({
      query: (args) => ({
        url: `/api/Patient/DeleteDrug?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["Drugs", "DoctorNotes"],
    }),

    //   getDoctorAppointments: builder.mutation({
    //   query: (args) => ({
    //     url: `api/Patient/GetAppointmentsByDoctorID?doctorID=${getParams(args)}`,
    //     method: "POST",
    //   }),
    //   invalidatesTags: ["PatientAdmission"],
    // }),

    addLabTests: builder.mutation<
      void,
      {
        tcopid: string | null;
        tcipid: string | null;
        patientID: string;
        labTestIDs: string;
        createdUserID: string;
      }
    >({
      query: (body) => ({
        url: "/api/Patient/AddLabTestsForPatient",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ADDED_LAB_TESTS", "DoctorNotes"],
    }),
    AddLabTestsAsService: builder.mutation<
      void,
      {
        tcopid: string | null;
        tcipid: string | null;
        patientID: string;
        labTestIDs: string;
        createdUserID: string;
      }
    >({
      query: (body) => ({
        url: "/api/Patient/AddLabTestsAsService",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ADDED_LAB_TESTS", "DoctorNotes"],
    }),
    addIndentSRN: builder.mutation({
      query: (body) => ({
        url: `/api/Patient/AddIndentSRN`,
        method: "POST",
        body
      }),
      invalidatesTags: ["ADD_INDENT_SRN", "DoctorNotes", "ADDED_DRUGS", "Drugs"],
    }),

    getAddedLabTests: builder.query<
      IAddedLabTest[],
      { tcOPID: string | null; tcIPID: string | null }
    >({
      query: (params) =>
        `api/Patient/GetLabTestsForPatient?${getParams(params)}`,
      providesTags: ["ADDED_LAB_TESTS", "DoctorNotes", "UPLOAD"],
    }),

    getDiscountDetails: builder.query({
      query: (tcIPID) =>
        `api/Patient/GetDiscountDetailsByTCIPID?tcipID=${tcIPID}`,
      providesTags: ["Discount"],
    }),
    GetPaymentDetailsByLabBillingID: builder.query({
      query: (billingID) =>
        `api/Patient/GetPaymentDetailsByLabBillingID?billingID=${billingID}`,
      providesTags: ["Discount", "Payments"],
    }),
    DownloadLabReportByID: builder.query({
      query: (params) => ({
        url: `api/Patient/DownloadLabReportByID?${getParams(params)}`,
        responseHandler: (response: any) => response.text(),
      })
    }),

    // getSTNDetails: builder.query({
    //   query: (tcIPID) =>
    //     `api/Patient/GetRaisedIndentByTCIPID?TcipId=${tcIPID}`,
    //   providesTags: ["Discount"],
    // }),

    searchInventory: builder.query<
      IDrug[],
      {
        searchText: string;
        locationID?: number;
        value?: number;
        searchType?: string;
        pageIndex?: number;
        pageSize?: number;
        planID?: number;
      }
    >({
      query: ({
        searchText,
        locationID = 1,
        value = 0,
        searchType = "Service",
        pageIndex = 1,
        pageSize = 50,
        planID = 0,
      }) => ({
        url: "api/Patient/SearchInventory",
        method: "POST",
        body: {
          searchText,
          value,
          searchType,
          pageIndex,
          pageSize,
          planID,
          locationID,
        },
      }),
    }),

    addDrugs: builder.mutation<void, IAddPatientDrugPayload[]>({
      query: (body) => ({
        url: "api/Patient/AddPatientDrug",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ADDED_DRUGS", "DoctorNotes"],
    }),

    addPrescribedDrugs: builder.mutation<void, IAddPatientDrugPayload[]>({
      query: (body) => ({
        url: "api/Patient/AddPatientPrescribedDrug",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ADDED_DRUGS", "DoctorNotes"],
    }),

    addDischargePrescribedDrugs: builder.mutation<
      void,
      IAddPatientDrugPayload[]
    >({
      query: (body) => ({
        url: "api/Patient/AddDischargePrescribedDrug",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ADDED_DRUGS"],
    }),

    generatePrescription: builder.mutation({
      query: (body) => ({
        url: "api/Patient/GeneratePrescription",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GENERATE_PRESCRIPTION", "Drugs", "DoctorNotes"],
    }),
    addPatientDiscount: builder.mutation<void, IAddPatientDiscount>({
      query: (body) => ({
        url: "api/Patient/AddPatientDiscount",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Discount", "Payments"],
    }),
    delPatientDiscount: builder.mutation({
      query: (args) => ({
        url: `api/Patient/DelPatientDiscount?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["Discount"],
    }),
    DeleteBlobUrlForLabTest: builder.mutation({
      query: (args) => ({
        url: `api/Patient/DeleteBlobUrlForLabTest?${getParams(args)}`,
        method: "POST",
      }),
      invalidatesTags: ["UPLOAD"],
    }),
    addOrDeleteApprovedDoctors: builder.mutation<void, IAddApprovedDoctor>({
      query: (body) => ({
        url: "api/Employee/AddOrUpdateApprovedDoctors",
        method: "POST",
        body,
      }),
      invalidatesTags: ["APPROVED_DOCTORS"],
    }),
    AddOrUpdateQualification: builder.mutation({
      query: (body) => ({
        url: "api/Common/AddOrUpdateQualification",
        method: "POST",
        body,
      }),
      invalidatesTags: ["QUALIFICATION"],
    }),
    AddOrUpdateSpecialization: builder.mutation({
      query: (body) => ({
        url: "api/Common/AddOrUpdateSpecialization",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SPECILIZATION"],
    }),
    UpdateEmployee: builder.mutation({
      query: (body) => ({
        url: "api/Employee/UpdateEmployee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users", "Privileges",]
    }),
    DeleteSpecialization: builder.mutation({
      query: (body) => ({
        url: "api/Common/DeleteSpecialization",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SPECILIZATION"],
    }),
    DeleteQualification: builder.mutation({
      query: (body) => ({
        url: "api/Common/DeleteQualification",
        method: "POST",
        body,
      }),
      invalidatesTags: ["QUALIFICATION"],
    }),
    FileUpload: builder.mutation({
      query: (formData) => ({
        url: "/api/Patient/FileUpload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["UPLOAD"],
    }),


  }),
});

export const {
  useGetDropdownQuery,
  useGetStateQuery,
  useGetLabStatusQuery,
  useGetDistrictQuery,
  useGetDiseaseQuery,
  useGetAllPatientsQuery,
  useGetDischageSummaryListQuery,
  useGetPatientQuery,
  useGetIPBillingsQuery,
  useGetPaymentsQuery,
  useGetDoctorsByServiceQuery,
  useGetSpecializationsQuery,
  useGetQualificationQuery,
  useGetDischargeSummaryQuery,
  useGetDoctorByIDQuery,
  useGetDoctorAvailabilityQuery,
  useGetHIMSEmployeeDetailsQuery,
  useGetOutPatientListQuery,
  useGetDoctorDetailsQuery,
  useGetOutPatientSummaryQuery,
  useGetPatientByMobileQuery,
  useGetOutPatientBYPatientIDQuery,
  useGetAllRolePrivilegesQuery,
  useGetOutPatientByOPIDQuery,
  useGetHimsRolesQuery,
  useGetBillingInfoQuery,
  useGetIPBillingDetailsBYTCIPIDQuery,
  useGetHimsPrivilegesQuery,
  useGetPrivilegesByRoleQuery,
  useGetAllEmployeesQuery,
  useGetLocationsQuery,
  useGetHimsScreensQuery,
  useGetDoctorAppointmentsQuery,
  useGetPatientVitalsQuery,
  useGetOutPatientForNurseQuery,
  useGetCountsForDashboardQuery,
  useGetCountsForIPBillingQuery,
  useGetOPSummaryQuery,
  useGetBedDetailsQuery,
  useGetRoomDetailsQuery,
  useGetLabServiceGroupsQuery,
  useGetDoctorVisitNotesQuery,
  useGetAddedLabTestsQuery,
  useGetIPRoomDetailsQuery,
  useGetDoctorVisitByvisitIDQuery,
  useGetVisitTypesQuery,
  useGetDrugsByIDQuery,
  useGetPrescriptionByIDQuery,
  useSearchInventoryQuery,
  useGetDrugsByVitalIDQuery,
  useGetVitalsByTCIPIDQuery,
  useGetPatientDischargeSummaryQuery,
  useGetAllCouponsQuery,
  useDeletePtMutation,
  useGetIPPtLookUpsQuery,
  useGetPTNurseQuery,
  useGetPTProcedureDetailsQuery,
  useGetProcedurestatusdropDownQuery,
  useGetBillingGroupQuery,
  useGetDiscountDetailsQuery,
  useGetSTNDetailsQuery,
  useGetIndentDetailsByIndentNoQuery,
  useGetApprovedDoctorsQuery,
  useGetHIMSEmployeeDetailsByADIDQuery,
  useGetLabBillingInfoQuery,
  useGetLabBillingDetailsQuery,
  useGetPaymentDetailsByLabBillingIDQuery,
  useGetCountsForLabBillingQuery,

  useLazyGetPatientByMobileQuery,
  useLazyGetHIMSEmployeeDetailsByEmailIDQuery,
  useLazyGetPatientQuery,
  useLazyGetDownloadDischargeSummaryByPatientIDQuery,
  useLazyGetDownloadLabReportPatientIDQuery,
  useLazyGetDownloadPaymentDetailsQuery,
  useLazyGetDownloadOPSummaryQuery,
  useLazyGetDoctorSlotAvailabilityQuery,
  useLazyGetDoctorAppointmentsQuery,
  useLazyGetPaymentsQuery,
  useLazyGetBillingInfoQuery,
  useLazyGetLabTestsByServiceGroupIDQuery,
  useLazyGetVitalsDetailsByVitalsIDQuery,
  useLazyGetDownloadInPatientBillQuery,
  useLazyGetChargesByBillingGroupQuery,
  useLazyGetIndentDetailsByIndentNoQuery,
  useLazyGetRaisedIndentDetailsByRividaQuery,
  useLazyGetIndentDetailsQuery,
  useLazyGetUserEmailValidationQuery,
  useLazyDownloadLabReportByIDQuery,
  useLazyDownloadLabReportQuery,

  usePostNewRolePrivilegeMutation,
  usePostNSDetailsMutation,
  usePostPaymentMutation,
  usePostDataMutation,
  useAddOrUpdatePatientMutation,
  useAddOrUpdateEmployeeMutation,
  usePostOutPatientMutation,
  usePostOutPatientTimeSlotMutation,
  usePostStatusUpdateMutation,
  usePostDischargeMutation,
  usePostAdmissionMutation,
  usePostCancelAppointmentMutation,
  usePostUpdateEmployeeRoleMutation,
  usePostPrivilegeMutation,
  usePostUpdateRequestStatusMutation,
  usePostDoctorVisitNotesMutation,
  usePostIPRoomDetailsByTCIPIDMutation,
  usePostPTDetailsMutation,
  usePostUpdateDrugMutation,
  useUpdateRolePrivilegesMutation,
  useUpdateHimsRoleMutation,
  useUpdatePatientAdmissionDateMutation,
  usePostRaiseIndentMutation,
  // useGetDoctorAppointmentsMutation,
  useAddOrUpdateVitalsMutation,
  useAddOrUpdateIPVitalsMutation,
  useAddLabTestsMutation,
  useAddDrugsMutation,
  useAddPrescribedDrugsMutation,
  useAddDischargePrescribedDrugsMutation,
  useGeneratePrescriptionMutation,
  usePostIPRoomCheckOutDetailsMutation,
  usePostLabTestsUpdateMutation,
  useDeleteNSMutation,
  useGetIPNSLookUpsQuery,
  useGetNSNurseQuery,
  useGetNSProcedureDetailsQuery,
  useAddPatientDiscountMutation,
  useAddIndentSRNMutation,
  useAddOrDeleteApprovedDoctorsMutation,
  useAddOrUpdateExternalEmployeeMutation,
  useAddExternalUserLoginMutation,
  useAddOrUpdateQualificationMutation,
  useAddOrUpdateSpecializationMutation,
  useUpdateEmployeeMutation,
  useAddLabTestsAsServiceMutation,
  useFileUploadMutation,

  useDeleteHimsRoleMutation,
  useDeleteHimsEmployeeMutation,
  useDeleteDrugMutation,
  useDelPatientDiscountMutation,
  useDeleteQualificationMutation,
  useDeleteSpecializationMutation,
  useDeleteBlobUrlForLabTestMutation,
} = api;

// export const makeApiRequest = (endpoint: string, method: string = 'GET') => ({
//     type: 'API_REQUEST',
//     payload: {
//       endpoint,
//       method,

//     },
//   });
// export default makeApiRequest;

// TODO: move this to util file
const getParams = (
  paramsObj: any
  //    {
  //   [key: string]: string | number | null | undefined;
  // }
) =>
  Object.keys(paramsObj)
    .filter((key) => paramsObj[key] !== null && paramsObj !== undefined)
    .map((key) => `${key}=${paramsObj[key]}`)
    .join("&");
