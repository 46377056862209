import {
  Anchor,
  Button,
  Checkbox,
  CheckboxOptionType,
  Col,
  Divider,
  Input,
  InputRef,
  Modal,
  Row,
  Space,
  Table,
  TableColumnType,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { razor_pay_cancle, razor_pay_device_cancle } from "../../../base_urls";
import { useIsAllowed } from "../../../hooks/role.hooks";
import { paymentStatusColorMap, statusColorMap } from "../../../models/Colors";
import { PRIVILEGE, Role } from "../../../models/PrivilegeRoles";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetOutPatientBYPatientIDQuery,
  useGetOutPatientForNurseQuery,
  useGetOutPatientListQuery,
  useLazyGetBillingInfoQuery,
  useLazyGetDoctorAppointmentsQuery,
  useLazyGetDownloadPaymentDetailsQuery,
} from "../../../services/ApiSlice";
import { RootState } from "../../../store";
import { FilterDropdownProps } from "antd/es/table/interface";
import { FormatDateTime } from "../../../Utils/FormatDateTime";

const { Text } = Typography;
type DataIndex = keyof IOutPatient;

const AppointmentDetails = () => {
  const { id }: any = useParams();
  const user = useSelector((state: RootState) => selectUser(state));
  const [cancledStatus, setCancledStatus] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const isAllowed = useIsAllowed();
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IOutPatient> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<i className="fa-solid fa-magnifying-glass" />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),

    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    filterIcon: (
      <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: 5 }} />
    ),
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const { data: allPatients, isFetching: isAllPatientsFetching } =
  //   useGetOutPatientListQuery(1);
  const [
    getPaymentDetails,
    {
      data: printData,
      isFetching: isPrintDataFetching,
      isSuccess: isPrintDataSuccess,
      isLoading: downloadPrintData,
    },
  ] = useLazyGetDownloadPaymentDetailsQuery();

  const handleDownloadPaymentDetails = (record: any) => {
    getPaymentDetails(record?.paymentDetailsID);
  };
  useEffect(() => {
    if (printData) {
      window.open(printData);
    }
  }, [printData]);

  const isRoleVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const { data: allPatients, isFetching: isAllPatientsFetching } =
    useGetOutPatientBYPatientIDQuery({ patientID: null, locationID: 1 });

  const { data: allOutPatientForNurse, isFetching: isallOutPatientForNurse } =
    useGetOutPatientForNurseQuery("");

  const [allDoctorAppointments, doctorAppointments] =
    useLazyGetDoctorAppointmentsQuery();

  useEffect(() => {
    allDoctorAppointments(user?.himsEmployeeID);
  }, [user]);
  useEffect(() => {}, [doctorAppointments.isFetching]);

  const [paymentDetailsExpanding, PaymentDetailsExpanding] =
    useLazyGetBillingInfoQuery();

  const patientDataID =
    allPatients?.find((option: any) => option?.uhid === id)?.patientID || 0;

  const DischargeDate = (dateString: string | number | Date) => {
    if (!dateString) return " - ";
    interface LocationState {
      value1: string;
      value2: string;
      value3: string;
    }
    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);
    const [month, day, year, time] = formattedDate.split(/[\s,]+/);
    const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");
    return `${day}-${month}-${year} ${timeWithAmPm}`;
  };
  const AdmissionDate = (dateString: string | number | Date) => {
    if (!dateString) return " - ";
    interface LocationState {
      value1: string;
      value2: string;
      value3: string;
    }
    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);

    const [month, day, year, time] = formattedDate.split(/[\s,]+/);
    const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");
    return `${day}-${month}-${year}`;
  };

  // const filteredPatients = useMemo(() => {
  //   if (Array.isArray(allPatients)) {
  //     const searchString = searchQuery?.toLocaleLowerCase().trim();
  //     return allPatients?.filter(
  //       (patient: any) =>
  //         patient.patientUHID?.toLowerCase().trim().includes(searchString) ||
  //         patient.patientName?.toLowerCase().trim().includes(searchString)
  //     );
  //   }
  //   return [];
  // }, [allPatients, searchQuery]);

  const filteredDoctorPatients = useMemo(() => {
    if (Array.isArray(doctorAppointments?.data)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return doctorAppointments?.data?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [doctorAppointments?.data, searchQuery]);

  const filteredNursePatients = useMemo(() => {
    if (Array.isArray(allOutPatientForNurse)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allOutPatientForNurse?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allOutPatientForNurse, searchQuery]);

  const handleCancle = async (
    PaymentDetailsID: string,
    userName: string,
    p2pRequestId: string
  ) => {
    try {
      const cancleDeviceRequestData = {
        username: userName,
        origP2pRequestId: p2pRequestId,
        pushTo: {
          deviceId: "null|ezetap_android",
        },
      };
      const cancleRequestData = {
        PaymentDetailsID: PaymentDetailsID,
      };
      const cancleDevicePayment = await fetch(razor_pay_device_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleDeviceRequestData),
      });

      const responseData = await cancleDevicePayment.json();
      if (responseData.success) {
        setCancledStatus(false);
      }

      const canclePayment = await fetch(razor_pay_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleRequestData),
      });
      if (!canclePayment.ok) {
        throw new Error("Failed to post p2p data to response status API");
      }
      if (!cancleDevicePayment.ok) {
        throw new Error(
          "Failed to post cancle device api  to response status API"
        );
      }
      // Handle success
    } catch (error) {
      console.error("Failed to post data to second API:", error);
    }
    // refetch();
  };

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allPatients)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allPatients?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allPatients, searchQuery]);

  const isRoleBasedVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const expandedRowRender = () => {
    const columns: any = [
      {
        title: "Payment For",
        dataIndex: "billingType",
        render: (text: string) => <span>{text || "-"}</span>,
        // sorter: (
        //   a: { paymentForDDSValue: string },
        //   b: { paymentForDDSValue: string }
        // ) => a.paymentForDDSValue?.length - b.paymentForDDSValue?.length,
        // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
      },
      {
        title: "Total Amount",
        dataIndex: "actualAmount",
        render: (text: number) => <span>{text || "-"}</span>,
        // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
        //   a.actualAmount - b.actualAmount,
      },

      {
        title: "Due",
        dataIndex: "dueAmount",
        render: (text: number) => <span>{text || "-"}</span>,
        // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
        //   a.actualAmount - b.actualAmount,
      },

      {
        title: "Paid",
        dataIndex: "paidAmount",
        render: (text: number) => <span>{text || "-"}</span>,
        // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
        //   a.actualAmount - b.actualAmount,
      },
      {
        title: "Payment Status",
        dataIndex: "paymentStatusValue",
        render: (paymentStatusValue: string) => (
          <Tag color={paymentStatusColorMap[paymentStatusValue?.toUpperCase()]}>
            {paymentStatusValue}
          </Tag>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (record: any) => (
          <Space>
            {/* {record.paymentStatusValue === "Paid" && (
              <Link to={""}>
                <span
                  className="link"
                  onClick={() => handleDownloadPaymentDetails(record)}
                >
                 
                  Invoice
                </span>
              </Link>
            )} */}
            {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
              record.paymentStatusValue !== "Paid" && (
                <Link to={`/payment-details/${record.id}`}>
                  <span className="link">Pay</span>
                </Link>
              )}
            {/* {[88, 89].includes(record.paymentStatusDDSID) && (
              <Link to={""}>
                <span
                  className="link"
                  onClick={() => {
                    handleCancle(
                      record.paymentDetailsID,
                      record.deviceUserName,
                      record.p2PRequestID
                    );
                  }}
                >
                  cancel
                </span>
              </Link>
            )} */}
          </Space>
        ),
        // sorter: true,
      },
    ];
    return (
      <Table
        size="small"
        loading={PaymentDetailsExpanding.isFetching}
        columns={columns}
        dataSource={PaymentDetailsExpanding?.data}
        pagination={false}
        rowClassName={(record, index) => {
          return record?.paymentStatus === "Paid" ? "green" : "red";
        }}
        style={{ padding: "30px" }}
      />
    );
  };

  const columns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      key: "tcopid",
      dataIndex: "tcopid",
      ...getColumnSearchProps("tcopid"),
      render: (text: string, record) =>
        isAllowed(PRIVILEGE.OutPatient_View) && (
          <Link className="hyperlink" to={`/details-tab/${record.tcopid}`}>
            <span>{text || "No UHID"}</span>
          </Link>
        ),
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "patientName",
      //dataIndex: "patientName",
      ...getColumnSearchProps("patientName"),

      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      key: "appointmentTime",
      dataIndex: "appointmentTime",

      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "doctorName",
      // dataIndex:"doctorName",
      ...getColumnSearchProps("doctorName"),
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
        a.doctorName?.length - b.doctorName?.length,
    },
    // {
    //   title: "Payment Status",
    //   key: "opStatus",
    //   render: ({ opStatus }) => (
    //     <Tag color={paymentStatusColorMap[opStatus?.toUpperCase()]}>
    //       {opStatus}
    //     </Tag>
    //   ),
    // },
    {
      title: "Status",
      key: "opStatus",
      dataIndex: "opStatus",
      render: (opStatus) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.opStatus !== "CANCELLED" && (
            <>
              <Space>
                {/* {isAllowed(PRIVILEGE.OutPatient_View) && (
                  <Link to={`/details-tab/${record.tcopid}`}>
                    <span className="link">View</span>
                  </Link>
                )} */}

                {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                  record?.opStatus !== "Completed" &&
                  record?.opStatus !== "Cancelled" && (
                    <Link to={`/payment-details/${record.tcopid}`}>
                      <span className="link">Pay</span>
                    </Link>
                  )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];
  const defaultCheckedList = columns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.filter(({ key }) =>
    checkedList.includes(key as string)
  );

  const doctorColumns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      dataIndex: "tcopid",
      ...getColumnSearchProps("tcopid"),
      render: (text: string, record: any) =>
        isAllowed(PRIVILEGE.OutPatient_View) && (
          <Link className="hyperlink" to={`/details-tab/${record.tcopid}`}>
            <span>{text || "No UHID"}</span>
          </Link>
        ),
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      ...getColumnSearchProps("patientName"),
      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    // {
    //   title: "Doctor Name",
    //   key: "Doctor",
    //   render: ({ doctorName, docSpecialization }) => (
    //     <Space direction="vertical" size={0}>
    //       {doctorName && <Text strong>{doctorName}</Text>}
    //       {docSpecialization && (
    //         <Text type="secondary">{docSpecialization}</Text>
    //       )}
    //     </Space>
    //   ),
    //   sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
    //     a.doctorName?.length - b.doctorName?.length,
    // },
    // {
    //   title: "Payment Status",
    //   key: "paymentStatus",
    //   render: ({ paymentStatus }) => (
    //     <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
    //       {paymentStatus}
    //     </Tag>
    //   ),
    // },
    {
      title: "Status",
      key: "opStatus",
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.requestStatus !== "CANCELLED" && (
            <>
              {" "}
              <Space>
                {/* {isAllowed(PRIVILEGE.OutPatient_View) && (
                  <Link to={`/details-tab/${record.tcopid}`}>
                    <span className="link">View</span>
                  </Link>
                )} */}

                {isRoleBasedVisible([Role.Mod, Role.Admin]) && (
                  <Link to={`/payment-details/${record.tcopid}`}>
                    <span className="link">Pay</span>
                  </Link>
                )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];

  const nurseColumns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      dataIndex: "tcopid",

      render: (text: string, record) =>
        isAllowed(PRIVILEGE.OutPatient_View) && (
          <Link to={`/details-tab/${record.tcopid}`}>
            <span>{text || "No UHID"}</span>
          </Link>
        ),
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "Doctor",
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
        a.doctorName?.length - b.doctorName?.length,
    },
    // {
    //   title: "Payment Status",
    //   key: "paymentStatus",
    //   render: ({ paymentStatus }) => (
    //     <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
    //       {paymentStatus}
    //     </Tag>
    //   ),
    // },
    {
      title: "Status",
      key: "opStatus",
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.requestStatus !== "CANCELLED" && (
            <>
              <Space>
                {/* {isAllowed(PRIVILEGE.OutPatient_View) && (
                  <Link to={`/details-tab/${record.tcopid}`}>
                    <span className="link">View</span>
                  </Link>
                )} */}

                {isRoleBasedVisible([Role.Mod, Role.Admin]) && (
                  <Link to={`/payment-details/${record.tcopid}`}>
                    <span className="link">Pay</span>
                  </Link>
                )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };

  const onExpand = (expanded: boolean, record: IOutPatient) => {
    if (expanded) {
      paymentDetailsExpanding(record?.tcopid);
      setSelectedRow(record?.tcopid);
    } else setSelectedRow(null);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCheckedList(defaultCheckedList);
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Appointments</h3>
                  {/* <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Appointments</li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <Row>
                    <Col>
                      <div>
                        <Input
                          placeholder="Search Patient"
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* <div>
                <div className="row filter-row">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block">
                      <input
                        type="text"
                        // value={searchQuery}
                        onChange={handleInputChange}
                        className="form-control floating"
                        placeholder="Search by ID/Patient Name"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="row">
              <>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <Tag color="geekblue" onClick={showModal}>
                      Select Columns
                    </Tag>
                    <Modal
                      //title="Columns"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <Divider>Columns</Divider>
                      <Checkbox.Group
                        value={checkedList}
                        options={options as CheckboxOptionType[]}
                        onChange={(value) => {
                          setCheckedList(value as string[]);
                        }}
                      />
                    </Modal>
                  </div>
                </div>
              </>
              <div className="col-md-12">
                <div className="table-responsive">
                  {isRoleVisible([Role.Admin, Role.Mod]) && (
                    <Table
                      pagination={
                        filteredPatients?.length > 10 ? undefined : false
                      }
                      loading={isAllPatientsFetching}
                      columns={newColumns}
                      dataSource={filteredPatients}
                      rowKey="tcopid"
                      expandable={{
                        expandedRowRender,
                        // defaultExpandedRowKeys: ["0"],
                        expandedRowKeys: selectedRow ? [selectedRow] : [],
                        onExpand: onExpand,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {isRoleVisible([Role.Nurse]) && (
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      pagination={
                        filteredNursePatients?.length > 10 ? undefined : false
                      }
                      loading={isallOutPatientForNurse}
                      columns={nurseColumns}
                      dataSource={filteredNursePatients}
                      rowKey="outPatientID"
                    />
                  </div>
                </div>
              </div>
            )}
            {isRoleVisible([Role.Doctor]) && (
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      pagination={
                        filteredDoctorPatients?.length > 10 ? undefined : false
                      }
                      loading={doctorAppointments?.isFetching}
                      columns={doctorColumns}
                      dataSource={filteredDoctorPatients}
                      rowKey="outPatientID"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
