import type { CheckboxProps, FormProps, TableProps } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  List,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import type { SearchProps } from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useList } from "../../../hooks/util.hooks";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useAddLabTestsAsServiceMutation,
  useAddLabTestsMutation,
  useGetLabServiceGroupsQuery,
  useLazyGetLabTestsByServiceGroupIDQuery,
} from "../../../services/ApiSlice";

const { Search } = Input;
const { Text, Paragraph, Title } = Typography;

type FormType = {
  serviceGroup: string;
  currentSelectedTests: ILabTest["code"][];
};

function NewLabRequest() {
  const ServiceGroup = useGetLabServiceGroupsQuery();
  const [getLabTests, LabTests] = useLazyGetLabTestsByServiceGroupIDQuery();
  const [addLabTests, AddLabTests] = useAddLabTestsMutation();
  const [addLabTestsAsService, AddLabTestsAsService] =
    useAddLabTestsAsServiceMutation();

  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [selectedTests, selectedTestsActions] = useList<ILabTest>();
  console.log(selectedTests);
  const [formInstance] = Form.useForm<FormType>();

  const currentSelectedGroup = Form.useWatch("serviceGroup", formInstance);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    let prvSelectedTests: ILabTest["code"][] = [];
    if (Array.isArray(LabTests.data)) {
      // Add previous selected tests
      prvSelectedTests = LabTests.data
        .filter(({ code }) =>
          selectedTests.some(({ code: code_ }) => code === code_)
        )
        .map(({ code }) => code);
    }
    formInstance.setFieldValue("currentSelectedTests", prvSelectedTests);
  }, [LabTests.data, selectedTests]);

  useEffect(() => {
    if (currentSelectedGroup) {
      getLabTests(currentSelectedGroup);
    }
  }, [currentSelectedGroup]);

  useEffect(() => {
    if (AddLabTests.isSuccess) {
      if (location.state?.TCOPID) {
        navigate(`/details-tab/${location.state?.TCOPID}`);
      } else {
        const from = location.state?.from;
        navigate(from ?? -1);
      }
      // const { from } = location.state;
    }
  }, [AddLabTests]);
  useEffect(() => {
    if (AddLabTestsAsService.isSuccess) {
      if (location.state?.patientID) {
        navigate(`/lab-requests`);
      } else {
        const from = location.state?.from;
        navigate(from ?? -1);
      }
      // const { from } = location.state;
    }
  }, [AddLabTestsAsService]);

  const currentSelectedTests = Form.useWatch(
    "currentSelectedTests",
    formInstance
  );

  useEffect(() => {
    if (Array.isArray(LabTests.data) && currentSelectedTests.length) {
      // Get selected tests data
      const newSelectedTests: ILabTest[] = [];

      for (const code_ of currentSelectedTests) {
        // check if already added
        if (!selectedTests.some(({ code }) => code === code_)) {
          const test = LabTests.data?.find(({ code }) => code === code_);
          if (test) newSelectedTests.push(test);
        }
      }

      // Add to 'selectedTests'
      selectedTestsActions.append(newSelectedTests);
    }
  }, [currentSelectedTests]);

  const onAddTests: FormProps<FormType>["onFinish"] = (values) => {
    const { currentSelectedTests } = values;

    if (Array.isArray(LabTests.data) && currentSelectedTests.length) {
      // Get selected tests data
      const newSelectedTests: ILabTest[] = [];

      for (const code_ of currentSelectedTests) {
        // check if already added
        if (!selectedTests.some(({ code }) => code === code_)) {
          const test = LabTests.data?.find(({ code }) => code === code_);
          if (test) newSelectedTests.push(test);
        }
      }

      // Add to 'selectedTests'
      selectedTestsActions.append(newSelectedTests);
    }
  };

  const onTestRemove = (code_: ILabTest["code"]) => {
    if (code_) {
      // Remove from selectedTests
      selectedTestsActions.filter(({ code }) => code !== code_);
    }
  };
  useEffect(() => {
    if (AddLabTests.isSuccess) {
      onCancel();
    }
  }, [AddLabTests]);

  const onCancel = () => {
    // const { from } = location.state;
    const from = location.state?.from;
    // const returnState = location.state?.returnState;
    let path: string | number = -1,
      state = {};
    if (from && from.includes("inpatient-details")) {
      path = from;
      state = { activeKey: "4" };
    }
    navigate(path as unknown as string, { state });
  };

  const onSubmit = () => {
    // const { TCOPID, TCIPID, patientID, doctorVisitID } = location.state;
    const TCOPID = location.state?.TCOPID;
    const TCIPID = location.state?.TCIPID;
    const patientID = location.state?.patientID;
    const doctorVisitID = location.state?.doctorVisitID;

    const payload = {
      tcopid: TCOPID ?? null,
      tcipid: TCIPID ?? null,
      patientID: patientID,
      labTestIDs: selectedTests.map(({ labTestID }) => labTestID).join(),
      createdUserID: user?.himsEmployeeID ?? "",
      doctorVisitID: doctorVisitID ?? null,
    };
    if (location.state?.flag === 1) {
      addLabTestsAsService(payload);
    } else addLabTests(payload);
  };

  const columns: TableProps<ILabTest>["columns"] = [
    {
      title: "Sl. No.",
      key: "slNo",
      render: (_value, _record, index) => index + 1,
    },
    {
      title: "Test",
      dataIndex: "testName",
    },
    // {
    //   title: "Date",
    //   key: "scheduleDateTime",
    //   render: (text) => FormatDateTime(text),
    // },
    {
      title: "Service Group",
      dataIndex: "serviceGroup",
    },
    {
      title: "Sample Type",
      dataIndex: "sampleType",
    },
    {
      title: "Price",
      dataIndex: "mrp",
      render: (price) => (
        <Paragraph>
          <Text type="secondary">₹</Text>{" "}
          <Text strong>{parseFloat(price).toFixed(2)}</Text>
          <Text type="secondary">/-</Text>
        </Paragraph>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_v, record) => (
        <Button type="text" danger onClick={() => onTestRemove(record.code)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Flex vertical gap={24}>
          <Title>New Lab Request</Title>

          <Spin
            spinning={AddLabTests.isLoading || AddLabTestsAsService.isLoading}
            fullscreen
          />

          <Card>
            <Form layout="vertical" form={formInstance} onFinish={onAddTests}>
              <Row gutter={24} align="middle">
                <Col span={6}>
                  <Form.Item name="serviceGroup" label="Service Group">
                    <Select
                      placeholder="Select"
                      disabled={ServiceGroup.isFetching}
                      loading={ServiceGroup.isFetching}
                      style={{ width: "100%" }}
                      options={ServiceGroup.data ?? []}
                      showSearch
                      optionFilterProp="label"
                      fieldNames={{
                        label: "serviceGroupName",
                        value: "serviceGroupID",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="currentSelectedTests" label="Tests">
                    <CheckboxList
                      loading={LabTests.isFetching}
                      list={LabTests.data ?? []}
                      labelKey="testName"
                      valueKey="code"
                      disabledList={selectedTests.map(({ code }) => code)}
                      showFilter={!!LabTests.data?.length}
                    />
                  </Form.Item>
                </Col>
                <Col flex="auto"></Col>
                <Col>
                  {/* <Button htmlType="submit" type="primary">
                    Add
                  </Button> */}
                </Col>
              </Row>
            </Form>
          </Card>

          <Table
            columns={columns}
            dataSource={selectedTests}
            pagination={false}
            rowKey="code"
            scroll={{ x: true }}
          />

          <Row justify="end" gutter={24}>
            <Col>
              <Button onClick={onCancel}>Cancel</Button>
            </Col>
            <Col>
              <Button
                disabled={!selectedTests.length}
                type="primary"
                onClick={onSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Flex>
      </div>
    </div>
  );
}

export default NewLabRequest;

type Props<T> = {
  list: T[];
  onChange?: (checkedValues: any[]) => void;
  value?: any[];
  labelKey: keyof T;
  valueKey: keyof T;
  disabledList: string[];
  loading: boolean;
  showFilter: boolean;
};

const PAGE_SIZE = 10;

function CheckboxList<T>({
  list,
  onChange: onChangeExt,
  value: valueExt,
  labelKey,
  valueKey,
  disabledList,
  loading,
  showFilter = false,
}: Props<T>) {
  const [filteredList, setFilteredList] = useState<T[]>(list);
  const [value, setValue] = useState<T[keyof T][]>(valueExt ?? []);

  useEffect(() => {
    if (onChangeExt) onChangeExt(value);
  }, [value]);

  useEffect(() => {
    if (Array.isArray(valueExt)) setValue(valueExt);
  }, [valueExt]);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const onSearch: SearchProps["onSearch"] = (value) =>
    setFilteredList(
      list.filter((item) =>
        String(item[labelKey]).toLowerCase().includes(value.toLocaleLowerCase())
      )
    );

  const onCheck = (value: T[keyof T]) => {
    setValue((prv) => [...prv, value]);
  };
  const onUncheck = (value: T[keyof T]) => {
    setValue((prv) => prv.filter((value_) => value_ !== value));
  };

  return (
    <List
      loading={loading}
      pagination={
        filteredList.length > PAGE_SIZE ? { pageSize: PAGE_SIZE } : false
      }
      style={{ width: "100%" }}
      header={
        showFilter ? (
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{ width: 200 }}
            enterButton="Search"
          />
        ) : undefined
      }
      grid={{ gutter: 16, column: 2 }}
      dataSource={filteredList}
      renderItem={(item) => {
        const value = item[valueKey];

        const onChange: CheckboxProps["onChange"] = (e) => {
          if (e.target.checked) onCheck(value);
          else onUncheck(value);
        };

        return (
          <List.Item>
            <Checkbox
              checked={valueExt?.includes(value)}
              onChange={onChange}
              value={value}
              disabled={disabledList?.includes(value as string)}
            >
              {item[labelKey] as string}
            </Checkbox>
          </List.Item>
        );
      }}
    />
  );
}

// TODO: create new util for sorting
// const sortBy = (key) => {
//   return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
// };
