import type { FormInstance, FormProps } from "antd";
import { Button, Card, Col, Form, Row, Select, Space, Typography } from "antd";
import type { Dayjs, ManipulateType } from "dayjs";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import DatePickerCarousel from "../../../components/DatePickerCarousel";
import {
  useGetLocationsQuery,
  useGetSpecializationsQuery,
} from "../../../services/ApiSlice";

const { Title } = Typography;

export interface IFilters {
  date: Dayjs | undefined;
  dateTime: Dayjs;
  specialityID: number | null;
  locationID: number;
}

type Props = {
  formInstance: FormInstance<IFilters>;
  onApply: FormProps<IFilters>["onFinish"];
};

function Filters({ formInstance, onApply }: Props) {
  const Specializations = useGetSpecializationsQuery();
  const Locations = useGetLocationsQuery();

  const selectedDate = Form.useWatch("date", formInstance);

  useEffect(() => {
    formInstance.resetFields(["dateTime"]);
  }, [selectedDate]);

  const specializationOptions = useMemo(
    () => getSpecializationOptions(Specializations.data),
    [Specializations]
  );
  const locationOptions = useMemo(
    () => getLocationOptions(Locations.data),
    [Locations]
  );

  const slots = useMemo(() => generateSlots(selectedDate), [selectedDate]);
  const selectedSlot = Form.useWatch("dateTime", formInstance);
  //   useEffect(() => {
  //     const selectedSlot = formInstance.getFieldValue("dateTime");
  //     if (!selectedSlot && slots.length)
  //       formInstance.setFieldValue("dateTime", slots[0]);
  //   }, [slots]);

  return (
    <Card title="Slot">
      <Form
        form={formInstance}
        layout="vertical"
        onFinish={onApply}
        initialValues={{ date: dayjs().startOf("D"), locationID: 1 }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="date" valuePropName="selectedDate">
              <DatePickerCarousel />
            </Form.Item>
            <Form.Item name="dateTime" label="Slots">
              <SlotSelector slots={slots} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name="specialityID"
                label="Specialization"
                initialValue={null}
              >
                <Select
                  loading={Specializations.isFetching}
                  disabled={Specializations.isFetching}
                  showSearch
                  optionFilterProp="label"
                  filterOption
                  options={specializationOptions}
                />
              </Form.Item>

              <Form.Item name="locationID" label="Location" initialValue={null}>
                <Select
                  loading={Locations.isFetching}
                  disabled={Locations.isFetching}
                  showSearch
                  optionFilterProp="label"
                  filterOption
                  options={locationOptions}
                />
              </Form.Item>
              <Row justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={!selectedSlot}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default Filters;

type DayjsArgs = [number, ManipulateType];

const START: DayjsArgs = [0, "h"],
  END: DayjsArgs = [24, "h"],
  SLOT_SIZE: DayjsArgs = [30, "m"];

const generateSlots = (date: Dayjs | undefined): Dayjs[] => {
  const slots: Dayjs[] = [];
  if (date) {
    const now = dayjs(),
      start = date.add(...START),
      end = date.add(...END);

    let slot = start.clone();
    while (slot.isBefore(end)) {
      if (slot.isAfter(now)) slots.push(slot);
      slot = slot.add(...SLOT_SIZE);
    }
  }
  return slots;
};

const getSpecializationOptions = (
  specializations: ISpecialization[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "All", value: null },
  ];
  if (Array.isArray(specializations)) {
    specializations.forEach((specialization) => {
      options.push({
        label: specialization.speciality,
        value: specialization.specialityID,
      });
    });
  }
  return options;
};

const getLocationOptions = (locations: ILocation[] | undefined) => {
  const options: { label: string; value: number | null }[] = [
    // { label: "All", value: null },
  ];
  if (Array.isArray(locations)) {
    locations.forEach((location) => {
      options.push({
        label: location.locationName,
        value: location.locationID,
      });
    });
  }
  return options;
};

type SlotsProps = {
  slots: Dayjs[];
  value?: Dayjs | undefined;
  onChange?: (date: Dayjs) => void;
};

function SlotSelector({ slots, value, onChange }: SlotsProps) {
  return (
    <Row gutter={[12, 12]}>
      {slots.length ? (
        slots.map((slot, index) => (
          <Col key={index} xs={6} sm={6} md={6} lg={4} xl={4} xxl={3}>
            <Button
              style={{ width: "100%" }}
              type={value?.isSame(slot, "m") ? "primary" : "default"}
              onClick={() => {
                if (onChange) onChange(slot);
              }}
            >
              {slot.format("HH:mm")}
            </Button>
          </Col>
        ))
      ) : (
        <Title level={5}>No Slots Available</Title>
      )}
    </Row>
  );
}
