import dayjs from 'dayjs';

export const FormatDateTime = (text: string): string => {
    return dayjs(text).isValid() ? dayjs(text).format("DD-MM-YYYY HH:mm")+" hrs" : "-";
};

export const FormatDate = (text: string): string => {
    return dayjs(text).isValid() ? dayjs(text).format("DD-MM-YYYY") : "-";
};

// export const FormatDateTime = (dateString: string | number | Date) => {
//     if (!dateString) return " - ";

//     const date = new Date(dateString);

//     const options: any = {
//         day: "2-digit",
//         month: "short",
//         year: "numeric",
//         hour: "2-digit",
//         minute: "2-digit",
//         hour12: true,
//     };
//     const formattedDate = new Intl.DateTimeFormat("en", options).format(date);

//     const [month, day, year, time] = formattedDate.split(/[\s,]+/);

//     const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");

//     return `${day}-${month}-${year} ${timeWithAmPm}`;
// };


