import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  add_payment_transaction_details,
  device_list,
  razor_pay_pay,
  razor_pay_status,
} from "../../base_urls";
import { selectUser } from "../../reducers/UserSlice";
import {
  useGetDropdownQuery,
  useGetOutPatientByOPIDQuery,
  useGetPaymentsQuery,
  usePostPaymentMutation,
  usePostStatusUpdateMutation,
} from "../../services/ApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";

interface Props {
  tCIPID: any;
  billingID: number | null;
}
export const AddPayment: React.FC<Props> = (porps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const user = useSelector(selectUser);
  const { data: paymentFor } = useGetDropdownQuery("paymentfor");
  const { data: paymentMode } = useGetDropdownQuery("paymentMode");
  // const { data: patientData } = useGetDischargeSummaryQuery(porps.tCIPID);
  // debugger
  const { data: patientData } = useGetOutPatientByOPIDQuery(porps.tCIPID);
  const { data: payments, refetch } = useGetPaymentsQuery(
    porps.tCIPID ?? skipToken
  );
  const [postPayment, { isLoading: isPosting, error: postError, data }] =
    usePostPaymentMutation();
  const [
    postStatusPayment,
    { isLoading: isPostingStatus, error: postStatusError, data: status },
  ] = usePostStatusUpdateMutation();
  const [dataDevice, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [requestID, setRequestID] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [emptyFormData, setEmptyFormData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const intervalIdRef = useRef(0);

  const hasPendingPayment = () => {
    if (payments && payments.length > 0) {
      return payments.some(
        (payment: { status: string }) =>
          payment.status === "Pending" || payment.status === "Initiated"
      );
    }
    return false;
  };
  const options =
    paymentFor?.map((item: any) => ({
      id: item?.dropDownSetID,
      value: item.dropDownValue,
      label: item.dropDownValue,
    })) || [];

  const deviceOptions =
    dataDevice?.map((item: any) => ({
      deviceID: item?.posDeviceID,
      id: item?.serialNumber,
      value: item.houDeviceID,
      label: item.houDeviceID,
    })) || [];
  const paymentModeOptions =
    paymentMode?.map((item: any) => ({
      id: item?.dropDownSetID,
      value: item.dropDownValue,
      label: item.dropDownValue,
    })) || [];
  const [formData, setFormData] = useState<any>({
    paymentMode: "",
    // paymentFor: "",
    deviceName: "",
    paymentDetailsID: null,
    amount: null,
    // paymentForDDSID: "",
    tCIPID: "",
    posDeviceID: null,
  });
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(device_list);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error: any) {
        setError("Error fetching data: " + error.message);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const customStyles = {
    option: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  const handleChange: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedFormData = { ...formData };
    const { name, value } = e.target as any;
    switch (name) {
      // case "paymentFor":
      //   const paymentForDDSID =
      //     options.find((option: any) => option.value === value)?.id || 0;
      //   updatedFormData = {
      //     ...updatedFormData,
      //     paymentForDDSID: paymentForDDSID,
      //     paymentFor: value,
      //   };
      //   break;
      case "paymentMode":
        const paymentModeID =
          paymentModeOptions.find((option: any) => option.value === value)
            ?.id || 0;
        updatedFormData = {
          ...updatedFormData,
          paymentModeDDSID: paymentModeID,
          paymentMode: value,
        };
        break;
      case "deviceName":
        const paymentDeviceID =
          deviceOptions.find((option: any) => option.value === value)
            ?.deviceID || 0;
        updatedFormData = { ...updatedFormData, posDeviceID: paymentDeviceID };
        break;
      case "amount":
        if (!isNaN(value)) {
          updatedFormData = { ...updatedFormData, amount: value };
          setErrorMessage("");
        } else {
          setErrorMessage("Please enter a numeric value");
        }
        break;
      default:
        updatedFormData = { ...updatedFormData, [name]: value };
    }
    setFormData(updatedFormData);
  };
  const handleAddPayment = async () => {
    try {
      // const idKey = porps.tCIPID.includes("IP") ? "tCIPID": "tCOPID"
      const isIP = porps.tCIPID.includes("IP");
      const isIPorOp = isIP ? "IP" : "OP";
      const paymentForDDSID =
        options.find((option: any) => option.value === isIPorOp)?.id || 0;
      // const discountData = JSON.parse(
      //   localStorage.getItem("AddDiscount") ?? "null"
      // );
      const addResponse = await postPayment({
        ...formData,
        // [idKey]: porps.tCIPID,
        paymentForDDSID: paymentForDDSID,
        paymentFor: isIPorOp,
        tCIPID: isIP ? porps.tCIPID : null,
        tCOPID: !isIP ? porps.tCIPID : null,
        paymentBy: user?.himsEmployeeID,
        billingID: porps.billingID,
        // ...discountData,
      }).unwrap(); //AddPaymentDetails--dbcall

      //   debugger;
      // Make a POST request to the desired API endpoint
      if (addResponse !== null && addResponse !== "" && selectedDevice !== "") {
        const device = dataDevice?.find(
          (d: any) => d.serialNumber === selectedDevice
        );
        // Next, post additional data to another API
        const providedData = {
          username: device?.userName,
          customerMobileNumber: patientData?.patientMobileNumber,
          externalRefNumber: porps.tCIPID,
          customerName: patientData?.patientName,
          amount: formData?.amount,
          pushTo: {
            deviceId: device.serialNumber + "|ezetap_android",
          },
          mode: formData.paymentMode.toUpperCase(),
        };
        // Merge provided data with existing formData
        const paymentData = { ...providedData };
        const response = await fetch(razor_pay_pay, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
          body: JSON.stringify(paymentData),
        });
        if (!response.ok) {
          throw new Error("Failed to post data to other API");
        }
        //shiva
        const responseData = await response.json();
        setRequestID(responseData.p2pRequestId);
        const trasactionData = {
          paymentDetailsID: addResponse,
          responseJSON: JSON.stringify(responseData),
          paymentStatusDDSID: 88,
        };
        try {
          //AddPyment Transaction Details
          const responseStatus = await fetch(add_payment_transaction_details, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any other headers as needed
            },
            body: JSON.stringify(trasactionData),
          });
          if (!responseStatus.ok) {
            throw new Error("Failed to post data to other API");
          }
          // Handle successful response
        } catch (error: any) {
          // Handle error
          console.error("Failed to post data:", error.message);
        }
        const providedResponseData = {
          username: device.userName,
          origP2pRequestId: responseData.p2pRequestId,
        };
        const renderAPICall = async () => {
          try {
            //ra
            // Make a POST request to the second API endpoint with the p2p value
            const responseStatus = await fetch(razor_pay_status, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                // Add any other headers as needed
              },
              body: JSON.stringify(providedResponseData),
            });
            if (!responseStatus.ok) {
              throw new Error("Failed to post p2p data to response status API");
            }
            const statusResponseData = await responseStatus.json();
            // Call razorPayStatus function with  API response
            razorPayStatus(statusResponseData, addResponse);
            // Handle success
          } catch (error) {
            console.error("Failed to post data to second API:", error);
            clearInterval(intervalIdRef.current);
          }
        };
        const id: any = setInterval(renderAPICall, 10000);
        intervalIdRef.current = id; // Set intervalId state
        // Handle success
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    let updatedFormData = { ...formData };
    updatedFormData = {
      ...updatedFormData,
      paymentMode: "",
      // paymentFor: "",
      // paymentForDDSID: "",
      deviceName: "",
      amount: "",
    };
    setFormData(updatedFormData);
  };
  const razorPayStatus = async (
    response: {
      realCode: string;
      messageCode: string;
      status: string;
    },
    addPaymentDetailsID: any
  ) => {
    if (
      response.messageCode == "P2P_DEVICE_TXN_DONE" &&
      response.status == "AUTHORIZED"
    ) {
      const statusUpdateData = {
        paymentDetailsID: addPaymentDetailsID,
        paymentStatusDDSID: 92,
      };
      try {
        const responseStatus = await postStatusPayment(
          statusUpdateData
        ).unwrap();
        if (!responseStatus.ok) {
          throw new Error("Failed to post data to other API");
        }
        // Handle successful response
      } catch (error: any) {
        // Handle error
        console.error("Failed to post data:", error.message);
      }
      //db call update Payment status (SUCCESS)
      clearInterval(intervalIdRef.current);
    } else if (response.messageCode == "P2P_DEVICE_CANCELED") {
      // clearTimeout(intervalId);
      //db call cancel Payment
      const statusCancelData = {
        paymentDetailsID: addPaymentDetailsID,
        paymentStatusDDSID: 91,
      };
      try {
        const responseStatus = await postStatusPayment(
          statusCancelData
        ).unwrap();
        if (!responseStatus.ok) {
          throw new Error("Failed to post data to other API");
        }
        // Handle successful response
      } catch (error: any) {
        // Handle error
        console.error("Failed to post data:", error.message);
      }
      clearInterval(intervalIdRef.current);
    } else if (
      response.messageCode == "P2P_STATUS_IN_CANCELED_FROM_EXTERNAL_SYSTEM"
    ) {
      const statusCancelData = {
        paymentDetailsID: addPaymentDetailsID,
        paymentStatusDDSID: 91,
      };
      try {
        const responseStatus = await postStatusPayment(
          statusCancelData
        ).unwrap();
        if (!responseStatus.ok) {
          throw new Error("Failed to post data to other API");
        }
        // Handle successful response
      } catch (error: any) {
        // Handle error
        console.error("Failed to post data:", error.message);
      }
      clearInterval(intervalIdRef.current);
      //db call cancel Payment
    } else if (
      (response.messageCode == "P2P_DEVICE_TXN_DONE" &&
        response.status == "FAILED") ||
      (response.messageCode == "P2P_DEVICE_TXN_DONE" &&
        response.status == "EXPIRED")
    ) {
      //db call update paymentstatus
      const statusUpdateData = {
        paymentDetailsID: addPaymentDetailsID,
        paymentStatusDDSID: 90,
      };
      try {
        const responseStatus = await postStatusPayment(
          statusUpdateData
        ).unwrap();
        if (!responseStatus.ok) {
          throw new Error("Failed to post data to other API");
        }
        // Handle successful response
      } catch (error: any) {
        // Handle error
        console.error("Failed to post data:", error.message);
      }
      clearInterval(intervalIdRef.current);
    } else if (
      response.messageCode == "P2P_DEVICE_SENT" ||
      response.messageCode == "P2P_DEVICE_RECEIVED" ||
      response.messageCode == "P2P_STATUS_QUEUED"
    ) {
    } else if (response.realCode == "P2P_ORIGINAL_P2P_REQUEST_IS_MISSING") {
      clearInterval(intervalIdRef.current);
    }
  };
  const handleClose = () => {
    // Reset form data when modal is closed
    let updatedFormData = { ...formData };
    updatedFormData = {
      ...updatedFormData,
      paymentMode: "",
      // paymentFor: "",
      // paymentForDDSID: "",
      deviceName: "",
      amount: "",
    };
    setFormData(updatedFormData);
  };

  return (
    <div>
      <div
        className="modal custom-modal fade"
        id="add_payment"
        role="dialog"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Payment</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddPayment();
                }}
              >
                <div>
                  {/* <div className="input-block mb-3">
                    <label className="col-form-label">
                      Payment Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      placeholder="Select Payment For"
                      styles={customStyles}
                      name="paymentFor"
                      value={
                        formData.paymentFor
                          ? options.find(
                              (option: any) =>
                                option.value === formData.paymentFor
                            )
                          : null
                      }
                      onChange={(selectedOption: any) => {
                        handleChange({
                          target: {
                            name: "paymentFor",
                            value: selectedOption.value,
                          },
                        });
                      }}
                    />
                  </div> */}
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Payment Mode <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={paymentModeOptions}
                      placeholder="Select Payment Mode"
                      styles={customStyles}
                      name="paymentMode"
                      value={
                        formData.paymentMode
                          ? options.find(
                              (option: any) =>
                                option.value === formData.paymentMode
                            )
                          : null
                      }
                      onChange={(selectedOption: any) => {
                        handleChange({
                          target: {
                            name: "paymentMode",
                            value: selectedOption.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="input-block mb-3">
                    {["UPI", "Card", "Online", "Internal"].includes(
                      formData.paymentMode
                    ) && (
                      <div className="input-block mb-3">
                        <label className="col-form-label"> Device Name </label>
                        <Select
                          options={
                            formData.paymentMode === "UPI" ||
                            formData.paymentMode === "Card" ||
                            formData.paymentMode === "Online" ||
                            formData.paymentMode === "Internal"
                              ? deviceOptions
                              : deviceOptions
                          }
                          placeholder="Select Device Name"
                          name="deviceName"
                          value={
                            formData.paymentMode === "UPI" ||
                            formData.paymentMode === "Card" ||
                            formData.paymentMode === "Online" ||
                            formData.paymentMode === "Internal"
                              ? deviceOptions.find(
                                  (option: any) =>
                                    option.value === formData.deviceName
                                )
                              : deviceOptions.find(
                                  (option: any) =>
                                    option.value === formData.deviceName
                                )
                          }
                          onChange={(selectedOption: any) => {
                            setSelectedDevice(selectedOption.id);
                            handleChange({
                              target: {
                                name: "deviceName",
                                value: selectedOption.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                      />
                    </div>
                    {errorMessage && (
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    )}
                  </div>
                  <div className="submit-section">
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal custom-modal fade" id="edit_payment" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Payment</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddPayment();
                }}
              >
                <div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Payment Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      placeholder="Select Payment For"
                      styles={customStyles}
                      name="paymentFor"
                      value={options.find(
                        (option: any) => option.value === formData.paymentFor
                      )}
                      onChange={(selectedOption: any) => {
                        handleChange({
                          target: {
                            name: "paymentFor",
                            value: selectedOption.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Payment Mode <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={paymentModeOptions}
                      placeholder="Select Payment Mode"
                      styles={customStyles}
                      name="paymentMode"
                      value={options.find(
                        (option: any) => option.value === formData.paymentMode
                      )}
                      onChange={(selectedOption: any) => {
                        handleChange({
                          target: {
                            name: "paymentMode",
                            value: selectedOption.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="input-block mb-3">
                    {["UPI", "Card"].includes(formData.paymentMode) && (
                      <div className="input-block mb-3">
                        <label className="col-form-label"> Device Name </label>
                        <Select
                          options={
                            formData.paymentMode === "UPI"
                              ? deviceOptions
                              : deviceOptions
                          }
                          placeholder="Select Device Name"
                          name="deviceName"
                          value={
                            formData.paymentMode === "UPI"
                              ? deviceOptions.find(
                                  (option: any) =>
                                    option.value === formData.deviceName
                                )
                              : deviceOptions.find(
                                  (option: any) =>
                                    option.value === formData.deviceName
                                )
                          }
                          onChange={(selectedOption: any) => {
                            setSelectedDevice(selectedOption.id);
                            handleChange({
                              target: {
                                name: "deviceName",
                                value: selectedOption.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="amount"
                        value={formData?.amount}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn"
                      //  data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Update Payment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
