import { Avatar, Button, Card, Divider, Space, Typography } from "antd";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";

import { FaUserGraduate } from "react-icons/fa";
const { Meta } = Card;
const { Text } = Typography;

function SpecialistCard({ details }: { details: ISpecialistDetails }) {
  return (
    <Card
      actions={[
        <Space
          split={<Divider type="vertical" />}
          align="center"
          style={{ justifyContent: "space-evenly", width: "100%" }}
        >
          <Button
            type="primary"
            style={{
              backgroundColor: details?.availabilityStatus ? "green" : "red",
            }}
          >
            {details?.availabilityStatus ? "Available" : "Not Available"}
          </Button>

          <Link to={details.doctorID}>
            <Button type="primary">Book Appointment</Button>
          </Link>
        </Space>,
      ]}
    >
      <Meta
        style={{ alignItems: "center" }}
        avatar={
          <Avatar
            size={{ xs: 80, sm: 64, md: 80, lg: 80, xl: 64, xxl: 100 }}
            icon={<i className="fa-regular fa-user" />}
          />
        }
        title={details?.name}
        description={
          <Space direction="vertical" size="small">
            <Space align="start">
              <FaUserGraduate className="speclist-card-img" />

              <Text type="secondary">{details.speciality}</Text>
              {details.qualification && (
                <Text strong>{details.qualification}</Text>
              )}
            </Space>
            {/* <Text type="secondary">
              <MdPersonAddAlt className="speclist-card-img" />
              {details.experience}{" "}
              {details.experience === "1"
                ? "Year Experience"
                : "Years Experience"}
            </Text> */}
            <Space size="small">
              <Text type="secondary">
                <i className="fa fa-volume-up" />
              </Text>
              {details.language.split(",").map((lan) => (
                <Text type="secondary">{lan}</Text>
              ))}
            </Space>
            {details.location && (
              <Space align="baseline" size="small">
                <CiLocationOn className="speclist-card-img" />
                <Text type="secondary">{details.location}</Text>
              </Space>
            )}
          </Space>
        }
      />
    </Card>
  );
}

export default SpecialistCard;
