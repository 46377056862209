import { skipToken } from "@reduxjs/toolkit/query";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/UserSlice";
import {
  useGetBedDetailsQuery,
  useGetLocationsQuery,
  useGetNSNurseQuery,
  useGetRoomDetailsQuery,
  usePostAdmissionMutation,
} from "../../services/ApiSlice";
import { RootState } from "../../store";
import { IndianMobileNumber } from "../../views/pages/Authentication/RegEx";

interface Props {
  patientID?: string;
  isModelOpen: boolean;
  onClose: () => void;
}

const { Title } = Typography;

function AddAdmissionModal({ patientID, isModelOpen, onClose }: Props) {
  const [formInstance] = Form.useForm<any>();
  const user = useSelector((state: RootState) => selectUser(state));
  const currentDate = dayjs();
  const selectedRoom = Form.useWatch(["roomID"], formInstance);

  const [postAdmission, PostAdmission] = usePostAdmissionMutation();
  const Rooms = useGetRoomDetailsQuery("");
  const Beds = useGetBedDetailsQuery(selectedRoom ?? skipToken);
  const Locations = useGetLocationsQuery();
  const ProcedureNurseDropDown = useGetNSNurseQuery("");
  const RoomNo = useMemo(
    () =>
      Array.isArray(Rooms.data)
        ? Rooms.data.map((item) => ({
            value: item?.roomID,
            label: item.roomNo,
          }))
        : [],
    [Rooms]
  );

  const selectNurse = useMemo(
    () =>
      Array.isArray(ProcedureNurseDropDown.data)
        ? ProcedureNurseDropDown.data.map((item) => ({
            value: item?.himsEmployeeID,
            label: item.employeeName,
          }))
        : [],
    [ProcedureNurseDropDown]
  );

  const BedsList = useMemo(
    () =>
      Array.isArray(Beds.data)
        ? Beds.data.map((item) => ({
            value: item?.bedID,
            label: item.bedNo,
          }))
        : [],
    [Beds]
  );
  const Location = useMemo(
    () =>
      Array.isArray(Locations.data)
        ? Locations.data.map((item) => ({
            value: item?.locationID,
            label: item.locationName,
          }))
        : [],
    [Locations]
  );

  useEffect(() => {
    if (PostAdmission.isSuccess) {
      onClose();
      notification.success({
        message: "Success",
        description: "Successfuly Added Admission",
      });
      formInstance.resetFields();
    } else if (PostAdmission.isError) {
      notification.error({
        message: "Error",
        description: "Error Adding Admission",
      });
      onClose();
      formInstance.resetFields();
    }
  }, [PostAdmission]);

  useEffect(() => {
    formInstance.setFieldValue("bedID", null);
  }, [selectedRoom]);
  // useEffect(() => {
  //   if (PostAdmission.isLoading) {
  //     formInstance.setFieldValue("noOfDaysStay", null);
  //   }
  // }, [PostAdmission]);

  const handleSubmit = async (values: any) => {
    try {
      const values = await formInstance.validateFields();
      await postAdmission({
        ...values,
        // admissionDate: dayjs(values.admissionDate).toISOString(),
        tcipid: null,
        dischargeDate: null,
        operationDate: null,
        statusID: 0,
        patientID: patientID,
        createUserID: user?.himsEmployeeID,
        modifiedUserID: user?.himsEmployeeID,
        admissionDate: dayjs(new Date()),
        noOfDaysStay: 1,
      }).unwrap();
      // Optionally, refetch data after successful post
      // await refetch();
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const birthDate = dayjs(date);
      const today = dayjs();
      const age = today.diff(birthDate, "year");
      formInstance.setFieldsValue({ age });
      formInstance.validateFields(["age"]);
    } else {
      formInstance.setFieldsValue({ age: undefined });
    }
  };
  const handleClose = () => {
    formInstance.resetFields();
    onClose();
  };

  return (
    <Modal
      closeIcon={<i className="fa-solid fa-xmark" />}
      open={isModelOpen}
      onCancel={handleClose}
      // width="50vw"
      centered
      title={
        <Title level={3} style={{ textAlign: "center" }}>
          Add Admission
        </Title>
      }
      // footer={null}
      okText={"Submit"}
      onOk={handleSubmit}
      zIndex={2000}
      maskClosable={false}
    >
      <Form
        form={formInstance}
        disabled={PostAdmission.isLoading}
        layout="vertical"
      >
        <Row gutter={[24, 16]}>
          {/* <Col span={12}>
            <Form.Item
              name="admissionDate"
              label="Admission Date"
              initialValue={currentDate}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(d) => dayjs().isAfter(d)}
              />
            </Form.Item>
          </Col> */}

          <Col span={12}>
            <Form.Item
              name="locationID"
              label="Location"
              rules={[{ required: true, message: "Please select location " }]}
            >
              <Select
                placeholder="Location"
                style={{ width: "100%" }}
                options={Location}
                loading={Locations.isFetching}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="roomID"
              label="Room No"
              rules={[{ required: true, message: "Please select room no" }]}
            >
              <Select
                placeholder="Room No"
                style={{ width: "100%" }}
                options={RoomNo}
                loading={Rooms.isFetching}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="bedID"
              label="Bed No"
              rules={[{ required: true, message: "Please select bed no" }]}
            >
              <Select
                placeholder="Bed No"
                style={{ width: "100%" }}
                options={BedsList}
                loading={Beds.isFetching}
                showSearch
                optionFilterProp="label"
                disabled={!selectedRoom || PostAdmission.isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="emergencyContactName"
              label="Emergency Contact Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
                { min: 3, message: "Minimum 3 characters required" },
              ]}
            >
              <Input placeholder="Emergency Contact Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="emergencyContactNumber"
              label="Emergency Contact Number"
              rules={[
                {
                  required: true,
                  message: "Please enter number ",
                },
                {
                  pattern: IndianMobileNumber,
                  message: "Please Enter Valid Mobile Number",
                },
              ]}
            >
              <Input placeholder="Emergency Contact Number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="assignedDoctorID"
              label="Doctor"
              rules={[{ required: true, message: "Please select doctor " }]}
            >
              <Select
                placeholder="Select doctor"
                style={{ width: "100%" }}
                options={selectNurse}
                loading={ProcedureNurseDropDown.isFetching}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              name="admissionDate"
              label="Admission Date"
              rules={[
                { required: true, message: "Please enter admission date" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={handleDateChange}
                disabledDate={(d) => dayjs().isBefore(d)}
              />
            </Form.Item>
          </Col> */}

          {/* <Col span={12}>
            <Form.Item
              name="noOfDaysStay"
              label="Number Of Days To Stay"
              rules={[
                { required: true, message: "Number Of Days To Stay" },
                {
                  validator(_, value) {
                    if (value > 30 && value < 1)
                      return Promise.reject(new Error("Value must be 1 to 30"));
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={1}
            >
              <InputNumber
                placeholder="Number Of Days To Stay"
                min={1}
                max={30}
                // TODO: add validation
              />
            </Form.Item>
          </Col> */}

          {/* <Col span={12}>
            <Form.Item name="reasonForVisit" label="Admission Reason">
              <Input.TextArea
                placeholder="Enter Reason For Admission"
                maxLength={1000}
                showCount
              />
            </Form.Item>
          </Col>  */}
        </Row>
      </Form>
    </Modal>
  );
}

export default AddAdmissionModal;
