import { skipToken } from "@reduxjs/toolkit/query";
import type { TableProps } from "antd";
import { Button, Col, Row, Space, Spin, Table, Typography } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useGeneratePrescriptionMutation,
  useGetDoctorVisitNotesQuery,
} from "../../../../services/ApiSlice";

const { Text, Paragraph } = Typography;
const { Summary } = Table;

type Data = {
  code: string;
  testName: string;
  serviceGroup: string;
  department: string;
  MRP: string;
  TAT: string;
  sampleType: string;
  methodology: string;
};

function IPVisit({
  TCIPID,
  patientID,
}: {
  TCIPID: string | null;
  patientID: string;
}) {
  const user = useSelector(selectUser);

  const DoctorVisitNotes = useGetDoctorVisitNotesQuery(TCIPID ?? skipToken);
  const [genetatePrescription, GeneratePrescription] =
    useGeneratePrescriptionMutation();

  const isRoleVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const testData = [
    {
      date: "28-03-2024",
      doctorName: "Dr Ram Chandra",
      specilization: "MBA",
      notes:
        "Founded in 1998, the website Google.com has become such an institution that in its short existence, it has changed not only the way we process the endless data found online, but also the way we think and talk about the internet.",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const handleDownload = (url: string, date: string | null) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link: any = document.createElement("a");
        link.href = url;
        date = date ? `_${date}` : "";
        link.setAttribute("download", `Prescription${date}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  useEffect(() => {
    if (GeneratePrescription.data) {
      handleDownload(GeneratePrescription?.data, "");
    }
  }, [GeneratePrescription]);

  // const totalAmount = useMemo(
  //   () =>
  //     selectedTests.reduce(
  //       (accumulator, { MRP: currentValue }) =>
  //         accumulator + parseFloat(currentValue),
  //       0
  //     ),
  //   [selectedTests]
  // );

  const onAddNotes = () => {
    // let path = "/lab-requests/new-request";
    // if (TCIPID) path += `?TCOPID=${TCIPID}`;
    navigate(`/inpatient-notes/${TCIPID}`);
  };

  const onTestRemove = (code_: Data["code"]) => {};

  const onAddLab = (doctorVisitID: number) => {
    navigate("/lab-requests/new-request", {
      state: { from: location.pathname, TCIPID, patientID, doctorVisitID },
    });
  };

  const onAddPrescription = (doctorVisitID: number) => {
    navigate(`/admissions/${TCIPID}/add-prescription`, {
      state: {
        from: location.pathname,
        TCIPID,
        patientID,
        doctorVisitID,
        returnState: { activeKey: "2" },
      },
    });
  };

  const onGeneratePrescription = (doctorVisitID: any, tcipid: string) => {
    genetatePrescription({
      doctorVisitID: doctorVisitID,
      categoryRefID: tcipid,
      createdBy: user?.himsEmployeeID,
    });
  };

  const onViewPrescription = (
    prescriptionURL: IDoctorVisit["prescriptionURL"]
  ) => {
    window.open(prescriptionURL); // TODO: view in new page instead of download
  };

  const columns: TableProps<IDoctorVisit>["columns"] = [
    {
      title: "ID",
      dataIndex: "doctorVisitID",
      render: (doctorVisitID) => (
        <Link
          className="hyperlink"
          to={`/inpatient-notes/${TCIPID}`}
          state={{ doctorVisitID }}
        >
          {doctorVisitID || "-"}
        </Link>
      ),
    },
    {
      title: " Visit Date",
      dataIndex: "visitDate",
      render: (text) => FormatDateTime(text),
    },
    {
      title: " Visit Purpose",
      dataIndex: "visitPurpose",
      render: (text) => text || "-",
    },

    {
      title: "Doctor Name",
      //   dataIndex: "doctorName",
      render: ({ doctorName, docSpecialization }) => (
        <span style={{ whiteSpace: "normal" }}>
          <Space direction="vertical" size={0}>
            {doctorName && <Text strong>{doctorName}</Text>}
            {docSpecialization && (
              <Text type="secondary">{docSpecialization}</Text>
            )}
          </Space>
        </span>
      ),
    },

    {
      title: "Prescription",
      key: "prescription",
      dataIndex: "doctorVisitID",
      render: (doctorVisitID, { prescriptionURL, isPharma, tcipid }) =>
        isPharma > 0 && prescriptionURL == null ? (
          isRoleVisible([Role.Doctor, Role.Nurse, Role.Admin]) && (
            <Button
              onClick={() => onGeneratePrescription(doctorVisitID, tcipid)}
              type="link"
            >
              Generate Prescription
            </Button>
          )
        ) : prescriptionURL ? (
          <Button
            onClick={() => onViewPrescription(prescriptionURL)}
            type="link"
          >
            Download Prescription
          </Button>
        ) : (
          isRoleVisible([Role.Doctor, Role.Nurse, Role.Admin]) && (
            <Button
              type="link"
              onClick={() => onAddPrescription(doctorVisitID)}
            >
              Add Prescription
            </Button>
          )
        ),
    },
    {
      title: "Lab Tests",
      key: "labTests",
      dataIndex: "doctorVisitID",
      render: (doctorVisitID) =>
        isRoleVisible([Role.Doctor, Role.Admin]) && (
          <Button onClick={() => onAddLab(doctorVisitID)} type="link">
            Add Lab
          </Button>
        ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_v, record) => (
    //     <Space>
    //       <Button type="link">Prescription</Button>
    //       {/* TODO: Replace with Upload component */}
    //       <Button type="link">Add Lab</Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <Spin spinning={GeneratePrescription.isLoading} fullscreen />
      <Table
        columns={columns}
        dataSource={DoctorVisitNotes?.data ?? []}
        loading={DoctorVisitNotes?.isFetching}
        pagination={false}
        bordered
        rowKey="code"
        //   scroll={{ x: "auto" }}
        title={() => (
          <Row gutter={24} justify="end">
            {isRoleVisible([Role.Doctor, Role.Admin, Role.Nurse]) && (
              <Col>
                <Button onClick={onAddNotes} type="primary">
                  Add
                </Button>
              </Col>
            )}
          </Row>
        )}
      />
    </>
  );
}

export default IPVisit;

// TODO: create new util for sorting
// const sortBy = (key) => {
//   return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
// };
