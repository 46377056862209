import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/PrivilegeRoles';
import { SessionStorageKeys } from '../shared/session-storage-keys';
 
interface UserState {
  user: User | null;
}
 
const initialState: UserState = {
  user: JSON.parse(localStorage.getItem(SessionStorageKeys.User) || 'null')
};
 
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
    }
  }
});
 
export const { setUser } = userSlice.actions;
 
export const selectUser = (state: { user: UserState }) => state.user.user;
 
export default userSlice.reducer;