import {
  Card,
  Flex,
  Form,
  Input,
  InputRef,
  Modal,
  Space,
  Spin,
  TableProps,
  Typography,
  notification,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useAddOrUpdateEmployeeMutation,
  useAddOrUpdateExternalEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetApprovedDoctorsQuery,
  useGetDropdownQuery,
  useGetHIMSEmployeeDetailsByADIDQuery,
  useGetHimsRolesQuery,
  useGetLocationsQuery,
  useGetQualificationQuery,
  useGetSpecializationsQuery,
  usePostNewRolePrivilegeMutation,
} from "../../../services/ApiSlice";

import { FilterDropdownProps } from "antd/es/table/interface";
import { useSelector } from "react-redux";
import { selectUser } from "../../../reducers/UserSlice";

function UserDetails() {
  const { Text } = Typography;
  const { confirm } = Modal;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputValue2, setInputValue2] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [emailPattern, setEmailPattern] = useState(
    /^[a-zA-Z0-9._%+-]+@healthonus\.io$/
  );
  const user = useSelector(selectUser);
  const Specializations = useGetSpecializationsQuery();
  const Qualifications = useGetQualificationQuery();
  const GetHIMSEmployeeDetails = useGetHIMSEmployeeDetailsByADIDQuery(id);
  const [formInstance] = Form.useForm();
  const { data: allEmployees, isFetching: isAllEmployeesLoading } =
    useGetAllEmployeesQuery("");
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const [addExternalDoctor, AddExternalDoctor] =
    useAddOrUpdateExternalEmployeeMutation();
  const Gender = useGetDropdownQuery("Gender");
  const GetApprovedDoctors = useGetApprovedDoctorsQuery();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const handleType = Form.useWatch("type", formInstance);
  const handleRole = Form.useWatch("roleName", formInstance);
  const handleFirstName = Form.useWatch("FirstName", formInstance);
  const [selectedRecord, setSelectedRecord] = useState<IEmployee | null>(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);
  type DataIndex = keyof IApprovedDoctors;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    if (handleRole !== 3) {
      formInstance.setFieldsValue({ type: "internal" });
    }
    // formInstance.resetFields();
  }, [handleRole]);
  useEffect(() => {
    if (handleFirstName) {
      const newPassword = generatePassword(handleFirstName);
      formInstance.setFieldsValue({ Password: newPassword });
      setPassword(newPassword);
    } else {
      formInstance.setFieldsValue({ Password: "" });
    }
  }, [handleFirstName]);
  useEffect(() => {
    if (AddExternalDoctor.isSuccess) {
      if (AddExternalDoctor.data) {
        navigate(`/UserList`);
        notification["success"]({
          message: "Users modification done successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (AddExternalDoctor.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [AddExternalDoctor]);

  const generatePassword = (firstName: string) => {
    const trimmedFirstName = firstName.replace(/\s/g, ""); // Remove spaces
    return `${trimmedFirstName}@123`;
  };
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const handleCancle = () => {
    confirm({
      title: "Are you sure want to exit ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        formInstance.resetFields();
        navigate("/UserList");
      },
    });
  };
  const handleDelete = (record: IApprovedDoctors) => {
    confirm({
      title: "Are you sure delete the doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        addExternalDoctor({
          email: record?.email,
          modifiedBy: user?.himsEmployeeID,
          approvedDoctorsID: record?.approvedDoctorsID,
        });
      },
    });
  };
  const specializationOptions = useMemo(
    () => getSpecializationOptions(Specializations.data),
    [Specializations]
  );
  const qualificationOptions = useMemo(
    () => getQualificationOptions(Qualifications.data),

    [Qualifications]
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    // Check if the input is a number
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };
  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    // Check if the input is a number
    if (/^\d*$/.test(value)) {
      setInputValue2(value);
    }
  };
  const genderOptions = useMemo(
    () =>
      Array.isArray(Gender.data)
        ? Gender.data.map(
            ({
              dropDownSetID,
              dropDownValue,
            }: {
              dropDownSetID: number;
              dropDownValue: string;
            }) => ({
              value: dropDownSetID,
              label: dropDownValue,
            })
          )
        : [],
    [Gender]
  );
  const handleClose = () => {
    setIsModelOpen(false);
  };

  const columns: TableProps<IEmployee>["columns"] = [
    {
      title: "Name",
      // dataIndex: "displayName",
      // ...getColumnSearchProps("displayName"),
      render: ({ displayName, isExternal }) => (
        <Space>
          {displayName && <Text strong>{displayName}</Text>}
          {isExternal && <Text type="secondary">External</Text>}
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      // ...getColumnSearchProps("email"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      // ...getColumnSearchProps("phoneNumber"),
      // render: (text: number) => <span>{text || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      // ...getColumnSearchProps("roleName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Location",
      dataIndex: "locationName",
      // ...getColumnSearchProps("locationName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space>
          {/* <Link to="#">
                <span className="link " onClick={() => handleEdit(record)}>
                  <i className="las la-pen"></i>
                </span>
              </Link> */}
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const handleEdit = (record: IEmployee) => {
    // setEditRole(true);
    setIsModelOpen(true);
    // setHimsRoleID(himsRoleID);
    setSelectedRecord(record);
    formInstance.setFieldsValue(record);
    window.scrollTo(0, 0);
  };

  const getLocationOptions = (locations: ILocation[] | undefined) => {
    const options: { label: string; value: number | null }[] = [
      // { label: "All", value: null },
    ];
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        options.push({
          label: location.locationName,
          value: location.locationID,
        });
      });
    }
    return options;
  };

  const [addOrUpdateEmployee, AddOrUpdateEmployee] =
    useAddOrUpdateEmployeeMutation();

  const Locations = useGetLocationsQuery();

  const locationOptions = useMemo(
    () => getLocationOptions(Locations.data),
    [Locations]
  );

  const filteredEmployees = useMemo(() => {
    if (Array.isArray(allEmployees)) {
      return allEmployees?.filter((employee) => employee.isExternal === 1);
    }
    return [];
  }, [allEmployees]);

  const handleAddUpdateUser = async () => {
    try {
      const values = await formInstance.validateFields();
      const userExits = allEmployees?.some(
        (role: { email: string }) =>
          role?.email?.toLowerCase().trim() ===
          values?.EmailID?.toLowerCase().trim()
      );
      const payload = {
        emailID: values?.EmailID,
        roleID: values?.roleName,
        locationID: 1,
        himsEmployeeID: selectedRecord?.himsEmployeeID,
      };
      if (selectedRecord) {
        try {
          await addOrUpdateEmployee(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        if (!userExits) {
          try {
            await addOrUpdateEmployee(payload).unwrap();
          } catch (error) {
            console.error("Failed to post data:", error);
          }
        } else {
          notification["warning"]({
            message: "User already exists",
            icon: (
              <i
                className="las la-exclamation-circle"
                style={{ color: "red" }}
              ></i>
            ),
          });
        }
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };
  //   const roleExists = allEmployees?.some(
  //     (role: { roleName: string }) =>
  //       role.roleName.toLowerCase().trim() === values.role.toLowerCase().trim()
  //   );
  //   if (!roleExists) {
  //     try {
  //       await postNewRolePrivilege({ RoleName: values.role }).unwrap();
  //       // Optionally, refetch data after successful post
  //       //  await refetch();
  //     } catch (error) {
  //       console.error("Failed to post data:", error);
  //     }
  //   } else {
  //     notification["warning"]({
  //       message: "Role already exists",
  //       icon: (
  //         <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
  //       ),
  //     });
  //   }
  // };

  // const handleInputChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value;
  //   if (value != null) {
  //     setSearchQuery(value);
  //   }
  // };

  const [updateUser, UserUpdate] = useAddOrUpdateEmployeeMutation();

  const onAddRole = () => {
    setIsModelOpen(true);
    // setSelectedRecord(true);
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.type) {
      if (changedValues.type === "external") {
        setEmailPattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      } else {
        setEmailPattern(/^[a-zA-Z0-9._%+-]+@healthonus\.io$/);
      }
      formInstance.validateFields(["EmailID"]);
    }
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();
      const userExits = filteredEmployees?.some(
        (role: { EmailID: string; email: string }) =>
          role?.email?.toLowerCase().trim() ===
          values?.EmailID?.toLowerCase().trim()
      );
      const payload = {
        ...values,
        LanguageKnown: values.LanguageKnown.join(","),
        locationID: 1,
        CreatedBy: user?.himsEmployeeID,
      };

      if (!userExits) {
        try {
          await addExternalDoctor(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        notification["warning"]({
          message: "User already exists",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };

  const HimsRoles = useGetHimsRolesQuery();

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Flex vertical gap={24}>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">User Info</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`/UserList`} className="hyperlink">
                    Users List
                  </Link>
                </li>
                <li className="breadcrumb-item active">User Info</li>
              </ul>
            </div>
          </div>

          <Spin spinning={GetHIMSEmployeeDetails.isLoading} fullscreen />

          <Card>
            <Form
              form={formInstance}
              onValuesChange={onValuesChange}
              onFinish={
                handleType !== "external"
                  ? handleAddUpdateUser
                  : handleAddUpdate
              }
            >
              {/* {handleType === "external" && ( */}
              <>
                <h4 className="card-title">Basic Information</h4>
                <div>&nbsp;</div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        First Name
                      </label>
                      <div className="col-lg-8">
                        <Form.Item name="FirstName">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.firstName}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        Mobile No.
                      </label>
                      <div className="col-lg-8">
                        <Form.Item name="PhoneNumber">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.phoneNumber}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">Gender</label>
                      <div className="col-lg-8">
                        <Form.Item name="GenderID">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.gender}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        Last Name
                      </label>
                      <div className="col-lg-8">
                        <Form.Item name="LastName">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.lastName}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        Alt. Mobile No.
                      </label>
                      <div className="col-lg-8">
                        <Form.Item name="AlternateContactNo">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.emergencyContactNo ??
                              "N/A"}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        Password
                      </label>
                      <div
                        className="col-lg-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Item name="Password">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.password
                              ? !showPassword
                                ? "•".repeat(
                                    GetHIMSEmployeeDetails.data?.password.length
                                  )
                                : GetHIMSEmployeeDetails.data?.password
                              : "N/A"}
                          </Text>
                        </Form.Item>{" "}
                        {GetHIMSEmployeeDetails.data?.password && (
                          <div
                            style={{
                              marginLeft: 10,
                              fontSize: 20,
                              marginBottom: 22,
                            }}
                            onClick={handlePassword}
                          >
                            {showPassword ? (
                              <i className="las la-low-vision"></i>
                            ) : (
                              <i className="lar la-eye"></i>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="card-title">Professional Information</h4>
                <div>&nbsp;</div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">Role</label>
                      <div className="col-lg-8">
                        <Form.Item name="roleName">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.roleName}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">
                        Location
                      </label>
                      <div className="col-lg-8">
                        <Form.Item name="locationName" initialValue={null}>
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.locationName}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">Type</label>
                      <div className="col-lg-8">
                        <Form.Item name="type">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.isExternal
                              ? "External"
                              : "Internal"}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-4 col-form-label">Email</label>
                      <div className="col-lg-8">
                        <Form.Item name="EmailID">
                          <Text type="secondary">
                            {GetHIMSEmployeeDetails.data?.email}
                          </Text>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Item>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Reg No
                        </label>
                        <div className="col-lg-8">
                          <Form.Item name="RegNumber">
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.regNumber ?? "N/A"}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Specialization
                        </label>
                        <div className="col-lg-8">
                          <Form.Item
                            name="SpecializationId"
                            initialValue={null}
                          >
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.speciality}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Experience
                        </label>
                        <div className="col-lg-8">
                          <Form.Item name="Experience">
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.experience
                                ? GetHIMSEmployeeDetails.data?.experience +
                                  " yrs"
                                : "N/A"}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Qualification
                        </label>
                        <div className="col-lg-8">
                          <Form.Item name="QualificationId" initialValue={null}>
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.qualification}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Hospital Name
                        </label>
                        <div className="col-lg-8">
                          <Form.Item name="hosipital">
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.hospitalName ??
                                "N/A"}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input-block mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Languages Known
                        </label>
                        <div className="col-lg-8">
                          <Form.Item name="LanguageKnown">
                            <Text type="secondary">
                              {GetHIMSEmployeeDetails.data?.languageKnown}
                            </Text>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Item>{" "}
              </>
              {/* )} */}
              <div className="text-end">
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                  }}
                >
                  {" "}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleCancle}
                  >
                    Back
                  </button>
                </Form.Item>
                {/* <Form.Item style={{ display: "inline-block" }}>
                  {" "}
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </Form.Item> */}
              </div>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
}

export default UserDetails;
const getSpecializationOptions = (
  specializations: ISpecialization[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "All", value: null },
  ];
  if (Array.isArray(specializations)) {
    specializations.forEach((specialization) => {
      options.push({
        label: specialization.speciality,
        value: specialization.specialityID,
      });
    });
  }
  return options;
};

const getQualificationOptions = (
  qualification: IQualification[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "Select", value: null },
  ];
  if (Array.isArray(qualification)) {
    qualification.forEach((qualification) => {
      options.push({
        label: qualification.qualification,
        value: qualification.qualificationID,
      });
    });
  }
  return options;
};
