import type { TableProps } from "antd";
import { Button, Col, Form, Input, Row, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useIsAllowed } from "../../../hooks/role.hooks";
import { PRIVILEGE } from "../../../models/PrivilegeRoles";
import { useLazyGetPatientByMobileQuery } from "../../../services/ApiSlice";
import { IndianMobileNumber } from "../Authentication/RegEx";

const PatientList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showPatientsByMobile, setShowPatientsByMobile] =
    useState<boolean>(false);

  const [getPatientsByMobile, patientsByMobile] =
    useLazyGetPatientByMobileQuery();

  const [formInstance] = Form.useForm();
  const navigate = useNavigate();
  const isAllowed = useIsAllowed();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // if (!patientsByMobile.isFetching && Array.isArray(patientsByMobile.data)) {
  //     if (patientsByMobile.data.length === 1) {
  //       const patientDetail = patientsByMobile.data[0];
  //       navigate(`/patients/${patientDetail.patientID}`);
  //     } else {
  //       notification["warning"]({
  //         message: "No Patient Found",
  //       });
  //     }
  //   }
  // }, [patientsByMobile]);

  const columns: TableProps<IPatientDetails>["columns"] = [
    {
      title: "UHID",
      dataIndex: "uhid",
      render: (text: string, record: any) => (
        isAllowed(PRIVILEGE.Client_View) ? (<Link className="hyperlink" to={`/patients/${record.patientID}`}>
          {text}
        </Link>
       ) : null),// Provide default value if firstName is empty
      sorter: (a: { patientID: string }, b: { patientID: string }) =>
        a.patientID.length - b.patientID.length,
    },

    {
      title: "First Name",
      dataIndex: "firstName",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
      sorter: (a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if lastName is empty
      sorter: (a: { lastName: string }, b: { lastName: string }) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNo",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if lastName is empty
      sorter: (a: { phoneNo: string }, b: { phoneNo: string }) =>
        a.phoneNo.length - b.phoneNo.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (text: string) => <span>{text || "-"}</span>,
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      onFilter: (selectedGender, { gender }) => gender === selectedGender,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   dataIndex: "patientID",
    //   render: (patientID) =>
    //     isAllowed(PRIVILEGE.Client_View) ? (
    //       <Link to={`/patients/${patientID}`}>
    //         <span className="link ">View</span>
    //       </Link>
    //     ) : null,
    // },
  ];

  const filteredPatients = useMemo(() => {
    let patients = patientsByMobile.data;
    if (Array.isArray(patients)) {
      const searchString = searchQuery.toLocaleLowerCase().trim();
      if (searchString) {
        return patients?.filter(
          (patient) =>
            patient.uhid?.toLowerCase().trim().includes(searchString) ||
            patient.firstName?.toLowerCase().trim().includes(searchString) ||
            patient.lastName?.toLowerCase().trim().includes(searchString) ||
            patient.phoneNo?.toLowerCase().trim().includes(searchString)
        );
      } else return patients;
    }
    return [];
  }, [searchQuery, patientsByMobile]);

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };

  const onCheckByMobile = (values: IPatientDetailsArgs) => {
    getPatientsByMobile(values);
    setShowPatientsByMobile(true);
  };

  const addPatient = async () => {
    try {
      const values = await formInstance.validateFields();
      const state = {
        phoneNo: values.PhoneNo,
      };
      navigate(`/patients/add-patient`, { state });
    } catch (error) {
      navigate(`/patients/add-patient`);
    }
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* <Breadcrumbs
            maintitle="Patient Registration"
            title="Home"
            subtitle="Patient Registration"
            modal="#add_holiday"
            name="Add Patient"
          /> */}
          {/* Tool Bar */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Patient Registration</h3>
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/patients">Patients</Link>
                  </li>
                  <li className="breadcrumb-item active">Patient Details</li>
                </ul> */}
              </div>
              <div className="col-auto float-end ms-auto">
                {patientsByMobile.isSuccess && (
                  <button
                    type="button"
                    className="btn add-btn mx-3"
                    onClick={addPatient}
                  >
                    {" "}
                    <i className="fa fa-plus" />
                    Add Patient
                  </button>
                )}
              </div>
            </div>
          </div>

          <Row
            justify={showPatientsByMobile ? "space-between" : "center"}
            style={showPatientsByMobile ? {} : { height: "30vh" }}
            align="middle"
          >
            {showPatientsByMobile && (
              <Col>
                <Input
                  placeholder="Search Patient"
                  onChange={handleInputChange}
                />
              </Col>
            )}
            <Col>
              <Form onFinish={onCheckByMobile} form={formInstance}>
                {!showPatientsByMobile ? (
                  <h6 className="page-title">Search patient here </h6>
                ) : null}
                <Space.Compact size={showPatientsByMobile ? "middle" : "large"}>
                  <Form.Item
                    name="PhoneNo"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Mobile Number",
                      },
                      {
                        pattern: IndianMobileNumber,
                        message: "Please Enter Valid Mobile Number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Mobile Number"
                      disabled={patientsByMobile.isFetching}
                    />
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={patientsByMobile.isFetching}
                    style={{ backgroundColor: "#7465ad" }}
                  >
                    Search
                  </Button>
                  {showPatientsByMobile ? (
                    <Button
                      icon={<i className="fa-solid fa-xmark" />}
                      onClick={() => {
                        setShowPatientsByMobile(false);
                        formInstance.resetFields();
                      }}
                    />
                  ) : null}
                </Space.Compact>
              </Form>
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-12">
              {showPatientsByMobile && (
                <div className="table-responsive">
                  <Table
                    pagination={
                      filteredPatients?.length > 10 ? undefined : false
                    }
                    loading={patientsByMobile.isFetching}
                    columns={columns}
                    dataSource={filteredPatients}
                    rowKey="patientID"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <AddPatient patientID={null}/> */}
    </>
  );
};
export default PatientList;
