/* eslint-disable no-unused-expressions */

import {
  Checkbox,
  Form,
  Input,
  InputRef,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  notification,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useAddOrUpdateEmployeeMutation,
  useAddOrUpdateExternalEmployeeMutation,
  useDeleteHimsEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetApprovedDoctorsQuery,
  useGetDropdownQuery,
  useGetHimsRolesQuery,
  useGetLocationsQuery,
  useGetSpecializationsQuery,
  usePostNewRolePrivilegeMutation,
} from "../../../services/ApiSlice";
import { IndianMobileNumber, emailrgx } from "../Authentication/RegEx";

const ExternalDoctors: React.FC<{}> = () => {
  const { confirm } = Modal;
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const Specializations = useGetSpecializationsQuery();
  const { data: allEmployees, isFetching: isAllEmployeesLoading } =
    useGetAllEmployeesQuery("");
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const [addExternalDoctor, AddExternalDoctor] =
    useAddOrUpdateExternalEmployeeMutation();
  const Gender = useGetDropdownQuery("Gender");
  const GetApprovedDoctors = useGetApprovedDoctorsQuery();
  const [deleteHimsEmployee, DeleteHimsEmployee] =
    useDeleteHimsEmployeeMutation();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<IEmployee | null>(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);
  type DataIndex = keyof IApprovedDoctors;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const filteredEmployees = useMemo(() => {
    if (Array.isArray(allEmployees)) {
      return allEmployees?.filter((employee) => employee.isExternal === 1);
    }
    return [];
  }, [allEmployees]);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const handleDelete = (record: IEmployee) => {
    confirm({
      title: "Do you want to delete the Doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        try {
          deleteHimsEmployee({
            empID: record?.adid,
            userID: user?.himsEmployeeID,
          });
          if (DeleteHimsEmployee.isSuccess) {
            if (DeleteHimsEmployee.data) {
              notification["success"]({
                message: "User Deleted Successfully",
                icon: (
                  <i
                    className="las la-check-double"
                    style={{ color: "green" }}
                  ></i>
                ),
              });
            } else {
              notification["warning"]({
                message: "Service Failure",
                icon: (
                  <i
                    className="las la-exclamation-circle"
                    style={{ color: "red" }}
                  ></i>
                ),
              });
            }
          } else if (DeleteHimsEmployee.error) {
            notification["warning"]({
              message: "Service Failure",
              icon: (
                <i
                  className="las la-exclamation-circle"
                  style={{ color: "red" }}
                ></i>
              ),
            });
          }
        } catch (error) {
          console.error("Failed to delete data:", error);
        }
      },
    });
  };
  const specializationOptions = useMemo(
    () => getSpecializationOptions(Specializations.data),
    [Specializations]
  );
  const genderOptions = useMemo(
    () =>
      Array.isArray(Gender.data)
        ? Gender.data.map(
            ({
              dropDownSetID,
              dropDownValue,
            }: {
              dropDownSetID: number;
              dropDownValue: string;
            }) => ({
              value: dropDownSetID,
              label: dropDownValue,
            })
          )
        : [],
    [Gender]
  );
  const handleClose = () => {
    setIsModelOpen(false);
  };

  const columns: TableProps<IEmployee>["columns"] = [
    {
      title: "Name",
      dataIndex: "displayName",
      // ...getColumnSearchProps("displayName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      // ...getColumnSearchProps("email"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      // ...getColumnSearchProps("phoneNumber"),
      // render: (text: number) => <span>{text || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      // ...getColumnSearchProps("roleName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Location",
      dataIndex: "locationName",
      // ...getColumnSearchProps("locationName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space>
          {/* <Link to="#">
            <span className="link " onClick={() => handleEdit(record)}>
              <i className="las la-pen"></i>
            </span>
          </Link> */}
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const [formInstance] = Form.useForm();

  const handleEdit = (record: IEmployee) => {
    // setEditRole(true);
    setIsModelOpen(true);
    // setHimsRoleID(himsRoleID);
    setSelectedRecord(record);
    formInstance.setFieldsValue(record);
    window.scrollTo(0, 0);
  };

  const getLocationOptions = (locations: ILocation[] | undefined) => {
    const options: { label: string; value: number | null }[] = [
      // { label: "All", value: null },
    ];
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        options.push({
          label: location.locationName,
          value: location.locationID,
        });
      });
    }
    return options;
  };

  const [addOrUpdateEmployee, AddOrUpdateEmployee] =
    useAddOrUpdateEmployeeMutation();

  const Locations = useGetLocationsQuery();

  const locationOptions = useMemo(
    () => getLocationOptions(Locations.data),
    [Locations]
  );

  // const handleSubmit = async (values: any) => {
  //   const roleExists = allEmployees?.some(
  //     (role: { roleName: string }) =>
  //       role.roleName.toLowerCase().trim() === values.role.toLowerCase().trim()
  //   );
  //   if (!roleExists) {
  //     try {
  //       await postNewRolePrivilege({ RoleName: values.role }).unwrap();
  //       // Optionally, refetch data after successful post
  //       //  await refetch();
  //     } catch (error) {
  //       console.error("Failed to post data:", error);
  //     }
  //   } else {
  //     notification["warning"]({
  //       message: "Role already exists",
  //       icon: (
  //         <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
  //       ),
  //     });
  //   }
  // };

  // const handleInputChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value;
  //   if (value != null) {
  //     setSearchQuery(value);
  //   }
  // };

  const [updateUser, UserUpdate] = useAddOrUpdateEmployeeMutation();
  const navigate = useNavigate();

  const onAddRole = () => {
    navigate(`/AddUpdateExternalDoctors`);
    // setIsModelOpen(true);
    // setSelectedRecord(true);
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();
      const userExits = allEmployees?.some(
        (role: { email: string }) =>
          role?.email?.toLowerCase().trim() ===
          values?.email?.toLowerCase().trim()
      );
      const payload = {
        ...values,
        LanguageKnown: values.LanguageKnown.join(","),
        locationID: 1,
        CreatedBy: user?.himsEmployeeID,
      };

      if (!userExits) {
        try {
          await addExternalDoctor(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        notification["warning"]({
          message: "User already exists",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };

  const HimsRoles = useGetHimsRolesQuery();

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Spin
            spinning={isAllEmployeesLoading || AddExternalDoctor.isLoading}
            fullscreen
          />

          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">External Doctor List</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    External Doctor List
                  </li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn mx-3"
                  onClick={onAddRole}
                >
                  {" "}
                  <i className="fa fa-plus" />
                  Add External Doctor
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredEmployees}
                  className="table-striped"
                  // rowKey="himsRoleID"     // here key is not unique hence repetation occurs
                  pagination={
                    allEmployees && allEmployees.length > 10 ? {} : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          closeIcon={<i className="fa-solid fa-xmark" />}
          open={isModelOpen}
          onCancel={handleClose}
          width="73vw"
          footer={null}
          zIndex={2000}
          maskClosable={false}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title mb-0">Add External Doctor</h4>
                      </div>

                      <div className="card-body">
                        {/* <Spin spinning={AddOrUpdatePatient.isLoading} fullscreen /> */}

                        <Form form={formInstance} onFinish={handleAddUpdate}>
                          <h4 className="card-title">Personal Information</h4>

                          <div className="row">
                            <div className="col-xl-6">
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="FirstName"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter First Name",
                                      },
                                      {
                                        min: 3,
                                        message: "Enter atleast 3 characters",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="First Name" />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="LastName"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Last Name",
                                      },
                                      {
                                        min: 3,
                                        message: "Enter atleast 3 characters",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Last Name" />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Gender
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="GenderID"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Gender",
                                      },
                                    ]}
                                  >
                                    <Select
                                      style={{ width: "100%" }}
                                      options={genderOptions}
                                      loading={Gender.isFetching}
                                      showSearch
                                      optionFilterProp="label"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="EmailID"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Email",
                                      },
                                      {
                                        pattern: emailrgx,
                                        message: "Please Enter Valid Email",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Email ID" />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Mobile No.
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="PhoneNumber"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Mobile Number",
                                      },
                                      {
                                        pattern: IndianMobileNumber,
                                        message:
                                          "Please Enter Valid Mobile Number",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Mobile Number" />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="input-block mb-3 row">
                                <label className="col-lg-3 col-form-label">
                                  Alternate Mobile No.
                                </label>
                                <div className="col-lg-9">
                                  <Form.Item
                                    name="AlternateContactNo"
                                    rules={[
                                      {
                                        pattern: IndianMobileNumber,
                                        message:
                                          "Please Enter Valid Mobile Number",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Mobile Number" />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h4 className="card-title">
                            Professional Information
                          </h4>
                          <h4></h4>

                          <Form.Item>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Reg No
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="RegNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Enter RegNo",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Reg No" />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Specialization
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="Specialization"
                                      initialValue={null}
                                    >
                                      <Select
                                        loading={Specializations.isFetching}
                                        disabled={Specializations.isFetching}
                                        showSearch
                                        optionFilterProp="label"
                                        filterOption
                                        options={specializationOptions}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                               
                                <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Experience
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="Experience"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Enter Experience",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Experience" />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                              <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Qualification
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="Qualification"
                                      initialValue={null}
                                    >
                                      <Select
                                        loading={Specializations.isFetching}
                                        disabled={Specializations.isFetching}
                                        showSearch
                                        optionFilterProp="label"
                                        filterOption
                                        options={specializationOptions}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Hospital Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="hosipital"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Enter Hospital Name",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Hospital Name" />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="input-block mb-3 row">
                                  <label className="col-lg-3 col-form-label">
                                    Languages Known
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Form.Item
                                      name="LanguageKnown"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Select Languages",
                                        },
                                      ]}
                                    >
                                      <Checkbox.Group>
                                        <Checkbox value="English">
                                          English
                                        </Checkbox>
                                        <Checkbox value="Hindi">Hindi</Checkbox>
                                        <Checkbox value="Telugu">
                                          Telugu
                                        </Checkbox>
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form.Item>

                          <div className="text-end">
                            <Form.Item
                              style={{
                                display: "inline-block",
                                marginRight: "8px",
                              }}
                            >
                              {" "}
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={onCancel}
                              >
                                Cancel
                              </button>
                            </Form.Item>
                            <Form.Item style={{ display: "inline-block" }}>
                              {" "}
                              <button type="submit" className="btn btn-primary">
                                Save
                              </button>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ExternalDoctors;

const getSpecializationOptions = (
  specializations: ISpecialization[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "All", value: null },
  ];
  if (Array.isArray(specializations)) {
    specializations.forEach((specialization) => {
      options.push({
        label: specialization.speciality,
        value: specialization.specialityID,
      });
    });
  }
  return options;
};
