import { Card, Col, Row, Skeleton, Typography } from "antd";
import { ReactNode } from "react";

const { Text } = Typography;

type IDetailsConfig<T> = {
  key: keyof T;
  label: string;
  formatter?: (value: T[keyof T]) => string | number | null;
};

type Props = {
  data: { [key: string | number]: any };
  config: IDetailsConfig<Props["data"]>[];
  loading?: boolean;
  title: string;
  spanConfig?: { label: number; value: number };
  gutter?: [number, number];
  showTwoCols?: boolean;
  action?: ReactNode | undefined;
};

function DetailsCard({
  data,
  config,
  title,
  loading = false,
  spanConfig = { label: 8, value: 16 },
  gutter = [16, 16],
  showTwoCols = false,
  action = undefined,
}: Props) {
  if (showTwoCols) spanConfig = { label: 6, value: 6 };

  return (
    <Card title={title} extra={action}>
      <Row gutter={gutter}>
        {config.map(({ label, key, formatter }) => (
          <>
            <Col span={spanConfig.label} key={`${key}-key`}>
              <Text type="secondary">{label} :</Text>
            </Col>
            <Col span={spanConfig.value} key={`${key}-value`}>
              {loading ? (
                <Skeleton active title={false} paragraph={{ rows: 1 }} />
              ) : (
                <Text strong>
                  {formatter ? formatter(data[key]) : data[key]}
                </Text>
              )}
            </Col>
          </>
        ))}
      </Row>
    </Card>
  );
}

export default DetailsCard;
