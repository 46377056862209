import DetailsCard from "../../../../components/DetailsCard";

const formatDate = (dateString: string | number | undefined) => {
  if (dateString === undefined) {
    return "Invalid Date";
  }
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

const fields: {
  key: keyof IPatientDetails;
  label: string;
  formatter?: (value: IPatientDetails[keyof IPatientDetails]) => string;
}[] = [
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "emailID", label: "Email ID" },
  { key: "phoneNo", label: "Mobile No" },
  { key: "dob", label: "Date Of Birth", formatter: formatDate },
  { key: "gender", label: "Gender" },
  { key: "bloodGroup", label: "Blood Group" },
];

function PatientCard({
  patientDetails,
  loading,
}: {
  patientDetails: IPatientDetails;
  loading: boolean;
}) {
  return (
    <DetailsCard
      config={fields}
      title="Patient Information"
      data={patientDetails}
      // showTwoCols
      loading={loading}
    />
  );
}

export default PatientCard;
