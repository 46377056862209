export const isNumber = (x: any, noStr?: boolean) =>
  ((typeof x === "number" ||
    x instanceof Number ||
    (!noStr && x && typeof x === "string" && !isNaN(x as unknown as number))) &&
    isFinite(x as number)) ||
  false;

export const isString = (a: any) => typeof a === "string";

export const groupBy = <T extends Record<string, unknown>, K extends keyof T>(
  arr: readonly T[],
  keyProperty: K
) => {
  const map: { [key: PropertyKey]: T[] } = {};
  arr.forEach((item) => {
    const actualKey = String(item[keyProperty]);
    if (map[actualKey]) map[actualKey].push(item);
    else map[actualKey] = [item];
  });
  return map;
};
