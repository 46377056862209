import type { TableProps } from "antd";
import { Button, Col, Flex, Row, Select, Space, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetHimsRolesQuery,
  useGetPrivilegesByRoleQuery,
  useUpdateRolePrivilegesMutation,
} from "../../../services/ApiSlice";

interface IDataSource {
  rolePrivilegeID: string;
  columnName: string;
  children: IPrivilege[];
}

function RolePrivilegeMap() {
  const [selectedRole, setSelectedRole] = useState<number>(1);
  const [selectedRolePrivilegeIDs, setSelectedRolePrivilegeIDs] = useState<
    React.Key[]
  >([]);

  const HimsRoles = useGetHimsRolesQuery();
  const PrivilegesByRole = useGetPrivilegesByRoleQuery(selectedRole);
  const [updateRolePrivileges, UpdateRolePrivileges] =
    useUpdateRolePrivilegesMutation();

  useEffect(() => {
    onPrivilegesReset();
  }, [PrivilegesByRole]);

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  const columns: TableProps<IDataSource>["columns"] = [
    {
      title: "Screen Name",
      dataIndex: "columnName",
    },
    {
      title: "Privilege Name",
      dataIndex: "privilegeName",
    },
    {
      title: "Privilege Description",
      dataIndex: "description",
    },
  ];

  const dataSource: IDataSource[] = useMemo(
    () =>
      Array.isArray(PrivilegesByRole.data)
        ? getDataSource(PrivilegesByRole.data)
        : [],
    [PrivilegesByRole.data]
  );

  const onPrivilegesChange = useCallback((newSelectedRowKeys: React.Key[]) => {
    setSelectedRolePrivilegeIDs(newSelectedRowKeys);
  }, []);

  const onGetPrivileges = ({ roleID }: { roleID: number }) => {
    setSelectedRole(roleID);
  };

  const onPrivilegesSubmit = () => {
    if (Array.isArray(PrivilegesByRole.data)) {
      const payload = PrivilegesByRole.data
        .flat()
        .map(({ rolePrivilegeID }) => ({
          RolePrivilegeID: rolePrivilegeID,
          HasPrivilege: selectedRolePrivilegeIDs.includes(rolePrivilegeID),
        }));

      updateRolePrivileges(payload);
    }
  };

  const onPrivilegesReset = () => {
    if (Array.isArray(PrivilegesByRole.data)) {
      setSelectedRolePrivilegeIDs(
        getSelectedPrivilegeIDs(PrivilegesByRole.data)
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Privileges Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Privileges Manager</li>
              </ul>
            </div>
          </div>
        </div>
        <Flex vertical gap="middle">
          <Row justify="space-between" gutter={24}>
            <Col>
              <Select
                value={selectedRole}
                options={himsRolesOptions}
                style={{ width: 200 }}
                loading={HimsRoles.isFetching}
                disabled={HimsRoles.isFetching}
                showSearch
                optionFilterProp="label"
                onChange={setSelectedRole}
              />
            </Col>
            <Col>
              <Space.Compact>
                <Button
                  onClick={onPrivilegesReset}
                  disabled={
                    PrivilegesByRole.isFetching ||
                    UpdateRolePrivileges.isLoading
                  }
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={onPrivilegesSubmit}
                  disabled={
                    PrivilegesByRole.isFetching ||
                    UpdateRolePrivileges.isLoading
                  }
                >
                  Submit
                </Button>
              </Space.Compact>
            </Col>
          </Row>
          <Table
            loading={
              PrivilegesByRole.isFetching || UpdateRolePrivileges.isLoading
            }
            columns={columns}
            rowSelection={{
              selectedRowKeys: selectedRolePrivilegeIDs,
              onChange: onPrivilegesChange,
              checkStrictly: false,
            }}
            dataSource={dataSource}
            rowKey="rolePrivilegeID"
            pagination={false}
          />
        </Flex>
      </div>
    </div>
  );
}

export default RolePrivilegeMap;

const getSelectedPrivilegeIDs = (privileges: IPrivilege[][]) => {
  const selectedIds: React.Key[] = [];
  privileges.flat().forEach((privilege) => {
    if (privilege.hasPrivilege) selectedIds.push(privilege.rolePrivilegeID);
  });
  return selectedIds;
};

const getDataSource = (privileges: IPrivilege[][]) =>
  privileges.map((privileges) => {
    const screenName = privileges[0]?.screenName;
    return {
      rolePrivilegeID: screenName + "screen", // TODO: need to check
      columnName: screenName,
      children: privileges,
    };
  });
