import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetPaymentsQuery } from "../../../services/ApiSlice";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import { skipToken } from "@reduxjs/toolkit/query";

const PaymentTab: React.FC<{}> = () => {
  const { id }: any = useParams();
  // const { data  } = useGetPaymentsQuery('E6E41F6A-C21E-4A44-A72B-F48072034675');
  const { data } = useGetPaymentsQuery(id ?? skipToken);

  const { address = {}, diseases = [] } = data;
  // const location = useLocation();
  // const { formData = [] } = location.state || {};

  // console.log(formData.firstName);

  // const detaiId=parseInt(id);
  // const patientId = parseInt(id);
  // console.log(allPatients.patientID)
  // const data: any = allPatients.find((element:any) => element.uhid === id);
  const personalDetails: any = [
    {
      Id: id,
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.emailID,
      Mobile: data.phoneNo,
      Gender: data.gender,
      Dob: data.dob,
      BloodGroup: data.bloodGroup,
      // Diseases: data.diseases,
    },
  ];
  const paymentDetails: any = [
    {
      PaymentFor: data?.paymentFor,
      Amount: data?.amount,
      PaymentDate: data?.paymentDate,
    },
  ];

  return (
    <>
      {" "}
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="tab-content">
            <div
              id="emp_patient"
              className="pro-overview tab-pane fade show active"
            >
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        Personal Information{" "}
                        <Link
                          to={`/patient-details-form/${id}`}
                          className="edit-icon"
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                      </h3>
                      <ul className="personal-info">
                        {/* {data.firstName} */}

                        {Array.isArray(personalDetails) ? (
                          personalDetails.map((item: any, index: any) => (
                            <ListItem key={index} data={item} />
                          ))
                        ) : (
                          <ListItem data={personalDetails} />
                        )}

                        {/* {data.map((item:any) => (
                       
                      <ListItem
                        // id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))} */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        Payment Details{" "}
                        {/* <Link
                                                    to="#"
                                                    className="edit-icon"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#emergency_contact_modal"
                                                >
                                                    <i className="fa fa-pencil" />
                                                </Link> */}
                      </h3>
                      {/* <h5 className="section-title">Primary</h5> */}
                      <ul className="personal-info">
                        {Array.isArray(paymentDetails) ? (
                          paymentDetails.map((item: any, index: any) => (
                            <ListItem key={index} data={item} />
                          ))
                        ) : (
                          <ListItem data={paymentDetails} />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-6 d-flex">
                                    <div className="card profile-box flex-fill">
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                Family Informations{" "}
                                                <Link
                                                    to="#"
                                                    className="edit-icon"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#family_info_modal"
                                                >
                                                    <i className="fa fa-pencil" />
                                                </Link>
                                            </h3>
                                            <div className="table-responsive">
                                                <table className="table table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Relationship</th>
                                                            <th>Date of Birth</th>
                                                            <th>Phone</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {familyInfoData.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.name}</td>
                                                                <td>{item.relationship}</td>
                                                                <td>{item.dob}</td>
                                                                <td>{item.phone}</td>
                                                                <td className="text-end">
                                                                    <div className="dropdown dropdown-action">
                                                                        <Link
                                                                            aria-expanded="false"
                                                                            data-bs-toggle="dropdown"
                                                                            className="action-icon dropdown-toggle"
                                                                            to="#"
                                                                        >
                                                                            <i className="material-icons">more_vert</i>
                                                                        </Link>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <Link to="#" className="dropdown-item">
                                                                                <i className="fa fa-pencil m-r-5" /> Edit
                                                                            </Link>
                                                                            <Link to="#" className="dropdown-item">
                                                                                <i className="fa fa-trash m-r-5" /> Delete
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              {/* <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#education_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {educationData.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.name}
                              </Link>
                              <div>{item.description}</div>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {experienceData.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.name}
                              </Link>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>

            {/* Bank Statutory Tab */}

            {/* Bank Statutory Tab */}
            {/*  Bank Tab */}
          </div>
          {/* Model Popup*/}
          {/* <ProjectDetails />
                    <PersonalInformationModelPopup /> */}
        </div>
      </div>
      {/* <AddPayment patientID={id} /> */}
    </>
  );
};
export const ListItem: React.FC<{ data: { [key: string]: string } }> = ({
  data,
}) => (
  <div>
    {Object.entries(data).map(([key, value]) => (
      <li>
        <div className="title">{key}</div>
        <div className="text">{value}</div>
      </li>
    ))}
  </div>
);

export default PaymentTab;
