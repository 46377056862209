import type { TabsProps } from "antd";
import { Card, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { Role } from "../../../models/PrivilegeRoles";
import AddUpdateOPSummary from "./AddUpdateOPSummary";
import LabTests from "./LabTests";
import PreviousMedicalHistory from "./PreviousMedicalHistory";
import Vitals from "./Vitals/Vitals";
import AddInpatientOPSummary from "../UserDetails/InpatientTabs/AddInpatientOPSummary";

type Props = {
  patientData: IPatientDetails;
  TCOPID: string;
};

function TabsCard({ patientData, TCOPID }: Props) {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("1");
  const isRoleBasedVisible = useRoleBasedVisible();
  useEffect(() => {
    if (location?.state?.activeKey) setActiveKey(location.state.activeKey);
  }, [location]);

  const tabItems = [
    {
      key: "1",
      label: "Vitals",
      children: (
        <Vitals
          isEditable={isRoleBasedVisible([Role.Admin])} // TODO: add status check
          patientId={patientData?.patientID}
        />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "2",
      label: "OP Notes",
      children: (
        <AddUpdateOPSummary
          TCOPID={TCOPID}
          isEditable={isRoleBasedVisible([Role.Admin, Role.Doctor, Role.Nurse])} // TODO: add status check
        />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "3",
      label: "Prescription",
      children: <WIP />,
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    // {
    //   key: "4",
    //   label: "Lab Tests",
    //   children: <LabTests TCOPID={TCOPID} patientID={patientData?.patientID} />,
    //   visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    // },
    {
      key: "5",
      label: "Previous Medical History",
      children: <PreviousMedicalHistory patientID={patientData?.patientID} />,
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
  ];

  const filteredTabItems: TabsProps["items"] = tabItems.filter((tabItem) =>
    isRoleBasedVisible(tabItem.visibleToRoles)
  );

  // SHORT_CIRCUIT
  if (!filteredTabItems.length) return null;

  return (
    <Card>
         <Tabs
        items={filteredTabItems}
        destroyInactiveTabPane
        activeKey={activeKey}
        onChange={setActiveKey}
      />
    </Card>
  );
}

export default TabsCard;

function WIP() {
  return (
    <Card>
      <Typography.Title
        level={4}
        type="secondary"
        style={{ textAlign: "center" }}
      >
        Work In Progress
      </Typography.Title>
    </Card>
  );
}
