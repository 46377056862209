import React from "react";
function NotAuthorized() {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid center">You are not authorized for this page</div>
      </div>
    </div>
  );
}
export default NotAuthorized;
