import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  TableProps,
  Typography,
  notification,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useAddOrUpdateEmployeeMutation,
  useAddOrUpdateExternalEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetApprovedDoctorsQuery,
  useGetDropdownQuery,
  useGetHIMSEmployeeDetailsByADIDQuery,
  useGetHimsRolesQuery,
  useGetLocationsQuery,
  useGetQualificationQuery,
  useGetSpecializationsQuery,
  useLazyGetUserEmailValidationQuery,
  usePostNewRolePrivilegeMutation,
  useUpdateEmployeeMutation,
} from "../../../services/ApiSlice";

import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { selectUser } from "../../../reducers/UserSlice";
import { IndianMobileNumber } from "../Authentication/RegEx";

function AddUpdateExternalDoctor() {
  const { confirm } = Modal;
  const { Text } = Typography;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputValue2, setInputValue2] = useState<string>("");
  const [emailCheck, setEmailCheck] = useState(false);
  const [helpMessage, setHelpMessage] = useState("");
  const [password, setPassword] = useState("");
  const [emailPattern, setEmailPattern] = useState(
    /^[a-zA-Z0-9._%+-]+@healthonus\.io$/
  );
  const user = useSelector(selectUser);
  const Specializations = useGetSpecializationsQuery();
  const Qualifications = useGetQualificationQuery();
  const [updateEmployee, UpdateEmployee] = useUpdateEmployeeMutation();
  const [formInstance] = Form.useForm();
  const { data: allEmployees, isFetching: isAllEmployeesLoading } =
    useGetAllEmployeesQuery("");
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const [addExternalDoctor, AddExternalDoctor] =
    useAddOrUpdateExternalEmployeeMutation();
  const [getUserEmailValidation, GetUserEmailValidation] =
    useLazyGetUserEmailValidationQuery();
  const Gender = useGetDropdownQuery("Gender");
  const GetHIMSEmployeeDetails = useGetHIMSEmployeeDetailsByADIDQuery(
    id ?? skipToken
  );
  const GetApprovedDoctors = useGetApprovedDoctorsQuery();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const handleType = Form.useWatch("type", formInstance);
  const handleRole = Form.useWatch("roleID", formInstance);
  const handleEmail = Form.useWatch("EmailID", formInstance);
  const handleFirstName = Form.useWatch("FirstName", formInstance);
  const [selectedRecord, setSelectedRecord] = useState<IEmployee | null>(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);
  type DataIndex = keyof IApprovedDoctors;
  const [addOrUpdateEmployee, AddOrUpdateEmployee] =
    useAddOrUpdateEmployeeMutation();

  useEffect(() => {
    if (handleRole !== 3) {
      formInstance.setFieldsValue({ type: "internal" });
    }
    // formInstance.resetFields();
  }, [handleRole]);
  useEffect(() => {
    setEmailCheck(false);
  }, [handleEmail]);
  useEffect(() => {
    if (id) {
      const experience = GetHIMSEmployeeDetails.data?.experience ?? "0.0";
      const [years, months] = experience.split(".").map(Number);
      formInstance.setFieldsValue({
        roleID: GetHIMSEmployeeDetails.data?.himsRoleID,
        locationName: GetHIMSEmployeeDetails.data?.locationID,
        type: GetHIMSEmployeeDetails.data?.isExternal ? "external" : "internal",
        EmailID: GetHIMSEmployeeDetails.data?.email,
        FirstName: GetHIMSEmployeeDetails.data?.firstName,
        LastName: GetHIMSEmployeeDetails.data?.lastName,
        PhoneNumber: GetHIMSEmployeeDetails.data?.phoneNumber,
        GenderID: GetHIMSEmployeeDetails.data?.genderID,
        AlternateContactNo: GetHIMSEmployeeDetails.data?.emergencyContactNo,
        Password: GetHIMSEmployeeDetails.data?.password,
        RegNumber: GetHIMSEmployeeDetails.data?.regNumber,
        QualificationId: GetHIMSEmployeeDetails.data?.qualificationID,
        hospitalName: GetHIMSEmployeeDetails.data?.hospitalName,
        LanguageKnown: GetHIMSEmployeeDetails.data?.languageKnown
          .split(",")
          .map((lang) => lang.trim()),
        experience_years: years || 0,
        experience_months: months ?? undefined,
        SpecializationId: GetHIMSEmployeeDetails.data?.specialityID,
      });
    }
  }, [GetHIMSEmployeeDetails]);
  useEffect(() => {
    if (GetUserEmailValidation.isSuccess) {
      if (GetUserEmailValidation.data) {
        setHelpMessage(GetUserEmailValidation.data);
        notification["success"]({
          message: "Validation checked successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (GetUserEmailValidation.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [GetUserEmailValidation]);
  // useEffect(() => {
  //   if (handleFirstName) {
  //     const newPassword = generatePassword(handleFirstName);
  //     formInstance.setFieldsValue({ Password: newPassword });
  //     setPassword(newPassword);
  //   } else {
  //     formInstance.setFieldsValue({ Password: "" });
  //   }
  // }, [handleFirstName]);
  // useEffect(() => {
  //   setPassword(keyGen(6));
  //   formInstance.setFieldsValue({ Password: keyGen(6) });
  // }, []);
  useEffect(() => {
    if (AddOrUpdateEmployee.isSuccess) {
      if (AddOrUpdateEmployee.data) {
        navigate(`/UserList`);
        notification["success"]({
          message: "Users modification done successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (AddOrUpdateEmployee.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [AddOrUpdateEmployee]);
  useEffect(() => {
    if (UpdateEmployee.isSuccess) {
      if (UpdateEmployee.data) {
        navigate(`/UserList`);
        notification["success"]({
          message: "Users modification done successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        GetHIMSEmployeeDetails.refetch();
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (UpdateEmployee.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [UpdateEmployee]);
  useEffect(() => {
    if (AddExternalDoctor.isSuccess) {
      if (AddExternalDoctor.data) {
        navigate(`/UserList`);
        notification["success"]({
          message: "Users modification done successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (AddExternalDoctor.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [AddExternalDoctor]);

  const generatePassword = (firstName: string) => {
    const trimmedFirstName = firstName.replace(/\s/g, ""); // Remove spaces
    return `${trimmedFirstName}@123`;
  };
  const keyGen = (keyLength: number) => {
    var i,
      key = "",
      characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    var charactersLength = characters.length;

    for (i = 0; i < keyLength; i++) {
      key += characters.substr(
        Math.floor(Math.random() * charactersLength + 1),
        1
      );
    }

    return key;
  };
  function generateRandomPassword(length: number) {
    const symbols = "@#$&";
    const numbers = "0123456789";
    const capitalAlphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const smallAlphabets = "abcdefghijklmnopqrstuvwxyz";

    let password = "";

    // Ensure at least one of each character type
    password += symbols[Math.floor(Math.random() * symbols.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password +=
      capitalAlphabets[Math.floor(Math.random() * capitalAlphabets.length)];
    password +=
      smallAlphabets[Math.floor(Math.random() * smallAlphabets.length)];

    const remainingLength = length - 4; // Subtracting 4 for the characters already chosen

    // Generate the rest of the password randomly
    const allCharacters = symbols + numbers + capitalAlphabets + smallAlphabets;
    console.log(allCharacters, symbols.length, "pas", password);
    for (let i = 0; i < remainingLength; i++) {
      password +=
        allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password characters
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  }
  const handleCancle = () => {
    confirm({
      title: "Are you sure want to exit ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        formInstance.resetFields();
        navigate("/UserList");
      },
    });
  };
  const handleDelete = (record: IApprovedDoctors) => {
    confirm({
      title: "Are you sure delete the doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        addExternalDoctor({
          email: record?.email,
          modifiedBy: user?.himsEmployeeID,
          approvedDoctorsID: record?.approvedDoctorsID,
        });
      },
    });
  };
  const specializationOptions = useMemo(
    () => getSpecializationOptions(Specializations.data),
    [Specializations]
  );
  const qualificationOptions = useMemo(
    () => getQualificationOptions(Qualifications.data),

    [Qualifications]
  );
  const emailValidate = () => {
    setEmailCheck(true);
    getUserEmailValidation({
      email: formInstance.getFieldValue("EmailID"),
      isExternalUser: handleType === "external" ? true : false,
    });
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    // Check if the input is a number
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };
  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    // Check if the input is a number
    if (/^\d*$/.test(value)) {
      setInputValue2(value);
    }
  };
  const genderOptions = useMemo(
    () =>
      Array.isArray(Gender.data)
        ? Gender.data.map(
            ({
              dropDownSetID,
              dropDownValue,
            }: {
              dropDownSetID: number;
              dropDownValue: string;
            }) => ({
              value: dropDownSetID,
              label: dropDownValue,
            })
          )
        : [],
    [Gender]
  );
  const handleClose = () => {
    setIsModelOpen(false);
  };

  const columns: TableProps<IEmployee>["columns"] = [
    {
      title: "Name",
      dataIndex: "displayName",
      // ...getColumnSearchProps("displayName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      // ...getColumnSearchProps("email"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      // ...getColumnSearchProps("phoneNumber"),
      // render: (text: number) => <span>{text || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      // ...getColumnSearchProps("roleName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Location",
      dataIndex: "locationName",
      // ...getColumnSearchProps("locationName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space>
          {/* <Link to="#">
              <span className="link " onClick={() => handleEdit(record)}>
                <i className="las la-pen"></i>
              </span>
            </Link> */}
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const handleEdit = (record: IEmployee) => {
    // setEditRole(true);
    setIsModelOpen(true);
    // setHimsRoleID(himsRoleID);
    setSelectedRecord(record);
    formInstance.setFieldsValue(record);
    window.scrollTo(0, 0);
  };

  const getLocationOptions = (locations: ILocation[] | undefined) => {
    const options: { label: string; value: number | null }[] = [
      // { label: "All", value: null },
    ];
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        options.push({
          label: location.locationName,
          value: location.locationID,
        });
      });
    }
    return options;
  };

  const Locations = useGetLocationsQuery();

  const locationOptions = useMemo(
    () => getLocationOptions(Locations.data),
    [Locations]
  );

  const filteredEmployees = useMemo(() => {
    if (Array.isArray(allEmployees)) {
      return allEmployees?.filter((employee) => employee.isExternal === 1);
    }
    return [];
  }, [allEmployees]);

  const handleAddUpdateUser = async () => {
    console.log("Hi");
    try {
      const values = await formInstance.validateFields();
      console.log(values?.experience_months);
      const experience_years =
        values?.experience_years !== undefined ? values.experience_years : 0;
      const experience_months =
        values?.experience_months !== undefined &&
        !isNaN(values?.experience_months)
          ? values.experience_months
          : 0;
      const experience = !isNaN(experience_months)
        ? `${experience_years}.${experience_months}`
        : `${experience_years}`;
      if (id) {
        const payload = {
          emailID: values?.emailID,
          roleID: values?.roleID,
          locationID: values?.locationName,
          mobileNumber: values?.PhoneNumber,
          experience: experience,
          specializationID: values?.SpecializationId,
          qualificationID: values?.QualificationId,
          modifiedBy: user?.himsEmployeeID,
          employeeID: id,
          firstName: values?.FirstName,
          lastName: values?.LastName,
          password: values?.Password,
          regNumber: values?.RegNumber,
          genderID: values?.GenderID,
        };
        try {
          await updateEmployee(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        const userExits = allEmployees?.some(
          (role: { email: string }) =>
            role?.email?.toLowerCase().trim() ===
            values?.EmailID?.toLowerCase().trim()
        );
        const payload = {
          emailID: values?.EmailID,
          roleID: values?.roleID,
          locationID: 1,
          himsEmployeeID: selectedRecord?.himsEmployeeID,
        };

        if (selectedRecord) {
          try {
            await addOrUpdateEmployee(payload).unwrap();
          } catch (error) {
            console.error("Failed to post data:", error);
          }
        } else {
          if (!userExits) {
            try {
              await addOrUpdateEmployee(payload).unwrap();
            } catch (error) {
              console.error("Failed to post data:", error);
            }
          } else {
            notification["warning"]({
              message: "User already exists",
              icon: (
                <i
                  className="las la-exclamation-circle"
                  style={{ color: "red" }}
                ></i>
              ),
            });
          }
        }
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };
  //   const roleExists = allEmployees?.some(
  //     (role: { roleName: string }) =>
  //       role.roleName.toLowerCase().trim() === values.role.toLowerCase().trim()
  //   );
  //   if (!roleExists) {
  //     try {
  //       await postNewRolePrivilege({ RoleName: values.role }).unwrap();
  //       // Optionally, refetch data after successful post
  //       //  await refetch();
  //     } catch (error) {
  //       console.error("Failed to post data:", error);
  //     }
  //   } else {
  //     notification["warning"]({
  //       message: "Role already exists",
  //       icon: (
  //         <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
  //       ),
  //     });
  //   }
  // };

  // const handleInputChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value;
  //   if (value != null) {
  //     setSearchQuery(value);
  //   }
  // };

  const [updateUser, UserUpdate] = useAddOrUpdateEmployeeMutation();

  const onAddRole = () => {
    setIsModelOpen(true);
    // setSelectedRecord(true);
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.type) {
      if (changedValues.type === "external") {
        setEmailPattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      } else {
        setEmailPattern(/^[a-zA-Z0-9._%+-]+@healthonus\.io$/);
      }
      formInstance.validateFields(["EmailID"]);
    }
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();
      const userExits = filteredEmployees?.some(
        (role: { EmailID: string; email: string }) =>
          role?.email?.toLowerCase().trim() ===
          values?.EmailID?.toLowerCase().trim()
      );
      const payload = {
        ...values,
        experience: values.experience_years + "." + values.experience_months,
        LanguageKnown: values.LanguageKnown.join(","),
        locationID: 1,
        CreatedBy: user?.himsEmployeeID,
      };
      if (id) {
        const payload = {
          emailID: values?.emailID,
          roleID: values?.roleID,
          locationID: values?.locationName,
          mobileNumber: values?.PhoneNumber,
          experience: values.experience_years + "." + values.experience_months,
          specializationID: values?.SpecializationId,
          qualificationID: values?.QualificationId,
          modifiedBy: user?.himsEmployeeID,
          employeeID: id,
          firstName: values?.FirstName,
          lastName: values?.LastName,
          password: values?.Password,
          regNumber: values?.RegNumber,
          genderID: values?.GenderID,
        };
        try {
          await updateEmployee(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        if (!userExits) {
          try {
            await addExternalDoctor(payload).unwrap();
          } catch (error) {
            console.error("Failed to post data:", error);
          }
        } else {
          notification["warning"]({
            message: "User already exists",
            icon: (
              <i
                className="las la-exclamation-circle"
                style={{ color: "red" }}
              ></i>
            ),
          });
        }
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };

  const HimsRoles = useGetHimsRolesQuery();

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Flex vertical gap={24}>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Add User</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`/patients`}>User Registration</Link>
                </li>

                {/* {true ? (
                  <li className="breadcrumb-item">
                    <Link to={`/patients/${patientID}`}>Patient Details</Link>
                  </li>
                ) : null}

                <li className="breadcrumb-item active">
                  {patientID ? "Update" : "Add"} Patient
                </li> */}
              </ul>
            </div>
          </div>

          <Spin
            spinning={
              isAllEmployeesLoading ||
              AddExternalDoctor.isLoading ||
              GetUserEmailValidation.isFetching ||
              GetHIMSEmployeeDetails.isFetching ||
              UpdateEmployee.isLoading
            }
            fullscreen
          />

          <Card>
            <Form
              form={formInstance}
              onValuesChange={onValuesChange}
              onFinish={
                handleType !== "external"
                  ? handleAddUpdateUser
                  : handleAddUpdate
              }
            >
              <h4 className="card-title">Basic Information</h4>
              <div>&nbsp;</div>

              <div className="row">
                <div className="col-xl-6">
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Role <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item
                        name="roleID"
                        rules={[
                          { required: true, message: "Please Enter Role" },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          options={himsRolesOptions}
                          loading={HimsRoles.isFetching}
                          showSearch
                          optionFilterProp="label"
                          placeholder="Role"
                          disabled={
                            !!id && !!GetHIMSEmployeeDetails.data?.isExternal
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Location <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item name="locationName" initialValue={null}>
                        <Select
                          loading={Locations.isFetching}
                          disabled={Locations.isFetching}
                          showSearch
                          optionFilterProp="label"
                          filterOption
                          options={locationOptions}
                          placeholder="Location"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  {handleRole === 3 && (
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Type <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name="type">
                          <Radio.Group defaultValue={"internal"}>
                            <Radio
                              value="internal"
                              disabled={handleRole !== 3 || !!id}
                            >
                              {" "}
                              Internal{" "}
                            </Radio>
                            <Radio
                              value="external"
                              disabled={handleRole !== 3 || !!id}
                            >
                              {" "}
                              External{" "}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Space size={0} direction="vertical">
                        <Space.Compact style={{ width: "100%" }}>
                          {" "}
                          <Form.Item
                            name="EmailID"
                            rules={
                              id
                                ? []
                                : [
                                    {
                                      required: true,
                                      message: "Please Enter Email",
                                    },
                                    {
                                      pattern: emailPattern,
                                      message:
                                        handleType !== "external"
                                          ? "Please Enter Valid HOU Email"
                                          : "Please Enter Valid Email",
                                    },
                                  ]
                            }
                          >
                            <Input placeholder="Email ID" disabled={!!id} />
                          </Form.Item>
                          <Button
                            style={{ marginBottom: 24 }}
                            type="primary"
                            onClick={emailValidate}
                            disabled={!!id}
                          >
                            Check
                          </Button>{" "}
                        </Space.Compact>
                        <div>
                          {" "}
                          <Text type="danger">{emailCheck && helpMessage}</Text>
                        </div>
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
              {handleType === "external" && (
                <>
                  <h4 className="card-title">Personal Information</h4>
                  <div>&nbsp;</div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="FirstName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter First Name",
                              },
                              {
                                min: 3,
                                message: "Enter atleast 3 characters",
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          Mobile No. <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="PhoneNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Mobile Number",
                              },
                              {
                                pattern: IndianMobileNumber,
                                message: "Please Enter Valid Mobile Number",
                              },
                            ]}
                          >
                            <Input type="text" placeholder="Mobile No" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          Gender
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="GenderID"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Gender",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              options={genderOptions}
                              loading={Gender.isFetching}
                              showSearch
                              optionFilterProp="label"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="LastName"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Last Name",
                              },
                              {
                                min: 3,
                                message: "Enter atleast 3 characters",
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          Alt. Mobile No.
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="AlternateContactNo"
                            rules={[
                              {
                                pattern: IndianMobileNumber,
                                message: "Please Enter Valid Mobile Number",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Mobile No"
                              disabled={!!id}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="input-block mb-3 row">
                        <label className="col-lg-3 col-form-label">
                          Password
                        </label>
                        <div className="col-lg-9">
                          <Form.Item
                            name="Password"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Password",
                              },
                              {
                                min: 3,
                                message: "Enter atleast 3 characters",
                              },
                            ]}
                            initialValue={generateRandomPassword(6)}
                          >
                            <Input placeholder="Password" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="card-title">Professional Information</h4>
                  <div>&nbsp;</div>
                  <Form.Item>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Reg No
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <Form.Item
                              name="RegNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter RegNo",
                                },
                              ]}
                            >
                              <Input placeholder="Reg No" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Specialization
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <Form.Item
                              name="SpecializationId"
                              initialValue={null}
                            >
                              <Select
                                loading={Specializations.isFetching}
                                disabled={Specializations.isFetching}
                                showSearch
                                optionFilterProp="label"
                                filterOption
                                options={specializationOptions}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Experience
                          </label>
                          <div className="col-lg-9">
                            <Space>
                              <Form.Item name="experience_years">
                                <InputNumber
                                  min={0}
                                  max={50}
                                  placeholder="Years"
                                  style={{ width: 186 }}
                                  disabled={!!id}
                                />
                              </Form.Item>
                              <Form.Item name="experience_months">
                                <InputNumber
                                  min={0}
                                  max={11}
                                  placeholder="Months"
                                  style={{ width: 186 }}
                                  disabled={!!id}
                                />
                              </Form.Item>{" "}
                            </Space>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Qualification
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <Form.Item
                              name="QualificationId"
                              initialValue={null}
                            >
                              <Select
                                loading={Qualifications.isFetching}
                                disabled={Qualifications.isFetching}
                                showSearch
                                optionFilterProp="label"
                                filterOption
                                options={qualificationOptions}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Hospital Name
                          </label>
                          <div className="col-lg-9">
                            <Form.Item name="hospitalName">
                              <Input
                                placeholder="Hospital Name"
                                disabled={!!id}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {/* <div className="input-block mb-3 row">
                  <label className="col-lg-3 col-form-label">
                    Qualification
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-9">
                    <Form.Item
                      name="genderDDSID"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Qualification",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        options={genderOptions}
                        loading={Gender.isFetching}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </div>
                </div> */}
                        <div className="input-block mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Languages Known
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <Form.Item
                              name="LanguageKnown"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Languages",
                                },
                              ]}
                            >
                              <Checkbox.Group disabled={!!id}>
                                <Checkbox value="English">English</Checkbox>
                                <Checkbox value="Hindi">Hindi</Checkbox>
                                <Checkbox value="Telugu">Telugu</Checkbox>
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>{" "}
                </>
              )}
              <div className="text-end">
                <Form.Item
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                  }}
                >
                  {" "}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                </Form.Item>
                <Form.Item style={{ display: "inline-block" }}>
                  {" "}
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </Form.Item>
              </div>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
}

export default AddUpdateExternalDoctor;
const getSpecializationOptions = (
  specializations: ISpecialization[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "All", value: null },
  ];
  if (Array.isArray(specializations)) {
    specializations.forEach((specialization) => {
      options.push({
        label: specialization.speciality,
        value: specialization.specialityID,
      });
    });
  }
  return options;
};

const getQualificationOptions = (
  qualification: IQualification[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "Select", value: null },
  ];
  if (Array.isArray(qualification)) {
    qualification.forEach((qualification) => {
      options.push({
        label: qualification.qualification,
        value: qualification.qualificationID,
      });
    });
  }
  return options;
};
