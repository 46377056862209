import { Avatar, Badge, Card, Skeleton, Space, Typography } from "antd";
import { CiLocationOn } from "react-icons/ci";
import { FaUserGraduate } from "react-icons/fa";

const { Meta } = Card;
const { Text } = Typography;
const { Ribbon } = Badge;

type Props = {
  details: ISpecialistDetails | undefined;
  loading: boolean;
};

function SpecialistDetailsCard({ details, loading }: Props) {
  return (
    <Ribbon
      text={
        loading
          ? "Loading..."
          : details?.availabilityStatus
          ? "Available"
          : "Not Available"
      }
      color={details?.availabilityStatus ? "green" : "red"}
    >
      <Card>
        <Meta
          style={{ alignItems: "center" }}
          avatar={
            loading ? (
              <Skeleton.Avatar active size={80} />
            ) : (
              <Avatar
                size={{ xs: 80, sm: 64, md: 80, lg: 80, xl: 64, xxl: 100 }}
                icon={<i className="fa-regular fa-user" />}
              />
            )
          }
          title={
            loading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 200 }}
              />
            ) : (
              details?.name
            )
          }
          description={
            <Space direction="vertical" size="small">
              <Space>
                <FaUserGraduate className="speclist-card-img" />
                {loading ? (
                  <Skeleton
                    active
                    title={false}
                    paragraph={{ rows: 1, width: 200 }}
                  />
                ) : (
                  <>
                    <Text type="secondary">{details?.speciality}</Text>{" "}
                    <Text strong>{details?.qualification}</Text>
                  </>
                )}
              </Space>

              {/* <Text type="secondary">
                <MdPersonAddAlt className="speclist-card-img" />
                {details?.experience}{" "}
                {details?.experience === "1"
                  ? "Year Experience"
                  : "Years Experience"}
              </Text> */}
              <Space>
                <Text type="secondary">
                  <i className="fa fa-volume-up" />
                </Text>
                {loading ? (
                  <Skeleton
                    active
                    title={false}
                    paragraph={{ rows: 1, width: 200 }}
                  />
                ) : (
                  <Text type="secondary">{details?.language}</Text>
                )}
              </Space>

              <Space>
                <Text type="secondary">
                  <CiLocationOn className="speclist-card-img" />
                </Text>
                {loading ? (
                  <Skeleton
                    active
                    title={false}
                    paragraph={{ rows: 1, width: 200 }}
                  />
                ) : (
                  <Text type="secondary">{details?.location}</Text>
                )}
              </Space>
            </Space>
          }
        />
      </Card>
    </Ribbon>
  );
}

export default SpecialistDetailsCard;
