import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useGetBedDetailsQuery,
  useGetIPRoomDetailsQuery,
  useGetRoomDetailsQuery,
  usePostIPRoomCheckOutDetailsMutation,
  usePostIPRoomDetailsByTCIPIDMutation,
} from "../../../../services/ApiSlice";

const { Text } = Typography;
const { TextArea } = Input;

const { Title } = Typography;

function RoomDetails({
  patientID,
  TCIPID,
}: {
  patientID: string;
  TCIPID: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddCommentsModal, setIsAddCommentsModal] = useState(false);
  const user = useSelector(selectUser);
  const [record, setSelectedRecord] = useState<any>(null);
  const [formInstance] = Form.useForm<any>();

  const [commentFormInstance] = Form.useForm<any>();
  const selectedRoom = Form.useWatch("roomID", formInstance);
  const selectedEntryDate = Form.useWatch("entryDate", formInstance);

  const Rooms = useGetRoomDetailsQuery(""); // TODO: get rooms based to selected dates
  const Beds = useGetBedDetailsQuery(selectedRoom ?? skipToken);
  const RoomDetails = useGetIPRoomDetailsQuery(TCIPID ?? skipToken);
  const [postRoomDetails, PostRoomDetails] =
    usePostIPRoomDetailsByTCIPIDMutation();
  const [postIPRoomCheckOutDetails, PostIPRoomCheckOutDetails] =
    usePostIPRoomCheckOutDetailsMutation();

  const isRoleBasedVisible = useRoleBasedVisible();

  const showModal = () => {
    setIsModalOpen(true);
    let entryDate = dayjs();
    if (Array.isArray(RoomDetails.data) && RoomDetails.data.length) {
      const latestRoom = RoomDetails.data.find(({ exitDate }) => exitDate); // TODO: need to verify key
      if (latestRoom && latestRoom.exitDate) {
        entryDate = dayjs(latestRoom.exitDate);
      }
    }
    formInstance.setFieldsValue({ entryDate });
  };

  const handleAddComments = (record: any) => {
    setSelectedRecord(record);
    setIsAddCommentsModal(true);
  };

  const isAllCheckOut =
    Array.isArray(RoomDetails?.data) &&
    RoomDetails?.data.some((data) => !data.comments);

  const handleOk = async () => {
    try {
      const values = await formInstance.validateFields();
      postRoomDetails({
        ...values,
        createdUserID: user?.himsEmployeeID,
        tcipid: TCIPID,
      });
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  const handleCancle = () => {
    setIsModalOpen(false);
    formInstance.resetFields();
  };

  const onCommentModalCancel = () => {
    setIsAddCommentsModal(false);
    commentFormInstance.resetFields();
  };

  const handleAddCheckOutReason = async () => {
    try {
      const values = await commentFormInstance.validateFields();
      postIPRoomCheckOutDetails({
        comments: values?.comments,
        checkOutByUserID: user?.himsEmployeeID,
        IPRoomDetailsID: record?.ipRoomDetailsID,
      });
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  useEffect(() => {
    if (PostRoomDetails.isSuccess) {
      handleCancle();
      notification.success({
        message: "Success",
        description: "Successfully extended room",
      });
    } else if (PostRoomDetails.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [PostRoomDetails]);

  useEffect(() => {
    if (PostIPRoomCheckOutDetails.isSuccess) {
      onCommentModalCancel();
    } else if (PostRoomDetails.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  });

  useEffect(() => {
    formInstance.setFieldValue("bedID", null);
  }, [selectedRoom]);
  useEffect(() => {
    formInstance.setFieldValue("exitDate", null);
  }, [selectedEntryDate]);

  const RoomNo = useMemo(
    () =>
      Array.isArray(Rooms.data)
        ? Rooms.data.map((item) => ({
            value: item?.roomID,
            label: item.roomNo,
          }))
        : [],
    [Rooms]
  );

  const BedsList = useMemo(
    () =>
      Array.isArray(Beds.data)
        ? Beds.data.map((item) => ({
            value: item?.bedID,
            label: item.bedNo,
          }))
        : [],
    [Beds]
  );

  const admissionCols: any = [
    {
      title: "Room No",
      render: (record: any) => (
        <Space direction="vertical">
          {record?.roomNo && <Text>{record?.roomNo}</Text>}
          {record?.bedNo && <Text type="secondary">{record?.bedNo}</Text>}
        </Space>
      ),
    },
    {
      title: "Check-In Date",
      dataIndex: "entryDate",
      render: (text: string) => FormatDateTime(text),
    },
    {
      title: "Check-Out Date",
      dataIndex: "exitDate",
      render: (dischargeDate: string) => (
        <span>{FormatDateTime(dischargeDate) || "-"}</span>
      ),
    },
    {
      title: "Added By",
      // dataIndex: "modifiedBy", // TODO: verify key
      render: (record: any) => (
        <Space direction="vertical">
          {record?.modifiedBy && <Text>{record?.modifiedBy}</Text>}
          {record?.modifiedDate && (
            <Text type="secondary">{FormatDateTime(record?.modifiedDate)}</Text>
          )}
        </Space>
      ),
    },
    {
      title: "Check Out By",
      dataIndex: "checkOutBy", // TODO: verify key
    },
    {
      title: "Check Out Reason",
      dataIndex: "comments",
      render: (text: string) => (
        <span style={{ whiteSpace: "normal" }}>{text}</span>
      ),
    },
    {
      title: "Action",
      render: (record: any) => (
        <Button
          type="primary"
          onClick={() => handleAddComments(record)}
          disabled={record?.comments}
        >
          Check Out
        </Button>
      ),
    },
  ];

  return (
    <Card>
      <Spin spinning={PostIPRoomCheckOutDetails.isLoading} fullscreen />
      {/* <button
                type="button"
                className="btn add-btn mx-3"
                onClick={showModal}
            >
                {" "}
                <i className="fa fa-plus" />
                Extend
            </button> */}

      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Select Room
          </Title>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancle}
        maskClosable={false}

        // cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form
          form={formInstance}
          disabled={PostRoomDetails.isLoading}
          layout="vertical"
        >
          <Row gutter={[24, 16]}>
            {/* <Col span={12}>
              <Form.Item
                name="entryDate"
                label="From Date"
                rules={[
                  { required: true, message: "Please select Check-In Date" },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  showTime={{
                    format: "HH A", // Display only hours
                  }}
                  format="DD-MM-YYYY HH A"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="exitDate"
                label="To Date"
                rules={[
                  { required: true, message: "Please select Check-Out Date" },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  showTime={{
                    format: "HH A", // Display only hours
                  }}
                  format="DD-MM-YYYY HH A"
                  disabledDate={(current) =>
                    dayjs(current).isBefore(
                      dayjs(formInstance.getFieldValue("entryDate")),
                      "day"
                    )
                  }
                />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                name="roomID"
                label="Room No"
                rules={[{ required: true, message: "Please Enter Room No" }]}
              >
                <Select
                  placeholder="Room No"
                  style={{ width: "100%" }}
                  options={RoomNo}
                  loading={Rooms.isFetching}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="bedID"
                label="Bed No"
                rules={[{ required: true, message: "Please Enter Bed No" }]}
              >
                <Select
                  placeholder="Bed No"
                  style={{ width: "100%" }}
                  options={BedsList}
                  loading={Beds.isFetching}
                  showSearch
                  optionFilterProp="label"
                  disabled={!selectedRoom || PostRoomDetails.isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Table
        loading={RoomDetails.isFetching}
        pagination={RoomDetails.data?.length < 10 ? false : undefined}
        columns={admissionCols}
        dataSource={RoomDetails.data}
        scroll={{ x: true }}
        // rowKey={(record) => record.id}
        title={() => (
          <Row gutter={24} justify="end">
            {!isAllCheckOut &&
              isRoleBasedVisible([Role.Mod, Role.Nurse, Role.Admin]) && (
                <Col>
                  <Button onClick={showModal} type="primary">
                    ADD
                  </Button>
                </Col>
              )}
          </Row>
        )}
      />
      <Modal
        open={isAddCommentsModal}
        centered
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Check Out Reason
          </Title>
        }
        onCancel={onCommentModalCancel}
        onOk={handleAddCheckOutReason}
        // okText={selectedRecord ? "UPDATE" : "ADD"}
        maskClosable={false}
      >
        <Form
          form={commentFormInstance}
          disabled={PostIPRoomCheckOutDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="comments"
            label="Reason:"
            rules={[{ required: true, message: "Please Enter Reason" }]}
          >
            <TextArea
              showCount
              maxLength={10000}
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Please Enter Reason"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default RoomDetails;
