import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Card, Col, Form, Input, Modal, Row, Spin } from "antd";
import { useEffect } from "react";
import {
  useGetOPSummaryQuery,
  usePostOutPatientMutation,
} from "../../../services/ApiSlice";

const { TextArea } = Input;
const { confirm } = Modal;

function AddUpdateOPSummary({
  TCOPID,
  isEditable,
}: {
  TCOPID: string;
  isEditable: boolean;
}) {
  const OPSummary = useGetOPSummaryQuery(TCOPID ?? skipToken);
  const [postOPSummary, PostOPSummary] = usePostOutPatientMutation();

  const [formInstance] = Form.useForm();

  useEffect(() => {
    if (OPSummary.data) {
      const values = {
        chiefCompliants: OPSummary?.data?.chiefCompliants,
        provisionalDiagnosis: OPSummary?.data?.provisionalDiagnosis,
        pastMedicalHistory: OPSummary?.data?.pastMedicalHistory,
        investigations: OPSummary?.data?.investigations,
        examination: OPSummary?.data?.examination,
      };
      formInstance.setFieldsValue(values);
    }
  }, [OPSummary.data]);

  const onSubmit = async (isDraft: boolean) => {
    confirm({
      title: "Are you sure to save ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const values = await formInstance.validateFields();
          postOPSummary({ ...values, TCOPID: TCOPID ?? "", isDraft: isDraft }); // TODO: check key
        } catch (error) {}
      },
    });
  };

  return (
    <Card>
      <Spin spinning={PostOPSummary.isLoading} fullscreen />

      <Form
        layout="vertical"
        form={formInstance}
        disabled={
          OPSummary.isFetching || PostOPSummary.isLoading || !isEditable
        }
      >
        <Form.Item
          name="chiefCompliants"
          label="Chief Complaints :"
          rules={[{ message: "Please Enter Chief Complaints", required: true }]}
        >
          <TextArea
            showCount
            placeholder="Please Enter Comments"
            maxLength={250}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item name="pastMedicalHistory" label="Past Medical History :">
          <TextArea
            showCount
            placeholder="Please Enter Comments"
            maxLength={250}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item name="examination" label="Examination :">
          <TextArea
            showCount
            placeholder="Please Enter Comments"
            maxLength={250}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item name="investigations" label="Investigations :">
          <TextArea
            showCount
            placeholder="Please Enter Comments"
            maxLength={250}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item name="provisionalDiagnosis" label="Provisional Diagnosis :">
          <TextArea
            showCount
            placeholder="Please Enter Comments"
            maxLength={250}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        {/* <Row justify="end">
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Follow-Up Required</Checkbox>
          </Form.Item>
        </Row> */}

        <Row justify="end">
          <Col>
            <Row justify="end" gutter={24}>
              {/* <Col>
                <Button onClick={() => onSubmit(true)}>Save Draft</Button>
              </Col> */}
              {isEditable ? (
                <Col>
                  <Button type="primary" onClick={() => onSubmit(false)}>
                    Save
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AddUpdateOPSummary;
