import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/css/line-awesome.min.css";
import "../src/assets/scss/main.scss";
import Approuter from "./Routes/Approuter";
import "./i18n";
import { IAppProps } from "./models/IAppProps";

const App: React.FC<IAppProps> = () => {
  return (
    <>
      <Approuter />
    </>
  );
};

export default App;
