import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useIsAllowed } from "../../hooks/role.hooks";
import { Role } from "../../models/PrivilegeRoles";
import { selectUser } from "../../reducers/UserSlice";
import { RootState } from "../../store";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const user = useSelector((state: RootState) => selectUser(state));
  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenunew, setSideMenuNew] = useState("dashboard");
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);

  const isAllowed = useIsAllowed();

  const toggleSidebar = (value: string) => {
    setSideMenu(value);
    setSideMenuNew(value);
  };

  const isSideMenuVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (pathname.includes("appointment-details")) setSideMenu("out-patient");
  }, [pathname]);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };
  const { t } = useTranslation();

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: "hidden" }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul
              className="sidebar-vertical"
              id="veritical-sidebar"
              style={{ display: "block" }}
            >
              <li className="menu-title">
                <span>{t("HONONOW")}</span>
              </li>
              {isSideMenuVisible([Role.Mod, Role.Admin]) && (
                <li
                  className={
                    pathname.includes("dashboard")
                      ? "active"
                      : "" || pathname.includes("edit-appointment")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/dashboard" onClick={() => toggleSidebar("")}>
                    <i className="las la-chart-bar"></i>
                    <span>{t("Dashboard")}</span>
                  </Link>
                </li>
              )}
              {/* {isAllowed(PRIVILEGE.Client) && ( */}
              {isSideMenuVisible([Role.Mod, Role.Admin]) && (
                <li
                  className={
                    pathname.includes("patients")
                      ? "active"
                      : "" || pathname.includes("add-patient")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/patients" onClick={() => toggleSidebar("")}>
                    {" "}
                    <i className="las la-user-circle"></i>
                    <span>{t("Patient Registration")}</span>
                  </Link>
                </li>
              )}
              {/* {isAllowed(PRIVILEGE.OutPatient) && ( */}
              {isSideMenuVisible([
                Role.Mod,
                Role.Admin,
                Role.Doctor,
                Role.Nurse,
              ]) && (
                <li className="submenu">
                  <Link
                    to="#"
                    className={isSideMenu === "out-patient" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "out-patient" ? "" : "out-patient"
                      )
                    }
                  >
                    <i className="las la-user-injured"></i>
                    <span> {t("Out Patient")}</span>
                    <span className="menu-arrow" />
                  </Link>
                  {isSideMenu === "out-patient" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("appointment-details")
                              ? "active"
                              : "" || pathname.includes("details-tab")
                              ? "active"
                              : "" || pathname.includes("payment-details")
                              ? "active"
                              : ""
                          }
                          to="/appointment-details"
                        >
                          {t("Appointments")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className={
                            pathname.includes("out-patient-details")
                              ? "active"
                              : ""
                          }
                          to="/out-patient-details"
                        >
                          {t("Out Patients")}
                        </Link>
                      </li> */}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* {isAllowed(PRIVILEGE.InPatient) && ( */}
              {isSideMenuVisible([
                Role.Mod,
                Role.Admin,
                Role.Doctor,
                Role.Nurse,
              ]) && (
                <li className="submenu">
                  <Link
                    to="#"
                    className={isSideMenu === "in-patient" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "in-patient" ? "" : "in-patient"
                      )
                    }
                  >
                    <i className="las la-procedures"></i>
                    <span> {t("In Patient")}</span>
                    <span className="menu-arrow" />
                  </Link>
                  {isSideMenu === "in-patient" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("discharge-summary-list")
                              ? "active"
                              : ""
                          }
                          to="/discharge-summary-list"
                        >
                          {t("Admissions")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* {isAllowed(PRIVILEGE.BillingInfo) && ( */}
              {isSideMenuVisible([Role.Mod, Role.Admin]) && (
                <li className="submenu">
                  <Link
                    to="#"
                    className={isSideMenu === "billing" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(isSideMenu === "billing" ? "" : "billing")
                    }
                  >
                    <i className="las la-rupee-sign"></i>
                    <span> {t("Billing")}</span>
                    <span className="menu-arrow" />
                  </Link>
                  {isSideMenu === "billing" ? (
                    <ul>
                      <li>
                        <Link
                          className={pathname === "/billing" ? "active" : ""}
                          to="/billing"
                        >
                          {t("OP Billing")}
                        </Link>
                      </li>
                      <li>
                        {/* <Link
                          className={
                            pathname.includes("ipbilling") ? "active" : ""
                          }
                          to="/ipbilling"
                        >
                          {t("IP Billing")}
                        </Link> */}
                        <Link
                          className={
                            pathname.includes("ipbilling") ||
                            pathname.includes("IPpayment-details")
                              ? "active"
                              : ""
                          }
                          to="/ipbilling"
                        >
                          {t("IP Billing")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            pathname === "/lab-billing" ? "active" : ""
                          }
                          to="/lab-billing"
                        >
                          {t("Lab Billing")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {isSideMenuVisible([Role.Mod, Role.Admin]) && (
                <li
                  className={pathname.includes("lab-requests") ? "active" : ""}
                >
                  <Link to="/lab-requests" onClick={() => toggleSidebar("")}>
                    <i className="fa-solid fa-flask"></i>
                    <span>{t("Lab Requests")}</span>
                  </Link>
                </li>
              )}

              {isSideMenuVisible([Role.Mod, Role.Admin]) && (
                <li className="submenu">
                  <Link
                    to="#"
                    className={isSideMenu === "doctor" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(isSideMenu === "doctor" ? "" : "doctor")
                    }
                  >
                    <i className="las la-stethoscope"></i>
                    <span> {t("Doctor")}</span>
                    <span className="menu-arrow" />
                  </Link>

                  {isSideMenu === "doctor" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("doctor") ? "active" : ""
                          }
                          to="/doctor"
                        >
                          {t("Doctors")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {isSideMenuVisible([Role.Admin]) && (
                <li className="submenu">
                  <Link
                    to="#"
                    className={
                      isSideMenu === "user-management" ? "subdrop" : ""
                    }
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu === "user-management"
                          ? ""
                          : "user-management"
                      )
                    }
                  >
                    <i className="las la-user-circle"></i>
                    <span> {t("User Management")}</span>
                    <span className="menu-arrow" />
                  </Link>

                  {isSideMenu === "user-management" ? (
                    <ul>
                      <li>
                        <Link
                          className={pathname.includes("roles") ? "active" : ""}
                          to="/roles"
                        >
                          {t("Roles")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                  {isSideMenu === "user-management" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("specialityLookup")
                              ? "active"
                              : ""
                          }
                          to="/specialityLookup"
                        >
                          {t("Speciality")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                  {isSideMenu === "user-management" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("qualificationLookup")
                              ? "active"
                              : ""
                          }
                          to="/qualificationLookup"
                        >
                          {t("Qualification")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                  {isSideMenu === "user-management" ? (
                    <ul>
                      {/* <li>
                      <Link
                        className={
                          pathname.includes("user-actions") ? "active" : ""
                        }
                        to="/user-actions"
                      >
                        {t("User Actions")}
                      </Link>
                    </li> */}
                      <li>
                        <Link
                          className={pathname === "/privilege" ? "active" : ""}
                          to="/privilege"
                        >
                          {t("Privilege List")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            pathname.includes("privilege-map") ? "active" : ""
                          }
                          to="/privilege-map"
                        >
                          {t("Role-Privilege Map")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            pathname.includes("UserList") ? "active" : ""
                          }
                          to="/UserList"
                        >
                          {t("Users")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            pathname.includes("ApprovedDoctors") ? "active" : ""
                          }
                          to="/ApprovedDoctors"
                        >
                          {t("Approved Doctors")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className={
                            pathname.includes("ExternalDoctors") ? "active" : ""
                          }
                          to="/ExternalDoctors"
                        >
                          {t("External Doctors")}
                        </Link>
                      </li> */}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
