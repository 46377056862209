import {
  Button,
  Checkbox,
  CheckboxOptionType,
  Col,
  ConfigProvider,
  Divider,
  Input,
  InputRef,
  Modal,
  Row,
  Space,
  Table,
  TableColumnType,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { paymentStatusColorMap } from "../../../models/Colors";
import { useGetLabBillingInfoQuery } from "../../../services/ApiSlice";

const IPBilling = () => {
  const { Text } = Typography;
  type DataIndex = keyof IBills;
  const location = useLocation();
  const { data: allBills, isFetching: isAllBillsFetching } =
    useGetLabBillingInfoQuery(null);
  const { id }: any = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IBills> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7465ad",
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (
      <i className="fa-solid fa-magnifying-glass " style={{ marginLeft: 5 }} />
    ),
  });

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allBills)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allBills?.filter(
        (patient: any) =>
          patient.id?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allBills, searchQuery]);

  const columns: TableProps<IBills>["columns"] = [
    {
      title: "ID ",
      key: "billingTypeRefID",
      dataIndex: "billingTypeRefID",
      render: (text, record) => (
        <Link
          className="hyperlink"
          to={`/lab-billing-details/${record?.patientID}`}
          state={{ from: location.pathname, record }}
        >
          {text || "-"}
        </Link>
      ),
    },
    {
      title: "Bill Type",
      // dataIndex: "billingType",
      render: ({ billingID, billingType }) => (
        <Space size={5}>
          {billingType && <Text strong>{billingType}</Text>}
          {billingID && <Text type="secondary">({billingID})</Text>}
        </Space>
      ),
    },
    {
      title: "Patient Name",
      key: "patientName",
      // dataIndex: "patientName",
      ...getColumnSearchProps("patientName"),

      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    //   {
    //     title: "Payment For",
    //     key: "billingType",
    //     dataIndex: "billingType",
    //     ...getColumnSearchProps("billingType"),
    //     render: (text: string) => <span>{text || "-"}</span>,

    //   },
    {
      title: "Total Amount",
      key: "totalAmount",
      dataIndex: "totalAmount",
      // ...getColumnSearchProps("totalAmount"),
      render: (text: number) => <span>{"₹ " + text || "-"}</span>,
    },
    {
      title: "Discount",
      key: "discountValue",
      dataIndex: "discountValue",
      ...getColumnSearchProps("discountValue"),
      render: (text: number) => <span>{text ? "₹ " + text : "-"}</span>,
      // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
      //   a.actualAmount - b.actualAmount,
    },

    {
      title: "Due",
      key: "dueAmount",
      dataIndex: "dueAmount",
      ...getColumnSearchProps("dueAmount"),
      render: (text: number) => <span>{"₹ " + text}</span>,
    },
    {
      title: "Paid",
      key: "paidAmount",
      dataIndex: "paidAmount",
      ...getColumnSearchProps("paidAmount"),
      render: (text: number) => <span>{text ? "₹ " + text : "-"}</span>,
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      // ...getColumnSearchProps("status"),
      render: (text: string) => (
        <Tag color={paymentStatusColorMap[text?.toUpperCase()]}>{text}</Tag>
      ),
    },

    // {
    //     title: "Action",
    //     key: "action",
    //     render: (record) => (
    //         <Space>
    //             {/* {record.paymentStatusValue === "Paid" && (
    //       <Link to={""}>
    //         <span
    //           className="link"
    //           onClick={() => handleDownloadPaymentDetails(record)}
    //         >
    //           Invoice
    //         </span>
    //       </Link>
    //     )} */}
    //             <Link to={`/payment-details/${record.id}`}>
    //                 <span className="link">View</span>
    //             </Link>
    //         </Space>
    //     ),
    //     sorter: true,
    // },
  ];
  const defaultCheckedList = columns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.filter(({ key }) =>
    checkedList.includes(key as string)
  );

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCheckedList(defaultCheckedList);
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Lab Billing</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Lab Billing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <Row>
                    <Col>
                      <div>
                        <Input
                          placeholder="Search Patient"
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="row">
              <>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <Tag color="geekblue" onClick={showModal}>
                      Select Columns
                    </Tag>
                    <Modal
                      //title="Columns displayed"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <Divider>Columns</Divider>
                      <Checkbox.Group
                        value={checkedList}
                        options={options as CheckboxOptionType[]}
                        onChange={(value) => {
                          setCheckedList(value as string[]);
                        }}
                      />
                    </Modal>
                  </div>
                </div>
              </>
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    pagination={
                      filteredPatients?.length > 10 ? undefined : false
                    }
                    loading={isAllBillsFetching}
                    columns={newColumns}
                    dataSource={allBills}
                    rowKey="outPatientID"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IPBilling;
