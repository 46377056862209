import { skipToken } from "@reduxjs/toolkit/query";
import {
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import Button, { isString } from "antd/es/button";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useAddOrUpdatePatientMutation,
  useGetDistrictQuery,
  useGetDropdownQuery,
  useGetPatientQuery,
  useGetStateQuery,
} from "../../../services/ApiSlice";

import { IndianMobileNumber, emailrgx, number } from "../Authentication/RegEx";

const { confirm } = Modal;

function AddUpdatePatient() {
  const { patientID }: any = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [age, setAge] = useState<number | null>(null);

  const BloodGroup = useGetDropdownQuery("BloodGroup");
  const Gender = useGetDropdownQuery("Gender");
  const States = useGetStateQuery("state");

  const [addOrUpdatePatient, AddOrUpdatePatient] =
    useAddOrUpdatePatientMutation();

  const scrolltopRef = useRef<HTMLDivElement>(null);
  console.log(location?.state?.tcipid);
  const PatientData = useGetPatientQuery(patientID ?? skipToken);

  const [formInstance] = Form.useForm<IPatientDetailsWithAddress>();

  const selectedState = Form.useWatch(["address", "stateID"], formInstance);
  const Districts = useGetDistrictQuery(selectedState ?? skipToken);

  useEffect(() => {
    if (AddOrUpdatePatient.isSuccess) {
      let path = "/patients/";
      if (location?.state?.tcipid)
        path = `/inpatient-details/${location?.state?.tcipid}`;
      // navigate(`/inpatient-details/${location?.state?.tcipid}`);
      else if (isString(AddOrUpdatePatient.data))
        path += AddOrUpdatePatient.data;
      else if (isString(patientID)) path += patientID;
      navigate(path);
    }
  }, [AddOrUpdatePatient]);

  useEffect(() => {
    if (PatientData.data) {
      const dob = PatientData.data.dob ? dayjs(PatientData.data.dob) : null;
      formInstance.setFieldsValue({ ...PatientData.data, dob });
    }
  }, [PatientData]);

  useEffect(() => {
    if (location.state) {
      if (isString(location.state.phoneNo)) {
        formInstance.setFieldsValue({
          phoneNo: location.state.phoneNo,
        });
      }
    }
  }, [location]);

  const bloodGroupOptions = useMemo(
    () =>
      Array.isArray(BloodGroup.data)
        ? BloodGroup.data.map(({ dropDownSetID, dropDownValue }) => ({
            value: dropDownSetID,
            label: dropDownValue,
          }))
        : [],
    [BloodGroup]
  );
  const stateOptions = useMemo(
    () =>
      Array.isArray(States.data)
        ? States.data.map(({ stateID, stateName }) => ({
            value: stateID,
            label: stateName,
          }))
        : [],
    [States]
  );
  const districtOptions = useMemo(
    () =>
      Array.isArray(Districts.data)
        ? Districts.data.map(({ districtID, districtName }) => ({
            value: districtID,
            label: districtName,
          }))
        : [],
    [Districts]
  );
  const genderOptions = useMemo(
    () =>
      Array.isArray(Gender.data)
        ? Gender.data.map(({ dropDownSetID, dropDownValue }) => ({
            value: dropDownSetID,
            label: dropDownValue,
          }))
        : [],
    [Gender]
  );
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const birthDate = dayjs(date);
      const today = dayjs();
      const age = today.diff(birthDate, "year");
      formInstance.setFieldsValue({ age });
      formInstance.validateFields(["age"]);
    } else {
      formInstance.setFieldsValue({ age: undefined });
    }
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== "") {
      setAge(parseInt(value, 10));
      formInstance.setFieldsValue({ dob: undefined });
    } else {
      setAge(null);
      formInstance.setFieldsValue({ dob: undefined });
    }
  };

  const handleSubmit = (values: IPatientDetailsWithAddress) => {
    const dobISO = values.dob ? dayjs(values.dob).toISOString() : undefined;
    const payload = {
      ...values,
      // dob: dayjs(values.dob).toISOString(),
      dob: dobISO,
      // age: parseInt(values.age, 10)
    };
    if (scrolltopRef.current) scrolltopRef!.current!.scrollIntoView();

    if (patientID) payload.patientID = patientID;
    addOrUpdatePatient(payload);
  };

  const handleCancle = () => {
    confirm({
      title: "Are you sure want to exit ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        formInstance.resetFields();
        let path = "";
        if (location?.state?.tcipid)
          path = `/inpatient-details/${location?.state?.tcipid}`;
        // navigate(`/inpatient-details/${location?.state?.tcipid}`);
        else path = "/patients";
        navigate(path);
      },
    });
  };

  return (
    <div className="page-wrapper" ref={scrolltopRef}>
      <div className="content container-fluid">
        <Flex vertical gap={24}>
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">
                {patientID ? "Update" : "Add"} Patient
              </h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`/patients`}>Patient Registration</Link>
                </li>

                {patientID ? (
                  <li className="breadcrumb-item">
                    <Link to={`/patients/${patientID}`}>Patient Details</Link>
                  </li>
                ) : null}

                <li className="breadcrumb-item active">
                  {patientID ? "Update" : "Add"} Patient
                </li>
              </ul>
            </div>
          </div>

          <Spin
            spinning={AddOrUpdatePatient.isLoading || PatientData.isFetching}
            fullscreen
          />
          <Card>
            <Form
              onFinish={handleSubmit}
              form={formInstance}
              disabled={AddOrUpdatePatient.isLoading || PatientData.isFetching}
            >
              <h4 className="card-title mb-3">Personal Information</h4>

              <div className="row">
                <div className="col-xl-6">
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter First Name",
                          },
                          {
                            min: 3,
                            message: "Enter atleast 3 characters",
                          }
                        ]}
                      >
                        <Input placeholder="First Name" maxLength={50}/>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Last Name",
                          },
                          {
                            min: 3,
                            message: "Enter atleast 3 characters",
                          }
                        ]}
                      >
                        <Input placeholder="Last Name" maxLength={50}/>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">Gender <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item name="genderDDSID" rules={[
                          {
                            required: true,
                            message: "Please select Gender",
                          }
                        ]}>
                        <Select
                          style={{ width: "100%" }}
                          options={genderOptions}
                          loading={Gender.isFetching}
                          defaultValue={"Select"}
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Blood Group
                    </label>
                    <div className="col-lg-9">
                      <Form.Item name="bloodGroupDDSID" >
                        <Select
                          style={{ width: "100%" }}
                          options={bloodGroupOptions}
                          defaultValue={"Select"}
                          loading={BloodGroup.isFetching}
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">Email </label>
                    <div className="col-lg-9">
                      <Form.Item name="emailID"
                      rules={[
                          {
                            min: 3,
                            message: "Enter atleast 3 characters",
                          },
                          {
                            pattern: emailrgx,
                            message: "Please enter valid email",
                          },
                        ]}>
                        <Input placeholder="Email ID" maxLength={50}/>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label">
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                      <Form.Item
                        name="phoneNo"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Mobile Number",
                          },
                          {
                            pattern: IndianMobileNumber,
                            message: "Please Enter Valid Mobile Number",
                          },
                        ]}
                      >
                        <Input placeholder="Mobile Number" disabled />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label ">
                      Date Of Birth
                    </label>
                    <div className="col-lg-9 ">
                      <Form.Item name="dob">
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={handleDateChange}
                          disabledDate={(d) => dayjs().isBefore(d)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="input-block mb-3 row">
                    <label className="col-lg-3 col-form-label ">
                      Age <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9 ">
                      <Form.Item
                        name="age"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Age",
                          },
                          {
                            pattern: number,
                            message: "Please enter a valid age (1-200)",
                          }
                        ]}
                      >
                        <Input
                          type="number"
                          style={{ width: "100%" }}
                          onChange={handleAgeChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="card-title">Address</h4>
              <Form.Item>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Address Line1<span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <Form.Item
                          name={["address", "addressLine1"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Address",
                            },
                            {
                              min: 3,
                              message: "Enter atleast 3 characters",
                            },
                          ]}
                        >
                          <Input placeholder="Address Line 1" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Address Line2
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name={["address", "addressLine2"]}>
                          <Input placeholder="Address Line 2" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Land Mark
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name={["address", "landMark"]}>
                          <Input placeholder="Land Mark" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Plot / Flat No.
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name={["address", "flatNumber"]}>
                          <Input placeholder="Flat No" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        City<span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <Form.Item
                          name={["address", "city"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter City",
                            },
                            {
                              min: 3,
                              message: "Enter atleast 3 characters",
                            },
                          ]}
                        >
                          <Input placeholder="City" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        State<span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <Form.Item
                          name={["address", "stateID"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Enter State",
                            },
                            // {
                            //   min: 3,
                            //   message: "Enter atleast 3 characters",
                            // },
                          ]}
                        >
                          <Select
                            options={stateOptions}
                            style={{ width: "100%" }}
                            loading={States.isFetching}
                            defaultValue={"Select"}
                            showSearch
                            optionFilterProp="label"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Pin Code
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name={["address", "pinCode"]}>
                          <Input placeholder="Pin Code" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="input-block mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        District
                      </label>
                      <div className="col-lg-9">
                        <Form.Item name={["address", "districtID"]}>
                          <Select
                            options={districtOptions}
                            style={{ width: "100%" }}
                            loading={Districts.isFetching}
                            defaultValue={"Select"}
                            showSearch
                            optionFilterProp="label"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="text-end">
                <Space direction="horizontal">
                  <Form.Item>
                    <Button danger onClick={handleCancle}>
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </Form.Item>
                </Space>
              </div>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
}

export default AddUpdatePatient;
