import { Button, Col, Form, FormInstance, Row, Select } from "antd";
import { useMemo } from "react";
import { useGetSpecializationsQuery } from "../../../../services/ApiSlice";

export interface IToolBarForm {
  specialist: string;
}

type Props = {
  formInstance: FormInstance<IToolBarForm>;
  onSubmit: (values: IToolBarForm) => void;
};

function ToolBar({ formInstance, onSubmit }: Props) {
  const { data: specialitions } = useGetSpecializationsQuery();

  const specialitionOptions = useMemo(() => {
    const options: { label: string; value: number | null }[] = [
      { label: "All", value: null },
    ];
    if (Array.isArray(specialitions)) {
      specialitions.forEach((specialition) => {
        options.push({
          label: specialition.speciality,
          value: specialition.specialityID,
        });
      });
    }
    return options;
  }, [specialitions]);

  return (
    <Form form={formInstance} onFinish={onSubmit}>
      <Row gutter={24}>
        <Col xs={14} sm={12} md={8} lg={8} xl={8} xxl={6}>
          <Form.Item name="specialist" initialValue={null}>
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              filterOption
              options={specialitionOptions}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            htmlType="submit"
            className="Button_background_color"
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ToolBar;
