import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Typography,
  notification,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DatePickerCarousel from "../../../../components/DatePickerCarousel";
import {
  useGetDoctorAvailabilityQuery,
  useGetOutPatientByOPIDQuery,
  usePostOutPatientTimeSlotMutation,
} from "../../../../services/ApiSlice";

const { Meta } = Card;
const { Title } = Typography;

type Props = {
  doctorID: string | undefined;
  patientDetails: IPatientDetails;
  doctorDetails: ISpecialistDetails | undefined;
};

const NO_OF_SLOTS = 19

function SlotSelector({ doctorID, patientDetails, doctorDetails }: Props) {
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [availabilityArgs, setAvailabilityArgs] =
    useState<IDoctorAvailabilityArgs>({
      doctorID,
      date: new Date().toISOString(),
    });

  const navigate = useNavigate();
  const { tcopid } = useParams();

  const { data: opData } = useGetOutPatientByOPIDQuery(tcopid ?? skipToken);
  const slots = useGetDoctorAvailabilityQuery(availabilityArgs);
  const [postOutPatientData, { isLoading: isPosting, error: postError }] =
    usePostOutPatientTimeSlotMutation();

  useEffect(() => {
    if (opData) {
      setAvailabilityArgs((prv) => ({
        ...prv,
        date: opData.appointmentTime,
      }));
    }
  }, [opData]);

  const onDateSelect = useCallback((date: Dayjs) => {
    setAvailabilityArgs((prvArgs) => ({
      ...prvArgs,
      date: date.toISOString(),
    }));
  }, []);

  const handleAddOutPatient = async () => {
    try {
      const addResponse = await postOutPatientData({
        ...patientDetails,
        ...doctorDetails,
        assignedDoctorID: doctorDetails?.doctorID,
        requestStatus: "NEW",
        appointmentTime:
          availabilityArgs?.date?.split("T")?.at(0) +
          "T" +
          selectedSlot +
          ":00.000Z",
        tcopid: tcopid ?? null,
      }).unwrap();
      if (
        addResponse &&
        addResponse != "00000000-0000-0000-0000-000000000000"
      ) {
        navigate(`/appointment-details`);
      } else {
        notification["warning"]({
          message: "Invalid Response",
        });
      }
    } catch (error) {
      console.error("Failed to post data:", error);
      notification["warning"]({
        message: "Faied to post data",
      });
    }
  };

  return (
    <Card>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Meta title="Book Appointment" description="Available in 5 mins" />
        <DatePickerCarousel
          onChange={onDateSelect}
          selectedDate={dayjs(availabilityArgs.date)}
        />
        <Title level={4}>Slots</Title>

        <Row gutter={[12, 12]}>
          {slots.isFetching ? (
            Array.from({ length: NO_OF_SLOTS }, (_, i) => (
              <Col key={i} xs={6} sm={6} md={6} lg={4} xl={4} xxl={3}>
                <Skeleton.Button active block />
              </Col>
            ))
          ) : Array.isArray(slots.data) ? (
            slots.data.map((slot) => (
              <Col key={slot.slot} xs={6} sm={6} md={6} lg={4} xl={4} xxl={3}>
                <Button
                  style={{ width: "100%" }}
                  type={selectedSlot === slot.slot ? "primary" : "default"}
                  onClick={() => {
                    setSelectedSlot(slot.slot);
                  }}
                >
                  {slot.slot}
                </Button>
              </Col>
            ))
          ) : (
            <Title level={5}>No Slots Available</Title>
          )}
        </Row>

        <Button
          type="primary"
          disabled={selectedSlot === ""}
          onClick={handleAddOutPatient}
        >
          Continue Booking
        </Button>
      </Space>
    </Card>
  );
}

export default SlotSelector;
