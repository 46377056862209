import { Button, Card, Carousel, Col, Row, Space, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const { Text } = Typography;

type IDatePickerContext = {
  onSelect: (date: Dayjs) => void;
  selectedDate: Dayjs;
};

const DatePickerContext = createContext<IDatePickerContext>(null!);

type Props = {
  futureLimit?: number;
  pastLimit?: number;
  visibleDays?: number;
  onChange?: (date: Dayjs) => void;
  selectedDate?: Dayjs;
};

function DatePickerCarousel({
  pastLimit = 0,
  futureLimit = 13,
  visibleDays = 7,
  onChange: onSelectExternal,
  selectedDate: selectedDateExternal,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    selectedDateExternal ?? dayjs()
  );

  const carouselRef = useRef<any>(null);

  useEffect(() => {
    if (selectedDateExternal) setSelectedDate(selectedDateExternal);
  }, [selectedDateExternal]);

  const datesArray = useMemo(() => {
    const dates = generateDates(pastLimit, futureLimit);
    return brakeIntoChunks<Dayjs>(dates, visibleDays);
  }, [pastLimit, futureLimit, visibleDays]);

  const onSelect = useCallback((date: Dayjs) => {
    setSelectedDate(date);
    if (onSelectExternal) onSelectExternal(date);
  }, []);

  const onLeftClick = () => {
    if (carouselRef.current?.prev) {
      carouselRef.current.prev();
    }
  };

  const onRightClick = () => {
    if (carouselRef.current?.next) {
      carouselRef.current.next();
    }
  };

  return (
    <Row gutter={16} align="middle" justify="space-between">
      <Col span={2}>
        <Button
          onClick={onLeftClick}
          icon={<i className="fa-solid fa-caret-left" />}
        />
      </Col>
      <Col span={20}>
        <DatePickerContext.Provider value={{ onSelect, selectedDate }}>
          <Carousel
            infinite={false}
            ref={(slider) => {
              carouselRef.current = slider;
            }}
            dots={false}
          >
            {datesArray.map((dates, index) => (
              <DateCards dates={dates} key={index} />
            ))}
          </Carousel>
        </DatePickerContext.Provider>
      </Col>
      <Col span={2}>
        <Button
          onClick={onRightClick}
          icon={<i className="fa-solid fa-caret-right" />}
        />
      </Col>
    </Row>
  );
}

const DateCards = ({ dates }: { dates: Dayjs[] }) => {
  return (
    <Row justify="space-evenly" style={{ padding: "10px 10px" }}>
      {dates.map((date) => (
        <Col span={3} key={date.get("date")}>
          <DateCard date={date} />
        </Col>
      ))}
    </Row>
  );
};

const DateCard = ({ date }: { date: Dayjs }) => {
  const { onSelect, selectedDate } = useContext(DatePickerContext);
  const onClick = () => {
    onSelect(date);
  };
  const day = date.day();
  const isWeekEnd = day === 0 || day === 6;
  return (
    <Card
      hoverable
      onClick={onClick}
      bodyStyle={{ padding: "10px 0" }}
      style={selectedDate.isSame(date, "date") ? { borderColor: "blue" } : {}}
    >
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <Text
          strong
          type={isWeekEnd ? "danger" : undefined}
          style={{ margin: "0" }}
        >
          {date.format("ddd")}
        </Text>
        <Text strong style={{ margin: "0", fontSize: 20 }}>
          {date.date()}
        </Text>
      </Space>
    </Card>
  );
};

const generateDates = (pastLimit: number, futureLimit: number): Dayjs[] => {
  const dates: Dayjs[] = [];

  // Add Today
  const today = dayjs().startOf("D");
  dates.push(today);

  // Add Past dates
  let current = 1;
  while (pastLimit >= current) {
    dates.unshift(today.subtract(current, "day"));
    current++;
  }

  // Add Future dates
  current = 1;
  while (futureLimit >= current) {
    dates.push(today.add(current, "day"));
    current++;
  }
  // Total len = pastLimit + today + futureLimit
  return dates;
};

// TODO: move into common util file
const brakeIntoChunks = <T,>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export default DatePickerCarousel;
