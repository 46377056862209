import { Col, Flex, Row } from "antd";
import { useParams } from "react-router";
import {
  useGetDoctorByIDQuery,
  useGetPatientQuery,
} from "../../../../services/ApiSlice";
import SpecialistDetailsCard from "./SpecialistDetails.Card";
import PatientCard from "./SpecialistDetails.PatientCard";

import SlotSelector from "./SpecialistDetails.SlotSelector";

function SpecialistDetails({}) {
  const { doctorID, patientID } = useParams();

  const PatientDetails = useGetPatientQuery(patientID);

  const SpecialistDetails = useGetDoctorByIDQuery({
    doctorID,
  });

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Flex vertical gap={24}>
              <SpecialistDetailsCard
                details={SpecialistDetails.data}
                loading={SpecialistDetails.isLoading}
              />

              <PatientCard
                patientDetails={PatientDetails.data}
                loading={PatientDetails.isFetching}
              />
            </Flex>
          </Col>
          <Col span={12}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <SlotSelector
                  doctorID={doctorID}
                  patientDetails={PatientDetails.data}
                  doctorDetails={SpecialistDetails.data}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row gutter={[24, 16]}>
          <Col span={12}>
            <PatientCard patientDetails={patientDetails} />
          </Col>
        </Row> */}
      </div>
    </>
  );
}

export default SpecialistDetails;
