import type {
  CheckboxOptionType,
  InputRef,
  TableColumnType,
  TableProps,
} from "antd";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { paymentStatusColorMap, statusColorMap } from "../../../models/Colors";
import { Role } from "../../../models/PrivilegeRoles";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetCountsForDashboardQuery,
  useGetOutPatientBYPatientIDQuery,
  useGetOutPatientForNurseQuery,
  useLazyGetDoctorAppointmentsQuery,
  useLazyGetDownloadPaymentDetailsQuery,
  useLazyGetPaymentsQuery,
} from "../../../services/ApiSlice";
import { RootState } from "../../../store";

const { Text } = Typography;
type DataIndex = keyof IOutPatient;

const PatientDashboard = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const user = useSelector((state: RootState) => selectUser(state));

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IOutPatient> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7465ad",
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (
      <i className="fa-solid fa-magnifying-glass " style={{ marginLeft: 5 }} />
    ),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [paymentDetailsExpanding, PaymentDetailsExpanding] =
    useLazyGetPaymentsQuery();
  const [
    getPaymentDetails,
    {
      data: printData,
      isFetching: isPrintDataFetching,
      isSuccess: isPrintDataSuccess,
      isLoading: downloadPrintData,
    },
  ] = useLazyGetDownloadPaymentDetailsQuery();

  useEffect(() => {
    if (printData) {
      window.open(printData);
    }
  }, [printData]);

  const isRoleBasedVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const isRoleVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const { data: allPatients, isFetching: isAllPatientsFetching } =
    useGetOutPatientBYPatientIDQuery({ patientID: null, locationID: 1 });

  const { data: allOutPatientForNurse, isFetching: isallOutPatientForNurse } =
    useGetOutPatientForNurseQuery("");

  const [allDoctorAppointments, doctorAppointments] =
    useLazyGetDoctorAppointmentsQuery();

  const { data: dashboardCounts, isFetching: isDashboardCounts } =
    useGetCountsForDashboardQuery("");
  const handleDownloadPaymentDetails = (record: any) => {
    // getPaymentDetails({ tcOPID: record?.tcipid, paymentDetailsID: record?.paymentDetailsID });
    getPaymentDetails(record?.paymentDetailsID);
  };

  // const [allDoctorAppointments, { isLoading: isPosting, error: postError }] =
  // useGetDoctorAppointmentsMutation();
  // console.log('doctor',allPatients)

  // const outPatientData =
  // allPatients?.find((option: any) => option?.uhid === patientID)?.patientID ||
  // 0;

  useEffect(() => {
    allDoctorAppointments(user?.himsEmployeeID);
  }, [user]);
  useEffect(() => {}, [doctorAppointments.isFetching]);

  const statsData = [
    {
      id: 1,
      title: "Out Patients",
      value: dashboardCounts?.opNotCompleted + " Active",
      values: dashboardCounts?.opCompleted + " Completed",
      subText: "Today",
    },

    // {
    //   id: 2,
    //   title: "Out Patients (Completed)",
    //   value: dashboardCounts?.opCompleted,
    //   subText: "Today",
    // },
    {
      id: 3,
      title: "In Patients",
      value: dashboardCounts?.ipNotCompleted + " Active",
      values: dashboardCounts?.ipCompleted + " Discharged",
      subText: "Today",
    },
    // {
    //   id: 4,
    //   title: "Discharged",
    //   value: dashboardCounts?.ipCompleted,
    //   subText: "Today",
    // },
  ];

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allPatients)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allPatients?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allPatients, searchQuery]);

  const filteredDoctorPatients = useMemo(() => {
    if (Array.isArray(doctorAppointments?.data)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return doctorAppointments?.data?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [doctorAppointments?.data, searchQuery]);

  const filteredNursePatients = useMemo(() => {
    if (Array.isArray(allOutPatientForNurse)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allOutPatientForNurse?.filter(
        (patient: any) =>
          patient.tcopid?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allOutPatientForNurse, searchQuery]);

  const expandedRowRender = () => {
    const columns: any = [
      // {
      //   title: "ID",
      //   dataIndex: "tcipid",
      //   render: (text: string) => <span>{text || "No Payment ID"}</span>, // Provide default value if firstName is empty
      //   sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
      //     a.tcipid.length - b.tcipid.length,
      // },
      {
        title: "Payment For",
        dataIndex: "paymentFor",
        render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
        sorter: (a: { firstName: string }, b: { firstName: string }) =>
          a.firstName.length - b.firstName.length,
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
        sorter: (a: { paymentMode: string }, b: { paymentMode: string }) =>
          a.paymentMode.length - b.paymentMode.length,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (text: string) => <span>{text || "-"}</span>, // Provide default value if lastName is empty
        sorter: (a: { lastName: string }, b: { lastName: string }) =>
          a.lastName.length - b.lastName.length,
      },
      {
        title: "Date",
        dataIndex: "paymentDate",
        render: (text: string) => <span>{FormatDateTime(text)}</span>,
        sorter: (
          a: { paymentDate: string | any[] },
          b: { paymentDate: string | any[] }
        ) => {
          // Sort by length of paymentDate string
          return a.paymentDate.length - b.paymentDate.length;
        },
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        render: (paymentStatus: string) => (
          <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
            {paymentStatus}
          </Tag>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (text: any, record: any) => (
          <Space>
            <Link to={""}>
              <span
                className="link"
                onClick={() => handleDownloadPaymentDetails(record)}
              >
                {/* <i className="las la-print"></i> */}
                Invoice
              </span>
            </Link>
            {/* {[88, 89].includes(record.paymentStatusDDSID) && (
              <Link to={""}>
                <span
                  className="link"
                  onClick={() => {
                    handleCancle(
                      record.paymentDetailsID,
                      record.deviceUserName,
                      record.p2PRequestID
                    );
                  }}
                >
                  cancel
                </span>
              </Link>
            )} */}
          </Space>
        ),
        sorter: true,
      },
    ];
    return (
      <Table
        size="small"
        loading={PaymentDetailsExpanding.isFetching}
        columns={columns}
        dataSource={PaymentDetailsExpanding?.data}
        pagination={false}
        rowClassName={(record, index) => {
          return record?.paymentStatus === "Paid" ? "green" : "red";
        }}
        style={{ padding: "30px" }}
      />
    );
  };

  const onExpand = (expanded: boolean, record: IOutPatient) => {
    if (expanded) {
      paymentDetailsExpanding(record?.tcopid);
      setSelectedRow(record?.tcopid);
    } else setSelectedRow(null);
  };

  const columns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      key: "tcopid",
      dataIndex: "tcopid",
      ...getColumnSearchProps("tcopid"),
      render: (text: string, record) => (
        <Link className="hyperlink" to={`/details-tab/${record.tcopid}`}>
          <span>{text || "No UHID"}</span>
        </Link>
      ),
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      // dataIndex: "patientName",

      ...getColumnSearchProps("patientName"),
      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      key: "appointmentTime",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "doctorName",
      // dataIndex: "doctorName",
      ...getColumnSearchProps("doctorName"),
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      // sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
      //   a.doctorName?.length - b.doctorName?.length,
    },
    // {
    //   title: "Payment Status",
    //   key: "opStatus",
    //   render: ({ opStatus }) => (
    //     <Tag color={paymentStatusColorMap[opStatus?.toUpperCase()]}>
    //       {opStatus}
    //     </Tag>
    //   ),
    // },
    {
      title: "Status",
      key: "opStatus",
      // dataIndex: "opStatus",
      ...getColumnSearchProps("opStatus"),
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.requestStatus !== "CANCELLED" && (
            <>
              <Space>
                <Link to={`/details-tab/${record.tcopid}`}>
                  <span className="link">View</span>
                </Link>
                {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                  record?.opStatus !== "Completed" &&
                  record?.opStatus !== "Cancelled" && (
                    <Link to={`/payment-details/${record.tcopid}`}>
                      <span className="link">Pay</span>
                    </Link>
                  )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];
  const defaultCheckedList = columns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.filter(({ key }) =>
    checkedList.includes(key as string)
  );

  console.log({ newColumns });

  const doctorColumns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      dataIndex: "tcopid",
      ...getColumnSearchProps("tcopid"),

      render: (text: string) => <span>{text || "No UHID"}</span>,
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      ...getColumnSearchProps("patientName"),

      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "Doctor",
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
        a.doctorName?.length - b.doctorName?.length,
    },
    {
      title: "Payment Status",
      key: "paymentStatus",
      render: ({ paymentStatus }) => (
        <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
          {paymentStatus}
        </Tag>
      ),
    },
    {
      title: "Status",
      key: "opStatus",
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.requestStatus !== "CANCELLED" && (
            <>
              {" "}
              <Space>
                <Link to={`/detailsTab/${record.tcopid}`}>
                  <span className="link">View</span>
                </Link>
                {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                  record?.requestStatus === "Booked" && (
                    <Link to={`/payment-details/${record.tcopid}`}>
                      <span className="link">Pay</span>
                    </Link>
                  )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];

  const nurseColumns: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      dataIndex: "tcopid",
      ...getColumnSearchProps("tcopid"),
      render: (text: string) => <span>{text || "No UHID"}</span>,
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      ...getColumnSearchProps("patientName"),
      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "Doctor",
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
        a.doctorName?.length - b.doctorName?.length,
    },
    {
      title: "Payment Status",
      key: "paymentStatus",
      render: ({ paymentStatus }) => (
        <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
          {paymentStatus}
        </Tag>
      ),
    },
    {
      title: "Status",
      key: "opStatus",
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.requestStatus !== "CANCELLED" && (
            <>
              {" "}
              <Space>
                <Link to={`/detailsTab/${record.tcopid}`}>
                  <span className="link">View</span>
                </Link>
                {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                  record?.requestStatus === "Booked" && (
                    <Link to={`/payment-details/${record.tcopid}`}>
                      <span className="link">Pay</span>
                    </Link>
                  )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCheckedList(defaultCheckedList);
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <div className="welcome-box">
              <div className="welcome-img">
                <img alt="" src={Avatar2} />
              </div>
              <div className="welcome-det">
                <h3 className="bolder">
                  {" "}
                  Welcome, {user?.employeeName ?? "Guest"}
                </h3>
                <p> {user?.roleName} </p>
              </div>
            </div> */}
            {isRoleVisible([Role.Admin, Role.Mod]) && (
              <>
                <h3 className="page-title bolder"> Dashboard </h3>
                <ul className="breadcrumb"></ul>

                <div>
                  <div className="row">
                    {statsData.map((stat, index) => (
                      <div className="col-md-6  d-flex" key={index}>
                        <div className="stats-info w-100">
                          <h5 className="bolder">{stat.title}</h5>
                          {stat.subText ? (
                            <h6 className="bolder">
                              <span>{stat.value} </span>
                              <span>{stat.values} </span>
                              <span className="textval">{stat.subText}</span>
                            </h6>
                          ) : (
                            <h4 className="bolder">{stat.value}</h4>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="OP-header">
              <div className="card-header ">
                <h3 className="card-title mb-0 bolder">Appointments</h3>
              </div>
            </div>

            <div className="mt-3">
              <div>
                <div className="row filter-row">
                  <div className="col-sm-10 col-md-10">
                    <div className="input-block">
                      <Row>
                        <Col span={6}>
                          <input
                            type="text"
                            // value={searchQuery}
                            onChange={handleInputChange}
                            className="form-control floating"
                            placeholder="Search by ID or Patient Name"
                          />
                        </Col>
                        {/* <Col span={2}>
                      <div>
                      <Anchor
                          affix={false}
                          onClick={showModal}
                          items={[
                            {
                              key: '1',
                              href: '#components',
                              title: 'Select Columns',
                            }
                          ]}
                        />
                        <Modal
                          //title="Columns"
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <Divider>Columns</Divider>
                          <Checkbox.Group
                            value={checkedList}
                            options={options as CheckboxOptionType[]}
                            onChange={(value) => {
                              setCheckedList(value as string[]);
                            }}
                          />

                        </Modal>
                      </div>
                      </Col> */}
                      </Row>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-md-3"></div> */}
                </div>
              </div>

              {isRoleVisible([Role.Admin, Role.Mod]) && (
                <>
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <Tag color="geekblue" onClick={showModal}>
                        Select Columns
                      </Tag>
                      <Modal
                        //title="Columns"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                      >
                        <Divider>Columns</Divider>
                        <Checkbox.Group
                          value={checkedList}
                          options={options as CheckboxOptionType[]}
                          onChange={(value) => {
                            setCheckedList(value as string[]);
                          }}
                        />
                      </Modal>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          pagination={
                            filteredPatients?.length > 10 ? undefined : false
                          }
                          loading={isAllPatientsFetching}
                          columns={newColumns}
                          dataSource={filteredPatients}
                          rowKey="tcopid"
                          expandable={{
                            expandedRowRender,
                            // defaultExpandedRowKeys: ["0"],
                            expandedRowKeys: selectedRow ? [selectedRow] : [],
                            onExpand: onExpand,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isRoleVisible([Role.Doctor]) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <Table
                        pagination={
                          filteredDoctorPatients?.length > 10
                            ? undefined
                            : false
                        }
                        loading={doctorAppointments?.isFetching}
                        columns={doctorColumns}
                        dataSource={filteredDoctorPatients}
                        rowKey="outPatientID"
                      />
                    </div>
                  </div>
                </div>
              )}
              {isRoleVisible([Role.Nurse]) && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <Table
                        pagination={
                          filteredNursePatients?.length > 10 ? undefined : false
                        }
                        loading={isallOutPatientForNurse}
                        columns={nurseColumns}
                        dataSource={filteredNursePatients}
                        rowKey="outPatientID"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDashboard;
