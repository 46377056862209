export const statusColorMap: { [status: string]: string } = {
  Booked: "blue",
  Completed: "green",
  Cancelled: "red",
  CheckedIn: "green",
  Inconsultation: "green",
  Confirmed: "green",
};

export const paymentStatusColorMap: { [status: string]: string } = {
  PENDING: "red",
  PARTIAL: "orange",
  COMPLETED: "green",
  PAID: "green",
  UNPAID: "red",
  INTIATED: "orange",
};

export const IPStatusColorMap: { [status: string]: string } = {
  ADMITTED: "green",
  ICUCHECKIN: "orange",
  ICUCHECKOUT: "orange",
  CHECKOUT: "green",
  DISCHARGED: "green"
};

export const IndentColorMap: { [status: string]: string } = {
  PENDING: "orange",
  NEW: "red",
  CLOSED: "gray",
  OPEN: "blue",
  RECEIVED: "green"
}

export const SourceColorMap: { [status: string]: string } = {
  RIVIDA: "yellow"
}