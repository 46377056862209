import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Form,
  Input,
  Space,
  Tabs,
  Tooltip,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOU } from "../../../Routes/ImagePath";
import { useAppDispatch } from "../../../hooks/store";
import { Role } from "../../../models/PrivilegeRoles";
import { setUser } from "../../../reducers/UserSlice";
import {
  useAddExternalUserLoginMutation,
  useGetHIMSEmployeeDetailsQuery,
} from "../../../services/ApiSlice";
import { LocalhostKeys } from "../../../shared/localhost-keys";
import { SessionStorageKeys } from "../../../shared/session-storage-keys";
import { emailrgx } from "./RegEx";

const { Text } = Typography;

const Login: React.FC<{}> = () => {
  const { instance, accounts } = useMsal();

  const dispatch = useAppDispatch();
  const [activeKey, setActiveKey] = useState("1");
  const [forgotPassword, setForgotPassword] = useState(false);
  const GetHIMSEmployee = useGetHIMSEmployeeDetailsQuery(
    accounts?.at(0)?.localAccountId ?? skipToken
  );
  const navigate = useNavigate();
  const [addExternalUserLogin, AddExternalUserLogin] =
    useAddExternalUserLoginMutation();

  useEffect(() => {
    if (GetHIMSEmployee.isSuccess && accounts?.at(0)?.localAccountId)
      setLoginDisplay();
    // else navigate("/not-authorized");
    // else navigate('/');
  }, [GetHIMSEmployee]);

  useEffect(() => {
    if (AddExternalUserLogin.isSuccess) {
      if (AddExternalUserLogin.data) {
        setExternalLoginDisplay();
      } else {
        notification.error({
          message: "Error",
          description: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    }
  }, [AddExternalUserLogin]);

  // const [isLoading, setIsLoading] = useState(false);
  const [formInstance] = Form.useForm();
  const [forgotFormInstance] = Form.useForm();
  const [isExternalLogin, setIsExternalLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loginEnable, setLoginEnabled] = useState(false);
  const handleEmail = Form.useWatch("EmailID", formInstance);
  useEffect(() => {
    setLoginEnabled(false);
  }, [handleEmail]);
  const handleEmailChange = useMemo(
    () =>
      handleEmail?.includes(".com") ||
      handleEmail?.includes(".in") ||
      handleEmail?.includes("gmail"),
    [handleEmail]
  );
  const handleEamilCheck = () => {
    if (!handleEmail?.includes("healthonus.io")) {
      setVisiblePassword(true);
    } else {
      setVisiblePassword(false);
    }
    setLoginEnabled(true);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInternalLogin = () => {
    // Handle internal login logic
    formInstance.resetFields();
    setIsExternalLogin(false);
  };

  const handleExternalLogin = () => {
    setIsExternalLogin(true);
  };
  const handleBack = () => {
    forgotFormInstance.resetFields();
    setForgotPassword(false);
  };

  const onFinish = (values: any) => {
    addExternalUserLogin(values);
    // Handle external login logic here with values.email and values.password
  };

  const handleLogin = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    try {
      instance.loginRedirect().then(() => {});
    } catch (error) {
      console.error(error);
    }
  };
  const tabItems: any = [
    // {
    //   key: "1",
    //   label: "Internal Login",
    //   children: (
    //     <button
    //       type="button"
    //       disabled={isLoading}
    //       onClick={(event) => handleLogin()}
    //       className={isLoading ? "" : "hidden"}
    //     >
    //       {isLoading ? "Loading......." : "Login"}{" "}
    //     </button>
    //   ),
    // },
    {
      key: "1",
      label: "Login",
      children: (
        <Form
          form={formInstance}
          name="external_login"
          initialValues={{ remember: true }}
          onFinish={visiblePassword ? onFinish : handleLogin}
        >
          {/* <div style={{ display: "flex", alignItems: "center" }}> */}
          <Space.Compact>
            <Form.Item
              name="EmailID"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email",
                },
                {
                  pattern: emailrgx,
                  message: "Please Enter Valid Email",
                },
              ]}
              // style={{ flex: 1, marginRight: "10px" }}
            >
              <Input
                placeholder="Email"
                suffix={
                  <Tooltip title="Email checking">
                    <i
                      className="lar la-arrow-alt-circle-right"
                      style={{ fontSize: 24 }}
                      onClick={handleEamilCheck}
                    ></i>
                  </Tooltip>
                }
              />
            </Form.Item>
            {/* <i
              className="lar la-arrow-alt-circle-right"
              style={{ fontSize: 24, marginTop: 5 }}
              onClick={handleEamilCheck}
            ></i> */}
          </Space.Compact>
          {/* <Col>
              <i
                className="lar la-arrow-alt-circle-right"
                style={{ fontSize: 24, marginTop: 5 }}
                onClick={handleEamilCheck}
              ></i>
            </Col> */}
          {/* </div> */}
          {visiblePassword && (
            <Form.Item
              name="Password"
              rules={[
                {
                  required: true,
                  message: "Please Enter Password",
                },
                {
                  min: 3,
                  message: "Enter atleast 3 characters",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          )}{" "}
          {/* <Form.Item wrapperCol={{ offset: 11 }}>
            <a
              className="login-form-forgot"
              onClick={() => {
                setForgotPassword(true);
                formInstance.resetFields();
              }}
            >
              Forgot password
            </a>
          </Form.Item> */}
          {loginEnable && (
            <Form.Item>
              <Button
                // type="submit"
                htmlType="submit"
                disabled={
                  isLoading || AddExternalUserLogin.isLoading || !loginEnable
                }
                className={
                  isLoading || AddExternalUserLogin.isLoading ? "" : "hidden"
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 40,
                  marginLeft: 30,
                }}
              >
                {isLoading || AddExternalUserLogin.isLoading ? (
                  "Loading......."
                ) : (
                  <span>Login</span>
                )}{" "}
              </Button>
            </Form.Item>
          )}
        </Form>
      ),
    },
  ];
  const setLoginDisplay = () => {
    if (accounts) {
      localStorage.setItem(
        SessionStorageKeys.User,
        JSON.stringify(GetHIMSEmployee.data)
      );
      dispatch(setUser({ user: GetHIMSEmployee.data }));
      if (GetHIMSEmployee.data) {
        if ([Role.Mod, Role.Admin].includes(GetHIMSEmployee.data.himsRoleID)) {
          navigate("/dashboard");
        } else if (
          [Role.Doctor, Role.Nurse].includes(GetHIMSEmployee.data.himsRoleID)
        ) {
          navigate("/appointment-details");
        }
        // else if (([Role.Nurse, Role.Admin]).includes(data.himsRoleID)) {
        //     navigate('/patients');
        // }
        else {
          navigate("/not-authorized");
        }
      } else {
        localStorage.setItem(LocalhostKeys.USERPRIVILEGES, "");
        navigate("/not-authorized");
        notification.error({
          message: "Error",
          description: "You are not authorized",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
          duration: 10,
        });
        instance.logout();
      }
    } else {
      navigate("/");
    }
  };

  const setExternalLoginDisplay = () => {
    if (AddExternalUserLogin.data) {
      localStorage.setItem(
        SessionStorageKeys.User,
        JSON.stringify(AddExternalUserLogin.data)
      );
      dispatch(setUser({ user: AddExternalUserLogin.data }));
      if (AddExternalUserLogin.data) {
        navigate("/appointment-details");
      } else {
        localStorage.setItem(LocalhostKeys.USERPRIVILEGES, "");
        navigate("/not-authorized");
      }
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="loginbody">
        <UnauthenticatedTemplate>
          <div className="logincontainer" id="container">
            <div className="loginlogoImageConatiner">
              <img src={HOU} alt="Image" className="img-fluid " />
            </div>
            <div className="logintoggle-container">
              <div className="logintoggle">
                <div className="logintoggle-panel logintoggle-right">
                  {forgotPassword ? (
                    <>
                      <h4 style={{ color: "#7465AD", marginBottom: 15 }}>
                        Generate new password
                      </h4>
                      <Form
                        form={forgotFormInstance}
                        name="external_login"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                      >
                        <Form.Item
                          name="EmailID"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Email",
                            },
                            {
                              pattern: emailrgx,
                              message: "Please Enter Valid Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                          name="Password"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Password",
                            },
                            {
                              min: 3,
                              message: "Enter atleast 3 characters",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Current Password"
                            iconRender={(visible) =>
                              visible ? (
                                <i
                                  className="las la-eye"
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <i
                                  className="las la-low-vision"
                                  onClick={togglePasswordVisibility}
                                ></i>
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter New Password",
                            },
                            {
                              min: 3,
                              message: "Enter atleast 3 characters",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="New Password"
                            iconRender={(visible) =>
                              visible ? (
                                <i
                                  className="las la-eye"
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <i
                                  className="las la-low-vision"
                                  onClick={togglePasswordVisibility}
                                ></i>
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirmPassword"
                          dependencies={["newPassword"]}
                          rules={[
                            {
                              required: true,
                              message: "Please Confirm New Password",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("The two passwords do not match!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder="Confirm New Password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <EyeInvisibleOutlined
                                  onClick={togglePasswordVisibility}
                                />
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item>
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={isLoading ? "" : "hidden"}
                          >
                            {isLoading ? "Loading......." : "Login"}{" "}
                          </button>
                        </Form.Item>
                        {/* <Form.Item wrapperCol={{ offset: 18 }}>
      <a
        className="login-form-forgot"
        onClick={() => {
          setForgotPassword(false);
        }}
      >
        Back
      </a>
    </Form.Item> */}
                        <div style={{ textAlign: "right" }}>
                          <a className="login-form-forgot" onClick={handleBack}>
                            Back
                          </a>
                        </div>
                      </Form>
                    </>
                  ) : (
                    <Tabs
                      items={tabItems}
                      destroyInactiveTabPane
                      activeKey={activeKey}
                      onChange={setActiveKey}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </UnauthenticatedTemplate>
      </div>
    </>
  );
};

export default Login;
