import { Card, Space, Table, TableProps, Tabs, Tag, Typography } from "antd";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { statusColorMap } from "../../../models/Colors";
import {
  useGetDischageSummaryListQuery,
  useGetOutPatientBYPatientIDQuery,
} from "../../../services/ApiSlice";

const { Title, Text } = Typography;

const admissionCols: any = [
  {
    title: "Admission ID",
    dataIndex: "tcipid",
  },
  {
    title: "Room No",
    dataIndex: "roomNo",
  },
  {
    title: "Bed No",
    dataIndex: "bedNumber",
  },
  {
    title: "Admission Date",
    dataIndex: "admissionDate",
    render: (text: string) => FormatDateTime(text),
  },
  {
    title: "Discharge Date",
    dataIndex: "dischargeDate",
    render: (dischargeDate: string) => FormatDateTime(dischargeDate),
  },
];

const appointmentCols: TableProps<IOutPatient>["columns"] = [
  {
    title: "Appointment ID",
    dataIndex: "tcopid",
  },
  {
    title: "Scheduled Date",
    dataIndex: "appointmentTime",
    render: (appointmentTime) => FormatDateTime(appointmentTime),
  },
  {
    title: "Doctor Name",
    key: "Doctor",
    render: ({ doctorName, docSpecialization }) => (
      <Space direction="vertical" size={0}>
        {doctorName && <Text strong>{doctorName}</Text>}
        {docSpecialization && <Text type="secondary">{docSpecialization}</Text>}
      </Space>
    ),
  },
  {
    title: "Status",
    key: "Status",
    render: ({ opStatus }) => (
      <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
    ),
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (record) => (
  //     <Link to={`/details-tab/${record.tcopid}`}>
  //       <span className="link">View</span>
  //     </Link>
  //   ),
  // },
];

function PreviousMedicalHistory({ patientID }: { patientID: string }) {
  const isRoleBasedVisible = useRoleBasedVisible();

  const Admission = useGetDischageSummaryListQuery(patientID);
  const Appointments = useGetOutPatientBYPatientIDQuery({patientID:patientID,locationID:1});

  return (
    <Card>
      <Tabs
        tabPosition="left"
        items={[
          {
            key: "1",
            label: "IP",
            children: (
              <Table
                loading={Admission.isFetching}
                pagination={Admission.data?.length < 10 ? false : undefined}
                columns={admissionCols}
                dataSource={Admission.data?.filter((item: { status: string; }) =>item.status === "Discharged")??[]}
                // rowKey={(record) => record.id}
              />
            ),
          },
          {
            key: "2",
            label: "OP",
            children: (
              <Table
                loading={Appointments.isFetching}
                pagination={Appointments.data?.length < 10 ? false : undefined} // TODO: change this
                columns={appointmentCols}
                dataSource={Appointments.data ?? []}
                rowKey="tcopid"
              />
            ),
          },
        ]}
      />
    </Card>
  );
}

export default PreviousMedicalHistory;
