export class User {
  Id?: string;
  himsRoleID?: number;
  employeeName?: string;
  groupID?: number;
  groupName?: string;
  roleName?: string;
  ActiveFlag?: string;
  CreateUserID?: string;
  CreateDate?: string;
  LastChangeUserID?: string;
  LastChangeDate?: Date;
  RoleOrder?: number;
  himsEmployeeID?: string;
  privileges: IPrivilege[] = [];
}

export enum Role {
  Admin = 1,
  Nurse = 2,
  Doctor = 3,
  Mod = 4,
}

// NOTE: sync this with PRIVILEGE in src\db.d.ts file
export const PRIVILEGE = {
  BillingInfo: 11,

  Client_AddPatient: 2,
  Client_View_AddAdmission: 5,
  Client_View_BookAppointment: 4,
  Client_View_EditPatient: 6,
  Client_View: 3,
  Client: 1,

  InPatient_View: 10,
  InPatient: 9,

  OutPatient_View: 8,
  OutPatient: 7,
  
  IPBillingInfo: 12,
} as const;

export type IPRIVILEGE = (typeof PRIVILEGE)[keyof typeof PRIVILEGE];
