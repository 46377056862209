/* eslint-disable no-unused-expressions */

import type { InputRef, TableColumnType, TableProps } from "antd";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useDeleteHimsRoleMutation,
  useGetHimsRolesQuery,
  usePostNewRolePrivilegeMutation,
  useUpdateHimsRoleMutation,
} from "../../../services/ApiSlice";
import { FilterDropdownProps } from "antd/es/table/interface";

const { confirm } = Modal;
const { Title } = Typography;
type DataIndex = keyof IHimsRole;

const Roles: React.FC<{}> = () => {
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<IHimsRole | null>(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<IHimsRole> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7465ad',
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) => record[dataIndex]
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: <i className="fa-solid fa-magnifying-glass " style={{marginLeft: 5}} />
  });

  const HimsRoles = useGetHimsRolesQuery();
  const [postNewRolePrivilege, PostNewRolePrivilege] =
    usePostNewRolePrivilegeMutation();
  const [updateRole, RoleUpdate] = useUpdateHimsRoleMutation();
  const [deleteRole, RoleDelete] = useDeleteHimsRoleMutation();

  const user = useSelector(selectUser);
  const [formInstance] = Form.useForm();

  const handleEdit = (record: IHimsRole) => {
    setIsModelOpen(true);
    setSelectedRecord(record);
    formInstance.setFieldsValue(record);
  };

  useEffect(() => {
    if (RoleUpdate.isSuccess) {
      notification["success"]({
        message: "Role updated",
        icon: <i className="las la-check-circle text-success"></i>,
      });
    }
  }, [RoleUpdate]);

  const handleDelete = (record: IHimsRole) => {
    confirm({
      title: "Do you want to delete the role ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        try {
          deleteRole({
            roleID: record?.himsRoleID,
            userID: user?.himsEmployeeID,
          });
          if (RoleDelete.isSuccess) {
            notification["success"]({
              message: "Role Deleted",
              icon: <i className="las la-check-circle text-success"></i>,
            });
          }
        } catch (error) {
          console.error("Failed to delete data:", error);
        }
      },
    });
  };

  const columns: TableProps<IHimsRole>["columns"] = [
    {
      title: "Role ID",
      dataIndex: "himsRoleID",
      ...getColumnSearchProps("himsRoleID"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      ...getColumnSearchProps("roleName"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string) => <span style={{ whiteSpace: "normal" }}>{text || "-"}</span>,
    },
    {
      title: "Modified By",
      dataIndex: "userID",
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Modified Date",
      dataIndex: "modifiedDate",
      render: (text: string) => <span>{FormatDateTime(text) || "-"}</span>,
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space>
          <Link to="#">
            <span className="link " onClick={() => handleEdit(record)}>
              <i className="las la-pen"></i>
            </span>
          </Link>
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const onAddRole = () => {
    setIsModelOpen(true);
  };
  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();

      if (selectedRecord) {
        try {
          await postNewRolePrivilege({
            himsRoleID: selectedRecord?.himsRoleID,
            roleName: values.roleName,
            userID: user?.himsEmployeeID,
            description: values?.description,
          }).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
        onCancel();
      } else {
        const roleExists = HimsRoles?.data?.some(
          (role: { roleName: string }) =>
            role?.roleName?.toLowerCase().trim() ===
            values?.roleName?.toLowerCase().trim()
        );
        if (!roleExists) {
          try {
            await postNewRolePrivilege({
              RoleName: values?.roleName,
              description: values?.description,
              userID: user?.himsEmployeeID,
            }).unwrap();

            // Optionally, refetch data after successful post
            //  await refetch();
          } catch (error) {
            console.error("Failed to post data:", error);
          }
        } else {
          notification["warning"]({
            message: "Role already exists",
            icon: (
              <i
                className="las la-exclamation-circle"
                style={{ color: "red" }}
              ></i>
            ),
          });
        }
        onCancel();
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Spin spinning={HimsRoles?.isFetching} fullscreen />

          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Roles</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Roles</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn mx-3"
                  onClick={onAddRole}
                >
                  {" "}
                  <i className="fa fa-plus" />
                  Add Role
                </button>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={HimsRoles?.data}
                  className="table-striped"
                  rowKey="himsRoleID"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModelOpen}
        centered
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            {selectedRecord ? "Update Role" : "Add Role"}
          </Title>
        }
        onCancel={onCancel}
        onOk={handleAddUpdate}
        okText={selectedRecord ? "UPDATE" : "ADD"}
        maskClosable={false}
      >
        <Form
          form={formInstance}
          disabled={PostNewRolePrivilege.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="roleName"
            label="Role Name"
            rules={[
              { required: true, message: "Please Enter Role Name" },
              { min: 3, message: "Role must be minimum 3 characters." },
            ]}
          >
            <Input
              placeholder="Role Name"
              // disabled={patientsByMobile.isFetching}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please Enter Description" },
              { min: 3, message: "Description must be minimum 3 characters." },
            ]}
          >
            <Input
              placeholder="Description"
              // disabled={patientsByMobile.isFetching}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Roles;