import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../../layout/Header";
import Sidebar from "../../../layout/Sidebar";

function Doctor({ }) {
  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {/* FIXME: styling issue*/}
      { }
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* TODO: replace with AntD component*/}
          <div className="row">
            <div className="col">
              <h3 className="page-title">Doctors</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Doctors</li>
              </ul>
            </div>
          </div>

          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Doctor;