import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import { useIsAllowed } from "../hooks/role.hooks";
import { IPRIVILEGE } from "../models/PrivilegeRoles";
import { selectUser } from "../reducers/UserSlice";

interface IAuthGuardProps {
  component: React.ComponentType<any>;
  privilegeID?: IPRIVILEGE;
}

const AuthGuard: React.FC<IAuthGuardProps> = ({
  component: Component,
  privilegeID,
  ...rest
}) => {
  const { accounts, inProgress, instance } = useMsal();
  const navigate = useNavigate();
  const isAllowed = useIsAllowed();
  const user = useSelector(selectUser);
  useEffect(() => {
    // if (
    //   inProgress === Constants.LOGIN ||
    //   inProgress === Constants.HANDLEREDIRECT
    // ) {
    //   <div>Loading...</div>;
    // }

    //   if (!accounts || accounts.length === 0) {
    //     navigate("/");
    //   }

    //   if (privilegeID && !isAllowed(privilegeID)) navigate("/not-authorized");
    if (!user?.himsRoleID) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Component
      {...rest}
      render={(props: JSX.IntrinsicAttributes) => <Component {...props} />}
    />
  );
};

export default AuthGuard;

// useEffect(() => {
//   const request = {
//     scopes: ["user.read", "profile"],
//   };

//   const acquireToken = async () => {
//     try {

//       const response = await instance.acquireTokenSilent(request);

//       const accessToken = response.accessToken;
//       dispatch(setAuthToken(accessToken));
//     } catch (error) {
//       console.error("Error acquiring token:", error);
//     }
//   };

//   acquireToken();
// }, [instance]);
