import { Col, Form, Row, Spin } from "antd";
import { useCallback, useState } from "react";
import { useGetDoctorsByServiceQuery } from "../../../../services/ApiSlice";
import SpecialistCard from "./SearchSpecialist.Card";
import ToolBar, { IToolBarForm } from "./SearchSpecialist.ToolBar";

function SearchSpecialist({}) {
  const [params, setParams] = useState<ISpecialistDetailsArgs>({
    specialityID: null,
    locationID: 1,
  });

  const [formInstance] = Form.useForm<IToolBarForm>();

  const { data: doctors,isLoading:isGetDoctorsByServiceQuery } = useGetDoctorsByServiceQuery(params);

  const onSubmit = useCallback((values: IToolBarForm) => {
    setParams({
      specialityID: values.specialist,
      locationID: 1,
    });
  }, []);

  return (
    <div className="content container-fluid">
      <Spin spinning={isGetDoctorsByServiceQuery} fullscreen />
      <ToolBar formInstance={formInstance} onSubmit={onSubmit} />
      {Array.isArray(doctors) ? (
        <Row gutter={[24, 16]}>
          {doctors.map((doctor) => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              xxl={6}
              key={doctor.doctorID}
            >
              <SpecialistCard details={doctor} />
            </Col>
          ))}
        </Row>
      ) : (
        <p>No Data</p>
      )}
    </div>
  );
}

export default SearchSpecialist;
