import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { Role } from "../../../models/PrivilegeRoles";
import { IsIPorOp } from "../../../models/Status";
import {
  useGetCountsForIPBillingQuery,
  useGetPatientDischargeSummaryQuery,
  useLazyGetDownloadDischargeSummaryByPatientIDQuery,
  usePostUpdateRequestStatusMutation,
} from "../../../services/ApiSlice";

type Props = {
  patientID: string;
  TCIPID: string;
};

const { Text } = Typography;
const { confirm } = Modal;

function DischargeSummary({ patientID, TCIPID }: Props) {
  const [isModelOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [getDischargeSummary, GetDischargeSummary] =
    useLazyGetDownloadDischargeSummaryByPatientIDQuery();
  const [updateRequestStatus, updateRequestStatusResponse] =
    usePostUpdateRequestStatusMutation();
  const isRoleBasedVisible = useRoleBasedVisible();
  const GetPaymentCounts = useGetCountsForIPBillingQuery(TCIPID ?? skipToken);

  const DischargeSummary = useGetPatientDischargeSummaryQuery(
    TCIPID ?? skipToken
  );

  // const resp: any = JSON.parse(DischargeSummary?.data?.clinicalSummary);
  // console.log('resp',resp);

  useEffect(() => {
    if (GetDischargeSummary.data) {
      window.open(GetDischargeSummary.data);
    }
  }, [GetDischargeSummary]);

  const testData = [
    {
      ID: "HOUTCIP000000103",
      doctorName: "Dr.M.Shekar",
      docSpecialization: "Cardio",
      date: new Date().toString(),
    },
  ];

  const onPrescribedDrug = () => {
    navigate(`/admissions/${TCIPID}/prescribed-drug`, {
      state: {
        from: location.pathname,
        TCIPID,
        patientID,
        // doctorVisitID,
        returnState: { activeKey: "7" },
        isPrescribedDrug: true,
      },
    });
  };

  const onAddPrescription = () => {
    navigate(`/IPDSAddPrescription/${TCIPID}`, {
      state: {
        from: location.pathname,
        TCIPID,
        patientID,
        doctorVisitID: null,
        TCOPID: null,
        returnState: { activeKey: "2" },
      },
    });
  };

  const onDischargeSummary = () => {
    navigate(`/discharge-summary/${TCIPID}`, {
      state: {
        from: location.pathname,
        TCIPID,
        patientID,
        returnState: { activeKey: "7" },
        flag: true,
      },
    });
  };
  const renderTitle = () => {
    if (GetPaymentCounts.data?.dueAmount !== null) {
      return (
        <span style={{ fontSize: "16px" }}>
          <i
            className="la la-exclamation-circle"
            style={{ color: "red", fontSize: "24px" }}
          />{" "}
          The patient has due amount of : ₹{GetPaymentCounts.data?.dueAmount}
        </span>
      );
      // ` <i className="la la-exclamation-circle" /> The patient has due amount of : ₹${GetPaymentCounts.data?.dueAmount}`;
    } else {
      return "Loading..."; // Or any other placeholder text or spinner while fetching data
    }
  };
  const onConfirmDischarge = () => {
    GetPaymentCounts.data?.dueAmount <= 0
      ? confirm({
          title: "Are you sure to discharge the patient ?",
          centered: true,
          okText: "Yes",
          cancelText: "No",
          onOk() {
            if (TCIPID) {
              updateRequestStatus({
                Id: TCIPID,
                Type: IsIPorOp.inPatient,
                Status: 129,
              });
            }
          },
        })
      : confirm({
          title: renderTitle(),
          centered: true,
          cancelButtonProps: { style: { display: "none" } },
        });
  };

  const columns: any = [
    {
      title: " Submitted Date",
      dataIndex: "modifiedDate",
      render: (text: any) => <span>{FormatDateTime(text)}</span>,
    },
    {
      title: " Doctor Name",
      dataIndex: "modifiedBy",
      render: (text: string) => <span>Dr. {text}</span>,
    },
    {
      title: " Discharge Date",
      dataIndex: "dischargeDate",
      render: (text: any) => <span>{FormatDateTime(text)}</span>,
    },
    {
      title: " Prescription",
      dataIndex: "",
      render: () => (
        <div>
          {" "}
          {isRoleBasedVisible([Role.Doctor,Role.Nurse, Role.Admin]) && (
            <Link className="hyperlink" to={""}>
              <span onClick={onAddPrescription}>Add Prescription</span>
            </Link>
          )}
        </div>
      ),
    },

    {
      title: "Action",
      render: () => (
        <div>
          {" "}
          {isRoleBasedVisible([Role.Doctor,Role.Admin, Role.Nurse]) && (
            <Button type="link" onClick={onDischargeSummary}>
              Edit
            </Button>
          )}
        </div>
      ),
      // isPharma > 0 && prescriptionURL == null ? (
      //   <Button
      //     onClick={() => onGeneratePrescription(doctorVisitID, tcipid)}
      //     type="link"
      //   >
      //     Generate Prescription
      //   </Button>
      // ) : prescriptionURL ? (
      //   <Button
      //     onClick={() => onViewPrescription(prescriptionURL)}
      //     type="link"
      //   >
      //     Download Prescription
      //   </Button>
      // ) : (
      //   <Button type="link" onClick={() => onAddPrescription(doctorVisitID)}>
      //     Add Prescription
      //   </Button>
      // ),
    },

    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_v, record) => (
    //     <Space>
    //       <Button type="link">Prescription</Button>
    //       {/* TODO: Replace with Upload component */}
    //       <Button type="link">Add Lab</Button>
    //     </Space>
    //   ),
    // },
  ];

  const afterDischargedColumns: any = [
    {
      title: " Submitted Date",
      dataIndex: "modifiedDate",
      render: (text: any) => <span>{FormatDateTime(text)}</span>,
    },
    {
      title: " Doctor Name",
      dataIndex: "modifiedBy",
      render: (text: string) => <span>Dr. {text}</span>,
    },
    {
      title: " Discharge Date",
      dataIndex: "dischargeDate",
      render: (text: any) => <span>{FormatDateTime(text)}</span>,
    },
  ];

  const onDownload = () => {
    getDischargeSummary(TCIPID);
  };

  return (
    <Card>
      <Flex gap={24} vertical>
        {/* <Card>
          <Spin
            spinning={
              GetPaymentCounts.isLoading ||
              GetDischargeSummary.isLoading ||
              updateRequestStatusResponse.isLoading
            }
            fullscreen
          />
          {GetPaymentCounts.data?.dueAmount > 0 ? (
            <Text type="danger" style={{ fontSize: 20 }}>
              The patient has due amount of : ₹{" "}
              {GetPaymentCounts.data?.dueAmount}
            </Text>
          ) : (
            <Text type="success">No Due Amount</Text>
          )}
        </Card> */}
        {DischargeSummary.data?.patientDischargeSummaryID &&
          DischargeSummary.data?.status !== "Discharged" && (
            // <Text>resp</Text>
            <Table
              columns={columns}
              dataSource={[DischargeSummary.data]}
              // loading={DoctorVisitNotes?.isFetching}
              pagination={false}
            ></Table>
          )}
        {DischargeSummary.data &&
          DischargeSummary.data?.status === "Discharged" && (
            <Table
              columns={afterDischargedColumns}
              dataSource={[DischargeSummary.data]}
              // loading={DoctorVisitNotes?.isFetching}
              pagination={false}
            ></Table>
          )}
        <Row gutter={24}>
          {/* <Col>
            <Button
              disabled={GetPaymentCounts.data?.dueAmount > 0}
              onClick={onPrescribedDrug}
            >
              Add Prescription
            </Button>
          </Col> */}

          <Col>
            <Space>
              {/* TODO: add condition to check if discharge summary is added */}
              {GetPaymentCounts.data?.dueAmount <= 0 &&
                DischargeSummary.data &&
                DischargeSummary.data?.status === "Discharged" &&
                isRoleBasedVisible([Role.Mod,Role.Doctor,Role.Admin, Role.Nurse]) && (
                  <Button
                    onClick={onDownload}
                    type="link"
                    // disabled={GetPaymentCounts.data?.dueAmount > 0}
                  >
                    Download Discharge Summary
                  </Button>
                )}{" "}
              {!DischargeSummary.data?.patientDischargeSummaryID &&
                isRoleBasedVisible([Role.Doctor,Role.Admin]) && (
                  // GetPaymentCounts.data?.dueAmount <= 0 &&
                  //   !DischargeSummary.data &&
                  <Button
                    onClick={onDischargeSummary}
                    // disabled={GetPaymentCounts.data?.dueAmount > 0}
                  >
                    Add Discharge Summary
                  </Button>
                )}
              {DischargeSummary.data?.patientDischargeSummaryID &&
                DischargeSummary.data?.status !== "Discharged" &&
                isRoleBasedVisible([Role.Mod,Role.Doctor,Role.Admin]) && (
                  <Button
                    onClick={onConfirmDischarge}
                    // disabled={GetPaymentCounts.data?.dueAmount > 0}
                  >
                    Confirm Discharge
                  </Button>
                )}
            </Space>
          </Col>

          {/* <col>
            <Button
              onClick={onDischargeSummary}
              disabled={GetPaymentCounts.data?.dueAmount > 0}
            >
              Add Prescribed Drugs
            </Button>
          </col> */}
        </Row>
      </Flex>
    </Card>
  );
}

export default DischargeSummary;
