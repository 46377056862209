import { Card, Col, Row, Spin, Typography } from "antd";
import DoctorCard from "./DoctorSelector/DoctorCard.DoctorSelector";
const { Text } = Typography;

type Props = {
  doctors: ISpecialistDetails[] | undefined;
  loading: boolean;
  selectedDoctor: ISpecialistDetails | null;
  onDoctorChange: (doctor: ISpecialistDetails) => void;
};

function DoctorsList({
  doctors,
  loading,
  selectedDoctor,
  onDoctorChange,
}: Props) {
  return (
    <Card>
      {loading ? (
        <Spin />
      ) : Array.isArray(doctors) && doctors.length ? (
        <Row gutter={[8, 8]}>
          {doctors.map((doctor) => (
            <Col span={12}>
              <DoctorCard
                key={doctor.doctorID}
                details={doctor}
                selectedDoctor={selectedDoctor}
                onDoctorChange={onDoctorChange}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <Text>No Doctors Available</Text>
      )}
    </Card>
  );
}

export default DoctorsList;
