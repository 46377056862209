import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import TextareaAutosize from "react-textarea-autosize";
import { useReactToPrint } from "react-to-print";
import {
  useGetOutPatientSummaryQuery,
  usePostOutPatientMutation,
} from "../../../services/ApiSlice";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";

const DischargeSummary: React.FC<{}> = (porps) => {
  const navigate = useNavigate();
  const { id }: any = useParams();

  const [postOutPatient, { isLoading: isPosting, error: postError }] =
    usePostOutPatientMutation();
  const { data } = useGetOutPatientSummaryQuery(id);
  const componentRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [formData, setFormData] = useState<any>({
    chiefCompliants: null,
    pastMedicalHistory: null,
    examination: null,
    investigations: null,
    provisionalDiagnosis: null,
    // "patientUHID": "HOU0000000001021",
    patientName: "",
    // "outPatientID": "bb3f89e8-c9e2-4599-8e92-0c94b7568dd9",
    tcopid: "10",
    // "patientID": "354724ef-9960-43cc-a5be-0af6956c0824",
    // "admissionDate": "2024-02-03T05:30:00",
    // "assignedDoctorID": null,
    // "doctorName": null,
    // "assignedNurseID": null,
    // "appointmentTime": null,
    // "appointmentType": null,
    // "comments": null,
    // "speciality": null,
    // "specialityID": null,
    // "requestStatus": null,
    // "opStatus": null,
    // "createdBy": null,
    // "locationID": null
  });

  const location = useLocation();

  const incomingFormData = location.state?.formData;
  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      // Check if formData is present in location state
      if (incomingFormData) {
        setFormData(incomingFormData); // Populate formData with incoming data
      } else {
        setFormData(data); // Use data from API call
      }
    }
  }, [id, incomingFormData, data]);
  const handleChange: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    let updatedFormData = { ...formData };
    switch (name) {
      default:
        updatedFormData = { ...updatedFormData, [name]: value };
    }
    setFormData(updatedFormData);
  };
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "No Payment Date";
    interface LocationState {
      value1: string;
      value2: string;
      value3: string;
    }
    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);
    const [month, day, year, time] = formattedDate.split(/[\s,]+/);
    const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");
    return `${day}-${month}-${year} ${timeWithAmPm}`;
  };

  const handleUpdateData = async () => {
    setFormData({ ...data, patientID: data.patientID });
    try {
      await postOutPatient({
        ...formData,
      }).unwrap();
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    setFormData({
      chiefCompliants: null,
      pastMedicalHistory: null,
      examination: null,
      investigations: null,
      provisionalDiagnosis: null,
      patientUHID: "HOU0000000001021",
      patientName: "",
      // "outPatientID": "bb3f89e8-c9e2-4599-8e92-0c94b7568dd9",
      tcopid: "10",
      // "patientID": "354724ef-9960-43cc-a5be-0af6956c0824",
      // "admissionDate": "2024-02-03T05:30:00",
      // "assignedDoctorID": null,
      // "doctorName": null,
      // "assignedNurseID": null,
      // "appointmentTime": null,
      // "appointmentType": null,
      // "comments": null,
      // "speciality": null,
      // "specialityID": null,
      // "requestStatus": null,
      // "opStatus": null,
      // "createdBy": null,
      // "locationID": null
    });
    navigate(`/discharge-summary-list/`);
  };
  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateData();
            }}
          >
            <div ref={componentRef} className="printable-content">
              <div className="tblbox">
                <div className="firstcol">
                  <div className="firstfield">
                    <div className="inputfield">
                      <p className="lblVal1">Name:</p>
                      <label className="lblVal">{data?.patientName}</label>
                    </div>
                    <div className="inputfield">
                      <p className="lblVal1">HOUTCOP ID:</p>
                      <label className="lblVal">{data?.tcopid}</label>
                    </div>
                  </div>
                  <div className="firstfield">
                    <div className="inputfield">
                      <p className="lblVal1">Age:</p>
                      <label className="lblVal">
                        {formData?.age} {data?.patientAge ? "yrs" : ""}{" "}
                      </label>
                    </div>
                    <div className="inputfield">
                      <p className="lblVal1"></p>
                      <label className="lblVal"></label>
                    </div>
                  </div>
                  <div className="firstfield">
                    <div className="inputfield">
                      <p className="lblVal1">Gender:</p>
                      <label className="lblVal">{data?.patientGender}</label>
                    </div>
                    <div className="inputfield">
                      <p className="lblVal1">UHID:</p>
                      <label className="lblVal">{data?.patientUHID}</label>
                    </div>
                  </div>
                  <div className="firstfield">
                    <div className="inputfield">
                      <p className="lblVal1">Location:</p>
                      <label className="lblVal">{data?.locationName}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tblbox1">
                <p className="lblVal1">Chief Complaints:</p>
                <TextareaAutosize
                  className="textarea1"
                  name="finalDiagnosis"
                  readOnly={formData?.patientDishchargeSummaryID}
                  defaultValue={data?.chiefCompliants}
                  onChange={handleChange}
                />
              </div>
              <div className="tblbox3">
                <p className="lblVal1">Past Medical History:</p>
                <TextareaAutosize
                  className="textarea1"
                  name="clinicalSummary"
                  readOnly={formData?.patientDishchargeSummaryID}
                  defaultValue={data?.pastMedicalHistory}
                  onChange={handleChange}
                />
              </div>
              <div className="tblbox3">
                <p className="lblVal1">Examination: </p>
                <TextareaAutosize
                  className="textarea1"
                  readOnly={formData?.patientDishchargeSummaryID}
                  name="investigationDone"
                  defaultValue={data?.examination}
                  onChange={handleChange}
                />
              </div>
              <div className="tblbox3">
                <p className="lblVal1">Investigations:</p>
                <TextareaAutosize
                  className="textarea1"
                  readOnly={formData?.patientDishchargeSummaryID}
                  name="treatmentGivenHospital"
                  defaultValue={data?.investigations}
                  onChange={handleChange}
                />
              </div>
              <div className="tblbox2">
                <p className="lblVal1">Provisional Diagnosis:</p>
                <TextareaAutosize
                  className="textarea1"
                  readOnly={formData?.patientDishchargeSummaryID}
                  name="progressInHospital"
                  defaultValue={data?.provisionalDiagnosis}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="text-end mt-4 btnmargin">
              {!formData?.outPatientSummaryID ? (
                <button
                  type="button"
                  disabled={true}
                  className="btn btn-primary mx-3"
                >
                  Print
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary mx-3"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )}

              {formData?.outPatientSummaryID ? null : (
                <button
                  disabled={formData?.paymentPending}
                  type="submit"
                  className="btn btn-primary"
                  aria-label="Close"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DischargeSummary;
