import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Skeleton,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import AddAdmissionModal from "../../../components/modelpopup/AddAdmissionModal";
import { useIsAllowed, useRoleBasedVisible } from "../../../hooks/role.hooks";
import { paymentStatusColorMap } from "../../../models/Colors";
import { PRIVILEGE, Role } from "../../../models/PrivilegeRoles";
import { IsIPorOp, OPStatus } from "../../../models/Status";
import {
  useGetDischageSummaryListQuery,
  useGetOutPatientBYPatientIDQuery,
  useGetOutPatientByOPIDQuery,
  useGetPatientQuery,
  useGetPaymentsQuery,
  useLazyGetDownloadOPSummaryQuery,
  useLazyGetDownloadPaymentDetailsQuery,
  usePostOutPatientTimeSlotMutation,
  usePostUpdateRequestStatusMutation,
} from "../../../services/ApiSlice";
import TabsCard from "./TabsCard";

const { Title } = Typography;
const { confirm } = Modal;

const DetailsTab: React.FC<{}> = () => {
  const isRoleBasedVisible = useRoleBasedVisible();
  const { Title, Text } = Typography;
  const { id }: any = useParams();
  const isAllowed = useIsAllowed();
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const OPData = useGetOutPatientByOPIDQuery(id ?? skipToken);
  const [formInstance] = Form.useForm();
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const OutPatientList = useGetOutPatientBYPatientIDQuery({
    patientID: null,
    locationID: 1,
  });
  const [getPaymentDetails, PaymentDetails] =
    useLazyGetDownloadPaymentDetailsQuery();
  const [addUpdateAppointment, AddUpdateAppointment] =
    usePostOutPatientTimeSlotMutation();

  useEffect(() => {
    if (PaymentDetails.data) {
      window.open(PaymentDetails.data);
    }
  }, [PaymentDetails.data]);

  const [getDownloadOPSummary, DownloadOPSummary] =
    useLazyGetDownloadOPSummaryQuery();

  const patientData = useMemo(
    () =>
      Array.isArray(OutPatientList.data)
        ? OutPatientList.data?.find((option: any) => option?.tcopid === id)
        : undefined,
    [id, OutPatientList.data]
  );

  const PatientData = useGetPatientQuery(patientData?.patientID ?? skipToken);
  const { data: source } = useGetDischageSummaryListQuery(
    patientData?.patientID ?? skipToken
  );

  const handleDownloadPaymentDetails = (record: any) => {
    getPaymentDetails(record?.paymentDetailsID);
  };
  const showModalAppointment = () => {
    formInstance.setFieldsValue({
      appointmentDate: dayjs(patientData?.appointmentTime),
    });
    setIsAppointmentModalOpen(true);
  };

  const {
    data: paymentDetails,
    refetch,
    isLoading: isPaymentListLoading,
  } = useGetPaymentsQuery(id ?? skipToken);

  const [cancelAppointment, updateCancelAppointment] =
    usePostUpdateRequestStatusMutation();
  const [updateRequestStatus, updateRequestStatusResponse] =
    usePostUpdateRequestStatusMutation();
  const [updateCompleteStatus, updateCompleteStatusResponse] =
    usePostUpdateRequestStatusMutation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (AddUpdateAppointment.isSuccess) {
      if (AddUpdateAppointment.data) {
        notification["success"]({
          message: "Appointment time updated successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (AddUpdateAppointment.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
    setIsAppointmentModalOpen(false);
  }, [AddUpdateAppointment]);

  useEffect(() => {
    if (DownloadOPSummary.data) {
      window.open(DownloadOPSummary.data);
    }
    if (updateRequestStatusResponse.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "CheckIn Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }

    if (updateCancelAppointment.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "Cancelled Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }
    if (updateCompleteStatusResponse.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "Cancelled Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }
  }, [
    DownloadOPSummary.data,
    updateRequestStatusResponse.isSuccess,
    updateCancelAppointment.isSuccess,
    updateCompleteStatusResponse.isSuccess,
  ]);

  const personalDetails: any = patientData
    ? [
        {
          UHID: patientData?.patientUHID,
          Name: patientData?.patientName,
          MobileNo: patientData?.patientMobileNumber,
          TCOPID: patientData?.tcopid,
        },
      ]
    : [];
  const doctorDetails: any = patientData
    ? [
        {
          Name: patientData?.doctorName,
          Specialization: patientData?.docSpecialization,
          MobileNo: patientData?.docMobileNumber,
          Location: patientData?.docLoaction,
        },
      ]
    : [];
  const appointmentDetails: any = patientData
    ? [
        {
          AppointmentTime: patientData?.appointmentTime
            ? FormatDateTime(patientData?.appointmentTime)
            : null,
          ReasonForVisit: patientData?.comments,
        },
      ]
    : [];

  const attendeeDetails = OPData
    ? [
        {
          FirstName: OPData?.data?.attendeeFirstName,
          LastName: OPData?.data?.attendeeLastName,
          Relation: OPData.data?.relationship,
          MobileNo: OPData?.data?.attendeeMobileNumber,
        },
      ]
    : [];

  const [allowNewIP, activeTCIPID] = useMemo(() => {
    if (Array.isArray(source) && source.length) {
      const ip = source.find(({ dischargeDate }) => !dischargeDate); // TODO: need to replace with dischargeDate
      if (ip) return [false, ip.tcipid];
    }
    return [true, null];
  }, [source]);

  const handleDownloadOpSummary = () => {
    getDownloadOPSummary(id);
  };

  const handleOpSummary = () => {
    navigate(`/op-summary/${id}`);
  };

  const handleViewIPDetails = () => {
    if (activeTCIPID) navigate(`/inpatient-details/${activeTCIPID}`);
    else console.error("Internal error");
  };

  const handleCancelRequest = () => {
    confirm({
      title: "Do you want to cancel the request ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          cancelAppointment({
            Id: id,
            Type: isPatient,
            Status: OPStatus.Cancelled,
          });
        }
      },
    });
  };
  const handleChangeAppointment = () => {
    navigate(
      // `/edit-appointment/${id}/${patientData?.patientID}/book-appointment`
      `/patients/${patientData?.patientID}/book-appointment/${id}`
    );
  };
  const handleCheckIn = () => {
    confirm({
      title: "Do you want to 'Check-In' the client ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateRequestStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.CheckIn,
          });
        }
      },
    });
  };

  const handleAddAdmission = () => {
    setIsModelOpen(true);
  };
  const handleCancelAppointment = () => {
    setIsAppointmentModalOpen(false);
  };
  const handleOkAppointment = async () => {
    try {
      const values = await formInstance.validateFields();
      try {
        await addUpdateAppointment({
          appointmentTime: values?.appointmentDate,
          outPatientID: patientData?.outPatientID,
        }).unwrap();
      } catch (error) {
        console.error("Failed to post data:", error);
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const handleMovetoDoctor = () => {
    confirm({
      title: "Do you want to Move the Client to Doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateRequestStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.InConsultation,
          });
        }
      },
    });
  };

  const handleComplete = () => {
    confirm({
      title: "Do you want to Complete the Process ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateCompleteStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.Complete,
          });
        }
      },
    });
  };

  const paymentColumns: any = [
    {
      title: "ID",
      dataIndex: "tcipid",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid.length - b.tcipid.length,
    },
    {
      title: "Payment For",
      dataIndex: "paymentFor",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
      sorter: (a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
      sorter: (a: { paymentMode: string }, b: { paymentMode: string }) =>
        a.paymentMode.length - b.paymentMode.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if lastName is empty
      sorter: (a: { lastName: string }, b: { lastName: string }) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (
        a: { paymentDate: string | any[] },
        b: { paymentDate: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.paymentDate.length - b.paymentDate.length;
      },
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      render: (text: string) => (
        <Tag color={paymentStatusColorMap[text?.toUpperCase()]}>{text}</Tag>
      ),
      sorter: (
        a: { status: string | any[] },
        b: { status: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.status.length - b.status.length;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space>
          {record?.paymentStatus === "Paid" && (
            <Link to={""}>
              <span
                className="link"
                onClick={() => handleDownloadPaymentDetails(record)}
              >
                {/* <i className="las la-print"></i> */}
                Invoice
              </span>
            </Link>
          )}
          {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
            record?.paymentStatus !== "Paid" && (
              <Link to={`/payment-details/${id}`}>
                <span className="link">Pay</span>
              </Link>
            )}

          {[88, 89].includes(record.paymentStatusDDSID) && (
            <Link to={""}>
              {/* <span
                    className="link"
                    onClick={() => {
                      handleCancle(
                        record.paymentDetailsID,
                        record.deviceUserName,
                        record.p2PRequestID
                      );
                    }}
                  >
                    cancel
                  </span> */}
            </Link>
          )}
        </Space>
      ),
      sorter: true,
    },
  ];

  return (
    <>
      {" "}
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin
            spinning={
              PaymentDetails.isFetching ||
              DownloadOPSummary?.isLoading ||
              AddUpdateAppointment.isLoading
            }
            fullscreen
          />

          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"></div>
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Appointment Details </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/appointment-details">Appointments</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Appointment Details
                  </li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                {isAllowed(PRIVILEGE.Client_View_AddAdmission) && (
                  <Link
                    to="#"
                    className="btn add-btn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#add_holiday"
                    onClick={handleAddAdmission}
                  >
                    <i className="fa fa-plus" />
                    Add IP Admission
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              id="emp_patient"
              className="pro-overview tab-pane fade show active"
            >
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">Patient Information </h3>
                      <ul className="personal-info">
                        <div className="text-center">
                          {/* <Spin spinning={isPatientLoading} /> */}
                        </div>

                        {Array.isArray(personalDetails) ? (
                          personalDetails.map((item: any, index: any) => (
                            <ListItem
                              key={index}
                              data={item}
                              isLoading={PatientData.isFetching}
                            />
                          ))
                        ) : (
                          <ListItem
                            data={personalDetails}
                            isLoading={PatientData.isFetching}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {isRoleBasedVisible([
                  Role.Admin,
                  Role.Doctor,
                  Role.Mod,
                  Role.Nurse,
                ]) && (
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          Appointment Details
                          {isRoleBasedVisible([Role.Admin]) && (
                            <Link
                              to="#"
                              className="edit-icon"
                              onClick={showModalAppointment}
                            >
                              <i className="fa fa-pencil" />
                            </Link>
                          )}
                        </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatinetListLoading} /> */}
                          </div>
                          {Array.isArray(appointmentDetails) ? (
                            appointmentDetails.map((item: any, index: any) => (
                              <ListItem
                                key={index}
                                data={item}
                                isLoading={PatientData.isFetching}
                              />
                            ))
                          ) : (
                            <ListItem
                              data={appointmentDetails}
                              isLoading={appointmentDetails.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {isRoleBasedVisible([Role.Mod, Role.Admin]) && (
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Doctor Information </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatinetListLoading} /> */}
                          </div>
                          {Array.isArray(doctorDetails) ? (
                            doctorDetails.map((item: any, index: any) => (
                              <ListItem
                                key={index}
                                data={item}
                                isLoading={PatientData.isFetching}
                              />
                            ))
                          ) : (
                            <ListItem
                              data={doctorDetails}
                              isLoading={PatientData.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {attendeeDetails.some((detail) =>
                  Object.values(detail).some(
                    (value) => value !== null && value !== undefined
                  )
                ) && (
                  <div className="col-md-12">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Attendee Information </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatientLoading} /> */}
                          </div>

                          {Array.isArray(attendeeDetails) ? (
                            attendeeDetails.map((item: any, index: any) => (
                              <AttendeeListItem
                                key={index}
                                data={item}
                                isLoading={OPData.isFetching}
                              />
                            ))
                          ) : (
                            <AttendeeListItem
                              data={attendeeDetails}
                              isLoading={OPData.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <TabsCard patientData={PatientData.data} TCOPID={id} />

          {isRoleBasedVisible([Role.Admin, Role.Mod]) && (
            <>
              <Title level={4}>Payment Details</Title>
              <Table
                pagination={paymentDetails?.length > 10 ? undefined : false}
                columns={paymentColumns}
                dataSource={paymentDetails}
                className="table-striped"
                rowKey={(record) => record.id}
                loading={isPaymentListLoading}
              />
            </>
          )}

          {/* {isRoleBasedVisible([Role.Mod, Role.Admin]) && (
            <div>
              <div className="mb-3">
                <h3 className="page-title">Payment Details </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={paymentDetails}
                      className="table-striped"
                      rowKey={(record) => record.id}
                      loading={isPaymentListLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div className="py-1"></div>
          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end">
                <Space size={30}>
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    (patientData?.opStatus?.toString() === "Completed" ||
                      patientData?.opStatus?.toString() === "Followup") && (
                      <Button type="primary" onClick={handleDownloadOpSummary}>
                        Download OP Summary
                      </Button>
                    )}
                  {/* {isRoleBasedVisible([Role.Doctor, Role.Admin]) && (
                    <Button type="primary" onClick={handleOpSummary}>
                      Summary Note
                    </Button>
                  )} */}
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    patientData?.opStatus?.toString() !== "Completed" &&
                    patientData?.opStatus?.toString() !== "Cancelled" && (
                      <Link to={`/payment-details/${id}`}>
                        <Button type="primary">Pay</Button>
                      </Link>
                    )}
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    patientData?.opStatus?.toString() === "Confirmed" && (
                      <Button type="primary" onClick={handleCheckIn}>
                        Check In
                      </Button>
                    )}
                  {/* <Button type="primary" onClick={handleChangeAppointmentDoctor}>
              Change Doctor
            </Button> */}
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    (patientData?.opStatus?.toString() === "Booked" ||
                      patientData?.opStatus?.toString() === "Confirmed") && (
                      <Button type="primary" onClick={handleChangeAppointment}>
                        Change Appointment
                      </Button>
                    )}
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    (patientData?.opStatus?.toString() === "Booked" ||
                      patientData?.opStatus?.toString() === "Confirmed") && (
                      <Button danger onClick={handleCancelRequest}>
                        Cancel Request
                      </Button>
                    )}

                  {isRoleBasedVisible([Role.Admin]) &&
                    patientData?.opStatus?.toString() === "CheckedIn" && (
                      <Button type="primary" onClick={handleMovetoDoctor}>
                        Move to Doctor
                      </Button>
                    )}

                  {isRoleBasedVisible([Role.Admin]) &&
                    patientData?.opStatus?.toString() === "Inconsultation" && (
                      <Button type="primary" onClick={handleComplete}>
                        Complete
                      </Button>
                    )}
                </Space>
              </div>
            </div>
          </div>
        </div>
        <AddAdmissionModal
          patientID={patientData?.patientID}
          isModelOpen={isModelOpen && allowNewIP}
          onClose={() => {
            setIsModelOpen(false);
          }}
        />
        <Modal
          title={
            <Space>
              <i
                className="la la-exclamation-circle"
                style={{ fontSize: "25px", color: "red" }}
              ></i>
              <Text>Warning</Text>
            </Space>
          }
          open={isModelOpen && !allowNewIP}
          okText="View Details"
          cancelText="Close"
          onOk={() => {
            handleViewIPDetails();
          }}
          onCancel={() => setIsModelOpen(false)}
        >
          <Title level={4}>
            You already have an active IP admission{" "}
            {activeTCIPID && `(${activeTCIPID})`} for this patient
          </Title>
        </Modal>
        <Modal
          title="Appointment Information"
          open={isAppointmentModalOpen}
          onOk={handleOkAppointment}
          onCancel={handleCancelAppointment}
          maskClosable={false}
        >
          <Form
            form={formInstance}
            disabled={PatientData.isLoading}
            layout="vertical"
          >
            <Form.Item
              name="appointmentDate"
              label="Appointment Date"
              rules={[
                { required: true, message: "Please Enter Appointment Date" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                format="YYYY-MM-DD HH:mm"
                // disabledDate={(d) => dayjs().isAfter(d)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};
export const ListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({ data, isLoading = false }) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <li>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            {isLoading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 300 }}
              />
            ) : (
              <div className="text">{value}</div>
            )}
          </li>
        ))}
      </div>
    </>
  );
};

export const AttendeeListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({ data, isLoading = false }) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div className="row">
        {Object.entries(data).map(([key, value]) => (
          <li className="col-md-6" key={key}>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            {isLoading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 300 }}
              />
            ) : (
              <div className="text">{value}</div>
            )}
          </li>
        ))}
      </div>
    </>
  );
};

export default DetailsTab;
