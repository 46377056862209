export enum IsIPorOp {
    inPatient = "ISIP",
    outPatient ='ISOP'
}
export enum OPStatus{
    Booked = 109,
    Confirmed =110,
    CheckIn = 111,
    InConsultation = 112,
    FollowUp = 113,
    Complete = 114,
    Cancelled = 115,
}