import { Space, Spin, Table, TableProps, Tag, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { razor_pay_cancle, razor_pay_device_cancle } from "../../../base_urls";
import { AddPayment } from "../../../components/modelpopup/AddPayment";
import { paymentStatusColorMap } from "../../../models/Colors";
import {
  useGetBillingInfoQuery,
  useGetPaymentsQuery,
  useLazyGetDownloadPaymentDetailsQuery,
} from "../../../services/ApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";

const PaymentDetails = () => {
  const { Text } = Typography;
  const location = useLocation();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const isIP = id.includes("IP");
  const [searchQuery, setSearchQuery] = useState("");
  const {
    data,
    refetch,
    isFetching: getPaymentdetails,
  } = useGetPaymentsQuery(id ?? skipToken);
  const [cancledStatus, setCancledStatus] = useState(true);
  const { data: allBills, isFetching: isAllBillsFetching } =
    useGetBillingInfoQuery("");
  const [
    getPaymentDetails,
    {
      data: printData,
      isFetching: isPrintDataFetching,
      isSuccess: isPrintDataSuccess,
      isLoading: downloadPrintData,
    },
  ] = useLazyGetDownloadPaymentDetailsQuery();

  const handleDownloadPaymentDetails = (record: any) => {
    getPaymentDetails(record?.paymentDetailsID);
  };

  const statsData = [
    {
      id: 1,
      title: "Total Amount",
      value: "15000",
      subText: "",
    },
    {
      id: 2,
      title: "Paid Amount",
      value: "1000",
      subText: "",
    },
    {
      id: 2,
      title: "Due Amount",
      value: "500",
      subText: "",
    },
  ];

  const billingcolumns: TableProps<IBills>["columns"] = [
    {
      title: "ID ",
      key: "id",
      dataIndex: "id",
      render: (text: string) => <span>{text || "-"}</span>,
      // sorter: (a: { id: string }, b: { id: string }) =>
      //   a.id?.length - b.id?.length,
    },
    {
      title: "Billing For",
      key: "actualAmount",
      dataIndex: "",
      render: (text: number) => <span>{"Room Charge" || "-"}</span>,
      // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
      //   a.actualAmount - b.actualAmount,
    },
    {
      title: "Price",
      key: "actualAmount",
      dataIndex: "actualAmount",
      render: (text: number) => <span>{"₹ " + text || "-"}</span>,
      // sorter: (a: { actualAmount: number }, b: { actualAmount: number }) =>
      //   a.actualAmount - b.actualAmount,
    },
  ];
  const defaultCheckedList = billingcolumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = billingcolumns.filter(({ key }) =>
    checkedList.includes(key as string)
  );

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allBills)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allBills?.filter(
        (patient: any) =>
          patient.id?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allBills, searchQuery]);

  useEffect(() => {
    if (printData) {
      window.open(printData);
    }
  }, [printData]);

  const handleCancle = async (
    PaymentDetailsID: string,
    userName: string,
    p2pRequestId: string
  ) => {
    try {
      const cancleDeviceRequestData = {
        username: userName,
        origP2pRequestId: p2pRequestId,
        pushTo: {
          deviceId: "null|ezetap_android",
        },
      };
      const cancleRequestData = {
        PaymentDetailsID: PaymentDetailsID,
      };
      const cancleDevicePayment = await fetch(razor_pay_device_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleDeviceRequestData),
      });

      const responseData = await cancleDevicePayment.json();
      if (responseData.success) {
        setCancledStatus(false);
      }

      const canclePayment = await fetch(razor_pay_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleRequestData),
      });
      if (!canclePayment.ok) {
        throw new Error("Failed to post p2p data to response status API");
      }
      if (!cancleDevicePayment.ok) {
        throw new Error(
          "Failed to post cancle device api  to response status API"
        );
      }
      // Handle success
    } catch (error) {
      console.error("Failed to post data to second API:", error);
    }
    refetch();
  };

  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "No Payment Date";
    interface LocationState {
      value1: string;
      value2: string;
      value3: string;
    }
    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);

    const [month, day, year, time] = formattedDate.split(/[\s,]+/);
    const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");
    return `${day}-${month}-${year} ${timeWithAmPm}`;
  };

  const handleClick = (
    detailsID: any,
    paymentFor: any,
    paymentMode: any,
    amount: any
  ) => {
    navigate("/payment-add-tab", {
      state: {
        value1: detailsID,
        value2: paymentFor,
        value3: paymentMode,
        value4: amount,
      },
    });
  };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "tcipid",
      render: (text: string) => <span>{text || "No Payment ID"}</span>, // Provide default value if firstName is empty
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid.length - b.tcipid.length,
    },
    {
      title: "Payment For",
      dataIndex: "paymentFor",
      render: (text: string) => <span>{text || "No Payment"}</span>, // Provide default value if firstName is empty
      sorter: (a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      render: (text: string) => <span>{text || "No Payment Mode"}</span>, // Provide default value if firstName is empty
      sorter: (a: { paymentMode: string }, b: { paymentMode: string }) =>
        a.paymentMode.length - b.paymentMode.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: string) => <span>{text || "No Amount"}</span>, // Provide default value if lastName is empty
      sorter: (a: { lastName: string }, b: { lastName: string }) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Date",
      dataIndex: "paymentDate",
      render: (text: string | number | Date) => <span>{formatDate(text)}</span>,
      sorter: (
        a: { paymentDate: string | any[] },
        b: { paymentDate: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.paymentDate.length - b.paymentDate.length;
      },
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus: string) => (
        <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
          {paymentStatus}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space>
          <Link to={""}>
            <span
              className="link"
              onClick={() => handleDownloadPaymentDetails(record)}
            >
              {/* <i className="las la-print"></i> */}
              Invoice
            </span>
          </Link>
          {[88, 89].includes(record.paymentStatusDDSID) && (
            <Link to={""}>
              <span
                className="link"
                onClick={() => {
                  handleCancle(
                    record.paymentDetailsID,
                    record.deviceUserName,
                    record.p2PRequestID
                  );
                }}
              >
                cancel
              </span>
            </Link>
          )}
        </Space>
      ),
      sorter: true,
    },
  ];

  const emptyRow = (
    <tr>
      <td colSpan={columns.length}>No data available</td>
    </tr>
  );

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin spinning={downloadPrintData} fullscreen />

          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={handleRefresh}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
          {/* <Breadcrumbs
            maintitle="Payment Details"
            title={isIP ? 'Admissions' : 'Appointments'}
            subtitle="Payment Details"
            modal="#add_payment"
            name="Add Payment"
          /> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  {isIP ? "Admissions" : "Appointments"}
                </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    {isIP ? (
                      <Link to={`/inpatient-details/${id}`}>
                        {isIP ? "Admissions" : "Appointments"}
                      </Link>
                    ) : (
                      <Link to="/appointment-details">
                        {isIP ? "Admissions" : "Appointments"}
                      </Link>
                    )}
                  </li>
                  <li className="breadcrumb-item active">Payment Details</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn mx-3"
                  data-bs-toggle="modal"
                  data-bs-target="#add_payment"
                >
                  {" "}
                  <i className="fa fa-plus" />
                  Add Payment
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <div>
                  <div className="row">
                    {statsData.map((stat, index) => (
                      <div className="col-md-4 d-flex green" key={index}>
                        <div className="stats-info w-100 ">
                          <h5 className="bolder">{stat.title}</h5>
                          {stat.subText ? (
                            <h3 className="bolder">
                              <span>{stat.value} </span>
                              <span className="textval">{stat.subText}</span>
                            </h3>
                          ) : (
                            <h4 className="bolder">{stat.value}</h4>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}

                {/* <h4>Billing Details</h4>
                <Table
                  // pagination={
                  //   filteredPatients?.length > 10 ? undefined : false
                  // }
                  // loading={isAllBillsFetching}
                  columns={billingcolumns}
                  dataSource={filteredPatients ?? []}
                  rowKey="outPatientID"
                /> */}

                {/* <h4>Transaction History</h4> */}
                <Table
                  pagination={data?.length > 10 ? undefined : false}
                  loading={getPaymentdetails}
                  columns={columns}
                  dataSource={data}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPayment tCIPID={id} billingID={null} />
    </>
  );
};
export default PaymentDetails;
