import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authentication/AuthSlice';
import { userSlice } from './reducers/UserSlice';
import { api } from './services/ApiSlice';



const reducer = {
  user: userSlice.reducer,
  [api.reducerPath]: api.reducer,
  auth: authReducer,

};

// const middleware:any = [ apiAuthMiddleware];

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;





