import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { SearchProps } from "antd/es/input";
import { useState } from "react";

const { Meta } = Card;
const { Text, Paragraph } = Typography;
const { Search } = Input;

type Drug = {
  label: string;
  price: number;
  madeBy: string;
  quantity: string;
  drugId: string;
};

type DrugPrescription = {
  quantity: number;
  dosage: {};
};

const AddPrescription = () => {
  const [prescription, setPrescription] = useState<{
    [drugId: string]: DrugPrescription;
  }>({});
  const [selectedDrug, setSelectedDrug] = useState<Drug | null>(null);

  const [formInstance] = Form.useForm();

  const onSearch: SearchProps["onSearch"] = (value) => console.log(value);

  const onAddQty = (drugId: Drug["drugId"]) => {
    setPrescription((prv) => {
      let quantity = 1,
        dosage = {};
      if (prv[drugId]) {
        quantity = prv[drugId].quantity + 1;
        dosage = prv[drugId].dosage;
      }

      return { ...prv, [drugId]: { quantity, dosage } };
    });
  };

  const onRemoveQty = (drugId: Drug["drugId"]) => {
    setPrescription((prv) => {
      let quantity = 0,
        dosage = {};
      if (prv[drugId] && prv[drugId].quantity) {
        quantity = prv[drugId].quantity - 1;
        dosage = prv[drugId].dosage;
      }

      return { ...prv, [drugId]: { quantity, dosage } };
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Flex gap={24} vertical>
          <Row>
            <Col span={6}>
              <Search
                placeholder="Search"
                enterButton="Search"
                onSearch={onSearch}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            {drugs.map((drug) => (
              <Col span={12}>
                <Card
                  actions={
                    prescription[drug.drugId]?.quantity
                      ? [
                          <Button
                            type="primary"
                            shape="circle"
                            onClick={() => onRemoveQty(drug.drugId)}
                            icon={<i className="fa-solid fa-minus" />}
                          />,
                          <Text strong>
                            {prescription[drug.drugId]?.quantity ?? 0}
                          </Text>,

                          <Button
                            type="primary"
                            shape="circle"
                            onClick={() => onAddQty(drug.drugId)}
                            icon={<i className="fa-solid fa-plus" />}
                          />,
                        ]
                      : [
                          <Button
                            style={{ width: "100%" }}
                            type="text"
                            onClick={() => onAddQty(drug.drugId)}
                          >
                            Add
                          </Button>,
                        ]
                  }
                >
                  <Meta
                    style={{ alignItems: "center" }}
                    avatar={
                      <Avatar
                        size={{
                          xs: 80,
                          sm: 64,
                          md: 80,
                          lg: 80,
                          xl: 64,
                          xxl: 100,
                        }}
                        icon={<i className="fa-regular fa-user" />}
                      />
                    }
                    title={drug.label}
                    description={
                      <Space direction="vertical" size="small">
                        <Text type="secondary">{drug.madeBy}</Text>
                        <Text type="secondary">{drug.quantity}</Text>
                        <Text strong>₹{drug.price}</Text>
                      </Space>
                    }
                  />
                  <Flex justify="end">
                    <Button onClick={() => setSelectedDrug(drug)}>
                      Add Dosage
                    </Button>
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
        </Flex>

        
        <Modal open={!!selectedDrug} onCancel={() => setSelectedDrug(null)}>
          <Form layout="vertical">
            <Form.Item>
              <Input />
            </Form.Item>

            <Space.Compact>
              <Form.Item
                name={["for", "number"]}
                initialValue={1}
                label="For"
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item name={["for", "period"]} initialValue="day" label=" ">
                <Select
                  options={[
                    { value: "day", label: "Day(s)" },
                    { value: "week", label: "Week(s)" },
                    { value: "month", label: "Month(s)" },
                    { value: "year", label: "Year(s)" },
                  ]}
                />
              </Form.Item>
            </Space.Compact>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AddPrescription;

const drugs: Drug[] = [
  {
    label: "DOLO 650",
    price: 255,
    madeBy: "asdf",
    quantity: "Pack of 15 Tabs",
    drugId: "dolo_650",
  },
  {
    label: "DOLO 660",
    price: 255,
    madeBy: "asdf",
    quantity: "Pack of 15 Tabs",
    drugId: "dolo_660",
  },
  {
    label: "DOLO 670",
    price: 255,
    madeBy: "asdf",
    quantity: "Pack of 15 Tabs",
    drugId: "dolo_670",
  },
];
