import type { TableProps, UploadFile } from "antd";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useDeleteBlobUrlForLabTestMutation,
  useFileUploadMutation,
  useGetAddedLabTestsQuery,
  useGetLabStatusQuery,
  usePostLabTestsUpdateMutation,
} from "../../../services/ApiSlice";
const { Text, Paragraph, Title } = Typography;

type Props = {
  patientID: string;
  doctorVisitID: number;
  TCOPID?: string;
  TCIPID?: string;
};

function LabRequests({ patientID, TCOPID, TCIPID, doctorVisitID }: Props) {
  const AddedLabTests = useGetAddedLabTestsQuery({
    tcOPID: TCOPID ?? null,
    tcIPID: TCIPID ?? null,
  });
  const [formInstance] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const [fileBlob, setFileBlob] = useState("");
  // const onAddTests = () => {
  //   navigate("/patients", {
  //     // state: {
  //     //   from: location?.pathname
  //     // },
  //   });
  // };
  const onAddTests = () => {
    navigate(`/patients`);
  };
  const [postLabTestsUpdate, PostLabTestsUpdate] =
    usePostLabTestsUpdateMutation();
  const [fileUpload, FileUpload] = useFileUploadMutation();
  const [deleteBlobUrlForLabTest, DeleteBlobUrlForLabTest] =
    useDeleteBlobUrlForLabTestMutation();
  useEffect(() => {
    if (PostLabTestsUpdate.isSuccess) {
      onCancel();
      setSelectedRecord(null);
    }
  }, [PostLabTestsUpdate]);

  const [selectedRecord, setSelectedRecord] = useState<IAddedLabTest | null>(
    null
  );

  const handleUpdateLabTest = async () => {
    try {
      const values = await formInstance.validateFields();
      console.log("Form values:", values);
      postLabTestsUpdate({
        // ...values,
        pltid: selectedRecord?.pltid,
        modifiedBy: user?.himsEmployeeID,
        scheduleDateTime: values?.scheduleDateTime,
        statusID: values?.statusID,
      });
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  const testData = [{}];

  const LabStatus = useGetLabStatusQuery("LabStatus");
  console.log("LabStatus", LabStatus.data);
  const isRoleBasedVisible = useRoleBasedVisible();

  const statusOptions = useMemo(
    () =>
      Array.isArray(LabStatus.data)
        ? LabStatus.data.map(({ dropDownSetID, dropDownValue }) => ({
            value: dropDownSetID,
            label: dropDownValue,
          }))
        : [],
    [LabStatus]
  );

  console.log("options", statusOptions);

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const handleEdit = (record: IAddedLabTest) => {
    setIsModelOpen(true);
    setSelectedRecord(record);
    formInstance.setFieldsValue({
      ...record,
      scheduleDateTime: record?.scheduleDateTime
        ? dayjs(record?.scheduleDateTime)
        : undefined,

      statusID: record?.statusID,
    });
  };
  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
  };
  const handleDownload = (blobUrl: any) => {
    window.open(blobUrl);
  };
  const handleDeleteBlobUrl = (pltid: number) => {
    deleteBlobUrlForLabTest({
      id: pltid,
      CreateduserId: user?.himsEmployeeID,
    });
  };
  const handleUpload = async (info: any) => {
    const { file } = info;
    if (!file) {
      console.error("No file to upload");
      return;
    }
    const formData = new FormData();
    formData.append("files", file);
    selectedRecord?.pltid &&
      formData.append("id", selectedRecord.pltid.toString());
    try {
      await fileUpload(formData).unwrap();
      message.success("File uploaded successfully");
    } catch (error) {
      message.error("File upload failed");
    }
  };

  const itemRender = (
    originNode: React.ReactNode,
    file: UploadFile,
    fileList: UploadFile[],
    actions: any
  ) => {
    return (
      <div
        className="upload-list-item"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{ flexGrow: 1, marginBottom: 5 }}>{originNode}</span>
        <i
          className="las la-trash"
          onClick={() => actions.remove(file)}
          style={{ cursor: "pointer", marginLeft: 10 }}
        />
      </div>
    );
  };

  const columns: TableProps<IAddedLabTest>["columns"] = [
    // {
    //   title: "PLTID",
    //   dataIndex: "pltid",
    //   // render: (_value, _record, index) => index + 1,
    // },
    {
      title: "Patient",
      key: "patientName",
      // dataIndex: "patientName",

      render: ({ patientName, uhid }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {uhid && <Text type="secondary">{uhid}</Text>}
        </Space>
      ),
    },

    {
      title: "Test",
      dataIndex: "code",
      render: (text) => <span style={{ whiteSpace: "normal" }}>{text}</span>,
    },
    {
      title: "Service Group",
      // dataIndex: "serviceGroupName",
      render: ({ serviceGroupName, testName }) => (
        <Space direction="vertical" size={0}>
          {serviceGroupName && <Text strong>{serviceGroupName}</Text>}
          {testName && (
            <Text type="secondary" style={{ whiteSpace: "normal" }}>
              {testName}
            </Text>
          )}
        </Space>
      ),
    },
    // {
    //   title: "Schedule Date",
    //   dataIndex: "ScheduleDateTime",
    //   render: (date) => (date ? dayjs(date).format("YYYY-MM-DD HH:mm") : "-"),
    // },

    {
      title: "Scheduled Date",
      dataIndex: "scheduleDateTime",
      render: (text: string, record) => (
        <>
          <Space>
            <Link to="">
              <span>{dayjs(text).format("DD-MM-YYYY HH:MM")}</span>
            </Link>
          </Space>
        </>
      ),
    },
    {
      title: "Added By",
      // dataIndex: "createdByName",
      render: (record) => (
        <Space direction="vertical" size={0}>
          {record?.createdByName && <Text strong>{record?.createdByName}</Text>}
          {record?.modifiedDate && (
            <Text type="secondary">{FormatDateTime(record?.modifiedDate)}</Text>
          )}
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => <Tag color="warning">{status}</Tag>,
    },

    // {
    //   title: "Media",
    //   dataIndex: "MRP",
    //   render: (price) => (
    //     <Space direction="vertical" size="small">
    //       {["X-Ray", "Report"].map((file) => (
    //         <Button type="link" icon={<i className="fa-solid fa-paperclip" />}>
    //           {file}
    //         </Button>
    //       ))}
    //     </Space>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_v, record) => (
        <Space>
          {/* TODO: Replace with Upload component */}
          {record?.status !== "Completed" && record?.status !== "Cancelled" && (
            <Button type="primary" onClick={() => handleEdit(record)}>
              Edit
            </Button>
          )}
          {record?.status === "Completed" && !record?.reportBlobUrl && (
            <Upload
              customRequest={handleUpload}
              itemRender={itemRender}
              maxCount={1}
            >
              <Button
                icon={<i className="las la-upload"></i>}
                onClick={() => {
                  setSelectedRecord(record);
                }}
              >
                Upload
              </Button>

              {/* {fileBlob && fileBlob} */}
            </Upload>
          )}
          {record?.reportBlobUrl && record?.status === "Completed" && (
            <button
              type="button"
              className="btn btn-outline-success"
              style={{ fontSize: 14 }}
              onClick={() => {
                handleDownload(record.reportBlobUrl);
              }}
            >
              Download
            </button>
            // <Button
            //   // type="primary"
            //   style={{
            //     backgroundColor: "green",
            //     color: "white",
            //   }}
            //   onClick={() => {
            //     handleDownload(record.reportBlobUrl);
            //   }}
            // >
            //   Download
            // </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Spin
          spinning={
            PostLabTestsUpdate.isLoading ||
            DeleteBlobUrlForLabTest.isLoading ||
            AddedLabTests.isFetching
          }
          fullscreen
        />
        <div className="page-header">
          <Flex vertical gap={24}>
            <Flex justify="space-between">
              <Title>Lab Requests</Title>
              <Link to="/patients">
                <Button type="primary">Add Lab Tests</Button>
              </Link>

              {/* <Link to="/patients">
                {isRoleBasedVisible([Role.Doctor, Role.Nurse]) && (
                  <Button onClick={onAddTests} type="primary">
                    Add Request
                  </Button>
                )}
              </Link> */}
            </Flex>
            <Table
              // pagination={AddedLabTests?.length > 10 ? undefined : false}
              scroll={{ x: "auto" }}
              loading={AddedLabTests.isFetching}
              columns={columns}
              rowKey="pltid"
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: 0 }}>{record?.reportBlobUrl}</p>
                    <Space>
                      <Button
                        onClick={() => {
                          handleDownload(record?.reportBlobUrl);
                        }}
                      >
                        View
                      </Button>
                      <Button
                        danger
                        onClick={() => {
                          handleDeleteBlobUrl(record?.pltid);
                        }}
                      >
                        Delete
                      </Button>
                    </Space>
                  </div>
                ),
                rowExpandable: (record) => !!record.reportBlobUrl,
              }}
              dataSource={AddedLabTests.data ?? []}
            />
          </Flex>
        </div>
        <Modal
          open={isModelOpen}
          centered
          title={
            <Title level={3} style={{ textAlign: "center" }}>
              LAB
            </Title>
          }
          onCancel={onCancel}
          // onOk={handleAddUpdate}
          onOk={handleUpdateLabTest}
          okText={"Save"}
          maskClosable={false}
        >
          <Form
            form={formInstance}
            // disabled={PostNewRolePrivilege.isLoading}
            disabled={PostLabTestsUpdate.isLoading}
            layout="vertical"
          >
            <Form.Item
              name="testName"
              label="Test Name"
              // rules={[
              //   { required: true, message: "Please Enter Role Name" },
              //   { min: 3, message: "Role must be minimum 3 characters." },
              // ]}
            >
              {/* <Input placeholder="Patient Name" disabled={true} /> */}
              <Text type="secondary">
                {formInstance.getFieldValue("testName")}
              </Text>
            </Form.Item>
            <Form.Item
              name="patientName"
              label="Patient Name"
              // rules={[
              //   { required: true, message: "Please Enter Description" },
              //   {
              //     min: 3,
              //     message: "Description must be minimum 3 characters.",
              //   },
              // ]}
            >
              {/* <Input placeholder="Patient Name" disabled={true} /> */}
              <Text type="secondary">
                {formInstance.getFieldValue("patientName")}
              </Text>
            </Form.Item>
            <Form.Item
              name="scheduleDateTime"
              label="Schedule Date"
              // rules={[
              //   { required: true, message: "Please select Check-Out Date" },
              // ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime={{
                  format: "HH A",
                }}
                format="DD-MM-YYYY HH A"
              />
            </Form.Item>
            <Form.Item
              name="statusID"
              label="Status"
              // rules={[{ required: true, message: "Please Enter Room No" }]}
            >
              <Select
                placeholder="Room No"
                style={{ width: "100%" }}
                // options={RoomNo}
                // loading={Rooms.isFetching}
                options={statusOptions}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default LabRequests;
