import type { TabsProps } from "antd";
import { Card, Tabs, Typography } from "antd";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { Role } from "../../../../models/PrivilegeRoles";
// import AddUpdateLabTests from "../../Dashboard/AddUpdateLabTests";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import LabTests from "../../Dashboard/LabTests";
import PreviousMedicalHistory from "../../Dashboard/PreviousMedicalHistory";
import DischargeSummary from "../DischargeSummaryCard";
import IPVisit from "./IPVisit";
import IPVitals from "./IPVitals";
import Medicine from "./Medicine";
import NursingService from "./Ns";
import Physiotherapy from "./PT";
import Prescription from "./Prescription";
import RoomDetails from "./RoomDetails";
import Indent from "./Indent/Indent";

type Props = {
  patientData: IPatientDetails;
  TCIPID: string;
};

function InPatientTabsCard({ patientData, TCIPID }: Props) {
  const [activeKey, setActiveKey] = useState("1");
  const isRoleBasedVisible = useRoleBasedVisible();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.activeKey) setActiveKey(location.state.activeKey);
  }, [location]);

  const tabItems = [
    {
      key: "1",
      label: "Vitals",
      children: (
        <IPVitals patientData={patientData} TCIPID={TCIPID} VitalsID={""} />
      ),
      //  (
      // <Vitals
      //   isEditable={isRoleBasedVisible([Role.Admin, Role.Nurse])} // TODO: add status check
      //   patientId={patientData?.patientID}
      // />
      // <IPVitals />
      // ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },

    {
      key: "2",
      label: "Doctor Rounds",
      children: (
        <IPVisit TCIPID={TCIPID} patientID={patientData?.patientID} />
        // <AddUpdateIPSummary
        //   TCOPID={TCIPID}
        //   isEditable={isRoleBasedVisible([Role.Admin, Role.Doctor])} // TODO: add status check
        // />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "3",
      label: "Prescription",
      children: (
        <Prescription patientID={patientData?.patientID} TCIPID={TCIPID} />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "4",
      label: "Lab",
      children: <LabTests patientID={patientData?.patientID} TCIPID={TCIPID} />,
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    // {
    //   key: "11",
    //   label: "Drugs",
    //   children: <Indent patientData={patientData} TCIPID={TCIPID} productID={""} />,
    //   visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor],
    // },
    // {
    //   key: "7",
    //   label: "Medicines",
    //   children: <Medicine patientID={patientData?.patientID} TCIPID={TCIPID} />,
    //   visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    // },
    {
      key: "6",
      label: "Room",
      children: (
        <RoomDetails patientID={patientData?.patientID} TCIPID={TCIPID} />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "9",
      label: "Nursing Service",
      children: (
        <NursingService patientID={patientData?.patientID} TCIPID={TCIPID} />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "10",
      label: "Physiotherapy",
      children: (
        <Physiotherapy patientID={patientData?.patientID} TCIPID={TCIPID} />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
    {
      key: "5",
      label: "Patient History",
      children: <PreviousMedicalHistory patientID={patientData?.patientID} />,
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },

    {
      key: "8",
      label: "Discharge",
      children: (
        <DischargeSummary patientID={patientData?.patientID} TCIPID={TCIPID} />
      ),
      visibleToRoles: [Role.Admin, Role.Nurse, Role.Doctor, Role.Mod],
    },
  ];

  const filteredTabItems: TabsProps["items"] = tabItems.filter((tabItem) =>
    isRoleBasedVisible(tabItem.visibleToRoles)
  );

  // SHORT_CIRCUIT
  if (!filteredTabItems.length) return null;

  return (
    <Card>
      <Tabs
        items={filteredTabItems}
        destroyInactiveTabPane
        activeKey={activeKey}
        onChange={setActiveKey}
      />
    </Card>
  );
}

export default InPatientTabsCard;

function WIP() {
  return (
    <Card>
      <Typography.Title
        level={4}
        type="secondary"
        style={{ textAlign: "center" }}
      >
        Work In Progress
      </Typography.Title>
    </Card>
  );
}
