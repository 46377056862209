import type { FormRule } from "antd";
import { Card, Form, Skeleton, Typography } from "antd";
import { isNumber } from "../../../../util";

const { Title, Text } = Typography;

export enum EVitalStatus {
  SAFE = "SAFE",
  URGENT = "URGENT",
  CRITICAL = "CRITICAL",
}

export interface IVitalConfig {
  key: string;
  title: string;
  statusFun: (value: string | number) => keyof typeof EVitalStatus;
  unit: string;
  inputs: number;
  dependencies?: string[];
  formatter?: (values: any) => string;
  rules?: FormRule[];
}

type Props = {
  config: IVitalConfig;
  isEditable: boolean;
  loading?: boolean;
};

function VitalCard({ config, isEditable, loading }: Props) {
  return (
    <Card>
      <Text style={{ textAlign: "center" }}>
        {config.title}
      </Text>

      {loading ? (
        <Skeleton active paragraph={false} />
      ) : config.dependencies && config.formatter ? (
        <Form.Item noStyle dependencies={config.dependencies}>
          {(props) => {
            if (config.formatter) {
              const values = props.getFieldsValue();
              const value = config.formatter(values);
              props.setFieldValue(config.key, value);
            }
            return (
              <Form.Item name={config.key} rules={config.rules ?? defaultRules}>
                <VitalInput isEditable={false} unit={config.unit} />
              </Form.Item>
            );
          }}
        </Form.Item>
      ) : (
        <Form.Item name={config.key} rules={config.rules ?? defaultRules}>
          <VitalInput isEditable={isEditable} unit={config.unit} />
        </Form.Item>
      )}
    </Card>
  );
}

export default VitalCard;

type VitalInputProps = {
  unit: string;
  isEditable: boolean;
  onChange?: (value: string) => void;
  value?: string;
};

function VitalInput({ onChange, value, isEditable, unit }: VitalInputProps) {
  return (
    <Title
      level={4}
      style={{ margin: 0, textAlign: "center" }}
      editable={
        isEditable
          ? {
              icon: <Text>{unit}</Text>,
              enterIcon: null,
              onChange: onChange,
              triggerType: ["text", "icon"],
            }
          : false
      }
      copyable={
        !isEditable
          ? {
              icon: [<Text>{unit}</Text>, <Text>{unit}</Text>],
            }
          : false
      }
    >
      {value}
    </Title>
  );
}

const defaultRules = [
  {
    validator(_: any, value: any) {
      if (value && !isNumber(value)) return Promise.reject();
      return Promise.resolve();
    },
    message: "Please Enter Valid Number",
  },
];
