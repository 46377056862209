import {
  Modal,
  Skeleton,
  Space,
  Spin,
  Table,
  TableProps,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormatDate, FormatDateTime } from "../../../Utils/FormatDateTime";
import { useIsAllowed } from "../../../hooks/role.hooks";
import { statusColorMap } from "../../../models/Colors";
import { PRIVILEGE, Role } from "../../../models/PrivilegeRoles";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetAllPatientsQuery,
  useGetDischageSummaryListQuery,
  useGetOutPatientBYPatientIDQuery,
  useGetPatientQuery,
} from "../../../services/ApiSlice";
import { RootState } from "../../../store";
import AddAdmissionModal from "../../../components/modelpopup/AddAdmissionModal";

const { Title, Text } = Typography;

const PatientTab: React.FC<{}> = () => {
  const user = useSelector((state: RootState) => selectUser(state));
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);

  const { patientID }: any = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isAllowed = useIsAllowed();

  const isRoleBasedVisible = (role: any) => {
    if (role.includes(user?.himsRoleID)) {
      return true;
    } else {
      return false;
    }
  };

  const { data: allPatients, isLoading: personalInformation } =
    useGetAllPatientsQuery();

  const patientDataID =
    allPatients?.find((option: any) => option?.uhid === patientID)?.patientID ||
    0;
  const { data = {}, error, isLoading } = useGetPatientQuery(patientID);
  const { data: source } = useGetDischageSummaryListQuery(patientID);
  const { data: outPatient } = useGetOutPatientBYPatientIDQuery({
    patientID: patientID,
    locationID: 1,
  });
  const { address = {}, diseases = [] } = data || {};
  const personalDetails: any = [
    {
      UHID: data?.uhid,
      FirstName: data?.firstName,
      LastName: data?.lastName,
      Email: data?.emailID,
      Mobile: data?.phoneNo,
      Gender: data?.gender,
      // DOB: data?.dob ? dayjs(data?.dob).format("DD-MM-YYYY") : null,
      Age: data?.age,
      BloodGroup: data?.bloodGroup,
      // Diseases: data.diseases,
    },
  ];
  const addressDetails: any = [
    {
      AddressLine1: address?.addressLine1,
      AddressLine2: address?.addressLine2,
      LandMark: address?.landMark,
      FlatNo: address?.flatNumber,
    },
  ];
  const areaDetails: any = [
    {
      City: address?.city,
      District: address?.districtName,
      Pincode: address?.pinCode,
      State: address?.stateName,
    },
  ];
  const diseaseDetails: any = [
    {
      Disease: diseases?.at(0)?.diseaseName,
      Description: diseases?.at(0)?.description,
      Comments: data?.comments,
    },
  ];

  const [allowNewIP, activeTCIPID] = useMemo(() => {
    if (Array.isArray(source) && source.length) {
      const ip = source.find(({ dischargeDate }) => !dischargeDate); // TODO: need to replace with dischargeDate
      if (ip) return [false, ip.tcipid];
    }
    return [true, null];
  }, [source]);

  const handleViewIPDetails = () => {
    if (activeTCIPID) navigate(`/inpatient-details/${activeTCIPID}`);
    else console.error("Internal error");
  };

  const handleAppointment = () => {
    navigate(`/patients/${patientID}/book-appointment`);
  };
  const handleGetPayments = () => {
    navigate(`/payment-details/${patientID}`);
  };
  const handleAddAdmission = () => {
    setIsModelOpen(true);
  };

  const handleGetDischargeSummary = () => {
    navigate(`/discharge-summary/${patientID}`);
  };
  const columns: any = [
    {
      title: "Admission ID",
      dataIndex: "tcipid",
      render: (text: string, record: any) => (
        <Link className="hyperlink" to={`/inpatient-details/${record.tcipid}`}>
          {text}
        </Link>
      ),
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid?.length - b.tcipid?.length,
    },
    {
      title: "Room No",
      dataIndex: "roomNo",
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (a: { roomNo: string }, b: { roomNo: string }) =>
        a.roomNo?.length - b.roomNo?.length,
    },
    {
      title: "Bed No",
      dataIndex: "bedNo",
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (a: { bedNumber: string }, b: { bedNumber: string }) =>
        a.bedNumber?.length - b.bedNumber?.length,
    },
    {
      title: "Admission Date",
      dataIndex: "admissionDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (a: { admissionDate: string }, b: { admissionDate: string }) =>
        a.admissionDate?.length - b.admissionDate?.length,
    },
    {
      title: "Discharge Date",
      dataIndex: "dischargeDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (a: { dischargeDate: string }, b: { dischargeDate: string }) =>
        a.dischargeDate?.length - b.dischargeDate?.length,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <>
          {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
            record?.opStatus !== "Completed" && (
              <Link to={`/IPpayment-details/${record.tcipid}`}>
                <span className="link">Pay</span>
              </Link>
            )}
        </>
      ),
      sorter: true,
    },
  ];
  const outPatientColums: TableProps<IOutPatient>["columns"] = [
    {
      title: "Appointment ID",
      dataIndex: "tcopid",
      render: (text: string, record: any) => (
        <Link className="hyperlink" to={`/details-tab/${record.tcopid}`}>
          <span>{text || "No UHID"}</span>
        </Link>
      ),
      sorter: (a: { patientUHID: string }, b: { patientUHID: string }) =>
        a.patientUHID?.length - b.patientUHID?.length,
    },
    {
      title: "Patient Name",
      key: "Patient",
      render: ({ patientName, patientMobileNumber }) => (
        <Space direction="vertical" size={0}>
          {patientName && <Text strong>{patientName}</Text>}
          {patientMobileNumber && (
            <Text type="secondary">{patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patientName: string }, b: { patientName: string }) =>
        a.patientName?.length - b.patientName?.length,
    },
    {
      title: "Scheduled Date",
      dataIndex: "appointmentTime",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      // sorter: (a, b) => new Date(a.appointmentTime) > new Date(b.appointmentTime),
    },
    {
      title: "Doctor Name",
      key: "Doctor",
      render: ({ doctorName, docSpecialization }) => (
        <Space direction="vertical" size={0}>
          {doctorName && <Text strong>{doctorName}</Text>}
          {docSpecialization && (
            <Text type="secondary">{docSpecialization}</Text>
          )}
        </Space>
      ),
      sorter: (a: { doctorName: string }, b: { doctorName: string }) =>
        a.doctorName?.length - b.doctorName?.length,
    },
    // {
    //   title: "Payment Status",
    //   key: "opStatus",
    //   render: ({ requestStatus }) => (
    //     <Tag color={paymentStatusColorMap[requestStatus?.toUpperCase()]}>{requestStatus}</Tag>
    //   ),
    // },
    {
      title: "Status",
      key: "Status",
      render: ({ opStatus }) => (
        <Tag color={statusColorMap[opStatus]}>{opStatus}</Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <div>
          {record.opStatus !== "CANCELLED" && (
            <>
              <Space>
                {/* <Link to={`/details-tab/${record.tcopid}`}>
                  <span className="link">View</span>
                </Link> */}
                {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                  record?.opStatus !== "Completed" && (
                    <Link to={`/payment-details/${record.tcopid}`}>
                      <span className="link">Pay</span>
                    </Link>
                  )}
              </Space>
            </>
          )}
        </div>
      ),
      sorter: true,
    },
  ];
  return (
    <>
      {" "}
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"></div>
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>

          {/* <Breadcrumbs
            maintitle="Patient Details"
            title="Home"
            subtitle="Patient Details"
            modal="#add_holiday"
            name="Add Admission"
          /> */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Patient Details </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/patients">Patient Registration</Link>
                  </li>
                  <li className="breadcrumb-item active">Patient Details</li>
                </ul>
              </div>

              <div className="col-auto float-end ms-auto">
                {/* {isAllowed(PRIVILEGE.Client_View_AddAdmission) && (
                  <Link
                    to="#"
                    className="btn add-btn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#add_holiday"
                    onClick={handleAddAdmission}
                  >
                    <i className="fa fa-plus" />
                    Add IP Admission
                  </Link>
                )} */}

                {isAllowed(PRIVILEGE.Client_View_BookAppointment) && (
                  <button
                    type="button"
                    className="btn add-btn mx-3"
                    onClick={handleAppointment}
                  >
                    {" "}
                    <i className="fa fa-plus" />
                    OP Appointment
                  </button>
                )}

                <Link
                  to="/lab-requests/new-request"
                  state={{ from: location.pathname, patientID, flag: 1 }}
                  className="btn add-btn"
                >
                  <i className="fa-solid fa-user-pen" /> Add Tests
                </Link>

                {isAllowed(PRIVILEGE.Client_View_EditPatient) && (
                  <Link to="update" className="btn add-btn mx-3">
                    <i className="fa-solid fa-user-pen" /> Edit
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              id="emp_patient"
              className="pro-overview tab-pane fade show active"
            >
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    {/* <Spin spinning={personalInformation} fullscreen /> */}

                    <div className="card-body">
                      <h3 className="card-title">
                        Personal Information{" "}
                        {/* <Link
                          to="#"
                          className="edit-icon"
                          onClick={() => {
                            setIsModelOpen(true);
                          }}
                        >
                          <i className="fa fa-pencil" />
                        </Link> */}
                      </h3>
                      <ul className="personal-info">
                        {Array.isArray(personalDetails) ? (
                          personalDetails.map((item: any, index: any) => (
                            <>
                              {/* <Skeleton loading={isLoading} active  > */}
                              <ListItem
                                key={index}
                                data={item}
                                isLoading={isLoading}
                              />
                              {/* </Skeleton> */}
                            </>
                          ))
                        ) : (
                          <ListItem
                            data={personalDetails}
                            isLoading={isLoading}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">Contact Address </h3>
                      <ul className="personal-info">
                        {Array.isArray(addressDetails) ? (
                          addressDetails.map((item: any, index: any) => (
                            <ListItem
                              key={index}
                              data={item}
                              isLoading={isLoading}
                            />
                          ))
                        ) : (
                          <ListItem
                            data={addressDetails}
                            isLoading={isLoading}
                          />
                        )}
                      </ul>
                      <ul className="personal-info">
                        {Array.isArray(areaDetails) ? (
                          areaDetails.map((item: any, index: any) => (
                            <ListItem
                              key={index}
                              data={item}
                              isLoading={isLoading}
                            />
                          ))
                        ) : (
                          <ListItem data={areaDetails} isLoading={isLoading} />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">Remarks</h3>
                      <ul className="personal-info">
                        {Array.isArray(diseaseDetails) ? (
                          diseaseDetails.map((item: any, index: any) => (
                            <ListItem key={index} data={item} />
                          ))
                        ) : (
                          <ListItem data={diseaseDetails} />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="card card-table flex-fill">
            <Spin spinning={personalInformation} />
            <Tabs
              style={{ overflowX: "auto" }}
              defaultActiveKey="2"
              type="card"
              items={[
                {
                  key: "1",
                  label: <Title level={4}>IP</Title>,
                  children: (
                    <Table
                      pagination={source?.length > 10 ? undefined : false}
                      columns={columns}
                      dataSource={source}
                      rowKey={(record) => record.id}
                    />
                  ),
                },
                {
                  key: "2",
                  label: <Title level={4}>OP</Title>,
                  children: (
                    <Table
                      pagination={outPatient?.length > 10 ? undefined : false}
                      columns={outPatientColums}
                      dataSource={outPatient}
                      rowKey="outPatientID"
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {/* <AddAdmission patientID={patientID} /> */}
      {/* <AddHoliday /> */}
      {/* <AddPatient patientID={patientID} /> */}
      {/* <AddPatientModel
        patientID={patientID}
        isModelOpen={isModelOpen}
        onClose={() => {
          setIsModelOpen(false);
        }}
      /> */}
      <Modal
        title={
          <Space>
            <i
              className="la la-exclamation-circle"
              style={{ fontSize: "25px", color: "red" }}
            ></i>
            <Text>Warning</Text>
          </Space>
        }
        open={isModelOpen && !allowNewIP}
        okText="View Details"
        cancelText="Close"
        onOk={() => {
          handleViewIPDetails();
        }}
        onCancel={() => setIsModelOpen(false)}
      >
        <Title level={4}>
          You already have an active IP admission{" "}
          {activeTCIPID && `(${activeTCIPID})`} for this patient
        </Title>
      </Modal>
      <AddAdmissionModal
        patientID={patientID}
        isModelOpen={isModelOpen && allowNewIP}
        onClose={() => {
          setIsModelOpen(false);
        }}
      />
    </>
  );
};
export const ListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({
  data,
  isLoading = false, // Default value for isLoading prop
}) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  // Render actual data
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <li>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            {isLoading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 300 }}
              />
            ) : (
              <div className="text">{value}</div>
            )}
          </li>
        ))}
      </div>
    </>
  );
};

export default PatientTab;
