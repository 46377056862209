import { skipToken } from "@reduxjs/toolkit/query";
import {
  Flex,
  Form,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Typography,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { razor_pay_cancle, razor_pay_device_cancle } from "../../../base_urls";
import { AddPayment } from "../../../components/modelpopup/AddPayment";
import { paymentStatusColorMap } from "../../../models/Colors";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useAddPatientDiscountMutation,
  useDelPatientDiscountMutation,
  useGetAllCouponsQuery,
  useGetApprovedDoctorsQuery,
  useGetBillingGroupQuery,
  useGetCountsForLabBillingQuery,
  useGetDischargeSummaryQuery,
  useGetDiscountDetailsQuery,
  useGetDropdownQuery,
  useGetIPBillingDetailsBYTCIPIDQuery,
  useGetLabBillingDetailsQuery,
  useGetPatientQuery,
  useGetPaymentDetailsByLabBillingIDQuery,
  useGetPaymentsQuery,
  useLazyDownloadLabReportQuery,
  useLazyGetChargesByBillingGroupQuery,
  useLazyGetDownloadPaymentDetailsQuery,
} from "../../../services/ApiSlice";
import LabPatientCard from "./LabPatientDetailsCard";

const LabBillingDetails = () => {
  const { Text } = Typography;
  const { confirm } = Modal;
  const location = useLocation();
  console.log(location.state?.record);
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [disValue, setDisValue] = useState<number | null>(null);
  const GetPaymentDetail = useGetPaymentDetailsByLabBillingIDQuery(
    location.state?.record?.billingID ?? skipToken
  );
  const [dueAmountAfterDiscount, setDueAmountAfterDiscount] = useState<
    number | null
  >(null);
  const [dueAmount, setDueAmount] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id }: any = useParams();
  const user = useSelector(selectUser);
  const [formInstance] = Form.useForm();
  const isIP = id.includes("IP");
  const { data: patientDetails, isLoading } = useGetDischargeSummaryQuery(id);
  const PatientDetails = useGetPatientQuery(id ?? skipToken);
  const GetLabBillingDetails = useGetLabBillingDetailsQuery(
    location.state?.record?.billingID ?? skipToken
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [couponData, setCouponData] = useState<Coupon | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<{
    billingGroupID: number;
    billingGroup: string;
  } | null>(null);
  const {
    data,
    refetch,
    isFetching: getPaymentdetails,
  } = useGetPaymentsQuery(id ?? skipToken);
  const GetApprovedDoctors = useGetApprovedDoctorsQuery();
  const [cancledStatus, setCancledStatus] = useState(true);
  const { data: allBills, isFetching: isAllBillsFetching } =
    useGetIPBillingDetailsBYTCIPIDQuery(id);

  const {
    data: dashboardCountsforBilling,
    isFetching: isDashboardCountsforBilling,
  } = useGetCountsForLabBillingQuery(
    location.state?.record?.billingID ?? skipToken
  );

  const { data: billingCount, isFetching: isBillingCountFetching } =
    useGetIPBillingDetailsBYTCIPIDQuery(id);
  const [addPatientDiscount, AddPatientDiscount] =
    useAddPatientDiscountMutation();
  const DiscountDetails = useGetDiscountDetailsQuery(
    location.state?.record?.billingTypeRefID ?? skipToken
  );
  const [deleteDiscount, DeleteDiscount] = useDelPatientDiscountMutation();

  const [
    getPaymentDetails,
    {
      data: printData,
      isFetching: isPrintDataFetching,
      isSuccess: isPrintDataSuccess,
      isLoading: downloadPrintData,
    },
  ] = useLazyGetDownloadPaymentDetailsQuery();

  const [
    getDownloadBill,
    {
      data: BillData,
      isLoading: downloadPrintDataBill,
      isSuccess: downloadIPBillSuccess,
    },
  ] = useLazyDownloadLabReportQuery();
  const CouponUnit = useGetDropdownQuery("CouponUnit");
  console.log(CouponUnit.data);
  const handleDownloadPaymentDetails = (record: any) => {
    getPaymentDetails(record?.paymentDetailsID);
  };
  const onDownloadInPatientBill = () => {
    getDownloadBill(location.state?.record?.billingID);
  };
  const DiscountCoupons = useGetAllCouponsQuery();
  const BillingGroup = useGetBillingGroupQuery();
  const [getBillingGroupCharges, GetBillingGroupCharges] =
    useLazyGetChargesByBillingGroupQuery();

  // const totalDiscount = useMemo(
  //   () =>
  //     GetBillingGroupCharges.data?.price && couponData?.couponDiscountPer
  //       ? GetBillingGroupCharges.data?.price *
  //         (couponData?.couponDiscountPer / 100)
  //       : undefined,
  //   [GetBillingGroupCharges, couponData]
  // );

  const handleDiscountTypeChange = (e: RadioChangeEvent) => {
    console.log(e.target.value);
    setDiscountType(e.target.value);
    setDiscountValue(null);
    setDueAmountAfterDiscount(null);
    formInstance.setFieldsValue({ flatAmount: null, percentageAmount: null });
  };

  const discountOnOptions = useMemo(
    () =>
      Array.isArray(DiscountCoupons.data)
        ? DiscountCoupons.data.map(({ couponCode }) => ({
            value: couponCode,
            label: couponCode,
          }))
        : [],
    [DiscountCoupons]
  );
  // const billingGroup = useMemo(
  //   () =>
  //     Array.isArray(BillingGroup.data)
  //       ? BillingGroup.data.map(({ billingGroupID, billingGroup }) => ({
  //           value: billingGroupID,
  //           label: billingGroup,
  //         }))
  //       : [],
  //   [BillingGroup]
  // );
  const billingGroup = useMemo(
    () =>
      Array.isArray(BillingGroup.data)
        ? BillingGroup.data
            .filter(
              ({ billingGroupID }) =>
                billingGroupID === 0 || billingGroupID === 2
            )
            .map(({ billingGroupID, billingGroup }) => ({
              value: billingGroupID,
              label: billingGroup,
            }))
        : [],
    [BillingGroup]
  );
  const approvedDoctors = useMemo(
    () =>
      Array.isArray(GetApprovedDoctors.data)
        ? GetApprovedDoctors.data.map(({ doctorID, doctorName }) => ({
            value: doctorID,
            label: doctorName,
          }))
        : [],
    [GetApprovedDoctors]
  );

  const totalDiscountString = localStorage.getItem("Discount");
  const totalDiscountValue =
    totalDiscountString !== null ? parseInt(totalDiscountString) : 0;

  const statsData = [
    {
      id: 1,
      title: "Total Amount",

      value: (
        <>
          {dashboardCountsforBilling?.totalAmount !== 0 && "₹ "}
          {dashboardCountsforBilling?.totalAmount ?? 0}
        </>
      ),
      //value: dashboardCountsforBilling?.totalAmount ?? 0,
      subText: "",
    },
    {
      id: 2,
      title: "Paid Amount",
      value: (
        <>
          {dashboardCountsforBilling?.paidAmount !== 0 && "₹ "}
          {dashboardCountsforBilling?.paidAmount ?? 0}
        </>
      ),
      // value: dashboardCountsforBilling?.paidAmount ?? 0,
      subText: "",
    },
    {
      id: 3,
      title: "Discount",
      value: (
        <>
          {dashboardCountsforBilling?.discountValue !== 0 && "₹ "}
          {dashboardCountsforBilling?.discountValue ?? 0}
          {/* {totalDiscountValue !== 0 && "₹ "}
          {totalDiscountValue ?? 0} */}
        </>
      ),
      // value: dashboardCountsforBilling?.dueAmount ?? 0,
      subText: "",
    },
    {
      id: 4,
      title: "Due Amount",
      value: (
        <>
          {/* {totalDiscountValue
            ? "₹ " +
              (dashboardCountsforBilling?.totalAmount -
                (dashboardCountsforBilling?.paidAmount + totalDiscountValue))
            : "₹ " + dashboardCountsforBilling?.dueAmount ?? 0} */}
          {dashboardCountsforBilling?.dueAmount !== 0 && "₹ "}
          {dashboardCountsforBilling?.dueAmount ?? 0}
        </>
      ),
      // value: dashboardCountsforBilling?.dueAmount ?? 0,
      subText: "",
    },
  ];

  const billingcolumns: TableProps<IBills>["columns"] = [
    {
      title: "Billing Date",
      key: "createdDate",
      dataIndex: "createdDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
    },
    {
      title: "Lab",
      key: "billingGroup",
      // dataIndex: "billingGroup",
      render: ({ code, testName }) => (
        <Space direction="vertical" size={0}>
          {testName && (
            <Text strong style={{ whiteSpace: "normal" }}>
              {testName}
            </Text>
          )}
          {code && <Text type="secondary">{code}</Text>}
        </Space>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (text: number) => <span>{"₹ " + text || "-"}</span>,
    },
    {
      title: "Added By",
      dataIndex: "modifiedBy",
      render: (text: number) => <span>{text || "-"}</span>,
    },
  ];
  const defaultCheckedList = billingcolumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = billingcolumns.filter(({ key }) =>
    checkedList.includes(key as string)
  );

  const filteredPatients = useMemo(() => {
    if (Array.isArray(allBills)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allBills?.filter(
        (patient: any) =>
          patient.id?.toLowerCase().trim().includes(searchString) ||
          patient.patientName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allBills, searchQuery]);

  useEffect(() => {
    if (selectedGroup?.billingGroup) {
      getBillingGroupCharges({
        tcipID: location.state?.record?.billingTypeRefID,
        billingGroupID: selectedGroup?.billingGroupID,
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (AddPatientDiscount.isSuccess) {
      if (AddPatientDiscount.data) {
        notification["success"]({
          message: "Successfully added discount",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
        formInstance.resetFields();
        onCancel();
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
        onCancel();
      }
    }
  }, [AddPatientDiscount]);

  useEffect(() => {
    console.log(printData);
    if (printData) {
      window.open(printData);
    }
  }, [printData]);

  useEffect(() => {
    if (BillData) {
      window.open(BillData);
    }
  }, [BillData]);

  // useEffect(() => {
  //   localStorage.removeItem("Discount");
  //   localStorage.removeItem("AddDiscount");
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCancle = async (
    PaymentDetailsID: string,
    userName: string,
    p2pRequestId: string
  ) => {
    try {
      const cancleDeviceRequestData = {
        username: userName,
        origP2pRequestId: p2pRequestId,
        pushTo: {
          deviceId: "null|ezetap_android",
        },
      };
      const cancleRequestData = {
        PaymentDetailsID: PaymentDetailsID,
      };
      const cancleDevicePayment = await fetch(razor_pay_device_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleDeviceRequestData),
      });

      const responseData = await cancleDevicePayment.json();
      if (responseData.success) {
        setCancledStatus(false);
      }

      const canclePayment = await fetch(razor_pay_cancle, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify(cancleRequestData),
      });
      if (!canclePayment.ok) {
        throw new Error("Failed to post p2p data to response status API");
      }
      if (!cancleDevicePayment.ok) {
        throw new Error(
          "Failed to post cancle device api  to response status API"
        );
      }
      // Handle success
    } catch (error) {
      console.error("Failed to post data to second API:", error);
    }
    refetch();
  };

  const handleAddDiscount = () => {
    // setDiscountType("percentage");
    setIsModelOpen(true);
  };
  const handleDelete = (patientDisID: number) => {
    confirm({
      title: "Are you sure delete the discount ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteDiscount({
          PateintDisId: patientDisID,
          CreatedBy: user?.himsEmployeeID,
        });
      },
    });
  };

  const handleFlatAmountChanged = (amount: number | null) => {
    if (amount) {
      setDiscountValue(amount);
      setDisValue(amount);
      setDueAmount(dashboardCountsforBilling?.dueAmount - amount);
      setDueAmountAfterDiscount(dashboardCountsforBilling?.dueAmount - amount);
    }
  };
  const hanldePercentageChanged = (percentage: number | null) => {
    formInstance.validateFields(["discountPer"]);
    let amount = null;
    if (percentage) {
      amount = GetBillingGroupCharges.data?.price * (percentage / 100);
      // setDueAmountAfterDiscount(dashboardCountsforBilling?.dueAmount - amount);
      setDueAmount(dashboardCountsforBilling?.dueAmount - amount);
    }

    // setDiscountValue(amount);
    setDisValue(amount);
  };

  const handleCouponCodeChange = (value: string) => {
    if (DiscountCoupons.data) {
      const selectedCoupon = DiscountCoupons.data.find(
        (coupon: Coupon) => coupon.couponCode === value
      );
      if (selectedCoupon) {
        setCouponData(selectedCoupon);
      } else {
        setCouponData(null);
      }
    }
  };
  const handleBillingGroupChange = (value: number) => {
    if (BillingGroup.data) {
      console.log(value);
      const selectedGroup = BillingGroup.data.find(
        (group: { billingGroupID: number; billingGroup: string }) =>
          group.billingGroupID === value
      );
      console.log(selectedGroup);
      if (selectedGroup) {
        setSelectedGroup(selectedGroup);
      } else {
        setSelectedGroup(null);
      }
    }
    setDiscountValue(null);
    setDueAmountAfterDiscount(null);
    setDiscountType(null);
    formInstance.setFieldsValue({ discountType: null });
  };

  const handleClick = (
    detailsID: any,
    paymentFor: any,
    paymentMode: any,
    amount: any
  ) => {
    navigate("/payment-add-tab", {
      state: {
        value1: detailsID,
        value2: paymentFor,
        value3: paymentMode,
        value4: amount,
      },
    });
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setCouponData(null);
    setSelectedGroup(null);
    setDiscountValue(null);
    setDueAmountAfterDiscount(null);
    setDiscountType(null);
  };

  const onAddDiscount = async () => {
    confirm({
      title: `The due amount is ₹  ${dueAmount}`,
      content: `Are you sure to continue adding a discount of ₹ ${disValue}?`,
      centered: true,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        try {
          const values: IAddPatientDiscount =
            await formInstance.validateFields();
          try {
            const isIP = id.includes("IP");
            await addPatientDiscount({
              ...values,
              tcipid: isIP ? location.state?.record?.billingTypeRefID : null,
              tcopid: isIP ? null : location.state?.record?.billingTypeRefID,
              createdBy: user?.himsEmployeeID,
              discountValue: disValue ? disValue : undefined,
              uhid: location.state?.record?.billingTypeRefID,
              billingTypeRefID: id,
              billingGroupID: selectedGroup?.billingGroupID
                ? selectedGroup?.billingGroupID
                : 0,
            }).unwrap();

            // Optionally, refetch data after successful post
            //  await refetch();
          } catch (error) {
            console.error("Failed to post data:", error);
          }
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      },
    });
  };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "uhid",
      render: (text: string) => <span>{text || "No Payment ID"}</span>, // Provide default value if firstName is empty
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid.length - b.tcipid.length,
    },
    // {
    //   title: "Payment For",
    //   dataIndex: "paymentFor",
    //   render: (text: string) => <span>{text || "No Payment"}</span>, // Provide default value if firstName is empty
    //   sorter: (a: { firstName: string }, b: { firstName: string }) =>
    //     a.firstName.length - b.firstName.length,
    // },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      render: (text: string) => <span>{text || "No Payment Mode"}</span>, // Provide default value if firstName is empty
      sorter: (a: { paymentMode: string }, b: { paymentMode: string }) =>
        a.paymentMode.length - b.paymentMode.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: string) => <span>{text || "No Amount"}</span>, // Provide default value if lastName is empty
      sorter: (a: { lastName: string }, b: { lastName: string }) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Date",
      dataIndex: "paymentDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (
        a: { paymentDate: string | any[] },
        b: { paymentDate: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.paymentDate.length - b.paymentDate.length;
      },
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus: string) => (
        <Tag color={paymentStatusColorMap[paymentStatus?.toUpperCase()]}>
          {paymentStatus}
        </Tag>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text: any, record: any) => (
    //     <Space>
    //       <Link to={""}>
    //         <span
    //           className="link"
    //           onClick={() => handleDownloadPaymentDetails(record)}
    //         >
    //           {/* <i className="las la-print"></i> */}
    //           Invoice
    //         </span>
    //       </Link>
    //       {[88, 89].includes(record.paymentStatusDDSID) && (
    //         <Link to={""}>
    //           <span
    //             className="link"
    //             onClick={() => {
    //               handleCancle(
    //                 record.paymentDetailsID,
    //                 record.deviceUserName,
    //                 record.p2PRequestID
    //               );
    //             }}
    //           >
    //             cancel
    //           </span>
    //         </Link>
    //       )}
    //     </Space>
    //   ),
    //   sorter: true,
    // },
  ];

  const discountColumns: any = [
    {
      title: "ID",
      // dataIndex: location.state?.record?.billingTypeRefID,
      render: () => location.state?.record?.billingTypeRefID,
    },
    {
      title: "Billing Group",
      dataIndex: "billingGroup",
    },
    {
      title: "Discount",
      // dataIndex: "discountType",
      render: (record: any) => (
        <Space direction="vertical" size={0}>
          {record?.discountType && <Text strong>{record?.discountType}</Text>}
          {record?.discountPer && (
            <Text type="secondary">{record?.discountPer + "%"}</Text>
          )}
          {record?.flatAmount && (
            <Text type="secondary">{"₹ " + record?.flatAmount}</Text>
          )}
        </Space>
      ),
    },
    // {
    //   title: "Discount Per",
    //   dataIndex: "discountPer",
    //   render: (text: any) => (text ? text + "%" : "-"),
    // },
    // {
    //   title: "Discount Amount",
    //   dataIndex: "discountValue",
    //   render: (text: any) => "₹ " + text,
    // },
    {
      title: "Approved By",
      // dataIndex: "approvedDoctorName",
      render: (record: any) => (
        <Space direction="vertical" size={0}>
          {record?.approvedDoctorName && (
            <Text strong>{record?.approvedDoctorName}</Text>
          )}
          {record?.speciality && (
            <Text type="secondary">{record?.speciality}</Text>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      render: (_: any, record: any) => (
        <Link to={""}>
          <span
            className="link"
            onClick={() => {
              handleDelete(record.patientDisID);
            }}
          >
            Delete
          </span>
        </Link>
      ),
    },
  ];

  const emptyRow = (
    <tr>
      <td colSpan={columns.length}>No data available</td>
    </tr>
  );

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin
            spinning={
              downloadPrintData ||
              downloadPrintDataBill ||
              isDashboardCountsforBilling ||
              DeleteDiscount.isLoading
            }
            fullscreen
          />

          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={handleRefresh}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  Lab Billing Info
                  {/* {isIP ? "Admissions" : "Appointments"} */}
                </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="hyperlink" to={"/lab-billing"}>
                      Billing
                      {/* {isIP ? "Admissions" : "Appointments"} */}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active"> Lab Billing Info</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn"
                  onClick={onDownloadInPatientBill}
                >
                  {" "}
                  Download Lab Test
                </button>
              </div>
              {
                <div className="col-auto float-end ms-auto">
                  <button
                    type="button"
                    className="btn add-btn mx-3"
                    onClick={handleAddDiscount}
                  >
                    {" "}
                    <i className="fa fa-plus" />
                    Add Discount
                  </button>
                </div>
              }
              {
                <div className="col-auto float-end ms-auto">
                  <button
                    type="button"
                    className="btn add-btn mx-3"
                    data-bs-toggle="modal"
                    data-bs-target="#add_payment"
                  >
                    {" "}
                    <i className="fa fa-plus" />
                    Add Payment
                  </button>
                </div>
              }
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {isLoading ? (
                <Skeleton
                  active
                  // paragraph={{ rows: 2, width: 300 }}
                  title={false}
                />
              ) : (
                <LabPatientCard
                  patientDetails={{
                    ...PatientDetails.data,
                    location: "Madhapur",
                  }}
                />
              )}
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col">
              Billing ID : {location.state?.record?.billingID}
            </div>
            {/* <div className="col-auto float-end ms-auto mb-3">
              {isIP ? "IP" : "OP"} : {id}
            </div> */}
          </div>
          {/* <div className="row">
            <div className="col-auto float-end ms-auto mb-3">
              Patient : {data?.[0]?.firstName + " " + data?.[0]?.lastName}
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <div>
                  <div className="row">
                    {statsData.map((stat, index) => (
                      <div className="col-md-3 d-flex green" key={index}>
                        <div className="stats-info w-100 ">
                          <h5 className="bolder">{stat.title}</h5>
                          {stat.subText ? (
                            <h3 className="bolder">
                              <span>{stat.value} </span>
                              <span className="textval">{stat.subText}</span>
                            </h3>
                          ) : (
                            <h4 className="bolder">{stat.value}</h4>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Flex gap={24} vertical>
                  <h4>Billing Items</h4>
                  <Table
                    pagination={
                      GetLabBillingDetails.data?.length > 10 ? undefined : false
                    }
                    loading={GetLabBillingDetails.isFetching}
                    columns={billingcolumns}
                    dataSource={GetLabBillingDetails.data}
                    rowKey="outPatientID"
                  />
                  {DiscountDetails?.data?.length !== 0 && (
                    <>
                      <h4>Discount Items</h4>
                      <Table
                        pagination={
                          DiscountDetails.data?.length > 10 ? undefined : false
                        }
                        loading={DiscountDetails.isFetching}
                        columns={discountColumns}
                        dataSource={DiscountDetails?.data}
                      />
                    </>
                  )}
                  <h4>Payment Transactions</h4>
                  <Table
                    pagination={
                      GetPaymentDetail.data?.length > 10 ? undefined : false
                    }
                    loading={GetPaymentDetail.isLoading}
                    columns={columns}
                    dataSource={GetPaymentDetail.data}
                    className="table-striped"
                    rowKey={(record) => record.id}
                  />
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPayment tCIPID={id} billingID={location.state?.record?.billingID} />
      <Modal
        open={isModelOpen}
        centered
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            {"Add Discount"}
          </Title>
        }
        onCancel={onCancel}
        onOk={onAddDiscount}
        okText={"ADD"}
        maskClosable={false}
      >
        {" "}
        <Spin
          spinning={
            GetBillingGroupCharges.isFetching || DiscountCoupons.isFetching
          }
          size="large"
          tip="Loading..."
        >
          <Form
            form={formInstance}
            disabled={AddPatientDiscount.isLoading}
            layout="vertical"
          >
            <Form.Item
              name="discountOn"
              label="Discount On"
              rules={[{ required: true, message: "Please Enter discount on " }]}
            >
              <Select
                style={{ width: "100%" }}
                options={billingGroup}
                loading={BillingGroup.isFetching}
                onChange={handleBillingGroupChange}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
            {selectedGroup?.billingGroup && (
              <Form.Item name="discountAmount" label="Total Value">
                <Text type="secondary">
                  ₹ {GetBillingGroupCharges.data?.price}
                </Text>
              </Form.Item>
            )}
            <Form.Item
              name="discountTypeID"
              label="Discount Type"
              rules={[
                { required: true, message: "Please Select Discount Type" },
              ]}
            >
              <Radio.Group
                onChange={handleDiscountTypeChange}
                value={discountType}
              >
                {CouponUnit.data?.map((unit: any) => (
                  <Radio key={unit.dropDownSetID} value={unit.dropDownSetID}>
                    {unit.dropDownValue}
                  </Radio>
                ))}
              </Radio.Group>
              {/* <Radio.Group
                onChange={handleDiscountTypeChange}
                value={discountType}
              >
                <Radio value="percentage">Percentage (%)</Radio>
                <Radio value="flat">Flat Amount (₹)</Radio>
              </Radio.Group> */}
            </Form.Item>
            {discountType === 146 && (
              <Form.Item
                name="flatAmount"
                label="Flat Amount (₹)"
                style={{ width: "100%" }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={handleFlatAmountChanged}
                />
              </Form.Item>
            )}
            {discountType === 147 && (
              <Form.Item
                name="discountPer"
                label="Percentage (%)"
                style={{ width: "100%" }}
                rules={[
                  {
                    validator: async (_, value) => {
                      if (
                        !value ||
                        (value >= 0 && value <= 100 && Number.isInteger(value))
                      ) {
                        // Check if the input has exactly two digits
                        if (value && value.toString().length < 3) {
                          return Promise.resolve();
                        } else if (value && value.toString().length > 2) {
                          return Promise.reject(
                            new Error(
                              "Please input a valid percentage less than 100"
                            )
                          );
                        }
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Please input a valid number between 0 and 100"
                        )
                      );
                    },
                  },
                ]}
                validateTrigger="onChange"
              >
                <InputNumber
                  min={0}
                  max={100}
                  style={{ width: "100%" }}
                  onChange={hanldePercentageChanged}
                />
              </Form.Item>
            )}

            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[
                { required: true, message: "Please Enter Remarks" },
                { min: 3, message: "Remarks must be minimum 3 characters." },
              ]}
            >
              <TextArea
                placeholder="Please Enter Remarks"
                showCount
                maxLength={500}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>
            <Form.Item
              name="approvedDoctorID"
              label="Approved By "
              rules={[{ required: true, message: "Please Enter discount on " }]}
            >
              <Select
                style={{ width: "100%" }}
                options={approvedDoctors}
                loading={GetApprovedDoctors.isFetching}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
            {/* <Row>
              <Col>
                {selectedGroup?.billingGroup && discountValue && (
                  <Form.Item
                    name="discountValue"
                    label="Total Discount"
                    style={{ fontSize: "20px" }}
                  >
                    <Text
                      type="secondary"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      ₹ {discountValue}
                    </Text>
                  </Form.Item>
                )}
              </Col>
              <Col>
                {selectedGroup?.billingGroup &&
                  dueAmountAfterDiscount &&
                  discountValue && (
                    <Form.Item
                      name="totalDiscount"
                      label="Due Amount"
                      style={{ fontSize: "20px" }}
                    >
                      <Text
                        type="secondary"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        ₹ {dueAmountAfterDiscount}
                      </Text>
                    </Form.Item>
                  )}
              </Col>
            </Row> */}
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default LabBillingDetails;
