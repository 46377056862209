export const unitsList = [
  { label: "mg", value: "mg" },
  { label: "ml", value: "ml" },
];

export const toBeTakenList = [
  { label: "After food", value: "After food" },
  { label: "Before food", value: "Before food" },
  { label: "NA", value: "NA" },
  //   { label: "On empty stomach", value: 4 },
  //   { label: "Anytime", value: 5 },
  //   { label: "At bedtime", value: 6 },
];

export const frequencyList = [
  { label: "Daily", value: "Daily" },
  { label: "Alternate day", value: "Alternate day" },
  { label: "Once in a day", value: "Once in a day" },
  { label: "Twice in a day", value: "Twice in a day" },
  { label: "Thrice in a day", value: "Thrice in a day" },
  { label: "Once a week", value: "Once a week" },
  { label: "Twice a week", value: "Twice a week" },
  { label: "Thrice a week", value: "Thrice a week" },
  { label: "Once in 15 days", value: "Once in 15 days" },
  { label: "SOS/Whenever required", value: "SOS/Whenever required" },
];

export const durationList = [
  { label: "Day(s)", value: "Day(s)" },
  { label: "Week(s)", value: "Week(s)" },
  { label: "Month(s)", value: "Month(s)" },
  { label: "Year(s)", value: "Year(s)" },
  //   {  label: "Until next review", value: "Until next review", },
];

export const typeOfDrugList = [
  { label: "Oral", value: "Oral" },
  { label: "Intranasal", value: "Intranasal" },
  { label: "Topical", value: "Topical" },
  { label: "Subcutaneous", value: "Subcutaneous" },
  { label: "Sublingual", value: "Sublingual" },
  { label: "Intramuscular (IM)", value: "Intramuscular (IM)" },
  { label: "Intravenous (IV)", value: "Intravenous (IV)" },
  { label: "Eye", value: "Eye" },
  { label: "Ear", value: "Ear" },
  { label: "Rectal", value: "Rectal" },
];

export const noOfCapsulesList = [
  { label: "0.25", value: "0.25" },
  { label: "0.5", value: "0.5" },
  { label: "1", value: "1" },
  { label: "1/2", value: "1/2" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];
