/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { HOU, avatar, lnEnglish } from "../../Routes/ImagePath";
import { selectUser } from "../../reducers/UserSlice";
import { RootState } from "../../store";
import { SessionStorageKeys } from "../../shared/session-storage-keys";

const Header = (props: any) => {
  const [notification, setNotifications] = useState(false);
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [flagImage, setFlagImage] = useState(lnEnglish);
  const { instance, accounts } = useMsal();
  const user = useSelector((state: RootState) => selectUser(state));
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };
  const handleLogOut = () => {
    instance.logout();
    // localStorage.removeItem("User");
    localStorage.setItem(SessionStorageKeys.User, JSON.stringify(null));
  };

  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  const Credencial = localStorage.getItem("credencial");
  const Value = JSON.parse(Credencial ?? "{}");
  const UserName = Value?.email?.split("@")[0];
  const ProfileName = UserName?.charAt(0).toUpperCase() + UserName?.slice(1);

  const { t, i18n } = useTranslation();

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      <div className="header-left">
        <Link to="" className="logo">
          <img src={HOU} width={80} height={40} alt="img" />
        </Link>
      </div>
      {/* /Logo */}

      {/* Header Title */}
      {/* <div className="page-title-box">
        <h3 className="mt-1">HealthOnUs</h3>
      </div> */}
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
        {/* Search */}
        <li className="nav-item">
          <div className="top-nav-search">
            <Link to="#" className="responsive-search">
              <i className="fa fa-search" />
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown has-arrow main-drop" style={{marginRight:30}}>
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1">
              <img src={avatar} alt="img" />
              <span className="status online" />
            </span>
            <span className="profile-name">
              {user?.himsRoleID && `${user?.employeeName}`}
            </span>
            <span className="role-name">{user?.roleName}</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            <button className="dropdown-item" onClick={handleLogOut}>
              Logout
            </button>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          <Link className="dropdown-item" to="/profile">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/settings/companysetting">
            Settings
          </Link>
          <Link className="dropdown-item" to="/login">
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
