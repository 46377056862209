import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Col, Flex, Form, Row, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useGetOutPatientByOPIDQuery,
  useGetPatientQuery,
  useLazyGetDoctorSlotAvailabilityQuery,
  usePostOutPatientTimeSlotMutation,
} from "../../../services/ApiSlice";
import AttendeeForm from "./AttendeeForm.BookAppointment";
import DoctorsList from "./Doctors.BookAppointment";
import type { IFilters } from "./Filters.BookAppointment";
import Filters from "./Filters.BookAppointment";
import PatientCard from "./PatientCard.BookAppointment";

function BookAppointment() {
  const [showDoctorsList, setShowDoctorsList] = useState<boolean>(false);
  const [selectedDoctor, setSelectedDoctor] =
    useState<ISpecialistDetails | null>(null);
  const navigate = useNavigate();
  const [isSelfBooking, setIsSelfBooking] = useState<boolean>(true);

  const { patientID, tcOPID } = useParams();
  const PatientDetails = useGetPatientQuery(patientID ?? skipToken);
  const OPData = useGetOutPatientByOPIDQuery(tcOPID ?? skipToken);
  const [getDoctors, Doctors] = useLazyGetDoctorSlotAvailabilityQuery();
  const [addUpdateAppointment, AddUpdateAppointment] =
    usePostOutPatientTimeSlotMutation();

  const [filtersFormInstance] = Form.useForm<IFilters>();
  const [additionalFormInstance] = Form.useForm();

  const selectedDate = Form.useWatch("date", filtersFormInstance);
  const selectedDateTime = Form.useWatch("dateTime", filtersFormInstance);

  useEffect(() => {
    setShowDoctorsList(false);
    setSelectedDoctor(null);
  }, [selectedDate, selectedDateTime]);

  useEffect(() => {
    if (AddUpdateAppointment.isSuccess) {
      navigate(`/appointment-details`);
    }
  }, [AddUpdateAppointment]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (OPData) {
      additionalFormInstance.setFieldsValue({
        firstName: OPData?.data?.attendeeFirstName,
        lastName: OPData?.data?.attendeeLastName,
        relation: OPData.data?.relationShipID,
        mobileNumber: OPData?.data?.attendeeMobileNumber,
        comments: OPData?.data?.comments,
      });
    }
  }, [OPData]);

  const onDoctorChange = useCallback((doctorDetails: ISpecialistDetails) => {
    setSelectedDoctor(doctorDetails);
  }, []);

  const onFilterApply = useCallback((values: IFilters) => {
    getDoctors({ ...values, dateTime: values.dateTime?.toISOString() });
    setShowDoctorsList(true);
  }, []);

  const onContinueBooking = () => {
    const filterValues = filtersFormInstance.getFieldsValue();
    const additionalValues = additionalFormInstance.getFieldsValue();

    const payload = {
      outPatientID: null,
      tcopid: tcOPID ? tcOPID : null,
      patientID: PatientDetails.data.patientID,
      assignedDoctorID: selectedDoctor?.doctorID,
      assignedNurseID: null,
      appointmentTime: filterValues.dateTime.toISOString(),
      appointmentType: "Out Patient",
      comments: additionalValues?.comments,
      specialityID: selectedDoctor?.specialityID,
      opStatusID: 109,
      paymentStatusID: 89,
      createdBy: null,
      locationID: selectedDoctor?.locationID,
      isSelf:
        additionalValues.firstName != null || additionalValues.firstName != ""
          ? false
          : true,
      attendeeFirstName: additionalValues.firstName,
      attendeeLastName: additionalValues.lastName,
      relationShipID: additionalValues.relation,
      attendeeMobileNumber: additionalValues.mobileNumber,
    };
    addUpdateAppointment(payload);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Spin spinning={AddUpdateAppointment?.isLoading} fullscreen />

        <div className="page-header">
          <Flex vertical gap={24}>
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Book Appointment</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/patients">Patient Registration</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`../patients/${patientID}`}>Patient Details</Link>
                  </li>
                  <li className="breadcrumb-item active">Book Appointment</li>
                </ul>
              </div>
            </div>

            <PatientCard
              details={PatientDetails.data}
              loading={PatientDetails.isLoading}
            />

            <AttendeeForm
              formInstance={additionalFormInstance}
              isSelfBooking={isSelfBooking}
              setIsSelfBooking={setIsSelfBooking}
              isEditable={!tcOPID}
            />

            <Filters
              formInstance={filtersFormInstance}
              onApply={onFilterApply}
            />

            {showDoctorsList && (
              <DoctorsList
                doctors={Doctors.data}
                loading={Doctors.isFetching}
                selectedDoctor={selectedDoctor}
                onDoctorChange={onDoctorChange}
              />
            )}

            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  disabled={!selectedDoctor}
                  onClick={onContinueBooking}
                >
                  Continue Booking
                </Button>
              </Col>
            </Row>
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default BookAppointment;
