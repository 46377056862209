import { skipToken } from "@reduxjs/toolkit/query";
import {
  DatePicker,
  Form,
  Modal,
  Select,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  LinkProps,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { paymentStatusColorMap } from "../../../models/Colors";
import { Role } from "../../../models/PrivilegeRoles";
import { IsIPorOp, OPStatus } from "../../../models/Status";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetBedDetailsQuery,
  useGetDischargeSummaryQuery,
  useGetLocationsQuery,
  useGetOutPatientBYPatientIDQuery,
  useGetOutPatientByOPIDQuery,
  useGetPaymentsQuery,
  useGetRoomDetailsQuery,
  useLazyGetDownloadOPSummaryQuery,
  useLazyGetDownloadPaymentDetailsQuery,
  usePostAdmissionMutation,
  usePostUpdateRequestStatusMutation,
  useUpdatePatientAdmissionDateMutation,
} from "../../../services/ApiSlice";
import InPatientTabsCard from "./InpatientTabs/InPatientTabsCard";
dayjs.locale("en");

const { Title } = Typography;
const { confirm } = Modal;
interface CustomLinkProps {
  to: string;
  state?: {
    tcipid: string;
  };
  className?: string;
  onClick?: () => void;
}
const InpatientDetails: React.FC<{}> = () => {
  const isRoleBasedVisible = useRoleBasedVisible();
  const { id }: any = useParams(); //TCIPID

  const user = useSelector(selectUser);
  const [initialRoomIDChange, setInitialRoomIDChange] = useState(true);
  const [initialRoom, setInitialRoom] = useState(null);
  const [initialBed, setInitialBed] = useState(null);

  const location = useLocation();
  const OPData = useGetOutPatientByOPIDQuery(id ?? skipToken);
  const OutPatientList = useGetOutPatientBYPatientIDQuery({
    patientID: null,
    locationID: 1,
  });
  const Rooms = useGetRoomDetailsQuery("");
  const [postAdmission, PostAdmission] = usePostAdmissionMutation();
  const [updateAdmission, UpdateAdmission] =
    useUpdatePatientAdmissionDateMutation();
  const [formInstance] = Form.useForm<any>();
  const selectedRoom = Form.useWatch(["roomID"], formInstance);

  const Locations = useGetLocationsQuery();

  const RoomNo = useMemo(
    () =>
      Array.isArray(Rooms.data)
        ? Rooms.data.map((item) => ({
            value: item?.roomID,
            label: item.roomNo,
          }))
        : [],
    [Rooms]
  );

  const Location = useMemo(
    () =>
      Array.isArray(Locations.data)
        ? Locations.data.map((item) => ({
            id: item?.locationID,
            value: item?.locationID,
            label: item.locationName,
          }))
        : [],
    [Locations]
  );

  const [getPaymentDetails, PaymentDetails] =
    useLazyGetDownloadPaymentDetailsQuery();

  useEffect(() => {
    if (PaymentDetails.data) {
      window.open(PaymentDetails.data);
    }
  }, [PaymentDetails.data]);
  useEffect(() => {
    if (initialRoomIDChange) {
      setInitialRoomIDChange(false);
    }
  }, [selectedRoom]);

  const [getDownloadOPSummary, DownloadOPSummary] =
    useLazyGetDownloadOPSummaryQuery();

  const patientData = useMemo(
    () =>
      Array.isArray(OutPatientList.data)
        ? OutPatientList.data?.find((option: any) => option?.tcopid === id)
        : undefined,
    [id, OutPatientList.data]
  );

  const PatientData = useGetDischargeSummaryQuery(id ?? skipToken);
  const Beds = useGetBedDetailsQuery(selectedRoom ?? skipToken);
  const BedsList = useMemo(
    () =>
      Array.isArray(Beds.data)
        ? Beds.data.map((item) => ({
            value: item?.bedID,
            label: item.bedNo,
          }))
        : [],
    [Beds]
  );

  const [isAdmissionModalOpen, setIsAdmissionModalOpen] = useState(false);
  //   const [editFormInstance] = Form.useForm<any>();
  const showModalAdmission = () => {
    const roomID = PatientData.data?.roomID;
    setInitialRoom(roomID);
    const bedID = PatientData.data?.bedID;
    setInitialBed(bedID);
    formInstance.setFieldsValue({
      admissionDate: dayjs(PatientData.data?.admissionDate),
      roomID: roomID,
      // {
      //   label: PatientData?.data?.roomNo,
      //   value: PatientData?.data?.roomID,
      // },
      bedID: bedID,
      locationID: PatientData?.data?.location,
    });
    setIsAdmissionModalOpen(true);
  };

  const handleOkAdmission = async () => {
    try {
      const values = await formInstance.validateFields();
      try {
        await updateAdmission({
          tcipid: id,
          admissionDate: values?.admissionDate,
          modifiedBy: user?.himsEmployeeID,
          bedID: values?.bedID,
          // BedsList.find((option) => option.label === values?.bedID)?.value ||
          // 0,
          roomID: values?.roomID,
          // RoomNo.find((option) => option.label === values?.roomID)?.value ||
          // 0,
          locationID:
            Location.find((option) => option.label === values?.locationID)
              ?.id || 0,
        }).unwrap();
        handleCancelAdmission();
      } catch (error) {
        console.error("Failed to post data:", error);
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const handleCancelAdmission = () => {
    setIsAdmissionModalOpen(false);
    formInstance.resetFields();
  };

  const handleDownloadPaymentDetails = (record: any) => {
    getPaymentDetails(record?.paymentDetailsID);
  };

  const {
    data: paymentDetails,
    refetch,
    isLoading: isPaymentListLoading,
  } = useGetPaymentsQuery(id ?? skipToken);

  const [cancelAppointment, updateCancelAppointment] =
    usePostUpdateRequestStatusMutation();
  const [updateRequestStatus, updateRequestStatusResponse] =
    usePostUpdateRequestStatusMutation();
  const [updateCompleteStatus, updateCompleteStatusResponse] =
    usePostUpdateRequestStatusMutation();

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (DownloadOPSummary.data) {
      window.open(DownloadOPSummary.data);
    }
    if (updateRequestStatusResponse.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "CheckIn Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }

    if (updateCancelAppointment.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "Cancelled Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }
    if (updateCompleteStatusResponse.isSuccess) {
      navigate(`/appointment-details`);
      //   notification["success"]({
      //     message: "Cancelled Successfully",
      //     icon: <i className="las la-check-circle text-success"></i>,
      //   });
    }
  }, [
    DownloadOPSummary.data,
    updateRequestStatusResponse.isSuccess,
    updateCancelAppointment.isSuccess,
    updateCompleteStatusResponse.isSuccess,
  ]);

  // useEffect(() => {
  //   formInstance.setFieldValue("bedID", null);
  // }, [selectedRoom]);
  useEffect(() => {
    // Update initialRoom when modal is opened
    if (isAdmissionModalOpen) {
      setInitialRoom(formInstance.getFieldValue("roomID"));
    }
  }, [isAdmissionModalOpen]);

  const personalDetails: any = PatientData
    ? [
        {
          // UHID: PatientData?.data?.uhid,
          UHID: (
            <>
              {" "}
              <a
                className="hyperlink"
                href={`/patients/${PatientData?.data?.patientID}`}
              >
                {PatientData?.data?.uhid}
              </a>
            </>
          ),
          Name: (
            <>
              {" "}
              <span className="personalDetails">
                {PatientData?.data?.patientName}
              </span>
            </>
          ),
          MobileNo: (
            <>
              {" "}
              <span className="personalDetails">
                {PatientData?.data?.phoneNo}
              </span>
            </>
          ),
          EmergencyContactName: (
            <>
              {" "}
              <span className="personalDetails">
                {PatientData?.data?.emergencyContactName}
              </span>
            </>
          ),
          EmergencyContactNo: (
            <>
              {" "}
              <span className="personalDetails">
                {PatientData?.data?.emergencyContactNumber}
              </span>
            </>
          ),
        },
      ]
    : [];
  const admissionDetails: any = PatientData
    ? [
        {
          AdmissionID: PatientData?.data?.tcipid,
          AdmissionDate: FormatDateTime(PatientData?.data?.admissionDate),
          RoomNo: PatientData?.data?.roomNo,
          BedNo: PatientData?.data?.bedNo,
          Location: PatientData?.data?.location,
          AdmissionReason: PatientData?.data?.reasonForVisit,
        },
      ]
    : [];

  const attendeeDetails = OPData
    ? [
        {
          FirstName: OPData?.data?.attendeeFirstName,
          LastName: OPData?.data?.attendeeLastName,
          Relation: OPData.data?.relationship,
          MobileNo: OPData?.data?.attendeeMobileNumber,
        },
      ]
    : [];
  const linkProps: CustomLinkProps = {
    to: `/patients/${PatientData?.data?.patientID}/update`,
    state: { tcipid: id },
    className: "edit-icon",
    onClick: () => {
      setIsModelOpen(true);
    },
  };
  const handleDownloadOpSummary = () => {
    getDownloadOPSummary(id);
  };

  const handleOpSummary = () => {
    navigate(`/op-summary/${id}`);
  };

  const handleCancelRequest = () => {
    confirm({
      title: "Do you want to cancel the request ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          cancelAppointment({
            Id: id,
            Type: isPatient,
            Status: OPStatus.Cancelled,
          });
        }
      },
    });
  };
  const handleRoomChange = (value: any) => {
    // if (initialRoom !== value) {
    // Room has changed after opening the modal, set bedID to null
    formInstance.setFieldsValue({ bedID: null });
  };
  const handleChangeAppointment = () => {
    navigate(
      // `/edit-appointment/${id}/${patientData?.patientID}/book-appointment`
      `/patients/${patientData?.patientID}/book-appointment/${id}`
    );
  };
  const handleCheckIn = () => {
    confirm({
      title: "Do you want to 'Check-In' the client ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateRequestStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.CheckIn,
          });
        }
      },
    });
  };

  const handleMovetoDoctor = () => {
    confirm({
      title: "Do you want to Move the Client to Doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateRequestStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.InConsultation,
          });
        }
      },
    });
  };

  const handleAddAdmission = () => {
    setIsModelOpen(true);
  };

  const handleComplete = () => {
    confirm({
      title: "Do you want to Complete the Process ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (id) {
          const isPatient = id.includes("IP")
            ? IsIPorOp.inPatient
            : IsIPorOp.outPatient;
          updateCompleteStatus({
            Id: id,
            Type: isPatient,
            Status: OPStatus.Complete,
          });
        }
      },
    });
  };

  const paymentColumns: any = [
    {
      title: "ID",
      dataIndex: "tcipid",
      render: (text: string) => <span>{text || "-"}</span>, // Provide default value if firstName is empty
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid.length - b.tcipid.length,
    },
    {
      title: "Payment For",
      dataIndex: "paymentFor",
      render: (text: string) => <span>{text || "No Payment"}</span>, // Provide default value if firstName is empty
      sorter: (a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      render: (text: string) => <span>{text || "No Payment Mode"}</span>, // Provide default value if firstName is empty
      sorter: (a: { paymentMode: string }, b: { paymentMode: string }) =>
        a.paymentMode.length - b.paymentMode.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: string) => <span>{text || "No Amount"}</span>, // Provide default value if lastName is empty
      sorter: (a: { lastName: string }, b: { lastName: string }) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (
        a: { paymentDate: string | any[] },
        b: { paymentDate: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.paymentDate.length - b.paymentDate.length;
      },
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      render: (text: string) => (
        <Tag color={paymentStatusColorMap[text?.toUpperCase()]}>{text}</Tag>
      ),
      sorter: (
        a: { status: string | any[] },
        b: { status: string | any[] }
      ) => {
        // Sort by length of paymentDate string
        return a.status.length - b.status.length;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space>
          {record?.paymentStatus === "Paid" && (
            <Link to={""}>
              <span
                className="link"
                onClick={() => handleDownloadPaymentDetails(record)}
              >
                {/* <i className="las la-print"></i> */}
                Invoice
              </span>
            </Link>
          )}
          {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
            record?.paymentStatus !== "Paid" && (
              <Link to={`/payment-details/${id}`}>
                <span className="link">Pay</span>
              </Link>
            )}

          {[88, 89].includes(record.paymentStatusDDSID) && (
            <Link to={""}>
              {/* <span
                    className="link"
                    onClick={() => {
                      handleCancle(
                        record.paymentDetailsID,
                        record.deviceUserName,
                        record.p2PRequestID
                      );
                    }}
                  >
                    cancel
                  </span> */}
            </Link>
          )}
        </Space>
      ),
      sorter: true,
    },
  ];

  const onPrescribedDrug = () => {
    navigate(`/admissions/${id}/prescribed-drug`, {
      state: {
        from: location.pathname,
        TCIPID: id,
        patientID: patientData?.patientID,
        doctorVisitID: null,
        returnState: { activeKey: "7" },
        isPrescribedDrug: true,
      },
    });
  };

  return (
    <>
      {" "}
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spin
            spinning={PaymentDetails.isFetching || DownloadOPSummary?.isLoading}
            fullscreen
          />

          <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0"></div>
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Admission Details </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="hyperlink" to="/discharge-summary-list">
                      Admissions
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Admission Details</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              id="emp_patient"
              className="pro-overview tab-pane fade show active"
            >
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <h3 className="card-title">
                        Patient Information
                        {isRoleBasedVisible([Role.Admin]) && (
                          <Link {...linkProps}>
                            <i className="fa fa-pencil" />
                          </Link>
                        )}
                      </h3>
                      <ul className="personal-info">
                        <div className="text-center">
                          {/* <Spin spinning={isPatientLoading} /> */}
                        </div>

                        {Array.isArray(personalDetails) ? (
                          personalDetails.map((item: any, index: any) => (
                            <ListItem
                              key={index}
                              data={item}
                              isLoading={PatientData.isFetching}
                            />
                          ))
                        ) : (
                          <ListItem
                            data={personalDetails}
                            isLoading={PatientData.isFetching}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {isRoleBasedVisible([
                  Role.Mod,
                  Role.Admin,
                  Role.Nurse,
                  Role.Doctor,
                ]) && (
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          Admission Information
                          {isRoleBasedVisible([Role.Admin]) && (
                            <Link
                              // to={`/patients/${PatientData?.data?.patientID}/update`}
                              to="#"
                              className="edit-icon"
                              onClick={showModalAdmission}
                            >
                              <i className="fa fa-pencil" />
                            </Link>
                          )}
                        </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatinetListLoading} /> */}
                          </div>
                          {Array.isArray(admissionDetails) ? (
                            admissionDetails.map((item: any, index: any) => (
                              <ListItem
                                key={index}
                                data={item}
                                isLoading={PatientData.isFetching}
                              />
                            ))
                          ) : (
                            <ListItem
                              data={admissionDetails}
                              isLoading={PatientData.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {attendeeDetails.some((detail) =>
                  Object.values(detail).some(
                    (value) => value !== null && value !== undefined
                  )
                ) && (
                  <div className="col-md-12">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Attendee Information </h3>
                        <ul className="personal-info">
                          <div className="text-center">
                            {/* <Spin spinning={isPatientLoading} /> */}
                          </div>

                          {Array.isArray(attendeeDetails) ? (
                            attendeeDetails.map((item: any, index: any) => (
                              <AttendeeListItem
                                key={index}
                                data={item}
                                isLoading={OPData.isFetching}
                              />
                            ))
                          ) : (
                            <AttendeeListItem
                              data={attendeeDetails}
                              isLoading={OPData.isFetching}
                            />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* {isRoleBasedVisible([Role.Doctor, Role.Admin]) && (
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">Reason for Visit </h3> */}
                {/* <ul className="personal-info">
                        <div className="text-center">
                          <Spin spinning={isPatinetListLoading} />
                        </div>
                        {Array.isArray(doctorDetails) ? (
                          doctorDetails.map((item: any, index: any) => (
                            <ListItem key={index} data={item} />
                          ))
                        ) : (
                          <ListItem data={doctorDetails} />
                        )}
                      </ul> */}
                {/* {patientData?.comments}
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>

          <Modal
            title="Admission Information"
            open={isAdmissionModalOpen}
            onOk={handleOkAdmission}
            onCancel={handleCancelAdmission}
          >
            <Form
              form={formInstance}
              disabled={PatientData.isLoading}
              layout="vertical"
            >
              <Form.Item
                name="admissionDate"
                label="Admission Date"
                rules={[
                  { required: true, message: "Please Enter Scheduled Date" },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(d) => dayjs().isBefore(d)}
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
              <Form.Item
                name="roomID"
                label="Room No"
                rules={[{ required: true, message: "Please Enter Room No" }]}
              >
                <Select
                  placeholder="Room No"
                  style={{ width: "100%" }}
                  options={RoomNo}
                  // loading={Rooms.isFetching}
                  onChange={handleRoomChange}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item
                name="bedID"
                label="Bed No"
                rules={[{ required: true, message: "Please Enter Bed No" }]}
              >
                <Select
                  placeholder="Bed No"
                  style={{ width: "100%" }}
                  options={BedsList}
                  loading={Beds.isFetching}
                  showSearch
                  optionFilterProp="label"
                  disabled={!selectedRoom || PostAdmission.isLoading}
                />
              </Form.Item>
              <Form.Item
                name="locationID"
                label="Location"
                rules={[{ required: true, message: "Please Enter Location " }]}
              >
                <Select
                  placeholder="Location"
                  style={{ width: "100%" }}
                  options={Location}
                  // loading={Locations.isFetching}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Form>
          </Modal>

          <InPatientTabsCard patientData={PatientData?.data} TCIPID={id} />
          {/* 
          {isRoleBasedVisible([Role.Admin, Role.Mod]) && (
            <>
              <Title level={4}>Payment Details</Title>
              <Table
                pagination={paymentDetails?.length > 10 ? undefined : false}
                columns={paymentColumns}
                dataSource={paymentDetails}
                className="table-striped"
                rowKey={(record) => record.id}
                loading={isPaymentListLoading}
              />
            </>
          )} */}

          {/* {isRoleBasedVisible([Role.Mod, Role.Admin]) && (
            <div>
              <div className="mb-3">
                <h3 className="page-title">Payment Details </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={paymentDetails}
                      className="table-striped"
                      rowKey={(record) => record.id}
                      loading={isPaymentListLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div className="py-1"></div>
          {/* <div className="row">
            <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
              <div className="d-flex justify-content-end">
                <Space size={30}>
                  <Link
                    to={
                      {
                        pathname: `/discharge-summary/${PatientData?.data?.tcipid}`,
                        // state: { formData: record }, // Pass formData as state
                      } as any
                    }
                  >
                    {PatientData?.data?.patientDischargeSummaryID ? (
                      <Button type="primary">Discharged</Button>
                    ) : (
                      <Button type="primary">Discharge</Button>
                    )}
                  </Link>
                  {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
                    PatientData?.data?.patientDischargeSummaryID === null && (
                      <Link
                        to={`/IPpayment-details/${PatientData?.data?.tcipid}`}
                      >
                        <Button type="primary">Pay</Button>
                      </Link>
                    )}
                 
                </Space>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export const ListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({ data, isLoading = false }) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) =>
          value ? (
            <li>
              <div className="title font-weight-bold">{formatKey(key)}</div>
              {isLoading ? (
                <Skeleton
                  active
                  title={false}
                  paragraph={{ rows: 1, width: 300 }}
                />
              ) : (
                <div className="text">{value}</div>
              )}
            </li>
          ) : null
        )}
      </div>
    </>
  );
};

export const AttendeeListItem: React.FC<{
  data: { [key: string]: string };
  isLoading?: boolean;
}> = ({ data, isLoading = false }) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div className="row">
        {Object.entries(data).map(([key, value]) => (
          <li className="col-md-6" key={key}>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            {isLoading ? (
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 300 }}
              />
            ) : (
              <div className="text">{value}</div>
            )}
          </li>
        ))}
      </div>
    </>
  );
};

export default InpatientDetails;
