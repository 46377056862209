/* eslint-disable no-unused-expressions */

import {
  Button,
  ConfigProvider,
  Form,
  Input,
  InputRef,
  Modal,
  Space,
  Spin,
  Table,
  TableColumnType,
  TableProps,
  notification,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useAddOrDeleteApprovedDoctorsMutation,
  useAddOrUpdateEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetApprovedDoctorsQuery,
  useGetHimsRolesQuery,
  useGetLocationsQuery,
  usePostNewRolePrivilegeMutation,
} from "../../../services/ApiSlice";
import { emailrgx } from "../Authentication/RegEx";

const ApprovedDoctors: React.FC<{}> = () => {
  const { confirm } = Modal;
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const { data: allEmployees, isFetching: isAllEmployeesLoading } =
    useGetAllEmployeesQuery("");
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const [addApprovedDoctor, AddApprovedDoctor] =
    useAddOrDeleteApprovedDoctorsMutation();
  const GetApprovedDoctors = useGetApprovedDoctorsQuery();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<IEmployee | null>(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);
  type DataIndex = keyof IApprovedDoctors;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const handleDelete = (record: IApprovedDoctors) => {
    confirm({
      title: "Are you sure delete the doctor ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        addApprovedDoctor({
          email: record?.email,
          modifiedBy: user?.himsEmployeeID,
          approvedDoctorsID: record?.approvedDoctorsID,
        });
      },
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IApprovedDoctors> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7465ad",
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (
      <i className="fa-solid fa-magnifying-glass " style={{ marginLeft: 5 }} />
    ),
  });

  // useEffect(() => {
  //   if (isRolePrivilege) {
  //     formInstance.resetFields();
  //   }
  // }, [isRolePrivilege]);

  const columns: TableProps<IApprovedDoctors>["columns"] = [
    {
      title: "Name",
      dataIndex: "doctorName",
      //   ...getColumnSearchProps("doctorName"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      //   ...getColumnSearchProps("email"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      //   ...getColumnSearchProps("mobileNumber"),
      render: (text: number) => <span>{text || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      //   ...getColumnSearchProps("role"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Location",
      dataIndex: "location",
      //   ...getColumnSearchProps("location"),
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: IApprovedDoctors) => (
        <Space>
          {/* <Link to="#">
            <span className="link " onClick={() => handleEdit(record)}>
              <i className="las la-pen"></i>
            </span>
          </Link> */}
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const [formInstance] = Form.useForm();

  const handleEdit = (record: IEmployee) => {
    // setEditRole(true);
    setIsModelOpen(true);
    // setHimsRoleID(himsRoleID);
    setSelectedRecord(record);
    formInstance.setFieldsValue(record);
    window.scrollTo(0, 0);
  };

  const getLocationOptions = (locations: ILocation[] | undefined) => {
    const options: { label: string; value: number | null }[] = [
      // { label: "All", value: null },
    ];
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        options.push({
          label: location.locationName,
          value: location.locationID,
        });
      });
    }
    return options;
  };

  const [addOrUpdateEmployee, AddOrUpdateEmployee] =
    useAddOrUpdateEmployeeMutation();

  const Locations = useGetLocationsQuery();

  const locationOptions = useMemo(
    () => getLocationOptions(Locations.data),
    [Locations]
  );

  const filteredEmployees = useMemo(() => {
    if (Array.isArray(allEmployees)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allEmployees?.filter((employee: any) =>
        employee?.displayName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allEmployees, searchQuery]);

  // const handleSubmit = async (values: any) => {
  //   const roleExists = allEmployees?.some(
  //     (role: { roleName: string }) =>
  //       role.roleName.toLowerCase().trim() === values.role.toLowerCase().trim()
  //   );
  //   if (!roleExists) {
  //     try {
  //       await postNewRolePrivilege({ RoleName: values.role }).unwrap();
  //       // Optionally, refetch data after successful post
  //       //  await refetch();
  //     } catch (error) {
  //       console.error("Failed to post data:", error);
  //     }
  //   } else {
  //     notification["warning"]({
  //       message: "Role already exists",
  //       icon: (
  //         <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
  //       ),
  //     });
  //   }
  // };

  // const handleInputChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value;
  //   if (value != null) {
  //     setSearchQuery(value);
  //   }
  // };

  const [updateUser, UserUpdate] = useAddOrUpdateEmployeeMutation();

  const onAddRole = () => {
    setIsModelOpen(true);
    // setSelectedRecord(true);
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();
      const doctorExists = GetApprovedDoctors.data?.some(
        (role: { email: string }) =>
          role?.email?.toLowerCase().trim() ===
          values?.email?.toLowerCase().trim()
      );

      const payload = {
        email: values?.email,
        modifiedBy: user?.himsEmployeeID,
        approvedDoctorsID: null,
      };
      if (!doctorExists) {
        try {
          await addApprovedDoctor(payload).unwrap();
        } catch (error) {
          console.error("Failed to post data:", error);
        }
      } else {
        notification["warning"]({
          message: "Doctor already exists",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };

  const HimsRoles = useGetHimsRolesQuery();

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Spin
            spinning={isAllEmployeesLoading || AddApprovedDoctor.isLoading}
            fullscreen
          />

          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Approved Doctor List</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Approved Doctor List
                  </li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn mx-3"
                  onClick={onAddRole}
                >
                  {" "}
                  <i className="fa fa-plus" />
                  Add Doctor
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={GetApprovedDoctors.data}
                  className="table-striped"
                  rowKey="himsRoleID"
                  loading={GetApprovedDoctors.isFetching}
                  pagination={
                    GetApprovedDoctors.data &&
                    GetApprovedDoctors.data.length > 10
                      ? {}
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isModelOpen}
          centered
          title={
            selectedRecord ? "Update Approved Doctor" : "Add Approved Doctor"
          }
          onCancel={onCancel}
          onOk={handleAddUpdate}
          maskClosable={false}
          okText={selectedRecord ? "UPDATE" : "ADD"}
        >
          <Spin
            spinning={AddApprovedDoctor.isLoading}
            size="large"
            tip="Loading..."
          >
            <Form
              form={formInstance}
              layout="vertical"
              disabled={AddApprovedDoctor.isLoading}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    pattern: emailrgx,
                    message: "Please Enter Valid Email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  // disabled={patientsByMobile.isFetching}
                />
              </Form.Item>
              {/* <div className="input-block mb-3 row">
              <label className="col-lg-3 col-form-label">Role</label>
              <Form.Item
                name="roleName"
                rules={[{ required: true, message: "Please Enter Role" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={himsRolesOptions}
                  loading={HimsRoles.isFetching}
                  showSearch
                  optionFilterProp="label"
                  placeholder="Role"
                />
              </Form.Item>
              <label className="col-lg-3 col-form-label">Location</label>

              <Form.Item name="locationName" initialValue={null}>
                <Select
                  loading={Locations.isFetching}
                  disabled={Locations.isFetching}
                  showSearch
                  optionFilterProp="label"
                  filterOption
                  options={locationOptions}
                  placeholder="Location"
                />
              </Form.Item>
            </div> */}
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

export default ApprovedDoctors;
