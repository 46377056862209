import type { TableProps } from "antd";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useRoleBasedVisible } from "../../../hooks/role.hooks";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useGetAddedLabTestsQuery,
  useLazyDownloadLabReportByIDQuery,
  useLazyGetDownloadLabReportPatientIDQuery,
  usePostLabTestsUpdateMutation,
} from "../../../services/ApiSlice";

const { Title, Text, Paragraph } = Typography;

function LabTests({
  patientID,
  TCOPID,
  TCIPID,
}: {
  patientID: string;
  TCOPID?: string;
  TCIPID?: string;
}) {
  const AddedLabTests = useGetAddedLabTestsQuery({
    tcOPID: TCOPID ?? null,
    tcIPID: TCIPID ?? null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const [postLabTestsUpdate, PostLabTestsUpdate] =
    usePostLabTestsUpdateMutation();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const [formInstance] = Form.useForm<any>();

  useEffect(() => {
    if (PostLabTestsUpdate.isSuccess) {
      onCancel();
      setSelectedRecord(null);
    }
  }, [PostLabTestsUpdate]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<IAddedLabTest | null>(
    null
  );
  const { confirm } = Modal;
  const handleEdit = (record: IAddedLabTest) => {
    setIsModelOpen(true);
    setSelectedRecord(record);
    formInstance.setFieldsValue({
      ...record,
      scheduleDateTime: record?.scheduleDateTime
        ? dayjs(record?.scheduleDateTime)
        : undefined,
    });
  };
  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
  };

  const handleUpdateLabTest = async () => {
    try {
      const values = await formInstance.validateFields();
      postLabTestsUpdate({
        ...values,
        pltid: selectedRecord?.pltid,
        modifiedBy: user?.himsEmployeeID,
        statusID: selectedRecord?.statusID,
      });
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  // const handleEdit = (record: IAddedLabTest) => {
  //   setIsModelOpen(true);
  //   formInstance.setFieldsValue(record);
  // };

  const isRoleBasedVisible = useRoleBasedVisible();

  const onAddTests = () => {
    navigate("/lab-requests/new-request", {
      state: { from: location.pathname, TCOPID, TCIPID, patientID },
    });
  };
  const [getLabDischargeSummary, GetLabDischargeSummary] =
    useLazyGetDownloadLabReportPatientIDQuery();
  const [downloadLabReport, DownloadLabReport] =
    useLazyDownloadLabReportByIDQuery();
  useEffect(() => {
    if (DownloadLabReport.isSuccess && DownloadLabReport.data) {
      window.open(DownloadLabReport.data);
      setSelectedRowKeys([]);
    }
  }, [DownloadLabReport.isSuccess, DownloadLabReport.data]);

  const downloadTests = () => {
    // getLabDischargeSummary(TCIPID);
    if (AddedLabTests.data) {
      const selectedDrugs = AddedLabTests.data.filter(
        (drug: any) => selectedRowKeys.indexOf(drug?.pltid) !== -1
      );
      downloadLabReport({
        tcipid: TCIPID,
        ids: selectedDrugs.map((drug: any) => drug.pltid).join(","),
      });
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: TableProps<IAddedLabTest>["columns"] = [
    {
      title: "Test Name",
      dataIndex: "testName",
      render: (text) => (
        <span style={{ whiteSpace: "normal" }}>{text || "-"}</span>
      ),
    },
    {
      title: "Service Group",
      dataIndex: "serviceGroupName",
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "scheduleDateTime",
    //   render: (date) => (date ? dayjs(date).format("YYYY-MM-DD HH:mm") : "-"),
    // },
    {
      title: "Scheduled Date",
      dataIndex: "scheduleDateTime",
      render: (text: string, record) => (
        <>
          <Space>
            <Link to="">
              <span>{dayjs(text).format("DD-MM-YYYY HH:MM")}</span>
            </Link>
            <Button className="hyperlink" onClick={() => handleEdit(record)}>
              Edit
            </Button>
          </Space>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => <Tag color="warning">{status}</Tag>,
      // render: ({ status, reportBlobUrl }) => (
      //   <Space direction="vertical" size={0}>
      //     {status && <Text strong>{status}</Text>}
      //     {reportBlobUrl && status === "Completed" && (
      //       <Link className="hyperlink" to={reportBlobUrl}>
      //         <Text type="secondary">View Report</Text>
      //       </Link>
      //     )}
      //   </Space>
      // ),
      // <Tag color="warning">{status}</Tag>,
    },
    {
      title: "Recommended By",
      dataIndex: "createdByName",
    },
    // {
    //   title: "Media",
    //   dataIndex: "reportBlobUrl",
    //   render: (reportBlobUrl) => (
    //     <Space direction="vertical" size="small">
    //       {["X-Ray", "Report"].map((file) => (
    //         <Button type="link" icon={<i className="fa-solid fa-paperclip" />}>
    //           {file}
    //         </Button>
    //       ))}
    //     </Space>
    //   ),
    // },
  ];

  return (
    <Card>
      <Spin spinning={DownloadLabReport.isLoading} fullscreen />
      {/* <Modal
        open={isModelOpen}
        centered
        title="Update  Procedure"
        //onOk={handleAddUpdate}
        okText={selectedRecord ? "UPDATE" : "ADD"}
        maskClosable={false}
      >
        <Form
          form={formInstance}
          // disabled={PostNewRolePrivilege.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="nsProcedureName"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please Enter Description" }]}
          >
            <Input
              placeholder="Description"
              // disabled={patientsByMobile.isFetching}
            />
          </Form.Item>
        </Form>
      </Modal> */}

      {/* <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Select Procedure
          </Title>
        }
        open={isModalOpen}
        footer={[
          <Button key="save" type="primary">
            save
          </Button>,
        ]}
        maskClosable={false}

        // cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form form={formInstance} layout="vertical">
          <Form.Item
            name="nsProcedureName"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              // options={selectProcedure}
              // loading={Procedure.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
      </Modal> */}

      <Modal
        open={isModelOpen}
        centered
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            LAB
          </Title>
        }
        onCancel={onCancel}
        onOk={handleUpdateLabTest}
        okText={"Save"}
        maskClosable={false}
      >
        <Form
          form={formInstance}
          disabled={PostLabTestsUpdate.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="testName"
            label="Test Name"
            // rules={[
            //   { required: true, message: "Please Enter Role Name" },
            //   { min: 3, message: "Role must be minimum 3 characters." },
            // ]}
          >
            {/* <Input placeholder="Patient Name" disabled={true} /> */}
            <Text type="secondary">
              {formInstance.getFieldValue("testName")}
            </Text>
          </Form.Item>
          <Form.Item
            name="patientName"
            label="Patient Name"
            // rules={[
            //   { required: true, message: "Please Enter Description" },
            //   {
            //     min: 3,
            //     message: "Description must be minimum 3 characters.",
            //   },
            // ]}
          >
            {/* <Input placeholder="Patient Name" disabled={true} /> */}
            <Text type="secondary">
              {formInstance.getFieldValue("patientName")}
            </Text>
          </Form.Item>
          <Form.Item
            name="scheduleDateTime"
            label="Schedule Date"
            // rules={[
            //   { required: true, message: "Please select Check-Out Date" },
            // ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          {/* <Form.Item
              name="status"
              label="Status"
              // rules={[{ required: true, message: "Please Enter Room No" }]}
            >
              <Select
                placeholder="Room No"
                options={statusOptions}

                style={{ width: "100%" }}
                // options={RoomNo}
                // loading={Rooms.isFetching}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item> */}
        </Form>
      </Modal>

      <Table
        loading={AddedLabTests.isFetching}
        columns={columns}
        dataSource={AddedLabTests.data ?? []}
        pagination={
          AddedLabTests.data && AddedLabTests.data?.length > 10
            ? { pageSize: 10 }
            : false
        }
        rowKey="pltid"
        scroll={{ x: true }}
        rowSelection={rowSelection}
        title={() => (
          <Row gutter={24} justify="end">
            <Col>
              {selectedRowKeys.length > 0 && (
                <Button onClick={downloadTests} type="primary">
                  Download
                </Button>
              )}
            </Col>
            <Col>
              <Button onClick={onAddTests} type="primary">
                Add Tests
              </Button>
            </Col>
          </Row>
        )}
      />
    </Card>
  );
}

export default LabTests;

// TODO: create new util for sorting
// const sortBy = (key) => {
//   return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
// };
