import { useNavigate, useParams } from "react-router";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import { Button, Checkbox, Col, Form, Input, Row, notification } from "antd";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { usePostOutPatientMutation } from "../../../services/ApiSlice";

const OpSummary: React.FC<{}> = () => {
  const { id }: any = useParams();

  const navigate = useNavigate();

  const [postOPSummary, OpSummary] = usePostOutPatientMutation();

  const componentRef = useRef<any>(null);

  const [formInstance] = Form.useForm();

  const { TextArea } = Input;

  const handleFinish = async (values: {}) => {
    try {
      await postOPSummary({
        ...values,
        TCOPID: id ?? "",
      }).unwrap();

      navigate(`/details-tab/${id}`);
    } catch (error) {
      console.error("Failed to post data:", error);
    }

    formInstance.resetFields();
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">OP Summary </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/details-tab/${id}`}>Patient Details</Link>
                  </li>
                  <li className="breadcrumb-item active">OP Summary</li>
                </ul>
              </div>
            </div>
          </div>
          <Form layout="vertical" onFinish={handleFinish} form={formInstance}>
            <div ref={componentRef}>
              <div className="tblbox1">
                <Form.Item name="chiefCompliants" label="Chief Complaints:">
                  <TextArea placeholder="Please Enter Comments" />
                </Form.Item>
                <Form.Item
                  name="pastMedicalHistory"
                  label="Past Medical History:"
                >
                  <TextArea placeholder="Please Enter Comments" />
                </Form.Item>
                <Form.Item name="examination" label="Examination:">
                  <TextArea placeholder="Please Enter Comments" />
                </Form.Item>
                <Form.Item name="investigations" label="Investigations:">
                  <TextArea placeholder="Please Enter Comments" />
                </Form.Item>
                <Form.Item
                  name="provisionalDiagnosis"
                  label="Provisional Diagnosis:"
                >
                  <TextArea placeholder="Please Enter Comments" />
                </Form.Item>
                {/* <Row justify="end">
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>FollowUp Required</Checkbox>
                  </Form.Item>
                </Row> */}
              </div>
            </div>
            <div className="text-end mt-4 btnmargin">
              <Row justify="end">
                <Col>
                  <Row justify="end">
                    <Col>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="btn btn-primary"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default OpSummary;
