/* eslint-disable no-unused-expressions */

import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  InputRef,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnType,
  TableProps,
  Tag,
  Typography,
  notification,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../reducers/UserSlice";
import {
  useAddOrUpdateEmployeeMutation,
  useDeleteHimsEmployeeMutation,
  useGetAllEmployeesQuery,
  useGetDropdownQuery,
  useGetHimsRolesQuery,
  useGetLocationsQuery,
  useGetQualificationQuery,
  useGetSpecializationsQuery,
  usePostNewRolePrivilegeMutation,
  useUpdateEmployeeMutation,
} from "../../../services/ApiSlice";
import { IndianMobileNumber, emailrgx } from "../Authentication/RegEx";

const UserList: React.FC<{}> = () => {
  const { confirm } = Modal;
  const { Text } = Typography;
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const { data: allEmployees, isFetching: isAllEmployeesLoading } =
    useGetAllEmployeesQuery("");
  const [deleteHimsEmployee, DeleteHimsEmployee] =
    useDeleteHimsEmployeeMutation();
  const [updateEmployee, UpdateEmployee] = useUpdateEmployeeMutation();
  const Specializations = useGetSpecializationsQuery();
  const Qualifications = useGetQualificationQuery();
  const Gender = useGetDropdownQuery("Gender");
  const user = useSelector(selectUser);
  const [
    postNewRolePrivilege,
    { isLoading: isPosting, error: postError, isSuccess: isRolePrivilege },
  ] = usePostNewRolePrivilegeMutation();
  const { Search } = Input;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<IEmployee | null>(null);
  const [pagination, setPagination] = useState<any>({});
  const [users, setUsers] = useState([
    {
      id: 1,
      roleName: "Admin",
      priviliges: ["Patients", "Appointments", "Discharge Summary"],
    },
  ]);
  type DataIndex = keyof IEmployee;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const genderOptions = useMemo(
    () =>
      Array.isArray(Gender.data)
        ? Gender.data.map(
            ({
              dropDownSetID,
              dropDownValue,
            }: {
              dropDownSetID: number;
              dropDownValue: string;
            }) => ({
              value: dropDownSetID,
              label: dropDownValue,
            })
          )
        : [],
    [Gender]
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IEmployee> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7465ad",
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (
      <i className="fa-solid fa-magnifying-glass " style={{ marginLeft: 5 }} />
    ),
  });

  // useEffect(() => {
  //   if (isRolePrivilege) {
  //     formInstance.resetFields();
  //   }
  // }, [isRolePrivilege]);

  const columns: TableProps<IEmployee>["columns"] = [
    {
      title: "Name",
      // dataIndex: "displayName",
      // ...getColumnSearchProps("displayName"),
      render: ({ displayName, isExternal }) => (
        <Space direction="vertical" size={0}>
          {displayName && <Text strong>{displayName}</Text>}
          {isExternal ? (
            <Tag color="#f50" bordered={false}>
              <span style={{ fontSize: 10 }}>External</span>
            </Tag>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      // ...getColumnSearchProps("email"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      // ...getColumnSearchProps("phoneNumber"),
      // render: (text: number) => <span>{text || "-"}</span>,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      // ...getColumnSearchProps("roleName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Location",
      dataIndex: "locationName",
      // ...getColumnSearchProps("locationName"),
      // render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space>
          <Link to="#">
            <span className="link " onClick={() => handleEdit(record)}>
              <i className="las la-pen"></i>
            </span>
          </Link>
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
          <Link to="#">
            <span className="link" onClick={() => handleView(record)}>
              <i className="las la-eye"></i>
            </span>
          </Link>
        </Space>
      ),
    },
  ];

  const [formInstance] = Form.useForm();

  const handleEdit = (record: IEmployee) => {
    navigate(`/AddUpdateExternalDoctors/${record?.adid}`);
    // setEditRole(true);
    // setHimsRoleID(himsRoleID);
    // setIsModelOpen(true);
    // setSelectedRecord(record);
    // formInstance.setFieldsValue(record);
    // window.scrollTo(0, 0);
  };
  const handleView = (record: IEmployee) => {
    navigate(`/user-details/${record?.adid}`);
  };
  const handleDelete = (record: IEmployee) => {
    confirm({
      title: "Do you want to delete the user ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        try {
          deleteHimsEmployee({
            empID: record?.adid,
            userID: user?.himsEmployeeID,
          });
          if (DeleteHimsEmployee.isSuccess) {
            if (DeleteHimsEmployee.data) {
              notification["success"]({
                message: "User Deleted Successfully",
                icon: (
                  <i
                    className="las la-check-double"
                    style={{ color: "green" }}
                  ></i>
                ),
              });
            } else {
              notification["warning"]({
                message: "Service Failure",
                icon: (
                  <i
                    className="las la-exclamation-circle"
                    style={{ color: "red" }}
                  ></i>
                ),
              });
            }
          } else if (DeleteHimsEmployee.error) {
            notification["warning"]({
              message: "Service Failure",
              icon: (
                <i
                  className="las la-exclamation-circle"
                  style={{ color: "red" }}
                ></i>
              ),
            });
          }
        } catch (error) {
          console.error("Failed to delete data:", error);
        }
      },
    });
  };

  const getLocationOptions = (locations: ILocation[] | undefined) => {
    const options: { label: string; value: number | null }[] = [
      // { label: "All", value: null },
    ];
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        options.push({
          label: location.locationName,
          value: location.locationID,
        });
      });
    }
    return options;
  };

  const [addOrUpdateEmployee, AddOrUpdateEmployee] =
    useAddOrUpdateEmployeeMutation();
  useEffect(() => {
    if (UpdateEmployee.isSuccess) {
      if (UpdateEmployee.data) {
        notification["success"]({
          message: "Updated employee successfully",
          icon: (
            <i className="las la-check-double" style={{ color: "green" }}></i>
          ),
        });
      } else {
        notification["warning"]({
          message: "Service Failure",
          icon: (
            <i
              className="las la-exclamation-circle"
              style={{ color: "red" }}
            ></i>
          ),
        });
      }
    } else if (UpdateEmployee.error) {
      notification["warning"]({
        message: "Service Failure",
        icon: (
          <i className="las la-exclamation-circle" style={{ color: "red" }}></i>
        ),
      });
    }
  }, [UpdateEmployee]);

  const Locations = useGetLocationsQuery();

  // const locationOptions = useMemo(
  //   () => getLocationOptions(Locations.data),
  //   [Locations]
  // );
  // const selected = Form.useWatch(["locationName"], formInstance);
  // console.log(selected)

  const locationOptions = useMemo(
    () =>
      Array.isArray(Locations.data)
        ? Locations.data.map(({ locationName, locationID }) => ({
            value: locationID,
            label: locationName,
          }))
        : [],
    [Locations]
  );
  const specializationOptions = useMemo(
    () => getSpecializationOptions(Specializations.data),
    [Specializations]
  );
  const qualificationOptions = useMemo(
    () => getQualificationOptions(Qualifications.data),

    [Qualifications]
  );

  const filteredEmployees = useMemo(() => {
    if (Array.isArray(allEmployees)) {
      const searchString = searchQuery?.toLocaleLowerCase().trim();
      return allEmployees?.filter((employee: any) =>
        employee?.displayName?.toLowerCase().trim().includes(searchString)
      );
    }
    return [];
  }, [allEmployees, searchQuery]);

  const [updateUser, UserUpdate] = useAddOrUpdateEmployeeMutation();
  const navigate = useNavigate();

  const onAddRole = () => {
    navigate(`/AddUpdateExternalDoctors`);
    // setIsModelOpen(true);
    // setSelectedRecord(true);
  };

  const onCancel = () => {
    setIsModelOpen(false);
    formInstance.resetFields();
    setSelectedRecord(null);
  };

  const handleAddUpdate = async () => {
    try {
      const values = await formInstance.validateFields();
      const payload = {
        emailID: values?.email,
        roleID: values?.himsRoleID,
        locationID: values?.locationID,
        mobileNumber: values?.phoneNumber,
        experience: values?.experience,
        specializationID: values?.specialityID,
        qualificationID: values?.qualificationID,
        modifiedBy: selectedRecord?.himsEmployeeID,
        employeeID: selectedRecord?.adid,
        firstName: values?.firstName,
        lastName: values?.lastName,
        password: values?.password,
        regNumber: values?.regNumber,
        genderID: values?.genderID,
      };
      try {
        await updateEmployee(payload).unwrap();
      } catch (error) {
        console.error("Failed to post data:", error);
      }
    } catch (error) {
      console.error("Failed to post data:", error);
    }
    onCancel();
  };

  const HimsRoles = useGetHimsRolesQuery();

  const himsRolesOptions = useMemo(
    () =>
      Array.isArray(HimsRoles.data)
        ? HimsRoles.data.map(({ roleName, himsRoleID }) => ({
            label: roleName,
            value: himsRoleID,
          }))
        : [],
    [HimsRoles]
  );

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Spin
            spinning={
              isAllEmployeesLoading ||
              AddOrUpdateEmployee.isLoading ||
              DeleteHimsEmployee.isLoading ||
              UpdateEmployee.isLoading
            }
            fullscreen
          />

          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Users List</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Users List</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <button
                  type="button"
                  className="btn add-btn mx-3"
                  onClick={onAddRole}
                >
                  {" "}
                  <i className="fa fa-plus" />
                  Add User
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={allEmployees}
                  className="table-striped"
                  // rowKey="himsRoleID"     // here key is not unique hence repetation occurs
                  pagination={
                    allEmployees && allEmployees.length > 10 ? {} : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isModelOpen}
          centered
          title={selectedRecord ? "Update User" : "Add User"}
          onCancel={onCancel}
          onOk={handleAddUpdate}
          maskClosable={false}
          okText={selectedRecord ? "UPDATE" : "ADD"}
        >
          <Form
            form={formInstance}
            disabled={UpdateEmployee.isLoading}
            layout="vertical"
          >
            <Row gutter={[24, 16]}>
              {selectedRecord?.isExternal ? (
                <>
                  <Col span={8}>
                    {" "}
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          min: 3,
                          message: "Enter atleast 3 characters",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          min: 3,
                          message: "Enter atleast 3 characters",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <Form.Item name="genderID" label="Gender">
                      <Select
                        style={{ width: "100%" }}
                        options={genderOptions}
                        loading={Gender.isFetching}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col span={8}>
                <Form.Item
                  name="phoneNumber"
                  label="Mobile No."
                  rules={[
                    {
                      pattern: IndianMobileNumber,
                      message: "Please Enter Valid Mobile Number",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Mobile No" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      pattern: emailrgx,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                >
                  <Input placeholder="Email" disabled />
                </Form.Item>
              </Col>
              {selectedRecord?.isExternal ? (
                <>
                  <Col span={8}>
                    {" "}
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          min: 3,
                          message: "Enter atleast 3 characters",
                        },
                      ]}
                    >
                      <Input placeholder="Password" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="qualificationID"
                      initialValue={null}
                      label="Qualification"
                    >
                      <Select
                        loading={Qualifications.isFetching}
                        disabled={Qualifications.isFetching}
                        showSearch
                        optionFilterProp="label"
                        filterOption
                        options={qualificationOptions}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col span={8}>
                <Form.Item name="himsRoleID" label="Role">
                  <Select
                    style={{ width: "100%" }}
                    options={himsRolesOptions}
                    loading={HimsRoles.isFetching}
                    showSearch
                    optionFilterProp="label"
                    placeholder="Role"
                    disabled={selectedRecord?.isExternal ? true : false}
                  />
                </Form.Item>
              </Col>

              {/* </div> */}
              {selectedRecord?.isExternal ? (
                <Col span={8}>
                  <Form.Item
                    name="specialityID"
                    initialValue={null}
                    label="Specialization"
                  >
                    <Select
                      loading={Specializations.isFetching}
                      disabled={Specializations.isFetching}
                      showSearch
                      optionFilterProp="label"
                      filterOption
                      options={specializationOptions}
                    />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              <Col span={8}>
                <Form.Item
                  name="locationID"
                  initialValue={null}
                  label="Location"
                >
                  <Select
                    loading={Locations.isFetching}
                    disabled={Locations.isFetching}
                    showSearch
                    optionFilterProp="label"
                    filterOption
                    options={locationOptions}
                    placeholder="Location"
                  />
                </Form.Item>
              </Col>

              {selectedRecord?.isExternal ? (
                <>
                  <Col span={8}>
                    {" "}
                    <Form.Item name="regNumber" label="Reg No">
                      <Input placeholder="Reg No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="experience" label="Experience">
                      <Input placeholder="experience" />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default UserList;

const getSpecializationOptions = (
  specializations: ISpecialization[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "All", value: null },
  ];
  if (Array.isArray(specializations)) {
    specializations.forEach((specialization) => {
      options.push({
        label: specialization.speciality,
        value: specialization.specialityID,
      });
    });
  }
  return options;
};

const getQualificationOptions = (
  qualification: IQualification[] | undefined
) => {
  const options: { label: string; value: number | null }[] = [
    { label: "Select", value: null },
  ];
  if (Array.isArray(qualification)) {
    qualification.forEach((qualification) => {
      options.push({
        label: qualification.qualification,
        value: qualification.qualificationID,
      });
    });
  }
  return options;
};
