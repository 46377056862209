import {
  Button,
  ConfigProvider,
  Input,
  InputRef,
  Space,
  Table,
  TableColumnType,
  Tag,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../Utils/FormatDateTime";
import { useIsAllowed, useRoleBasedVisible } from "../../../hooks/role.hooks";
import { IPStatusColorMap } from "../../../models/Colors";
import { useGetDischageSummaryListQuery } from "../../../services/ApiSlice";

const { Text } = Typography;
type DataIndex = keyof IDischargeDetails;

const DischargeSummaryList = () => {
  const isAllowed = useIsAllowed();
  const {
    data: allPatients,
    refetch,
    isFetching: dischargeSummaryList,
  } = useGetDischageSummaryListQuery("");
  const [searchQuery, setSearchQuery] = useState("");
  const isRoleBasedVisible = useRoleBasedVisible();
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return " - ";
    interface LocationState {
      value1: string;
      value2: string;
      value3: string;
    }
    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);
    const [month, day, year] = formattedDate.split(/[\s,]+/);

    return `${day}-${month}-${year} `;
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IDischargeDetails> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#7465ad",
            },
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<i className="fa-solid fa-magnifying-glass" />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText((selectedKeys as string[])[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </ConfigProvider>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (
      <i className="fa-solid fa-magnifying-glass " style={{ marginLeft: 5 }} />
    ),
  });

  const filteredPatients = allPatients?.filter(
    (patient: any) =>
      patient.tcipid
        .toLowerCase()
        .trim()
        .includes(searchQuery.toLocaleLowerCase().trim()) ||
      patient.patient
        .toLowerCase()
        .trim()
        .includes(searchQuery.toLocaleLowerCase().trim())
  );
  const columns: any = [
    {
      title: "Admission ID",
      dataIndex: "tcipid",
      ...getColumnSearchProps("tcipid"),
      render: (text: string, record: any) => (
        <Link className="hyperlink" to={`/inpatient-details/${record.tcipid}`}>
          {text}
        </Link>
      ),
      sorter: (a: { tcipid: string }, b: { tcipid: string }) =>
        a.tcipid.localeCompare(b.tcipid),
    },
    {
      title: "Patient Name",
      // dataIndex: "patient",
      // ...getColumnSearchProps("patient"),

      // render: (text: string) => <span>{text || "-"}</span>,
      // sorter: (a: { patient: string }, b: { patient: string }) =>
      //   a.patient.localeCompare(b.patient),
      render: (record: any) => (
        <Space direction="vertical" size={0}>
          {record?.patient && <Text strong>{record?.patient}</Text>}
          {record?.patientMobileNumber && (
            <Text type="secondary">{record?.patientMobileNumber}</Text>
          )}
        </Space>
      ),
      sorter: (a: { patient: string }, b: { patient: string }) =>
        a.patient?.length - b.patient?.length,
    },
    {
      title: "Room",
      key: "patient",
      // dataIndex: "roomNo",
      // render: (text: string) => <span>{text || "-"}</span>,
      render: (record: any) => (
        <Space direction="vertical" size={0}>
          {record?.roomNo && <Text strong>{record?.roomNo}</Text>}
          {record?.bedNo && <Text type="secondary">{record?.bedNo}</Text>}
        </Space>
      ),
      sorter: (a: { roomNo: string }, b: { roomNo: string }) =>
        a.roomNo?.length - b.roomNo?.length,
    },
    {
      title: "Admitted Date",
      dataIndex: "admissionDate",

      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (a: { admissionDate: string }, b: { admissionDate: string }) =>
        a.admissionDate?.length - b.admissionDate?.length,
    },
    {
      title: "Discharge Date",
      dataIndex: "dischargeDate",
      render: (text: string) => <span>{FormatDateTime(text)}</span>,
      sorter: (a: { dischargeDate: string }, b: { dischargeDate: string }) =>
        a.dischargeDate?.length - b.dischargeDate?.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <Tag color={IPStatusColorMap[text?.toUpperCase()]}>{text}</Tag>
      ),
      sorter: (a: { status: string }, b: { status: string }) =>
        a.status?.length - b.status?.length,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text: any, record: any) => (
    //     <Space>
    //       {isAllowed(PRIVILEGE.InPatient_View) && (
    //         <Link to={`/inpatient-details/${record.tcipid}`}>
    //           <span className="link">View</span>
    //         </Link>
    //       )}

    //       {/* <Link
    //         to={
    //           {
    //             pathname: `/discharge-summary/${record.tcipid}`,
    //             state: { formData: record }, // Pass formData as state
    //           } as any
    //         }
    //       >
    //         {record?.patientDischargeSummaryID ? (
    //           <span className="dislink">Discharged</span>
    //         ) : (
    //           <span className="link">Discharge</span>
    //         )}
    //       </Link>
    //       {isRoleBasedVisible([Role.Mod, Role.Admin]) &&
    //         record?.patientDischargeSummaryID === null && (
    //           <Link to={`/payment-details/${record.tcipid}`}>
    //             <span className="link">Pay</span>
    //           </Link>
    //         )} */}
    //     </Space>
    //   ),
    //   sorter: true,
    // },
  ];

  const emptyRow = (
    <tr>
      <td colSpan={columns.length}>No data available</td>
    </tr>
  );

  const handleInputChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    if (value != null) {
      setSearchQuery(value);
    }
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <Spin spinning={dischargeSummaryList} fullscreen /> */}

          <div className="row">
            <div className="col">
              <h3 className="page-title">Admissions</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="hyperlink" to="/dashboard">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Admissions</li>
              </ul>
            </div>
          </div>

          <div className="mt-3">
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <input
                    type="text"
                    // value={searchQuery}
                    onChange={handleInputChange}
                    className="form-control floating"
                    placeholder="Search by ID/Patient Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  pagination={filteredPatients?.length > 10 ? undefined : false}
                  loading={dischargeSummaryList}
                  columns={columns}
                  dataSource={filteredPatients?.map((patient: any) => patient)}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DischargeSummaryList;
