import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useGetOutPatientBYPatientIDQuery,
  usePostCancelAppointmentMutation,
  usePostOutPatientTimeSlotMutation,
} from "../../../services/ApiSlice";

const EditAppointment: React.FC<{}> = (porps) => {
  const { tcopid } = useParams();
  const navigate = useNavigate();
  const [postTimeSlotData, { isLoading: isPosting, error: postError }] =
    usePostOutPatientTimeSlotMutation();
  const [
    cancelAppointment,
    {
      isLoading: isCancelLoading,
      isError: isCancelError,
      isSuccess: isCancelSuccess,
    },
  ] = usePostCancelAppointmentMutation();
  const { data: allPatients = [] } = useGetOutPatientBYPatientIDQuery({
    patientID: null,
    locationID: 1,
  });

  useEffect(() => {
    if (isCancelSuccess) navigate("/");
  }, [isCancelSuccess]);

  const patientData = useMemo(
    () => allPatients?.find((option: any) => option?.tcopid === tcopid),
    [tcopid, allPatients]
  );

  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "No Payment Date";

    const date = new Date(dateString);
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en", options).format(date);

    const [month, day, year, time] = formattedDate.split(/[\s,]+/);
    const timeWithAmPm = time + " " + (date.getHours() >= 12 ? "PM" : "AM");
    return `${day}-${month}-${year} ${timeWithAmPm}`;
  };

  const doctorDetails: any = patientData
    ? [
        {
          DoctorName: patientData.doctorName,
          Email: patientData.docEmail,
          Mobile: patientData.docMobileNumber,
          Location: patientData.docLoaction,
          Experience: patientData.docExperience,
          Qualification: patientData.docQualification,
          Specialization: patientData.docSpecialization,
        },
      ]
    : [];

  const handleChangeAppointment = () => {
    navigate(
      `${patientData?.patientID}/book-appointment/${patientData?.assignedDoctorID}`
    );
  };

  const handleChangeAppointmentDoctor = () => {
    navigate(`${patientData?.patientID}/book-appointment`);
  };

  const handleCancelRequest = () => {
    if (tcopid)
      cancelAppointment({
        tcOPID: tcopid,
        status: "",
      });
  };

  const handleUpdate = async () => {
    try {
      const addResponse = await postTimeSlotData({
        ...patientData,
        appointmentTime: "2024-07-07T09:32:18.435Z",
      }).unwrap();
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Appointments</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Change Appointment</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body card-buttons">
                <div className="row">
                  <div className="col-md-12">
                    <div className="firstcol">
                      <div className="firstfield">
                        <div className="inputfield">
                          <p className="lblVal1">UHID:</p>
                          <label className="lblVal">
                            {patientData?.patientUHID}
                          </label>
                        </div>
                      </div>
                      <div className="firstfield">
                        <div className="inputfield">
                          <p className="lblVal1">Patient:</p>
                          <label className="lblVal">
                            {patientData?.patientName}
                          </label>
                        </div>
                        {/* <div className="inputfield">
                          <p className="lblVal1">Doctor Name</p>
                          <label className="lblVal">
                            {patientData.doctorName}{" "}
                          </label>
                        </div> */}
                      </div>
                      <div className="firstfield">
                        <div className="inputfield">
                          <p className="lblVal1">Mobile No:</p>
                          <label className="lblVal">
                            {patientData?.patientMobileNumber}
                          </label>
                        </div>
                        {/* <div className="inputfield">
                          <p className="lblVal1">Doctor Specialization:</p>
                          <label className="lblVal">{patientData.speciality}</label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <div className="lblVal1">
                    <h3 className="card-title">Doctor Information </h3>
                  </div>

                  <ul className="personal-info">
                    {Array.isArray(doctorDetails) ? (
                      doctorDetails.map((item: any, index: any) => (
                        <ListItem key={index} data={item} />
                      ))
                    ) : (
                      <ListItem data={doctorDetails} />
                    )}
                  </ul>
                  <div className="row">
                    <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-outline-success"
                          onClick={handleChangeAppointmentDoctor}
                        >
                          Change Doctor{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">Time Slot</h3>
                  <div className="inputfield">
                    <p className="lblVal1">Schedule Appointment:</p>
                    <label className="lblVal">
                      {patientData
                        ? formatDate(patientData?.admissionDate)
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="row mb-3 mx-1">
                  <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={handleChangeAppointment}
                      >
                        Change Appointment{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 mx-1">
              <div className="col-6 col-sm-4 col-md-2 col-xl mb-3 mb-xl-0">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleCancelRequest}
                  >
                    Cancel Request{" "}
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mx-3"
                aria-label="Close"
                onClick={handleUpdate}
              >
                Update
              </button>
            </div> */}
          </div>
        </div>
        {/* <div className={`slot-selector ${isSlotSelectorVisible ? 'visible' : ''}`}>
        <SlotSelector doctorID={patientData.assignedDoctorID}/>
        </div>
        */}
      </div>
    </>
  );
};
export const ListItem: React.FC<{ data: { [key: string]: string } }> = ({
  data,
}) => {
  const formatKey = (key: string) => {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  return (
    <>
      <div>
        {Object.entries(data).map(([key, value]) => (
          <li>
            <div className="title font-weight-bold">{formatKey(key)}</div>
            <div className="text">{value}</div>
          </li>
        ))}
      </div>
    </>
  );
};

export default EditAppointment;
