import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
  notification
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormatDateTime } from "../../../../Utils/FormatDateTime";
import { useRoleBasedVisible } from "../../../../hooks/role.hooks";
import { Role } from "../../../../models/PrivilegeRoles";
import { selectUser } from "../../../../reducers/UserSlice";
import {
  useDeleteNSMutation,
  useGetIPNSLookUpsQuery,
  useGetNSNurseQuery,
  useGetNSProcedureDetailsQuery,
  useGetProcedurestatusdropDownQuery,
  usePostNSDetailsMutation
} from "../../../../services/ApiSlice";
import { paymentStatusColorMap } from "../../../../models/Colors";



const { Title, Text } = Typography;

function NursingService({
  patientID,
  TCIPID,
}: {
  patientID: string;
  TCIPID: string;
}) {

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModelOpenEdit, setIsModelOpenEdit] = useState<boolean>(false);
  const [editFormInstance] = Form.useForm<any>();
  const [addformInstance] = Form.useForm<any>();
  const user = useSelector(selectUser);
  const isRoleBasedVisible = useRoleBasedVisible();
  const { confirm } = Modal;

  const ProcedureNameDropDown = useGetIPNSLookUpsQuery("");
  const ProcedureNurseDropDown = useGetNSNurseQuery("");
  const SelectStateDropDown = useGetProcedurestatusdropDownQuery("");
  const getnsDetails = useGetNSProcedureDetailsQuery(TCIPID ?? skipToken);
  const [postNsDetails, PostNsDetails] = usePostNSDetailsMutation();
  const [selectedRecord, setSelectedRecord] = useState<INSDetails | null>(null);
  const [deleteProcedure, ProcedureDelete] = useDeleteNSMutation();

  const currentDate = dayjs(new Date());

  const showModal = () => {
    setIsModalOpenAdd(true);
  };

  const selectProcedure = useMemo(
    () =>
      Array.isArray(ProcedureNameDropDown.data)
        ? ProcedureNameDropDown.data.map((item) => ({
          value: item?.nsProcedureMasterID,
          label: item.nsProcedureName,
        }))
        : [],
    [ProcedureNameDropDown]
  );

  const selectNurse = useMemo(
    () =>
      Array.isArray(ProcedureNurseDropDown.data)
        ? ProcedureNurseDropDown.data.map((item) => ({
          value: item?.himsEmployeeID,
          label: item.employeeName,
        }))
        : [],
    [ProcedureNurseDropDown]
  );

  const selectState = useMemo(
    () =>
      Array.isArray(SelectStateDropDown.data)
        ? SelectStateDropDown.data.map(({ dropDownSetID, dropDownValue }) => ({
          value: dropDownSetID,
          label: dropDownValue,
        }))
        : [],
    [SelectStateDropDown]
  );

  const addNSData = async () => {
    try {
      const values = await addformInstance.validateFields();
      postNsDetails({
        ...values,
        lastChangedBy: user?.himsEmployeeID,
        tcipid: TCIPID,
      });
      handleCancleAdd();
    } catch (error) {
      console.error("Failed to post data ", error);
    }
  };

  const handleCancleAdd = () => {
    setIsModalOpenAdd(false);
    addformInstance.resetFields();
    //editFormInstance.resetFields();
  };

  const handleDelete = (record: INSDetails) => {
    confirm({
      title: "Do you want to delete the procedure ?",
      centered: true,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        try {
          deleteProcedure({
            nsProcedureID: record?.nsProcedureID,
            modifiedBy: user?.himsEmployeeID,
          });
          if (ProcedureDelete.isSuccess) {
            notification["success"]({
              message: "Procedure Deleted",
              icon: <i className="las la-check-circle text-success"></i>,
            });
          }
        } catch (error) {
          console.error("Failed to delete data:", error);
        }
      },
    });
  };

  useEffect(() => {
    if (ProcedureDelete.isSuccess) {
      notification.success({
        message: "Success",
        description: "Successfully",
      });
    } else if (ProcedureDelete.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [ProcedureDelete]);

  const handleEdit = (record: INSDetails) => {
    // console.log('record', record)
    setIsModelOpenEdit(true);
    setSelectedRecord(record);
    // addformInstance.setFieldsValue(record);
    editFormInstance.setFieldsValue({ ...record, scheduledDate: dayjs(record.scheduledDate) });
  };

  const editNSDetails = async () => {
    if (selectedRecord) {
      try {
        const values = await editFormInstance.validateFields();
        postNsDetails({
          ...values,
          tcipid: TCIPID,
          lastChangedBy: user?.himsEmployeeID,
          nsProcedureID: selectedRecord?.nsProcedureID
        });
        onCancelEdit();
      } catch (error) {
        console.error("Failed to post data ", error);
      }
    }
  };
  const onCancelEdit = () => {
    setIsModelOpenEdit(false);
    addformInstance.resetFields();
    editFormInstance.resetFields();
    setSelectedRecord(null);
  };


  useEffect(() => {
    if (PostNsDetails.isSuccess) {
      notification.success({
        message: "Success",
        description: "Successfully",
      });
    } else if (PostNsDetails.isError) {
      notification.error({ message: "Error", description: "Service Failure" });
    }
  }, [PostNsDetails]);



  const admissionCols: TableProps<INSDetails>["columns"] = [
    {
      title: "Procedure Name",
      dataIndex: "nsProcedureName",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate", // TODO: verify key
      render: (date) => (FormatDateTime(date))
    },
    {
      title: "Procedure Status",
      dataIndex: "status",
      render: (text:string) => (
        <Tag color={paymentStatusColorMap[text?.toUpperCase()]}>
            {text}
        </Tag>),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedNurse",
    },
    {
      title: "Modified On",
      dataIndex: "modifiedDate",
      render: (date) => (FormatDateTime(date))
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
    },
    {
      title: "Action",
      key: "",
      render: (record) => (
        <Space>
          {record.status !== 'Completed' && <>
          <Link to="#">
            <span className="link "
              onClick={() => handleEdit(record)}
            >
              <i className="las la-pen"></i>
            </span>
          </Link>
          <Link to="#">
            <span
              className="link btnColor"
              onClick={() => handleDelete(record)}
            >
              <i className="las la-trash"></i>
            </span>
          </Link>
          </>}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      <Modal
        open={isModelOpenEdit}
        centered
        title="Update  Procedure"
        onCancel={onCancelEdit}
        onOk={editNSDetails}
        maskClosable={false}
      >
        <Form
          form={editFormInstance}
          disabled={PostNsDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="nsProcedureMasterID"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectProcedure}
              loading={ProcedureNameDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="assignedNurseID"
            label="Assigned To"
            rules={[
              { required: true, message: "Please Enter Assigned to" },
            ]}
          >
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              options={selectNurse}
              loading={ProcedureNurseDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label="Scheduled Date"
            rules={[
              { required: true, message: "Please Enter Scheduled Date" },
            ]}
          >
            {/* <DatePicker style={{ width: "100%" }} disabledDate={(d) => dayjs().isAfter(d)} /> */}
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              disabledDate={(d) => dayjs().isAfter(d)} 
              //disabledDate={(current) => current && current < dayjs().endOf('day')}
            />
          </Form.Item>
          <Form.Item
            name="statusID"
            label="Status"
          >
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              options={selectState}
              loading={SelectStateDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          {/* <Form.Item
            name="lastChangedBy"
            label="Edited Date"
            rules={[
              { required: true, message: "Please Enter Edited Date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} disabledDate={(d) => {
              const currentDate = dayjs().startOf('day');
              const selectedDate = dayjs(d).startOf('day');
              return !currentDate.isSame(selectedDate);
            }} />
          </Form.Item> */}
        </Form>
      </Modal>






      <Modal
        title={
          <Title level={3} style={{ textAlign: "center" }}>
            Select Procedure
          </Title>
        }
        open={isModalOpenAdd}
        onOk={addNSData}
        onCancel={handleCancleAdd}
        maskClosable={false}>
        <Form
          form={addformInstance}
          disabled={PostNsDetails.isLoading}
          layout="vertical"
        >
          <Form.Item
            name="nsProcedureMasterID"
            label="Procedure Name"
            rules={[{ required: true, message: "Please  Select Procedure" }]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectProcedure}
              loading={ProcedureNameDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="assignedNurseID"
            label="Assigned To"
            rules={[
              { required: true, message: "Please Enter Assigned to" },
            ]}
          >
            <Select
              placeholder="Select Procedure"
              style={{ width: "100%" }}
              options={selectNurse}
              loading={ProcedureNurseDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="scheduledDate"
            label="Scheduled Date"
            rules={[
              { required: true, message: "Please Enter Scheduled Date" },
            ]}
          >
            {/* <DatePicker style={{ width: "100%" }} disabledDate={(d) => dayjs().isAfter(d)} /> */}
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              disabledDate={(d) => dayjs().isAfter(d)} 
              //disabledDate={(current) => current && current < dayjs().endOf('day')}
            />
          </Form.Item>
          <Form.Item
            name="statusID"
            label="Status"
          >
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              options={selectState}
              loading={SelectStateDropDown.isFetching}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          {/* <Form.Item
            name="lastChangedBy"
            label="Edited Date"
            rules={[
              { required: true, message: "Please Enter Edited Date" },
            ]}
          >
            <DatePicker style={{ width: "100%" }} disabledDate={(d) => {
              const currentDate = dayjs().startOf('day');
              const selectedDate = dayjs(d).startOf('day');
              return !currentDate.isSame(selectedDate);
            }} />
          </Form.Item> */}
        </Form>
      </Modal>

      <Table
        loading={getnsDetails.isFetching}
        pagination={getnsDetails.data?.length < 10 ? false : undefined}
        columns={admissionCols}
        dataSource={getnsDetails.data}
        scroll={{ x: true }}
        // rowKey={(record) => record.id}
        title={() => (
          <Row gutter={24} justify="end">
            {isRoleBasedVisible([Role.Admin,Role.Nurse,Role.Doctor]) && (
              <Col>
                <Button onClick={showModal} type="primary">
                  Add Procedure
                </Button>
              </Col>
            )}
          </Row>
        )}
      />
    </Card>
  );
}

export default NursingService;

