/* eslint-disable no-unused-expressions */

import { Button, Col, Form, Input, Row, Table, notification } from "antd";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import { emailrgx } from "../Authentication/RegEx";
import { AddUserRole } from "../../../components/modelpopup/AddUserRole";
import {
  useGetDropdownQuery,
  useLazyGetHIMSEmployeeDetailsByEmailIDQuery,
  useGetHimsRolesQuery,
  usePostUpdateEmployeeRoleMutation,
} from "../../../services/ApiSlice";

const UserAction: React.FC<{}> = () => {
  const { Search } = Input;
  const {
    data: rolesGroupDropdown,
    error,
    isLoading,
  } = useGetHimsRolesQuery();
  const [
    getPatientByEmail,
    {
      data: employeeDetails,
      isFetching: isEmployeeDetailsFetching,
      isSuccess: isEmployeeDetailsSuccess,
    },
  ] = useLazyGetHIMSEmployeeDetailsByEmailIDQuery();
  const [postUpdatedEmployeeRole, { isLoading: isPosting, error: postError }] =
    usePostUpdateEmployeeRoleMutation();
  const [formData, setFormData] = useState("");
  const [formInstance] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (employeeDetails && employeeDetails.roleName) {
      setFormData(employeeDetails?.himsRoleID);
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (isEmployeeDetailsSuccess) {
      formInstance.resetFields();
    }
  }, [isEmployeeDetailsSuccess]);

  const options: OptionsOrGroups<any, GroupBase<any>> = useMemo(
    () =>
      Array.isArray(rolesGroupDropdown)
        ? rolesGroupDropdown.map((item: any) => ({
            id: item?.himsRoleID,
            value: item.himsRoleID,
            label: item.roleName,
          }))
        : [],
    [rolesGroupDropdown]
  );

  const customStyles = {
    option: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
    // menu: (provided: any) => ({
    //     ...provided,
    //     maxHeight: "100px", // Set the maximum height for the dropdown menu
    //     overflowY: "auto", // Enable vertical scrolling
    //   }),
  };

  const [users, setUsers] = useState([]);

  const handleChange: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as any;
    setFormData(value);
  };

  const handleUpdate = (himsEmployeeID: string) => {
    const data = {
      himsEmployeeID: himsEmployeeID,
      himsRoleID: formData,
    };
    postUpdatedEmployeeRole(data);
  };

  const columns: any = [
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (
        a: { lastName: string | any[] },
        b: { lastName: string | any[] }
      ) => a.lastName.length - b.lastName.length,
    },
    {
      title: "Mobile No",
      dataIndex: "phoneNumber",
      render: (text: string) => <span>{text || "-"}</span>,
      sorter: (
        a: { phoneNo: string | any[] },
        b: { phoneNo: string | any[] }
      ) => a.phoneNo.length - b.phoneNo.length,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      render: (text: any) => <span>{text || "-"}</span>,
      sorter: (
        a: { emailID: string | any[] },
        b: { emailID: string | any[] }
      ) => a.emailID.length - b.emailID.length,
    },
    {
      title: "Role Name",
      //   dataIndex: "roleName",
      render: (record: any) => (
        <div style={{ width: 200 }}>
          <Select
            options={options}
            placeholder="Select Role"
            styles={customStyles}
            value={options.find((option: any) => option.value === formData)}
            onChange={(selectedOption: any) => {
              handleChange({
                target: {
                  value: selectedOption.value,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <span
          className="link "
          onClick={() => {
            handleUpdate(record?.himsEmployeeID);
          }}
        >
          Update
        </span>
      ),
    },
  ];

  const handleSubmit = (values: any) => {
    getPatientByEmail(values.emailID);
    // notification["success"]({
    //   message: "Role Added",
    // });
  };

  return (
    <>
      {/* <Sidebar />
      <Header /> */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Role Management</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/roles">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Assign Role</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <Breadcrumbs
            maintitle="Role Management"
            title="Home"
            subtitle="User Action"
            modal="#add_user_role"
            name="Assign Role"
          /> */}
          <Row justify="space-between">
            <Col></Col>
            <Col>
              <Form onFinish={handleSubmit} form={formInstance}>
                <Form.Item
                  name="emailID"
                  rules={[
                    { required: true, message: "Please Enter Email Address" },
                    {
                      pattern: emailrgx,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                >
                  <Search
                    placeholder="Email"
                    enterButton={
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isEmployeeDetailsFetching}
                        style={{ backgroundColor: "#7465ad" }}
                      >
                        Check
                      </Button>
                    }
                    disabled={isEmployeeDetailsFetching}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {users ? (
                  <Table
                    columns={columns}
                    style={{ height: 500 }}
                    dataSource={employeeDetails ? [employeeDetails] : []}
                    className="table-striped"
                    loading={isEmployeeDetailsFetching}
                    //  rowKey={(record) => record.id}
                  />
                ) : (
                  "No Data"
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddUserRole />
    </>
  );
};

export default UserAction;
